import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import {ModalButtonDelete} from '../../components/Modal/ModalButton';


const DeleteContentModal = ({ data }) => {

	const {deleteModalState, onDeleteClose, handleDeleteContent, title} = data;

	return (
		<Modal show={deleteModalState}>
			<ModalHeading heading={title} onClose={onDeleteClose} />
			<ModalForm onSubmit={handleDeleteContent} style={{ marginBottom: '2.5rem' }}>
				<p className='modal__text' style ={{marginBottom:'1rem'}}>Are you sure you want to delete this record?</p>
				<ModalButtonDelete title='Confirm Delete' onClick={handleDeleteContent} />
			</ModalForm>
		</Modal>
	);
};

export default DeleteContentModal;
