import React from 'react';

const TimeInput = ({ label, value, onChange, error }) => {
	return (
		<div className='modal_input'>
			<label htmlFor={label} className='modal_input--label'>
				{label}
			</label>
			<input type='time' id={label} value={value} onChange={(e) => onChange(e)} className='modal_input--text' />
			{error && <p className='error_message'>{error}</p>}
		</div>
	);
};

export default TimeInput;
