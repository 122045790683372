export const ASSESSMENT_REQUEST = 'ASSESSMENT_REQUEST';
export const ASSESSMENT_SUCCESS = 'ASSESSMENT_SUCESSS';
export const ASSESSMENT_FAIL = 'ASSESSMENT_FAIL';
export const ASSESSMENT_RESET = 'ASSESSMENT_RESET';

export const GETUSERDETAILS_REQUEST = 'GETUSERDETAILS_REQUEST';
export const GETUSERDETAILS_SUCCESS = 'GETUSERDETAILS_SUCCESS';
export const GETUSERDETAILS_FAIL = 'GETUSERDETAILS_FAIL';
export const GETUSERDETAILS_RESET = 'GETUSERDETAILS_RESET';

export const PREVIOUS_ASSESSMENT_REQUEST = 'PREVIOUS_ASSESSMENT_REQUEST';
export const PREVIOUS_ASSESSMENT_SUCCESS = 'PREVIOUS_ASSESSMENT_SUCESSS';
export const PREVIOUS_ASSESSMENT_FAIL = 'PREVIOUS_ASSESSMENT_FAIL';
export const PREVIOUS_ASSESSMENT_RESET = 'PREVIOUS_ASSESSMENT_RESET';

export const SELF_ASSESSMENT_REQUEST = 'SELF_ASSESSMENT_REQUEST';
export const SELF_ASSESSMENT_SUCCESS = 'SELF_ASSESSMENT_SUCCESS';
export const SELF_ASSESSMENT_FAIL = 'SELF_ASSESSMENT_FAIL';
export const SELF_ASSESSMENT_RESET = 'SELF_ASSESSMENT_RESET';

export const QUESTION_RESULT_REQUEST = 'QUESTION_RESULT_REQUEST';
export const QUESTION_RESULT_SUCCESS = 'QUESTION_RESULT_SUCCESS';
export const QUESTION_RESULT_FAIL = 'QUESTION_RESULT_FAIL';
export const QUESTION_RESULT_RESET = 'QUESTION_RESULT_RESET';

export const NEXT_QUESTION_REQUEST = 'NEXT_QUESTION_REQUEST';
export const NEXT_QUESTION_SUCCESS = 'NEXT_QUESTION_SUCCESS';
export const NEXT_QUESTION_FAIL = 'NEXT_QUESTION_FAIL';
export const NEXT_QUESTION_RESET = 'NEXT_QUESTION_RESET';

export const PREVIOUS_QUESTION_REQUEST = 'PREVIOUS_QUESTION_REQUEST';
export const PREVIOUS_QUESTION_SUCCESS = 'PREVIOUS_QUESTION_SUCCESS';
export const PREVIOUS_QUESTION_FAIL = 'PREVIOUS_QUESTION_FAIL';
export const PREVIOUS_QUESTION_RESET = 'PREVIOUS_QUESTION_RESET';

export const SAVE_ANSWER_REQUEST = 'SAVE_ANSWER_REQUEST';
export const SAVE_ANSWER_SUCCESS = 'SAVE_ANSWER_SUCCESS';
export const SAVE_ANSWER_FAIL = 'SAVE_ANSWER_FAIL';
export const SAVE_ANSWER_RESET = 'SAVE_ANSWER_RESET';

export const REPORT_DTO_REQUEST = 'REPORT_DTO_REQUEST';
export const REPORT_DTO_SUCCESS = 'REPORT_DTO_SUCCESS';
export const REPORT_DTO_FAIL = 'REPORT_DTO_FAIL';
export const REPORT_DTO_RESET = 'REPORT_DTO_RESET';

export const GET_BMI_QUESTIONS_REQUEST = 'GET_BMI_QUESTIONS_REQUEST';
export const GET_BMI_QUESTIONS_SUCCESS = 'GET_BMI_QUESTIONS_SUCCESS';
export const GET_BMI_QUESTIONS_FAIL = 'GET_BMI_QUESTIONS_FAIL';
export const GET_BMI_QUESTIONS_RESET = 'GET_BMI_QUESTIONS_RESET';

export const CALCULATE_BMI_REQUEST = 'CALCULATE_BMI_REQUEST';
export const CALCULATE_BMI_SUCCESS = 'CALCULATE_BMI_SUCCESS';
export const CALCULATE_BMI_FAIL = 'CALCULATE_BMI_FAIL';
export const CALCULATE_BMI_RESET = 'CALCULATE_BMI_RESET';

export const UPDATE_USER_DETAILS_REQUEST = 'UPDATE_USER_DETAILS_REQUEST';
export const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS';
export const UPDATE_USER_DETAILS_FAIL = 'UPDATE_USER_DETAILS_FAIL';
export const UPDATE_USER_DETAILS_RESET = 'UPDATE_USER_DETAILS_RESET';

export const SUMMARY_PDF_REQUEST = 'SUMMARY_PDF_REQUEST';
export const SUMMARY_PDF_SUCCESS = 'SUMMARY_PDF_SUCCESS';
export const SUMMARY_PDF_FAIL = 'SUMMARY_PDF_FAIL';
export const SUMMARY_PDF_RESET = 'SUMMARY_PDF_RESET';
