import {
    GET_PAYMENT_PRODUCTS_FAIL,
    GET_PAYMENT_PRODUCTS_REQUEST,
    GET_PAYMENT_PRODUCTS_RESET,
    GET_PAYMENT_PRODUCTS_SUCCESS
} from "../constants/paymentConstants";

export const getProductsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PAYMENT_PRODUCTS_REQUEST:
            return { loading: true };

        case GET_PAYMENT_PRODUCTS_SUCCESS:
            return { data: action.payload, loading: false };

        case GET_PAYMENT_PRODUCTS_FAIL:
            return { loading: false, error: action.payload };

        case GET_PAYMENT_PRODUCTS_RESET:
            return {};

        default:
            return state;
    }
}; 