import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { DeleteClientInput } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonDelete } from '../../components/Modal/ModalButton';
import ModalTextInput from '../../components/Modal/ModalTextInput';
import DeleteInvite from '../../components/Skeletons/DeleteInvite'
const DeleteClient = ({ deleteModalState, onDeleteClose, data}) => {

	const {deleteData, setDeleteData, handleDeleteClient, clinicName, deleteClient} = data;
	return (

		<Modal className='modal__Container' show={deleteModalState}>
			<ModalHeading heading='Delete Client' onClose={onDeleteClose} />
				{
					deleteClient.loading?
					<DeleteInvite />
					:
				<ModalForm onSubmit={handleDeleteClient} style={{ marginBottom: '2.5rem' }}>
				<DeleteClientInput style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
					<h4>
						Please enter <span style={{ color: 'var(--red)', fontStyle: 'italic' }}>{clinicName}</span> in
						the text box
					</h4>
					<ModalTextInput type='text' icon='/sprite.svg#icon-phone'
					id='Email'
					value={deleteData.deleteInput}
					onChange={(e)=> {
					setDeleteData({...deleteData, deleteInput: e.target.value, deleteInputError: ''})
					}}/>
						{
							deleteData.deleteInputError?
							<p
							className="error_message__deleteClient"
							>{deleteData.deleteInputError}</p>
						: null
						}
					<h5
					style={{ color: 'var(--red)' }}>
						Please note, this will delete all client data permanently and will not be recovered.
					</h5>
				</DeleteClientInput>

				<ModalButtonDelete title='Delete Client' onClick={handleDeleteClient} />
			</ModalForm>
			}

		</Modal>
	);
};

export default DeleteClient;
