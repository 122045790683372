import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';

const PDFDetailsModal = ({ data, onClose }) => {
	return (
		<Modal show={data.status}>
			<ModalHeading heading={data.title} onClose={onClose} />
			<div className="modalContainer__form">
				<iframe
					src={data.pdfLink}
					type="application/pdf"
					title="suremed-tnc"
					style={{ height: '60vh', width: '60vw', borderRadius: '2rem' }}
				/>
			</div>
		</Modal>
	);
};

export default PDFDetailsModal;
