import React from 'react';

import TableRows from '../../components/Skeletons/TableRows';

const ContentTable = ({ items, data }) => {
	const {uploadHistory} = data; 
	return (
		<div className='tableContainer' style={{ height: '75vh', marginTop: '0' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>User Name</th>
						<th>Title</th>
						<th>Type</th>
						<th>Start Date</th>
						<th>End Date</th>
						<th>Total Time</th>
					</tr>
				</thead>
				<tbody>
					{
						uploadHistory && uploadHistory.loading?
						<TableRows columns='13'/>
						:
						uploadHistory && uploadHistory.token &&
						uploadHistory.token.map((item) => (
							<tr >
								<td></td>
								<td>{item.firstname + ' ' + item.lastname}</td>
								<td>{item.title}</td>
								<td className='table__iconBox'>
									{item.type === 'Video' ? (
										<button className='table__buttonBig'>
											<svg className='table__button--iconBigBlack'>
												<use xlinkHref={`/sprite.svg#icon-play-circle`} />
											</svg>
											<span>Video</span>
										</button>
									) : (
										<button className='table__buttonBig'>
											<svg className='table__button--iconBigBlack'>
												<use xlinkHref={`/sprite.svg#icon-pdf`} />
											</svg>
											<span>PDF</span>
										</button>
									)}
								</td>
								<td>{item.startdate}</td>
								<td>{item.enddate}</td>
								<td>{item.totalcount}</td>
							</tr>
						))}
				</tbody>
			</table>
		</div>
	);
};

export default ContentTable;
