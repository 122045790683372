import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { LoginUserSkeleton } from '../../../components/Skeletons/manageUsers';

const LoginAs = ({ data }) => {
	const { modalLoginAdminState, loginAsUserData, onEditClose, adminLoginHandler, adminLogin, Highestrole } = data;

	return (
		<Modal show={modalLoginAdminState}>
			<ModalHeading
			heading={`Login as ${Highestrole}`} onClose={onEditClose}
			/>
			{adminLogin && adminLogin.loading ? (
					<LoginUserSkeleton />
				) : (
			<Fragment>
				<form className='modalContainer__form'>
					<div className='filter__input--labels'>
						<p className='modal__text'>
							Are you sure you want to login with <span>{loginAsUserData.patientEmail}</span> ?
						</p>
					</div>
				</form>
				<div className='modal__actions'>

					<button className='modal__button modal__button--delete' onClick={adminLoginHandler}>
						Yes, please
					</button>
				</div>
			</Fragment>
				)}
		</Modal>
	);
};

export default LoginAs;
