import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { validateEmail, validatePassword } from '../../utils/validators';
import { registerClinicNoInviteAction } from '../../redux/actions/clinicActions';
import { REGISTER_CLINIC_NO_INVITE_RESET } from '../../redux/constants/clinicsConstants';
import RegisterNoInviteSkeleton from '../../components/Skeletons/RegisterNoInviteSkeleton';

function Index({ history }) {
  const dispatch = useDispatch();
  const registerClinicNoInvite = useSelector(
    (state) => state.registerClinicNoInvite
  );

  const [data, setData] = useState({
    email: '',
    password: '',
    cPassword: '',
  });
  const [dataError, setDataError] = useState({
    email: '',
    password: '',
    cPassword: '',
  });
  const [currentEye, setCurrentEye] = useState(false);
  const [confirmCurrentEye, setConfirmCurrentEye] = useState(false);

  const showPassword = () => {
    var x = document.getElementById('password');
    setCurrentEye(!currentEye);
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };
  const showConfirmPassword = () => {
    var y = document.getElementById('confirm-password');
    setConfirmCurrentEye(!confirmCurrentEye);
    if (y.type === 'password') {
      y.type = 'text';
    } else {
      y.type = 'password';
    }
  };

  useEffect(() => {
    if (
      registerClinicNoInvite &&
      registerClinicNoInvite.data &&
      registerClinicNoInvite.data.succeeded === true
    ) {
      history.push('/user/verify-email');
    } else if (registerClinicNoInvite && registerClinicNoInvite.error !== '') {
      toast.error(registerClinicNoInvite.error);
      setDataError({ ...dataError, cPassword: registerClinicNoInvite.error });
    }
    //eslint-disable-next-line
  }, [registerClinicNoInvite, history]);

  useEffect(() => {
    return () => {
      dispatch({ type: REGISTER_CLINIC_NO_INVITE_RESET });
    };
  }, [dispatch]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (data.cPassword === data.password) {
      if (!data.email.trim() || data.email.trim() === '') {
        setDataError({ ...dataError, email: 'Please enter an email' });
      } else if (!validateEmail(data.email)) {
        setDataError({ ...dataError, email: 'Please enter a valid email' });
      } else if (!data.password.trim() || data.password.trim() === '') {
        setDataError({ ...dataError, password: 'Please enter a password' });
      } else if (!validatePassword(data.password)) {
        setDataError({
          ...dataError,
          password:
            'Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.',
        });
      } else {
        let Email = data.email;
        let Password = data.password;
        dispatch(registerClinicNoInviteAction(Email, Password));
      }
    } else {
      setDataError({ ...dataError, cPassword: 'Password does not match' });
    }
  };

  return (
    <main className='loginContainer'>
      <div className='loginContainer__bg' />
      <div className='registerContainer__box'>
        <div className='loginContainer__box--right'>
          <img
            src='/images/logo.png'
            alt='Suremed Logo'
            className='loginContainer__box--logo'
          />
          <h1 className='loginContainer__heading'>ENROLL CLINIC</h1>
          {/* <h1 className='loginContainer__subheading'>Invite Required</h1> */}
        </div>
        <div className='registerClinic--noInvite__box--right'>
          <p className='clinic-warning__message'>
            This enrollment page is strictly for Clinics and Health Care
            Providers. If you are a patient, please use this{' '}
            <a
              className='Patient--warning--link'
              href='https://myportal.perspectivesincare.com/#/register/register-patient'
            >
              {' '}
              link
            </a>{' '}
            to enroll with your clinic pin.
            {/* This enrollment page is strictly for Clinics and Health Care Providers. If you are a patient, please use this <a className='Patient--warning--link' href='https://portal.perspectivesincare.com/register/register-patient'> link</a> to enroll with your clinic pin. */}
          </p>
          <p
            className='clinic-warning__message'
            style={{ paddingBottom: '2rem' }}
          >
            Enrolling here will not register you as a patient, therefore it will
            not allow you to fulfill the requirements set by your Health Care
            Provider and may delay your appointments.
          </p>
          {registerClinicNoInvite && registerClinicNoInvite.loading ? (
            <RegisterNoInviteSkeleton />
          ) : (
            <form className='registerClinic--noInvite__form'>
              <div className='addressContainer__formgroup--inputBox'>
                <div className='logoContainer__formgroup--iconBox'>
                  <svg className='loginContainer__formgroup--icon'>
                    <use xlinkHref='/sprite.svg#icon-email' />
                  </svg>
                </div>
                <input
                  type='text'
                  placeholder='Clinic Email'
                  id='email'
                  value={data.email}
                  onChange={(e) => {
                    setData({ ...data, email: e.target.value });
                    setDataError('');
                  }}
                  className='loginContainer__formgroup--input'
                />
              </div>
              <p className='error_message' style={{ marginLeft: '1.5rem' }}>
                {dataError.email}
              </p>
              <div className='addressContainer__formgroup--inputBox'>
                <div className='logoContainer__formgroup--iconBox'>
                  <svg className='loginContainer__formgroup--icon'>
                    <use xlinkHref='/sprite.svg#icon-key' />
                  </svg>
                </div>
                <input
                  type='password'
                  autoComplete='new-password'
                  placeholder='Choose Password'
                  id='password'
                  value={data.password}
                  onChange={(e) => {
                    setData({ ...data, password: e.target.value });
                    setDataError('');
                  }}
                  className='loginContainer__formgroup--input'
                />
                <svg
                  style={{ margin: '1rem' }}
                  className='loginContainer__formgroup--icon'
                  onClick={showPassword}
                >
                  {currentEye ? (
                    <use xlinkHref={`/sprite.svg#icon-hidePassword`} />
                  ) : (
                    <use xlinkHref={`/sprite.svg#icon-showPassword`} />
                  )}
                </svg>
              </div>
              <p className='error_message' style={{ marginLeft: '1.5rem' }}>
                {dataError.password}
              </p>
              <div className='addressContainer__formgroup--inputBox'>
                <div className='logoContainer__formgroup--iconBox'>
                  <svg className='loginContainer__formgroup--icon'>
                    <use xlinkHref='/sprite.svg#icon-key' />
                  </svg>
                </div>
                <input
                  type='password'
                  autoComplete='new-password'
                  placeholder='Confirm Password'
                  id='confirm-password'
                  value={data.cPassword}
                  onChange={(e) => {
                    setData({ ...data, cPassword: e.target.value });
                    setDataError('');
                  }}
                  className='loginContainer__formgroup--input'
                />
                <svg
                  style={{ margin: '1rem' }}
                  className='loginContainer__formgroup--icon'
                  onClick={showConfirmPassword}
                >
                  {confirmCurrentEye ? (
                    <use xlinkHref={`/sprite.svg#icon-hidePassword`} />
                  ) : (
                    <use xlinkHref={`/sprite.svg#icon-showPassword`} />
                  )}
                </svg>
              </div>
              <p className='error_message' style={{ marginLeft: '1.5rem' }}>
                {dataError.cPassword}
              </p>

              <div className='registerContainer__formgroup--btn'>
                <button
                  className='registerContainer__button'
                  onClick={handleSubmit}
                >
                  <span className='loginContainer__button--text'>Submit</span>
                  <div className='loginContainer__button--iconBox'>
                    <svg className='loginContainer__button--icon'>
                      <use xlinkHref='/sprite.svg#icon-login' />
                    </svg>
                  </div>
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </main>
  );
}

export default Index;
