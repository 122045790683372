import React from 'react';
import classes from '../Notifications.module.css';

const TextBox = ({ label, id, placeholder, value, onChange, error }) => {
  return (
    <div className={classes.textBox}>
      <label htmlFor={id} className={classes.textBox_label}>
        {label}
      </label>
      <input type='text' id={id} placeholder={placeholder} value={value} onChange={onChange} className={classes.textBox_input} />
      {error && <p className={classes.textBox_error}>{error}</p>}
    </div>
  );
};

export default TextBox;
