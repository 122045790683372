import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom' 
import { logout, verfiyPatientAction } from '../../redux/actions/userActions'
import './VerifyEmail.css'
// import { toast } from 'react-toastify';

const VerifyPatient = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { verfiyCode } = useParams();
    // const verifiedUser = useSelector((state) => state.verifiedUser); 

    useEffect(() => { 
        dispatch(verfiyPatientAction({
            "verificationToken": verfiyCode
        }))
    }, [dispatch]);

    // useEffect(() => {
    //     dispatch(logout()) 
    //     if (verifiedUser && verifiedUser.error !== '') {
    //         toast.error(verifiedUser.error) 
    //     }
    //      else if (verifiedUser && !verifiedUser.loading) {
    //         // history.push('/login')
    //         setTimeout(() => { 
    //             window.close();
    //         }, 2500);
    //     }
    // }, [verifiedUser, history])


    return (
        <div className='verifyEmail__Container'>
           <h1>Email Verified.</h1>
           <p>Now you can log in to take your Perspectives in Care assessment. </p>
            {/* <h1>Please Verify</h1>
            <p>Please check your email to verify your address. </p>
            <p>If you do not see a verification email, please check your spam or trash folders.</p> */}
            {/* <Link className='verifyEmail__Button' to='/login' onClick={() => dispatch(logout())}>Back to Log in</Link> */}
        </div>
    )
}

export default VerifyPatient
