import React from 'react';

const ModalRadioButtons = ({ label, radioButtons, onChange, error, style, containerStyle }) => {

	return (
		<div className='modal_input' style={containerStyle}>
			<p className='modal_input--label'>{label}</p>
			<div className='modal__radioButtons' style={style}>
				{radioButtons &&
					radioButtons.map((radioButton, index) => (
						<div key={index + 1} className='modal__radioButton'>
							<input
								type='radio'
								id={radioButton.label}
								name={label}
								value={radioButton.value}
								// checked={checked === radioButton.value}
								onChange={(e) => onChange(e)}
								className='modal__radioButton--input'
							/>
							<span className='modal__radioButton--radio' />
							<label htmlFor={radioButton.label} className='modal__radioButton--label'>
								{radioButton.label}
							</label>
						</div>
					))}
			</div>
			{error && <p className='error_message'>{error}</p>}
		</div>
	);
};


export const ModalRadioCardButtons = ({ icon, label, value, checked, onChange, error, style }) => {

	return (
		<div className='modal_input' style={{ paddingTop: '1.5rem', gap: '2rem' }}>
			<div className='modal__radioButtons' style={style}>
				<div className='modal__radioButton' style={{alignItems:'start'}}>
					<input
						type='radio'
						id={label}
						name={label}
						value={value}
						checked={true}	//checked={checked === value} 
						onChange={(e) => ''} //onChange={(e) => onChange(e)}
						className='modal__radioButton--input'
					/>
					<span className='modal__radioButton--radio' />
					<label htmlFor={label} className='modal__radioButton--label' style={{ marginLeft: '1rem', fontSize: '2rem', fontWeight: '500', display: 'flex', gap: '1rem', marginTop: '-8px' }} s>
						{label}
						<svg className={'brand__icon'}>
							<use xlinkHref={`/sprite.svg#icon-${icon}`} />
						</svg>
					</label>
				</div>
			</div>
			{error && <p className='error_message'>{error}</p>}
		</div>
	);
};

export default ModalRadioButtons;
