import React from 'react';

export const InputsSection = ({ children, style }) => {
	return <div className='modalContainer__form--section' style={style}>{children}</div>;
};
export const InputsSection2 = ({ children, style }) => {
	return <div className='modalContainer__form--section-2' style={style}>{children}</div>;
};
export const InputsUpload = ({ children, style }) => {
	return <div className='modalContainer__form--section-2' style={style}>{children}</div>;
}
export const DeleteClientInput = ({ children, style }) => {
	return <div className='modalContainer__form--deleteInput' style={style}>{children}</div>;
}