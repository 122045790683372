import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/actions/userActions';
import { toggleMenu } from '../Header';

const MainSidebar = ({ current = 'none' }) => {
	const dispatch = useDispatch();

	return (
		<Fragment>
			<nav className='navigation' style={{ zIndex: 500 }}>
				<div>
					<div className='navigation__logo--container'>
						<img src='/images/logo-dark.png' alt='logo' className='navigation__logo' />
						<button className='sidebarnav__button' onClick={toggleMenu}>
							<svg className='sidebarnav__icon'>
								<use xlinkHref={`/sprite.svg#icon-menu`} />
							</svg>
						</button>
					</div>

					<img src='/images/favicon.png' alt='logo' className='navigation__logo2' />
					<ul className='navigation__list'>
						<div className={`${current === 'dashboard' && 'currentNav'}`}>
							<li className='navigation__list--item'>
								<Link to='/dashboard' className='navigation__list--link' id='dashboardLinkIcon'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/sprite.svg#icon-dashboard`} />
									</svg>
									&nbsp;
								</Link>
							</li>
							<li className='navigation__list--itemHover'>
								<Link to='/dashboard' className='navigation__list--link' id='dashboardLink'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/sprite.svg#icon-dashboard`} />
									</svg>
									<span>Dashboard</span>
								</Link>
							</li>
							{current === 'dashboard' && <div className='activeNav' />}
						</div>
						<div className={`${current === 'manage-clients' && 'currentNav'}`}>
							<li className='navigation__list--item'>
								<Link to='/manage-clients' className='navigation__list--link' id='manageclienticon'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/sprite.svg#icon-doctor`} />
									</svg>
									&nbsp;
								</Link>
							</li>
							<li className='navigation__list--itemHover'>
								<Link to='/manage-clients' className='navigation__list--link' id='manageclient'>
									<svg className='navigation__icon'>
										<use xlinkHref={`/sprite.svg#icon-doctor`} />
									</svg>
									<span>Manage Clients</span>
								</Link>
							</li>
							{current === 'manage-clients' && <div className='activeNav' />}
						</div>
					</ul>
				</div>
				<ul className='navigation__list' style={{ marginTop: '0', marginBottom: '3rem' }}>
					<div className={`${current === 'my-profile' && 'currentNav'}`}>
						<li className='navigation__list--item'>
							<Link to='/my-profile' className='navigation__list--link' id='myProfileLinkIcon'>
								<svg className='navigation__icon2'>
									<use xlinkHref={`/sprite.svg#icon-user`} />
								</svg>
								&nbsp;
							</Link>
						</li>
						<li className='navigation__list--itemHover'>
							<Link to='/my-profile' className='navigation__list--link2'  id='myProfileLink'>
								<svg className='navigation__icon2'>
									<use xlinkHref={`/sprite.svg#icon-user`} />
								</svg>
								<span>My Profile</span>
							</Link>
						</li>
						{current === 'my-profile' && <div className='activeNav' />}
					</div>
					<div>
						<li className='navigation__list--item'>
							<Link to='/login' className='navigation__list--link'  id='logoutLinkIcon' onClick={() => dispatch(logout())}>
								<svg className='navigation__icon2'>
									<use xlinkHref={`/sprite.svg#icon-logout`} />
								</svg>
								&nbsp;
							</Link>
						</li>
						<li className='navigation__list--itemHover'>
							<Link  to='/login' onClick={() => dispatch(logout())} id='logoutLink' className='navigation__list--link2'>
								<svg className='navigation__icon2'>
									<use xlinkHref={`/sprite.svg#icon-logout`} />
								</svg>
								<span>Logout</span>
							</Link>
						</li>
					</div>
					<div className='navigation__list--copyrightHidden'>
						<span>&nbsp;</span>
					</div>
					<div className='navigation__list--copyright'>
						<span>&copy; {new Date().getFullYear()} Sure Med Compliance</span>
					</div>
				</ul>
				{/* <div className='toggle' onClick={toggleMenu} style={{ zIndex: 510 }} /> */}
			</nav>
		</Fragment>
	);
};

export default MainSidebar;
