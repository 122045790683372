import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import { InputsSection } from '../../../components/Modal/InputsSection';
import { ModalButtonPrimary } from '../../../components/Modal/ModalButton';
import ModalForm from '../../../components/Modal/ModalForm';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { ModalTextBox1, ModalDateBox, ModalSelectBox } from '../../../components/Modal/ModalTextBox';
import { EditUserSkeleton } from '../../../components/Skeletons/manageUsers';

const EditModal = ({ data }) => {
  const { modalEditState, updateUser, setupdateUser, onEditClose, editEmailHandler, updatemanageUser, clinicProviders, allClinics, setupdateUserConfirm } = data;
  return (
    <Modal show={modalEditState}>
      <ModalHeading heading='Edit User Details' onClose={onEditClose} />
      {updatemanageUser && updatemanageUser.loading ? (
        <EditUserSkeleton />
      ) : (
        <Fragment>
          <ModalForm>
            <InputsSection>
              <ModalTextBox1
                label='First Name'
                placeholder='First Name'
                value={updateUser.fName}
                icon={`/sprite.svg#icon-user`}
                onChange={event => {
                  setupdateUser({ ...updateUser, fName: event.target.value });
                }}
                error=''
              />
              <ModalTextBox1
                label='Last Name'
                placeholder='Last Name'
                value={updateUser.lName}
                icon={`/sprite.svg#icon-user`}
                onChange={event => {
                  setupdateUser({ ...updateUser, lName: event.target.value });
                }}
                error=''
              />
            </InputsSection>
            <InputsSection>
              <ModalTextBox1
                label='Email'
                placeholder='Email'
                icon={`/sprite.svg#icon-email`}
                value={updateUser.email}
                onChange={event => {
                  setupdateUser({ ...updateUser, email: event.target.value });
                }}
                error={updateUser.error && <p className='error_message'>{updateUser.error}</p>}
              />

              <ModalDateBox
                label='Date of Birth'
                value={updateUser.birthDate}
                onChange={event => {
                  setupdateUser({ ...updateUser, birthDate: event.target.value, birthDateerror: '' });
                }}
                error={updateUser.birthDateerror && updateUser.birthDateerror}
              />
            </InputsSection>
            <InputsSection>
              <ModalSelectBox
                label='Select Clinic'
                value={updateUser.clinic}
                onChange={event => {
                  setupdateUser({ ...updateUser, clinic: event.target.value, clinicError: '', provider: '' });
                  let clinicName = '';
                  allClinics &&
                    allClinics.data &&
                    allClinics.data.map(clin => {
                      if (clin.clinicId === event.target.value) clinicName = clin.name;
                    });
                  setupdateUserConfirm(prevVal => ({ ...prevVal, newClinic: clinicName }));
                }}
                error={updateUser.clinicError && <p className='error_message'>{updateUser.clinicError}</p>}
              >
                {allClinics &&
                  allClinics.data &&
                  allClinics.data.map(clinic =>
                    clinic.name && !clinic.isDeleted ? (
                      <option value={clinic.clinicId} key={clinic.clinicId}>
                        {clinic.name}
                      </option>
                    ) : null
                  )}
              </ModalSelectBox>

              <ModalSelectBox
                label='Select Provider'
                value={updateUser.provider}
                onChange={event => {
                  setupdateUser({ ...updateUser, provider: event.target.value, providerError: '' });
                  let providerName = '';
                  clinicProviders &&
                    clinicProviders.data &&
                    clinicProviders.data
                      .filter(prov => prov.clinicId === updateUser.clinic)
                      .map(provider => {
                        if (provider.providerId === event.target.value) providerName = provider.displayName;
                      });
                  setupdateUserConfirm(prevVal => ({ ...prevVal, newProvider: providerName }));
                }}
                error={updateUser.providerError && <p className='error_message'>{updateUser.providerError}</p>}
              >
                <option value=''>Select Provider</option>
                {clinicProviders &&
                  clinicProviders.data &&
                  clinicProviders.data
                    .filter(prov => prov.clinicId === updateUser.clinic)
                    .map(provider => (
                      <option value={provider.providerId} key={provider.providerId}>
                        {provider.displayName}
                      </option>
                    ))}
              </ModalSelectBox>
            </InputsSection>
            <ModalButtonPrimary className='modal__actions' title='Update' onClick={editEmailHandler} />
          </ModalForm>
        </Fragment>
      )}
    </Modal>
  );
};

export default EditModal;
