import React from 'react'
import { useDispatch } from 'react-redux'; 
import { signupPdfAction } from '../../redux/actions/providerActions'
import TableRows from '../../components/Skeletons/TableRows';
import { useEffect } from 'react';

export default function ShowData({ showFilter, data, setShowBack }) {
	const dispatch = useDispatch();

	const userIndex = 1;

	const {
		clinicProvider
	} = data;


	return (
		<div className='tableContainer' style={{ height: '63vh' }}>
			<table className='table'>
				<thead >
					<tr>
						<th style={{ textAlign: 'center' }}>#</th>
						<th style={{ textAlign: 'center' }}>Name</th>
						<th style={{ textAlign: 'center' }}>Gender</th>
						<th style={{ textAlign: 'center' }}>Credential</th>
						<th style={{ textAlign: 'center' }}>Provider/Clinic Pin</th>
						<th style={{ textAlign: 'center' }}>Download Form</th>
					</tr>
				</thead>
				<tbody>
					{
						clinicProvider &&
							!clinicProvider.loading ?
							clinicProvider.data &&
								clinicProvider.data.length > 0 ? (
								clinicProvider.data.map((user, index) => (
									<tr key={index + 1} >
										<td style={{ textAlign: 'center' }}>{userIndex + index}</td>
										<td style={{ textAlign: 'center' }}> {user.displayName}</td>
										<td style={{ textAlign: 'center' }}>{user.gender}</td>
										<td style={{ textAlign: 'center' }}>{user.credentials}</td>
										<td style={{ textAlign: 'center' }}>{user.signUpCode}</td>
										<td>
											<div className='table__iconBox'
												style={{ display: 'flex', justifyContent: 'center' }}
											>
												<button
													onClick={() => dispatch(signupPdfAction(user.providerId, user.signUpCode))}
													className='table__button2' style={{ marginRight: '0.5rem' }}>
													<svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
														<use xlinkHref={`/sprite.svg#icon-pdf`} />
													</svg>
													<span>Download Form</span>
												</button>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan='13'>No users found</td>
								</tr>
							) : (
								<TableRows columns='13' />
							)}
				</tbody>
			</table>
		</div>
	)
}
