import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import TextAreaInput from '../../components/Modal/TextAreaInput';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import ModalTextInput from '../../components/Modal/ModalTextInput';
import ModalRadioButtons from '../../components/Modal/ModalRadioButtons';
import InputsSections from '../../components/Modal/InputsSections';
import UploadInput from '../../components/Modal/UploadInput';
import { UploadContentSkeleton } from '../../components/Skeletons/ManageClient';

const UploadContent = ({ modalState, onClose, shortDesc, setShortDesc, contentType, setContentType, handleUpload, data }) => {

	const { uploadContent, setUploadContent, uploadContentError, setUploadContentError, mediaName, setMediaName, uploadContentDetails,partnerContentDetails } = data;

	function fileValidation(event) {
		setUploadContentError({ ...uploadContentError, file: '' })
		if (uploadContent.isVideo === 'video') {
			const allowedExtensions = ['mp4'];
			const { name: fileName, size: fileSize } = event.target.files[0];
			const file_size = fileSize / 1024 / 1024; // in MiB  
			const fileExtension = fileName.split(".").pop();
			if (!allowedExtensions.includes(fileExtension)) {
				setUploadContentError({ ...uploadContentError, file: 'Selected file is Invalid.' });
				setUploadContent({ ...uploadContent, file: '' })
			} else if (file_size > 20) {
				alert('Maximum upload file size: 20MB');
			} else {
				setMediaName(fileName);
				setUploadContent({ ...uploadContent, file: event.target.files[0] })
			}
		} else if (uploadContent.isVideo === 'pdf') {
			const allowedExtensions = ['pdf']
			const { name: fileName, size: fileSize } = event.target.files[0];
			const file_size = fileSize / 1024 / 1024; // in MiB  
			const fileExtension = fileName.split(".").pop();
			if (!allowedExtensions.includes(fileExtension)) {
				setUploadContentError({ ...uploadContentError, file: 'Selected file is Invalid.' })
			} else if (file_size > 5) {
				alert('Maximum upload file size: 5MB');
			} else {
				setMediaName(fileName);
				setUploadContent({ ...uploadContent, file: event.target.files[0] })
			}
		} else if (uploadContent.isVideo === 'disclosure') {
			const allowedExtensions = ['pdf']
			const { name: fileName, size: fileSize } = event.target.files[0];
			const file_size = fileSize / 1024 / 1024; // in MiB   
			const fileExtension = fileName.split(".").pop();
			if (!allowedExtensions.includes(fileExtension)) {
				setUploadContentError({ ...uploadContentError, file: 'Selected file is Invalid.' })
			} else if (file_size > 5) {
				alert('Maximum upload file size: 5MB');
			} else {
				setMediaName(fileName);
				setUploadContent({ ...uploadContent, file: event.target.files[0] })
			}
		} else {
			if (!uploadContent.url.trim() || uploadContent.url.trim() === '') {
				setUploadContentError({ ...uploadContentError, url: 'Please enter a URL' });
			}
		}
	}

	return (
		<Modal show={modalState}>
			<ModalHeading heading='Upload Content' onClose={onClose} />
			{
				uploadContentDetails && uploadContentDetails.loading ?
					<UploadContentSkeleton />
					:
					<ModalForm onSubmit={handleUpload} style={{ marginBottom: '2.5rem' }}>
						<InputsSection
							style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', }}
						>
							<ModalTextInput
								width={"22rem"}
								label='Content Title'
								placeholder='Content Title'
								value={UploadContent.title}
								onChange={(e) => {
									setUploadContent({ ...uploadContent, title: e.target.value })
									setUploadContentError({ ...uploadContentError, title: '' })
								}}
								error={uploadContentError.title}
							/>
							<div className='modal_input--deleteClient' style={{ width: '22rem', display: 'flex', flexDirection: 'column' }}>
								<label className='modal_input--label'>
									Content Partner
								</label>
								<div className='modal_dropdown--box'>
									<select
										className='filter__input--select'
										value={UploadContent.category}
										onChange={(e) => {
											setUploadContent({ ...uploadContent, category: e.target.value })
											setUploadContentError({ ...uploadContentError, category: '' })
										}}
										name='partner'
										id='partner'>
										<option value=''>Select Partner</option>
										{partnerContentDetails &&
											partnerContentDetails.data &&
											partnerContentDetails.data.length > 0 &&
											partnerContentDetails.data.map((item) => (
												<option value={item.title}>{item.title} </option>
											))}

										{/* <option value='ProAssurance'>ProAssurance </option>
										<option value='Norgesic'>Norgesic </option>
										<option value='Youturn Health'>Youturn Health </option> */}
									</select>
								</div>
								<p className='error_message'>{uploadContentError.category}</p>
							</div>
						</InputsSection>
						<InputsSections style={{ display: 'flex', alignItems: 'flex-start' }}>
							<InputsSection style={{
								width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: '1rem'
							}}>
								<TextAreaInput
									label='Short Description'
									placeholder='Short Description about content...'
									value={UploadContent.desc}
									onChange={(e) => {
										setUploadContent({ ...uploadContent, desc: e.target.value })
										setUploadContentError({ ...uploadContentError, desc: '' })
									}}
									error={uploadContentError.desc}
									style={{ alignItems: 'center', justifyContent: 'center', height: '6rem', minHeight: '6rem' }}
								/>
							</InputsSection>
						</InputsSections>
						<InputsSections style={{ display: 'flex', alignItems: 'flex-start' }}>
							<InputsSection
								style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}
							>
								<ModalRadioButtons
									containerStyle={{ alignItems: "center" }}
									label='Content Type'
									placeholder='Content Category'
									radioButtons={[
										{
											id: 1,
											label: 'Video',
											value: 'video'
										},
										{
											id: 2,
											label: 'PDF',
											value: 'pdf'
										},
										{
											id: 2,
											label: 'URL',
											value: 'url'
										},
										{
											id: 3,
											label: 'Disclosure',
											value: 'disclosure'
										}
									]}
									onChange={(e) => {
										setUploadContent({ ...uploadContent, isVideo: e.target.value })
										setUploadContentError({ ...uploadContentError, isVideo: '' })
									}}
									error={uploadContentError.isVideo}
								/>
								{uploadContent.isVideo === 'url' ?
									<InputsSection style={{
										width: '100%', height: '100%', display: 'flex', justifyContent: 'center', padding: '1rem'
									}}>
										<ModalTextInput
											label='Web URL'
											width="100%"
											placeholder='Ex.https://myportal.perspectivesincare.com/'
											containerStyle={{ width: "100%" }}
											value={UploadContent.title}
											onChange={(e) => {
												setUploadContent({ ...uploadContent, url: e.target.value })
												setUploadContentError({ ...uploadContentError, url: '' })
											}}
											error={uploadContentError.url}
										/>
									</InputsSection>
									:
									<React.Fragment>
										<UploadInput
											value={UploadContent.file}
											onChange={fileValidation}
											label={mediaName ? mediaName : 'Upload File'}
											style={{ alignItems: 'center', justifyContent: 'center', height: '10rem' }}
										/>
										{
											uploadContent.isVideo &&
												uploadContent.isVideo === 'video' ?
												<label className='modal_input--label'>
													Maximum upload file size: 20MB
												</label>
												:
												<label className='modal_input--label'>
													Maximum upload file size: 5MB
												</label>
										} 
										<p className='error_message'>{uploadContentError.file}</p>
									</React.Fragment>}
							</InputsSection>
						</InputsSections>
						<ModalButtonPrimary title='Upload Content' onClick={handleUpload} />
					</ModalForm>
			}
		</Modal>
	);
};

export default UploadContent;
