import React, { Fragment } from 'react';
import './ModMedScreen.css'
import '../HomeScreen/HomeScreen.css'

const FilterUsers = ({ data }) => {
	const {
		showFilter,
		setShowFilter, 
		handleClear,
		filterData,
		setFilterData,
		handleFilter,
	} = data;
	

	return (
		<Fragment>
			{!showFilter ? (
				<div className='filter__header'>
					<button id="filterHide" className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Hide Search
					</button>
				</div>
			) : (
				<div className='filter__header'>
					<button id="filterShow" className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Search Users
					</button>
				</div>
			)}
			<div className='ProviderFilter'
				style={{ maxHeight: showFilter ? 0 : '34rem' }}
			>
				<div className='filter__content'>
					<div className='Providerfilter__content--top'>
						<div className='filter__input--labels'>
							<label htmlFor='email' className='filter__input--label'>
								User Email
							</label>
							<div className='filter__input'>
								<label htmlFor='email' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-email`} />
									</svg>
								</label>
								<input
									type='text'
									id='email'
									placeholder='User Email'
									value={filterData.Email}
									onChange={(e) => setFilterData({ ...filterData, Email: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='firstName' className='filter__input--label'>
								Patient First Name
							</label>
							<div className='filter__input'>
								<label htmlFor='firstName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='firstName'
									placeholder='Jon'
									value={filterData.FirstName}
									onChange={(e) => setFilterData({ ...filterData, FirstName: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='lastName' className='filter__input--label'>
								Patient Last Name
							</label>
							<div className='filter__input'>
								<label htmlFor='lastName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='lastName'
									placeholder='Doe'
									value={filterData.LastName}
									onChange={(e) => setFilterData({ ...filterData, LastName: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
					</div> 
					<div className='Providerfilter__content--top2' >
						<div className='filter__input--labels'>
							<label htmlFor='date' className='filter__input--label'>
								Date of Birth
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='date'
									name='date'
									value={filterData.BirthDate}
									onChange={(e) => setFilterData({ ...filterData, BirthDate: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div style={{ display: 'flex', gap: '1rem' }}>
							<button className='filter__input--button'
								id='searchBtn'
								onClick={()=>{handleFilter(1)}}
							>
								Search
							</button>
							<button className='filter__input--button'
								id='clearBtn'
								onClick={handleClear}
							>
								Clear
							</button>
						</div>
					</div>


				</div>
			</div>
		</Fragment>
	);
};

export default FilterUsers;
