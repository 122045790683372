import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Content from '../../components/Content';
import FilterSummaryUpload from './FilterSummaryUpload';
import { clinicProvidersAction, getAllClinicsAction } from '../../redux/actions/clinicActions';
import {
	logout,
	getUserListForSFTPAction,
	GetSFTPGenerateByAssessmentIdPDFAction,
} from '../../redux/actions/userActions';
import {
	DELETE_MANAGE_USER_RESET,
	MANAGE_USER_RESET,
	ADMINLOGIN_MANAGE_USER_RESET
} from '../../redux/constants/userConstants';
import { GET_CLINIC_PROVIDERS_RESET, GET_CLINICS_RESET } from '../../redux/constants/clinicsConstants';
import './SummaryUploadScreen.css';
import TableRows from '../../components/Skeletons/TableRows';
import { toast } from 'react-toastify';

const SummaryUploadScreen = ({ history }) => {
	const dispatch = useDispatch();
	const [usersList, setUsersList] = useState([]);
	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const [loading, setLoading] = useState(false);

	const pageLimit = 10;

	let pageNo = totalPageSize / pageLimit;
	const newIndex = (pageNumber * 10) - 9;

	const [showFilter, setShowFilter] = useState(true);
	const [filter, setFilter] = useState({
		provider: '0',
		clinic: '0',
		role: 'All',
		confirmed: 'All'
	});
	const [textInputs, setTextInputs] = useState({
		userId: '',
		email: '',
		fName: '',
		lName: '',
		toDate: '',
		fromDate: ''
	});

	const userLogin = useSelector((state) => state.userLogin);
	const manageUsers = useSelector((state) => state.manageUsers);
	const clinicProviders = useSelector((state) => state.clinicProviders);
	const allClinics = useSelector((state) => state.allClinics);

	useEffect(() => {
		const userInfo = localStorage.getItem('userInfo');
		const tokenInfo = localStorage.getItem('token');
		const clientIdInfo = localStorage.getItem('clientId');
		const getMenusInfo = localStorage.getItem('getMenus');

		if (userInfo === "undefined" || userInfo === undefined) {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('userInfo');
			localStorage.removeItem('token');
			localStorage.removeItem('clientId');
			localStorage.removeItem('clientUrl');
			localStorage.removeItem('getMenus');
			history.push("/login");
			dispatch(logout())
		} else if (tokenInfo === "undefined" || tokenInfo === undefined) {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('userInfo');
			localStorage.removeItem('token');
			localStorage.removeItem('clientId');
			localStorage.removeItem('clientUrl');
			localStorage.removeItem('getMenus');
			history.push("/login");
			dispatch(logout())
		} else if (clientIdInfo === "undefined" || clientIdInfo === undefined) {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('userInfo');
			localStorage.removeItem('token');
			localStorage.removeItem('clientId');
			localStorage.removeItem('clientUrl');
			localStorage.removeItem('getMenus');
			history.push("/login");
			dispatch(logout())
		} else if (getMenusInfo === "undefined" || getMenusInfo === undefined) {
			localStorage.removeItem('userInfo');
			localStorage.removeItem('token');
			localStorage.removeItem('clientId');
			localStorage.removeItem('clientUrl');
			localStorage.removeItem('getMenus');
			history.push("/login");
			dispatch(logout())
		}
	}, [dispatch])



	useEffect(
		() => {
			if (userLogin && userLogin.userInfo && !userLogin.userInfo.token) {
				dispatch(logout())
				history.push('/login');
			} else if (!localStorage.getItem('userInfo')) {
				dispatch(logout())
				history.push('/login');
			} else if (userLogin && userLogin.userInfo
				&& userLogin.userInfo.issuccess
				&& userLogin.userInfo.issuperadmin
				&& !userLogin.userInfo.isclinicadmin
				&& !userLogin.userInfo.isprovider
				&& !userLogin.userInfo.ispatient) {
				getManageUsers(pageNumber);
				dispatch(clinicProvidersAction());
				dispatch(getAllClinicsAction());
			}
		}, [userLogin, history, dispatch]
	)

	const getManageUsers = (selectedPage) => {
		let data = {};
		if (filter.provider) {
			data.providerId = filter.provider;
		} if (filter.clinic) {
			data.clinicId = filter.clinic;
		} if (filter.role) {
			data.role = filter.role;
		} if (filter.confirmed) {
			data.emailConf = filter.confirmed;
		} if (textInputs.email) {
			data.Email = textInputs.email;
		} if (textInputs.fName) {
			data.FirstName = textInputs.fName;
		} if (textInputs.lName) {
			data.LastName = textInputs.lName;
		} if (textInputs.fromDate) {
			data.startDate = textInputs.fromDate;
		} if (textInputs.toDate) {
			data.endDate = textInputs.toDate;
		}
		data.pageNumber = selectedPage;
		data.rowsOfPage = pageLimit;
		dispatch(getUserListForSFTPAction(true, data));
	}

	useEffect(
		() => {
			if (manageUsers && manageUsers.data && manageUsers.data.length > 0) {
				setUsersList(manageUsers.data);
				setTotalPageSize(manageUsers.data[0].totalCount);
			} else if (manageUsers && manageUsers.error && manageUsers.error.status === 501) {
				dispatch(logout())
				history.push('/login');
			}
		},
		[manageUsers, dispatch, history]
	);

	useEffect(
		() => {
			return () => {
				dispatch({ type: MANAGE_USER_RESET });
				dispatch({ type: GET_CLINIC_PROVIDERS_RESET });
				dispatch({ type: GET_CLINICS_RESET });
				dispatch({ type: DELETE_MANAGE_USER_RESET });
				dispatch({ type: ADMINLOGIN_MANAGE_USER_RESET });
			};
		},
		[dispatch]
	);

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		getManageUsers(selectedPage + 1);
		setPageNumber(selectedPage + 1);
	};

	const handleClear = () => {
		setPageNumber(1);
		setFilter({
			provider: '0',
			clinic: '0',
			role: 'All',
			confirmed: 'All'
		});
		setTextInputs({
			email: '',
			fName: '',
			lName: '',
			fromDate: '',
			toDate: ''
		});

		dispatch(getUserListForSFTPAction(true, { pageNumber: 1, rowsOfPage: pageLimit }));
	};

	const handleFilterSubmit = () => {
		if (
			filter.provider === '0' &&
			filter.clinic === '0' &&
			filter.role === 'All' &&
			filter.confirmed === 'All' &&
			textInputs.email.trim() === '' &&
			textInputs.fName.trim() === '' &&
			textInputs.lName.trim() === '' &&
			textInputs.fromDate.trim() === '' &&
			textInputs.toDate.trim() === ''
		) {
			alert('Enter filter details');
		} else {
			setUsersList([]);
			const isFilter = true;

			const data = {
				providerId: filter.provider,
				clinicId: filter.clinic,
				role: filter.role,
				emailConf: filter.confirmed,
				Email: textInputs.email,
				FirstName: textInputs.fName,
				LastName: textInputs.lName,
				startDate: textInputs.fromDate,
				endDate: textInputs.toDate,
				"pageNumber": 1,
				"rowsOfPage": pageLimit
			};
			dispatch(getUserListForSFTPAction(isFilter, data));
			setPageNumber(1);
		}
	};



	const requestSftpUploadHandler = async () => {
		let assessmentResIdArr = usersList.filter((d) => d.checked); //.map((obj) => obj.assessmentresid);
		console.log(assessmentResIdArr, 'assessmentResIdArr');
		if (!assessmentResIdArr || assessmentResIdArr.length == 0) {
			toast.error("Please select a assessment from the list");
		} else {
			setLoading(true);
			let dataPromise = await Promise.all(assessmentResIdArr.map(async (item) => {
				try {
					let data = await dispatch(GetSFTPGenerateByAssessmentIdPDFAction(item.assessmentresid));
					if (data && data.data && data.data.status) {
						if (item.checked) {
							item.checked = false;
							item.sftpComplete = true;
						} else {
							item.checked = true;
							item.sftpComplete = false
						}
						setUsersList([...usersList, assessmentResIdArr]);
					} else {
						toast.error(data);
					}
					return item;
				} catch (error) {
					return { ...item, error };
				}
			}));
			if (dataPromise) {
				setLoading(false);
			}
		}
	};

	const onChangeHandler = (i) => {
		// setUsersList(usersList.map((d) => d.checked = false)); 
		const newArr = [...usersList];
		if (newArr[i].checked) {
			// newArr[i].sftpComplete = true;
			newArr[i].checked = false;
		} else {
			// newArr[i].sftpComplete = false;
			newArr[i].checked = true;
		}
		setUsersList(newArr);
	};

	return (
		<Content currentMenu="Summary Upload" headerTitle="Summary Upload">
			<FilterSummaryUpload
				data={{
					showFilter,
					setShowFilter,
					clinicProviders,
					allClinics,
					filter,
					setFilter,
					textInputs,
					setTextInputs,
					handleFilterSubmit,
					handleClear
				}}
			/>

			<div className="tableContainer" style={{ height: '65vh' }}>
				<table className="table" style={{ borderSpacing: 'none' }}>
					<thead>
						<tr>
							<th>#</th>
							<th>User F/L Name</th>
							<th>Email</th>
							<th>Date of Birth</th>
							<th>Provider</th>
							<th>Clinic Name</th>
							<th>Assessment</th>
							<th>Reference Number</th>
							<th>Role</th>
							<th>Status</th>
							<th style={{ textAlign: 'center', width: '10rem' }}>
								<button
									disabled={loading}
									className="filter__input--button"
									onClick={() => requestSftpUploadHandler()}
								>
									Upload
								</button>
							</th>
						</tr>
					</thead>
					<tbody>
						{
							usersList && manageUsers && !manageUsers.loading ? usersList.length > 0 ? (
								usersList.map((user, index) => (
									user.assessmentrefno && user.mostRecentAssDate &&
									<tr key={index + 1}>
										<td>{newIndex + index}</td>
										<td>{user.patientFirstName + ' ' + user.patientLastName}</td>
										<td>{user.patientEmail}</td>
										<td>
											{new Date(user.birthDate).toLocaleDateString('en-US', {
												year: 'numeric',
												month: '2-digit',
												day: '2-digit'
											})}
										</td>
										<td style={{ textAlign: 'left' }}> {user.providerName}</td>
										<td>{user.clinicName}</td>
										{/* <td>{user.patientEmail}</td> */}

										{user.mostRecentAss !== "None" ?
											<td style={{ display: 'flex', alignItems: 'center', whiteSpace: "normal", maxWidth: "215px" }}>
												<div className='table__button2' style={{ marginRight: '0.5rem' }}>
													<svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
														<use xlinkHref={`/sprite.svg#icon-pdf`} />
													</svg>
													<span>Assessment</span>
												</div>
												<span className='table__iconBox'>
													{user.mostRecentAss}
												</span>
											</td>
											:
											<td style={{ display: 'flex', alignItems: 'center' }}></td>}
										<td>
											<span className='table__iconBox'>
												Ref. No.: {user.assessmentrefno}
											</span>
										</td>
										<td>{user.highestrole}</td>
										<td>
											{
												user.sftpComplete ?
													'Complete' :
													user.complete ?
														'Complete' :
														'Pending'
											}
										</td>
										<td style={{ justifyContent: 'center', display: 'flex' }}>
											<div className="modal__radioButton">
												<input
													type="checkbox"
													id={user.firstName + index}
													name={user.firstName + index}
													className="modal__checkbox--input"
													style={{
														zIndex: 'auto'
													}}
													checked={user.checked}
													disabled={loading}
													onChange={() => onChangeHandler(index)}
												/>
											</div>
										</td>
									</tr>
								))
							) : (
								<tr>
									<td colSpan='14' style={{ textAlign: "center" }}>No summary assessments found</td>
								</tr>
							) : (
								<TableRows columns='14' />
							)}
					</tbody>
				</table>
			</div>
			{manageUsers &&
				manageUsers.data &&
				manageUsers.data[0] &&
				manageUsers.data[0].totalCount > 10 && (
					<div className="tableContainer--paginater">
						<ReactPaginate
							nextClassName={''}
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={pageNo}
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={5}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'paginationActive'}
							forcePage={pageNumber - 1}
						/>
					</div>
				)}

		</Content>
	);
};

export default SummaryUploadScreen;
