import React from 'react';
import './InviteClinics.css';

function data({ data, invitedClinics }) {
	const { setModalState, setSelectedInvite, setselectUpdateEmailInvite, setModalEditState } = data;

	return (
		<div className='pending-container'>
			{invitedClinics &&
				invitedClinics.data &&
				invitedClinics.data.map((invite) => (
					<div className='pending-email' key={invite.clinicInviteId}>
						<div>
							<p>{invite.emailAddress}</p>
							{/* <p>{invite.website && invite.website.name}</p> */}
							<p className='website--text'>{invite.website && invite.website.url}</p>
						</div>

						<div className='flex'>
							<button
								className='table__button'
								onClick={() => {
									setModalEditState(true);
									setselectUpdateEmailInvite(invite);
								}}>
								<svg className='table__button--icon'>
									<use xlinkHref={`/sprite.svg#icon-edit`} />
								</svg>
								<span>Edit</span>
							</button>
							<button
								className='table__button table__button--delete'
								onClick={() => {
									setModalState(true);
									setSelectedInvite(invite);
								}}>
								<svg className='table__button--icon-red'>
									<use xlinkHref={`/sprite.svg#icon-delete`} />
								</svg>
								<span>Delete</span>
							</button>
						</div>
					</div>
				))}
		</div>
	);
}

export default data;
