import React, { Fragment } from 'react';

export const AddScheduleSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				<div style={{display: 'flex' ,justifyContent: 'space-between' }}>
						
						<div className='content__input--skeleton skeleton' />
                        <div className='content__input--skeleton skeleton' />
                        <div className='content__input--skeleton skeleton' />
					
				</div>
				
				<br/>
				<div className='scheduler-content'>
					
					<div className='contents__input--skeleton skeleton' />
					
				</div>
                <div className='profile--skeleton__button' style={{paddingBottom: '1rem'}}>
				    <div className='filter__input--button-skeleton skeleton' />
			    </div>
			</div>
		</Fragment>
	);
};