import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { DeleteClientInput } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonDelete } from '../../components/Modal/ModalButton';

const DeleteAppVersion = ({ data }) => {
	const { modalDeleteState, onDeleteModalClose, onDeleteHandler } = data;

	return (

		<Modal className='modal__Container' show={modalDeleteState}>
			<ModalHeading heading='Delete App Version' onClose={onDeleteModalClose} />
			{
				<ModalForm onSubmit={onDeleteHandler} style={{ marginBottom: '2.5rem' }}>
					<DeleteClientInput style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
						<h4>
							Are you sure you want to delete this app version?
						</h4>
					</DeleteClientInput>
					<ModalButtonDelete title='Delete Version' onClick={onDeleteHandler} />
				</ModalForm>
			}

		</Modal>
	);
};

export default DeleteAppVersion;
