import React from 'react';
import classes from '../Notifications.module.css';

const Button = ({ label, icon, onClick, disabled, style }) => {
  return (
    <button className={classes.notification_button} style={style} onClick={onClick} disabled={disabled}>
      {label}
      <svg className={classes.notification_button_icon}>
        <use xlinkHref={`/sprite.svg#icon-${icon}`} />
      </svg>
    </button>
  );
};

export const DeleteButton = ({ label, icon, onClick, disabled, style }) => {
  return (
    <button className={classes.notification_delete_button} style={style} onClick={onClick} disabled={disabled}>
      {label}
      <svg className={classes.notification_delete_button_icon}>
        <use xlinkHref={`/sprite.svg#icon-${icon}`} />
      </svg>
    </button>
  );
};

export default Button;
