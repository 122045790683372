import React from "react";
import Modal from "../../components/Modal";
import ModalHeading from "../../components/Modal/ModalHeading";

const PatienteSignModal = ({ data }) => {
	const { eSignModalState, formData, setformData, formDataError, setformDataError, onESignModalClose, onSubmitHandler } = data;

	return (
		<Modal show={eSignModalState} style={{zIndex:9999999}}>
			<ModalHeading
				heading='User eSignature' onClose={onESignModalClose}
			/>
			<React.Fragment>
				<form className='modalContainer__form'>
					<div className='filter__input--labels'>
						<label htmlFor='username' className='modal__text' style={{maxWidth:'40rem'}}>
								By typing your name below, you are signing this application electronically. You agree that your electronic signature is the legal equivalent of your manual signature with the same legal force and effect.
						</label>
						<div className='filter__input'>
							<label htmlFor='username' className='filter__input--iconBox'>
								<svg className='filter__input--icon'>
									<use xlinkHref={`/sprite.svg#icon-user`} />
								</svg>
							</label>
							<input
								type='text'
								id='username'
								placeholder='John Smith'
								className='filter__input--text'
								value={formData.IseSignature}
								onChange={(event) => {
									setformData({ ...formData, IseSignature: event.target.value});
									setformDataError("");
								}}
							/>
						</div>
						{formDataError.IseSignature && <p className='error_message'>{formDataError.IseSignature}</p>}
					</div>
				</form>
				<div className='modal__actions'>
					<button className="modal__button modal__button--update" onClick={onSubmitHandler}>
						Submit
					</button>
				</div>
			</React.Fragment>
		</Modal>
	);
};

export default PatienteSignModal;
