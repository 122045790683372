import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ForgotPasswordSkeleton } from '../../components/Skeletons/ForgotPasswordSkeleton';
import { forgotPasswordAction } from '../../redux/actions/userActions';
import { FORGOT_PASSWORD_RESET } from '../../redux/constants/userConstants';
import { validateEmail } from '../../utils/validators';
import '../LoginScreen/LoginScreen.css';

const Index = ({history}) => {
	const dispatch = useDispatch();

    const forgotPassword = useSelector((state) => state.forgotPassword);

	const [email, setEmail] = useState('');
	const [ emailError, setEmailError ] = useState('');

	useEffect(
		() => {
			if (forgotPassword && forgotPassword.data === "") {
				history.push('/user/verify-email')
			} else if(forgotPassword && forgotPassword.error!== ''){
				toast.error(forgotPassword.error)
			}
		},
		[ forgotPassword, history ]
	);

	useEffect(() => {
        return () => {
            dispatch({ type: FORGOT_PASSWORD_RESET })
        }
    }, [dispatch])

	const handleSubmit = ()=>{
		if (!email.trim() || email.trim() === '') {
			setEmail('');
			setEmailError('Please enter your email');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter a valid email');
		} else{
			const formData = {
			Email: email
			}
			dispatch(forgotPasswordAction(formData))
		}
	}

	return (
		<main className='loginContainer'>
			<div className='loginContainer__bg' />
			<div className='loginContainer__box'>
				<div className='loginContainer__box--right'>
					<img src='/images/logo.png' alt='Suremed Logo' className='loginContainer__box--logo' />
					{/* <h1 className='loginContainer__heading'>Welcome Back</h1> */}
					<h1 className='loginContainer__subheading'>Forgot Password</h1>
				</div>
				<div className='loginContainer__box--left'>
				{forgotPassword && forgotPassword.loading?
				<ForgotPasswordSkeleton/> :
				<form className='loginContainer__form' style={{ marginTop: '8rem' }}>
						{/* <h2 className='loginContainer__form--heading'>Forgot Password</h2> */}
						<div className='loginContainer__formgroup'>
							<label htmlFor='email' className='loginContainer__formgroup--label'>
								Email
							</label>
							<div className='loginContainer__formgroup--inputBox'>
								<div className='logoContainer__formgroup--iconBox'>
									<svg className='loginContainer__formgroup--icon'>
										<use xlinkHref='/sprite.svg#icon-email' />
									</svg>
								</div>
								<input
									type='text'
									placeholder=''
									id='email'
									className='loginContainer__formgroup--input'
									value={email}
									onChange={(e)=> {setEmail(e.target.value)
									setEmailError('')}}
								/>
							</div>
								<p className='error_message'>{emailError}</p>
							<div className='loginContainer__formgroup--forgotPassword'>
								<Link to='/login' className='loginContainer__formgroup--forgotPasswordBtn'>
									Back to Log in
								</Link>
							</div>
						</div>

						<div className='loginContainer__formgroup--btn'>
							<button className='loginContainer__button' onClick={handleSubmit}>
								<span className='loginContainer__button--text'>Submit</span>
								<div className='loginContainer__button--iconBox'>
									<svg className='loginContainer__button--icon'>
										<use xlinkHref='/sprite.svg#icon-chevron-right' />
									</svg>
								</div>
							</button>
						</div>
					</form>
			}

				</div>
			</div>
		</main>
	);
};

export default Index;
