import React from 'react';
import useApi from './useApi';

const useGetAllPatients = () => {
  const [allPatients, setAllPatients] = React.useState([]);

  const { loading: getAllPatientsLoading, makeApiCall } = useApi({
    url: `/Notification/GetPatientListByClinicId`,
    method: 'GET'
  });

  const getAllPatients = async () => {
    const formData = { providerId: '0', clinicId: '0', role: 'Patient', emailConf: 'Confirmed', pageNumber: 1, rowsOfPage: 15 };
    const { response, error } = await makeApiCall(formData);

    if (response) {
      setAllPatients(response);
    }

    if (error) {
      setAllPatients([]);
    }
  };

  return {
    getAllPatientsLoading,
    getAllPatients,
    allPatients,
  };
};

export default useGetAllPatients;
