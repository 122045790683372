import React, { Fragment } from 'react';

const RegisterNoInviteSkeleton = () => {
    return (
		<Fragment>
			<div className='modalContainer__form' style={{marginTop:'5rem'}}>
				<div>
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text--skeleton skeleton' />
						<div className='login__input--skeleton skeleton' />
					</div>

				</div>

				<br/>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='login__input--skeleton skeleton' />

				</div>
                <br/>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='login__input--skeleton skeleton' />

				</div>
                <br/>
                <div className='modal__actions'>

				<div className='login__input--button-skeleton skeleton' />
			</div>
			</div>



		</Fragment>
	);
};

export default RegisterNoInviteSkeleton;
