import React from 'react';

const UnverifiedVerifyEmail = () => {
	return (
		<div>
			<div className="modal__actions">
				<div className="filter__input--label-skeleton skeleton" />
				<div className="modal__button--cancel-skeleton skeleton" />
			</div>
			<div className="modal__actions">
				<div className="filter__input--labellong-skeleton skeleton" />
			</div>
			<div className="modal__actions">
				<div className="modal__button--update-skeleton skeleton" />
			</div>
		</div>
	);
};
export default UnverifiedVerifyEmail;
