import React from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';

const EditConfirmation = ({ showModal, currentClinic, prevClinic, onConfirm, onClose }) => {
  return (
    <Modal show={showModal}>
      <ModalHeading heading='Are you sure?' onClose={onClose} />

      <div className='modalContainer__form'>
        <div className='filter__input--labels'>
          <p className='modal__text'>
            Do you wish to move this patient from <span>{prevClinic}</span> to <span>{currentClinic}</span> ?
          </p>
        </div>
      </div>
      <div className='modal__actions'>
        <button className='modal__button modal__button--update' onClick={onConfirm}>
          Confirm
        </button>
      </div>
    </Modal>
  );
};

export default EditConfirmation;
