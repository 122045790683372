import React from 'react';
import ModalCheckBox from '../../components/Modal/ModalCheckBox';

const InviteUserForm2 = ({ props, deleteInvite }) => {
    const { dataError, setDataError, cloneInvite, setCloneInvite } = props;

    return (
        <form className='clinic--signupContainer__form'
        //style={{width: '80%'}}
        >

            <button
                onClick={deleteInvite}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    border: 'none',
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    transition: 'all .4s'
                }}>
                <svg className='modal__heading--icon'>
                    <use xlinkHref={`/sprite.svg#icon-close`} />
                </svg>
            </button>
            <div className="formgroup--validation">
                <div className="addressContainer__formgroup--inputBox">
                    <div className="logoContainer__formgroup--iconBox">
                        <svg className="loginContainer__formgroup--icon">
                            <use xlinkHref="/sprite.svg#icon-email" />
                        </svg>
                    </div>
                    <input
                        type="text"
                        placeholder="Invite Email"
                        id="clinic-name"
                        value={cloneInvite.email}
                        onChange={(e) => {
                            setCloneInvite({ ...cloneInvite, email: e.target.value })
                            setDataError('')
                        }}
                        className="loginContainer__formgroup--input"
                    />
                </div>
                <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.email}</p>
            </div>
            <div
                style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0 1rem 0' }}
            >
                <ModalCheckBox
                    label='Is Admin'
                    checked={cloneInvite.IsAdmin}
                    value={cloneInvite.IsAdmin}
                    onChange={() => {
                        setCloneInvite({ ...cloneInvite, IsAdmin: !cloneInvite.IsAdmin })
                        setDataError({ ...dataError, IsAdmin: '' })
                    }}
                />
                <ModalCheckBox
                    label='Is Provider'
                    checked={cloneInvite.IsProvider}
                    value={cloneInvite.IsProvider}
                    onChange={() => {
                        //setCloneInvite({...cloneInvite, IsProvider: !cloneInvite.IsProvider})
                        if (cloneInvite.IsProvider === true) setCloneInvite({ ...cloneInvite, IsProvider: !cloneInvite.IsProvider, npi: '', credentials: '', gender: '', validDeaCert: false })
                        else setCloneInvite({ ...cloneInvite, IsProvider: !cloneInvite.IsProvider })
                        setDataError({ ...dataError, IsAdmin: '' })
                    }}
                />
            </div>
            <p className="error_message" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{dataError.IsAdmin}</p>

            <div className="flex-form">
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox">
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-user" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            placeholder="First Name"
                            id="firstName"
                            value={cloneInvite.FirstName}
                            onChange={(e) => {
                                setCloneInvite({ ...cloneInvite, FirstName: e.target.value })
                                setDataError('')
                            }}
                            className="loginContainer__formgroup--input"
                        />
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.FirstName2}</p>
                </div>
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox">
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-user" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            placeholder="Last Name"
                            id="lastName"
                            value={cloneInvite.LastName}
                            onChange={(e) => {
                                setCloneInvite({ ...cloneInvite, LastName: e.target.value })
                                setDataError('')
                            }}
                            className="loginContainer__formgroup--input"
                        />
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.LastName2}</p>
                </div>
            </div>
            <div className="flex-form" >
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox" style={{
                        backgroundColor: cloneInvite.IsProvider ? null : 'var(--placeholder)'
                    }}>
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-menu" />
                            </svg>
                        </div>
                        {cloneInvite && cloneInvite.IsProvider ?
                            <input
                                type="text"
                                placeholder="NPI"
                                id="lastName"
                                value={cloneInvite.npi}
                                onChange={(e) => {
                                    setCloneInvite({ ...cloneInvite, npi: e.target.value })
                                    setDataError('')
                                }}
                                className="loginContainer__formgroup--input"
                            />
                            :
                            <input
                                type="text"
                                disabled
                                placeholder="NPI"
                                id="lastName"
                                value={cloneInvite.npi}
                                className="loginContainer__formgroup--input"
                            />
                        }
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.npi}</p>
                </div>
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox"
                        style={{ backgroundColor: cloneInvite.IsProvider ? null : 'var(--placeholder)' }}>
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-status" />
                            </svg>
                        </div>
                        {cloneInvite && cloneInvite.IsProvider ?
                            <input
                                type="text"
                                placeholder="Credentials"
                                id="lastName"
                                value={cloneInvite.credentials}
                                onChange={(e) => {
                                    setCloneInvite({ ...cloneInvite, credentials: e.target.value })
                                    setDataError('')
                                }}
                                className="loginContainer__formgroup--input"
                            />
                            :
                            <input
                                type="text"
                                disabled
                                placeholder="Credentials"
                                id="lastName"
                                value={cloneInvite.credentials}
                                // onChange={(e) => {setCloneInvite({ ...cloneInvite, credentials: e.target.value })
                                // setDataError('')}}
                                className="loginContainer__formgroup--input"
                            />
                        }
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.credentials}</p>
                </div>
            </div>
            <div className='flex-form' style={{ alignItems: 'center', gap: '5rem',marginBottom: '2rem' }}>
                <div className="formgroup--validation smallInputContainer">
                    <div className="registerContainer__formgroup--inputBox"
                        style={{ backgroundColor: cloneInvite.IsProvider ? null : 'var(--placeholder)' }}>
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-user" />
                            </svg>
                        </div>
                        {cloneInvite && cloneInvite.IsProvider ?
                            <select
                                className='filter__input--select'
                                value={cloneInvite.gender}
                                onChange={(event) => {
                                    setCloneInvite({ ...cloneInvite, gender: event.target.value })
                                    // setInviteDataError({...inviteDataError, email: '' })
                                }}
                                id='providers'>
                                <option value=''>Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                            </select>
                            :
                            <select
                                className='filter__input--select'
                                disabled
                                value={cloneInvite.gender}
                                onChange={(event) => {
                                    setCloneInvite({ ...cloneInvite, gender: event.target.value })
                                    // setInviteDataError({...inviteDataError, email: '' })
                                }}
                                id='providers'>
                                <option value=''>Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                            </select>}
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.lName}</p>
                </div>
                {cloneInvite && cloneInvite.IsProvider ?
                    <ModalCheckBox
                        label='Valid DEA Certificate'
                        checked={cloneInvite.validDeaCert}
                        value={cloneInvite.validDeaCert}
                        onChange={() => {
                            setCloneInvite({ ...cloneInvite, validDeaCert: !cloneInvite.validDeaCert })
                        }}
                    />
                    :
                    <ModalCheckBox
                        disabled
                        label='Valid DEA Certificate'
                    />
                }
            </div>
        </form>
    )
};

export default InviteUserForm2;
