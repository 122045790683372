import { CHANGE_CONSENT_DOCUMENTS_STATUS_FAIL, CHANGE_CONSENT_DOCUMENTS_STATUS_REQUEST, CHANGE_CONSENT_DOCUMENTS_STATUS_RESET, CHANGE_CONSENT_DOCUMENTS_STATUS_SUCCESS, CONSENT_DOCUMENTS_FAIL, CONSENT_DOCUMENTS_REQUEST, CONSENT_DOCUMENTS_RESET, CONSENT_DOCUMENTS_SUCCESS } from '../constants/consentConstants';
import {
CLINIC_FILTER_REQUEST,
CLINIC_FILTER_SUCCESS,
CLINIC_FILTER_FAIL,
CLINIC_FILTER_RESET,
CLINIC_PROVIDER_REQUEST,
CLINIC_PROVIDER_SUCCESS,
CLINIC_PROVIDER_FAIL,
CLINIC_PROVIDER_RESET,
USER_LIST_REQUEST,
USER_LIST_SUCCESS,
USER_LIST_FAIL,
USER_LIST_RESET,
PROVIDER_ASSESSMENT_REQUEST,
PROVIDER_ASSESSMENT_SUCCESS,
PROVIDER_ASSESSMENT_FAIL,
PROVIDER_ASSESSMENT_RESET,
ADD_INVITE_REQUEST,
ADD_INVITE_SUCCESS,
ADD_INVITE_FAIL,
ADD_INVITE_RESET,
SAVE_CLINIC_USER_REQUEST,
SAVE_CLINIC_USER_SUCCESS,
SAVE_CLINIC_USER_FAIL,
SAVE_CLINIC_USER_RESET,
VIDEO_DETAILS_REQUEST,
VIDEO_DETAILS_SUCCESS,
VIDEO_DETAILS_FAIL,
VIDEO_DETAILS_RESET,
VIDEO_THUMB_REQUEST,
VIDEO_THUMB_SUCCESS,
VIDEO_THUMB_FAIL,
VIDEO_THUMB_RESET,
DELETE_USER_REQUEST,
DELETE_USER_SUCCESS,
DELETE_USER_FAIL,
DELETE_USER_RESET,
CURRENT_USER_REQUEST,
CURRENT_USER_SUCCESS,
CURRENT_USER_FAIL,
CURRENT_USER_RESET,
TERMS_ACCEPT_REQUEST,
TERMS_ACCEPT_SUCCESS,
TERMS_ACCEPT_FAIL,
TERMS_ACCEPT_RESET,
EHR_CLINIC_FILTER_REQUEST,
EHR_CLINIC_FILTER_SUCCESS,
EHR_CLINIC_FILTER_FAIL,
EHR_CLINIC_FILTER_RESET

} from '../constants/providerConstants'

export const currentUserReducer = (state = {}, action) => {
	switch (action.type) {
		case CURRENT_USER_REQUEST:
			return { loading: true };

		case CURRENT_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case CURRENT_USER_FAIL:
			return { loading: false, error: action.payload };

		case CURRENT_USER_RESET:
			return {};

		default:
			return state;
	}
};

export const clinicFilterReducer = (state = {}, action) => {
	switch (action.type) {
		case CLINIC_FILTER_REQUEST:
			return { loading: true };

		case CLINIC_FILTER_SUCCESS:
			return { data: action.payload, loading: false };

		case CLINIC_FILTER_FAIL:
			return { loading: false, error: action.payload };

		case CLINIC_FILTER_RESET:
			return {};

		default:
			return state;
	}
};

export const clinicProviderReducer = (state = {}, action) => {
	switch (action.type) {
		case CLINIC_PROVIDER_REQUEST:
			return { loading: true };

		case CLINIC_PROVIDER_SUCCESS:
			return { data: action.payload, loading: false };

		case CLINIC_PROVIDER_FAIL:
			return { loading: false, error: action.payload };

		case CLINIC_PROVIDER_RESET:
			return {};

		default:
			return state;
	}
};

export const manageUserListReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LIST_REQUEST:
			return { loading: true };

		case USER_LIST_SUCCESS:
			return { data: action.payload, loading: false };

		case USER_LIST_FAIL:
			return { loading: false, error: action.payload };

		case USER_LIST_RESET:
			return {};

		default:
			return state;
	}
};

export const providerAssessmentReducer = (state = {}, action) => {
	switch (action.type) {
		case PROVIDER_ASSESSMENT_REQUEST:
			return { loading: true };

		case PROVIDER_ASSESSMENT_SUCCESS:
			return { data: action.payload, loading: false };

		case PROVIDER_ASSESSMENT_FAIL:
			return { loading: false, error: action.payload };

		case PROVIDER_ASSESSMENT_RESET:
			return {};

		default:
			return state;
	}
};

export const addInviteProviderReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_INVITE_REQUEST:
			return { loading: true };

		case ADD_INVITE_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_INVITE_FAIL:
			return { loading: false, error: action.payload };

		case ADD_INVITE_RESET:
			return {};

		default:
			return state;
	}
};
export const saveClinicUserReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_CLINIC_USER_REQUEST:
			return { loading: true };

		case SAVE_CLINIC_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case SAVE_CLINIC_USER_FAIL:
			return { loading: false, error: action.payload };

		case SAVE_CLINIC_USER_RESET:
			return {};

		default:
			return state;
	}
};

export const videoDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case VIDEO_DETAILS_REQUEST:
			return { loading: true };

		case VIDEO_DETAILS_SUCCESS:
			return { data: action.payload, loading: false };

		case VIDEO_DETAILS_FAIL:
			return { loading: false, error: action.payload };

		case VIDEO_DETAILS_RESET:
			return {};

		default:
			return state;
	}
};
export const videoThumbReducer = (state = {}, action) => {
	switch (action.type) {
		case VIDEO_THUMB_REQUEST:
			return { loading: true };

		case VIDEO_THUMB_SUCCESS:
			return { data: action.payload, loading: false };

		case VIDEO_THUMB_FAIL:
			return { loading: false, error: action.payload };

		case VIDEO_THUMB_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteUserReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_USER_REQUEST:
			return { loading: true };

		case DELETE_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case DELETE_USER_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_USER_RESET:
			return {};

		default:
			return state;
	}
};

export const temrsAcceptReducer = (state = {}, action) => {
	switch (action.type) {
		case TERMS_ACCEPT_REQUEST:
			return { loading: true };

		case TERMS_ACCEPT_SUCCESS:
			return { data: action.payload, loading: false };

		case TERMS_ACCEPT_FAIL:
			return { loading: false, error: action.payload };

		case TERMS_ACCEPT_RESET:
			return {};

		default:
			return state;
	}
};



export const consentFormsDocReducer = (state = {}, action) => {
	switch (action.type) {
		case CONSENT_DOCUMENTS_REQUEST:
			return { loading: true };

		case CONSENT_DOCUMENTS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CONSENT_DOCUMENTS_FAIL:
			return { loading: false, error: action.payload };
		case CONSENT_DOCUMENTS_RESET:
			return {};
		default:
			return state;
	}
};



export const changeConsentFormStatusReducer = (state = {}, action) => {
	switch (action.type) {
		case CHANGE_CONSENT_DOCUMENTS_STATUS_REQUEST:
			return { loading: true };

		case CHANGE_CONSENT_DOCUMENTS_STATUS_SUCCESS:
			return { data: action.payload, loading: false };

		case CHANGE_CONSENT_DOCUMENTS_STATUS_FAIL:
			return { loading: false, error: action.payload };

		case CHANGE_CONSENT_DOCUMENTS_STATUS_RESET:
			return {};

		default:
			return state;
	}
};


export const clinicEHRFilterReducer = (state = {}, action) => {
	switch (action.type) {
		case EHR_CLINIC_FILTER_REQUEST:
			return { loading: true };

		case EHR_CLINIC_FILTER_SUCCESS:
			return { data: action.payload, loading: false };

		case EHR_CLINIC_FILTER_FAIL:
			return { loading: false, error: action.payload };

		case EHR_CLINIC_FILTER_RESET:
			return {};

		default:
			return state;
	}
};