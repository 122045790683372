import React from 'react';

export const ModalButtonPrimary = ({ title, onClick, style }) => {
	return (
		<button className='modal__button modal__button--update' onClick={(e) => {
			e.preventDefault()
			onClick()
		}} style={style} >
			{title}
		</button>
	);
};

export const ModalButtonDelete = ({ title, onClick }) => {
	return (
		<button className='modal__button modal__button--delete' onClick={onClick}>
			{title}
		</button>
	);
};

