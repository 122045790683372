export const REGISTER_CLINIC_REQUEST = 'REGISTER_CLINIC_REQUEST';
export const REGISTER_CLINIC_SUCCESS = 'REGISTER_CLINIC_SUCESSS';
export const REGISTER_CLINIC_FAIL = 'REGISTER_CLINIC_FAIL';
export const REGISTER_CLINIC_RESET = 'REGISTER_CLINIC_RESET';

export const REGISTER_CLINIC_INITIAL_REQUEST = 'REGISTER_CLINIC_INITIAL_REQUEST';
export const REGISTER_CLINIC_INITIAL_SUCCESS = 'REGISTER_CLINIC_INITIAL_SUCESSS';
export const REGISTER_CLINIC_INITIAL_FAIL = 'REGISTER_CLINIC_INITIAL_FAIL';
export const REGISTER_CLINIC_INITIAL_RESET = 'REGISTER_CLINIC_INITIAL_RESET';

export const REGISTER_CLINIC_NO_INVITE_REQUEST = 'REGISTER_CLINIC_NO_INVITE_REQUEST';
export const REGISTER_CLINIC_NO_INVITE_SUCCESS = 'REGISTER_CLINIC_NO_INVITE_SUCESSS';
export const REGISTER_CLINIC_NO_INVITE_FAIL = 'REGISTER_CLINIC_NO_INVITE_FAIL';
export const REGISTER_CLINIC_NO_INVITE_RESET = 'REGISTER_CLINIC_NO_INVITE_RESET';

export const CLINIC_SIGN_UP_REQUEST = 'CLINIC_SIGN_UP_REQUEST';
export const CLINIC_SIGN_UP_SUCCESS = 'CLINIC_SIGN_UP_SUCESSS';
export const CLINIC_SIGN_UP_FAIL = 'CLINIC_SIGN_UP_FAIL';
export const CLINIC_SIGN_UP_RESET = 'CLINIC_SIGN_UP_RESET';

export const REGISTER_CLINIC_USER_REQUEST = 'REGISTER_CLINIC_USER_REQUEST';
export const REGISTER_CLINIC_USER_SUCCESS = 'REGISTER_CLINIC_USER_SUCESSS';
export const REGISTER_CLINIC_USER_FAIL = 'REGISTER_CLINIC_USER_FAIL';
export const REGISTER_CLINIC_USER_RESET = 'REGISTER_CLINIC_USER_RESET';

export const GET_CLINIC_PROVIDERS_REQUEST = 'GET_CLINIC_PROVIDERS_REQUEST';
export const GET_CLINIC_PROVIDERS_SUCCESS = 'GET_CLINIC_PROVIDERS_SUCESSS';
export const GET_CLINIC_PROVIDERS_FAIL = 'GET_CLINIC_PROVIDERS_FAIL';
export const GET_CLINIC_PROVIDERS_RESET = 'GET_CLINIC_PROVIDERS_RESET';

export const GET_CLINICS_REQUEST = 'GET_CLINICS_REQUEST';
export const GET_CLINICS_SUCCESS = 'GET_CLINICS_SUCESSS';
export const GET_CLINICS_FAIL = 'GET_CLINICS_FAIL';
export const GET_CLINICS_RESET = 'GET_CLINICS_RESET';

export const GET_PROVIDER_BY_ID_REQUEST = 'GET_PROVIDER_BY_ID_REQUEST';
export const GET_PROVIDER_BY_ID_SUCCESS = 'GET_PROVIDER_BY_ID_SUCCESS';
export const GET_PROVIDER_BY_ID_FAIL = 'GET_PROVIDER_BY_ID_FAIL';
export const GET_PROVIDER_BY_ID_RESET = 'GET_PROVIDER_BY_ID_RESET';

export const INVITE_CLINIC_REQUEST = 'INVITE_CLINIC_REQUEST';
export const INVITE_CLINIC_SUCCESS = 'INVITE_CLINIC_SUCESSS';
export const INVITE_CLINIC_FAIL = 'INVITE_CLINIC_FAIL';
export const INVITE_CLINIC_RESET = 'INVITE_CLINIC_RESET';

export const ADD_INVITE_CLINIC_REQUEST = 'ADD_INVITE_CLINIC_REQUEST';
export const ADD_INVITE_CLINIC_SUCCESS = 'ADD_INVITE_CLINIC_SUCESSS';
export const ADD_INVITE_CLINIC_FAIL = 'ADD_INVITE_CLINIC_FAIL';
export const ADD_INVITE_CLINIC_RESET = 'ADD_INVITE_CLINIC_RESET';

export const DELETE_INVITE_CLINIC_REQUEST = 'DELETE_INVITE_CLINIC_REQUEST';
export const DELETE_INVITE_CLINIC_SUCCESS = 'DELETE_INVITE_CLINIC_SUCESSS';
export const DELETE_INVITE_CLINIC_FAIL = 'DELETE_INVITE_CLINIC_FAIL';
export const DELETE_INVITE_CLINIC_RESET = 'DELETE_INVITE_CLINIC_RESET';

export const UPDATE_INVITE_CLINIC_REQUEST = 'UPDATE_INVITE_CLINIC_REQUEST';
export const UPDATE_INVITE_CLINIC_SUCCESS = 'UPDATE_INVITE_CLINIC_SUCCESS';
export const UPDATE_INVITE_CLINIC_FAIL = 'UPDATE_INVITE_CLINIC_FAIL';
export const UPDATE_INVITE_CLINIC_RESET = 'UPDATE_INVITE_CLINIC_RESET';

export const ALL_CLINICS_TOGGLE_REQUEST = 'ALL_CLINICS_TOGGLE_REQUEST';
export const ALL_CLINICS_TOGGLE_SUCCESS = 'ALL_CLINICS_TOGGLE_SUCCESS';
export const ALL_CLINICS_TOGGLE_FAIL = 'ALL_CLINICS_TOGGLE_FAIL';
export const ALL_CLINICS_TOGGLE_RESET = 'ALL_CLINICS_TOGGLE_RESET';

export const PROVIDER_CLINICS_TOGGLE_REQUEST = 'PROVIDER_CLINICS_TOGGLE_REQUEST';
export const PROVIDER_CLINICS_TOGGLE_SUCCESS = 'PROVIDER_CLINICS_TOGGLE_SUCCESS';
export const PROVIDER_CLINICS_TOGGLE_FAIL = 'PROVIDER_CLINICS_TOGGLE_FAIL';
export const PROVIDER_CLINICS_TOGGLE_RESET = 'PROVIDER_CLINICS_TOGGLE_RESET';

export const HIGHEST_ROLE_REQUEST = 'HIGHEST_ROLE_REQUEST';
export const HIGHEST_ROLE_SUCCESS = 'HIGHEST_ROLE_SUCCESS';
export const HIGHEST_ROLE_FAIL = 'HIGHEST_ROLE_FAIL';
export const HIGHEST_ROLE_RESET = 'HIGHEST_ROLE_RESET';

export const GET_NPI_NUMBER_DETAIL_REQUEST = 'GET_NPI_NUMBER_DETAIL_REQUEST';
export const GET_NPI_NUMBER_DETAIL_SUCCESS = 'GET_NPI_NUMBER_DETAIL_SUCCESS';
export const GET_NPI_NUMBER_DETAIL_FAIL = 'GET_NPI_NUMBER_DETAIL_FAIL';
export const GET_NPI_NUMBER_DETAIL_RESET = 'GET_NPI_NUMBER_DETAIL_RESET';

export const GET_NOTIFICATION_TEMP_REQUEST = 'GET_NOTIFICATION_TEMP_REQUEST';
export const GET_NOTIFICATION_TEMP_SUCCESS = 'GET_NOTIFICATION_TEMP_SUCESSS';
export const GET_NOTIFICATION_TEMP_FAIL = 'GET_NOTIFICATION_TEMP_FAIL';
export const GET_NOTIFICATION_TEMP_RESET = 'GET_NOTIFICATION_TEMP_RESET';