import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import Invite from './Invite';

import { validateEmail } from '../../utils/validators';
import {
	inviteAdminAction,
	createInviteAdminAction,
	deleteInviteAdminAction,
	updateEmailAdminAction
} from '../../redux/actions/adminActions';
import {
	INVITE_ADMIN_RESET,
	ADD_INVITE_ADMIN_RESET,
	DELETE_INVITE_ADMIN_RESET,
	UPDATE_INVITE_ADMIN_RESET
} from '../../redux/constants/adminConstants';

import './InviteAdmins.css';
import DeleteInvite from '../../components/Skeletons/DeleteInvite';
import EditInviteEmail from '../../components/Skeletons/EditInviteEmail';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import { toast } from 'react-toastify';

const InviteAdmins = ({ history }) => {
	const dispatch = useDispatch();
	const [modalState, setModalState] = useState(false);
	const [modalEditState, setModalEditState] = useState(false);
	const [selectedInvite, setSelectedInvite] = useState({});
	const [selectUpdateEmailInvite, setselectUpdateEmailInvite] = useState({});

	const [deleteEmail, setDeleteEmail] = useState({
		value: '',
		error: ''
	});

	const [editEmail, setEditEmail] = useState({
		value: '',
		error: ''
	});

	const [email, setEmail] = useState({
		value: '',
		error: ''
	});

	const invitedAdmins = useSelector((state) => state.invitedAdmins);
	const createInviteAdmin = useSelector((state) => state.createInviteAdmin);
	const deleteInviteAdmin = useSelector((state) => state.deleteInviteAdmin);
	const updateAdminEmail = useSelector((state) => state.updateAdminEmail);
	const userLogin = useSelector((state) => state.userLogin);
	const activeToken = useSelector((state) => state.activeToken);

	useEffect(
		() => {
			if (!activeToken) {
				history.push('/login');
			} else if (!activeToken.token) {
				history.push('/login');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.applicationUser &&
				userLogin.userInfo.applicationUser.isPatient === true &&
				userLogin.userInfo.applicationUser.isSuperAdmin === false
			) {
				history.push('/questionaires');
			}
		},
		[activeToken, userLogin, history]
	);


	useEffect(
		() => {
			dispatch(inviteAdminAction());
		},
		[dispatch]
	);

	useEffect(
		() => {
			if (createInviteAdmin && createInviteAdmin.data && createInviteAdmin.data.adminInviteId) {
				toast.success('Invite Sent')
				dispatch({ type: ADD_INVITE_ADMIN_RESET });
				setEmail({ value: '', error: '' });
				dispatch(inviteAdminAction());
			}
		},
		[createInviteAdmin, dispatch]
	);

	useEffect(
		() => {
			if (deleteInviteAdmin && deleteInviteAdmin.data && deleteInviteAdmin.data.adminInviteId) {
				dispatch({ type: DELETE_INVITE_ADMIN_RESET });
				setDeleteEmail({ value: '', error: '' });
				setModalState(false);
				dispatch(inviteAdminAction());
			}
		},
		[deleteInviteAdmin, dispatch]
	);
	useEffect(
		() => {
			if (updateAdminEmail && updateAdminEmail.data === '') {
				dispatch({ type: UPDATE_INVITE_ADMIN_RESET });
				setEditEmail({ value: '', error: '' });
				setModalEditState(false);
				dispatch(inviteAdminAction());
			}
		},
		[updateAdminEmail, dispatch]
	);
	useEffect(
		() => {
			if (selectUpdateEmailInvite && selectUpdateEmailInvite.emailAddress) {
				setEditEmail({ value: selectUpdateEmailInvite.emailAddress });
			}
		},
		[selectUpdateEmailInvite]
	);

	useEffect(
		() => {
			return () => {
				dispatch({ type: INVITE_ADMIN_RESET });
			};
		},
		[dispatch]
	);

	const onEditClose = () => {
		setModalEditState(false);
		setEditEmail({ value: '', error: '' });
	};

	const onClose = () => {
		setModalState(false);
		setDeleteEmail({ value: '', error: '' });
	};

	const onEditEmailHandler = () => { 
		if (!editEmail.value.trim() || editEmail.value.trim() === '') {
			setEditEmail({ ...editEmail, error: 'Please enter email address' });
		} else if (!validateEmail(editEmail.value)) {
			setEditEmail({ ...editEmail, error: 'Please enter valid email address' });
		} else {
			dispatch(
				updateEmailAdminAction(selectUpdateEmailInvite.adminInviteId, {
					adminInviteId: selectUpdateEmailInvite.adminInviteId,
					emailAddress: editEmail.value
				})
			);
		}
	};
	const onDeleteHandler = (e) => {
		//e.preventDefault();
		if (!deleteEmail.value.trim() || deleteEmail.value.trim() === '') {
			setDeleteEmail({ ...deleteEmail, error: 'Please enter email address' });
		} else if (!validateEmail(deleteEmail.value)) {
			setDeleteEmail({ ...deleteEmail, error: 'Please enter valid email address' });
		} else if (selectedInvite && selectedInvite.emailAddress !== deleteEmail.value) {
			setDeleteEmail({ ...deleteEmail, error: `Please enter ${selectedInvite.emailAddress} in above text box` });
		} else {
			dispatch(deleteInviteAdminAction(selectedInvite.adminInviteId));
		}
	};

	const onInviteAdminHandler = (e) => {
		//e.preventDefault();
		if (!email.value.trim() || email.value.trim() === '') {
			setEmail({ ...email, error: 'Please enter email address' });
		} else if (!validateEmail(email.value)) {
			setEmail({ ...email, error: 'Please enter valid email address' });
		} else {
			dispatch(createInviteAdminAction(email.value));
		}
	};

	return (
		<Content currentMenu="Invite Admins" headerTitle="Invite Admins">
			<Modal show={modalEditState}>
				{updateAdminEmail && updateAdminEmail.loading ? (
					<EditInviteEmail />
				) : (
					<Fragment>
						<ModalHeading heading="Edit email" onClose={onEditClose} />

						{/* <h2 className='modal__heading text-red'>Edit Email</h2> */}

						<form className="modalContainer__form" onSubmit={onEditEmailHandler}>
							{/* <div className="filter__input--labels">
								<label htmlFor="email" className="modal__text">
									Email Address
								</label>
								<div className="filter__input">
									<label htmlFor="email" className="filter__input--iconBox">
										<svg className="filter__input--icon">
											<use xlinkHref={`/sprite.svg#icon-email`} />
										</svg>
									</label>
									<input
										type="text"
										id="email"
										placeholder="johndoe@example.com"
										className="filter__input--text"
										value={editEmail.value}
										onChange={(event) => {
											setEditEmail({ value: event.target.value });
										}}
									/>
								</div>
								{editEmail.error && <p className="error_message">{editEmail.error}</p>}
							</div> */}

							<ModalTextBox
								label="Email Address"
								placeholder="johndoe@example.com"
								value={editEmail.value}
								icon="/sprite.svg#icon-email"
								onChange={(event) => {
									setEditEmail({ value: event.target.value });
								}}
								error={editEmail.error}
							/>
						</form>
						<div className="modal__actions">
							{/* <button className='modal__button--cancel' onClick={onEditClose}>
								Cancel
							</button> */}
							<button className="modal__button modal__button--update" onClick={onEditEmailHandler}>
								Update
							</button>
						</div>
					</Fragment>
				)}
			</Modal>

			<Modal show={modalState}>
				{deleteInviteAdmin && deleteInviteAdmin.loading ? (
					<DeleteInvite />
				) : (
					<Fragment>
						<ModalHeading heading="Delete invite" onClose={onClose} />

						{/* <h2 className="modal__heading text-red">Delete Invite</h2> */}

						<form className="modalContainer__form" onSubmit={onDeleteHandler}>
							<ModalTextBox
								label={
									<span className="modal__text">
										Please enter <span>{selectedInvite.emailAddress}</span> in text box
									</span>
								}
								placeholder="johndoe@example.com"
								value={deleteEmail.value}
								icon="/sprite.svg#icon-email"
								onChange={(event) => {
									setDeleteEmail({ value: event.target.value });
								}}
								error={deleteEmail.error}
							/>
						</form>
						<div className="modal__actions">
							<button className="modal__button modal__button--delete" onClick={onDeleteHandler}>
								Delete
							</button>
						</div>
					</Fragment>
				)}
			</Modal>
			<Invite
				data={{
					setModalState,
					setModalEditState,
					email,
					setEmail,
					onInviteAdminHandler,
					setSelectedInvite,
					setselectUpdateEmailInvite
				}}
				invitedAdmins={invitedAdmins}
				inviteAdmin={createInviteAdmin}
			/>
		</Content>
	);
};

export default InviteAdmins;
