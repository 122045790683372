import axios from 'axios';
import {
	GET_ALL_WEBSITES_REQUEST,
	GET_ALL_WEBSITES_SUCCESS,
	GET_ALL_WEBSITES_FAIL
} from '../constants/websiteConstants';

export const getAllWebsitesAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_WEBSITES_REQUEST });

		
		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/Websites`, config);

		dispatch({
			type: GET_ALL_WEBSITES_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_ALL_WEBSITES_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
