import React from 'react';
import './InviteAdmins.css';

function InvitedEmails({ data, invitedAdmins }) {
	const { setModalState, setSelectedInvite, setModalEditState, setselectUpdateEmailInvite } = data;
	return (
		<div className='pending-container'>
			{invitedAdmins &&
				invitedAdmins.data &&
				invitedAdmins.data.map((invite) => (
					<div className='pending-email' key={invite.adminInviteId}>
						<p>{invite.emailAddress}</p>
						<div className='flex'>
							<button
								className='table__button'
								onClick={() => {
									setModalEditState(true);
									setselectUpdateEmailInvite(invite);
								}}>
								<svg className='table__button--icon'>
									<use xlinkHref={`/sprite.svg#icon-edit`} />
								</svg>
								<span>Edit</span>
							</button>
							<button
								className='table__button table__button--delete'
								onClick={() => {
									setModalState(true);
									setSelectedInvite(invite);
								}}>
								<svg className=' table__button--icon-red'>
									<use xlinkHref={`/sprite.svg#icon-delete`} />
								</svg>
								<span>Delete</span>
							</button>
						</div>
					</div>
				))}
		</div>
	);
}

export default InvitedEmails;
