import React from 'react';
import ModalCheckBox from '../../components/Modal/ModalCheckBox';

const InviteUserForm = ({ props, addInvite, inviteEmail }) => {
    const { dataError, setDataError, userData, setUserData, secondInvite } = props;

    return (
        <form className='clinic--signupContainer__form'>

            <div className="formgroup--validation">
                <div className="addressContainer__formgroup--inputBox">
                    <div className="logoContainer__formgroup--iconBox">
                        <svg className="loginContainer__formgroup--icon">
                            <use xlinkHref="/sprite.svg#icon-email" />
                        </svg>
                    </div>
                    <input
                        type="text"
                        placeholder="Invite Email"
                        id="clinic-name"
                        value={inviteEmail}
                        //value={userData.value}
                        onChange={() => {
                            setUserData({ ...userData, email: inviteEmail })
                            setDataError('')
                        }}
                        className="loginContainer__formgroup--input"

                    />
                </div>
            </div>
            <div
                style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0 1rem 0' }}
            >
                <ModalCheckBox
                    label='Is Admin'
                    checked={userData.IsAdmin}
                    value=''
                    onChange={() => { }}
                // value={userData.IsAdmin}
                // onChange={()=> {
                //     setUserData({...userData, IsAdmin: !userData.IsAdmin})
                //     setDataError({...dataError, IsAdmin: '' })}}
                />
                <ModalCheckBox
                    label='Is Provider'
                    checked={userData.IsProvider}
                    value={userData.IsProvider}
                    onChange={() => {
                        if (userData.IsProvider === true) setUserData({ ...userData, IsProvider: !userData.IsProvider, npi: '', credentials: '', gender: '', validDeaCert: false })
                        else setUserData({ ...userData, IsProvider: !userData.IsProvider })
                    }}
                />
                {/* <div
            //style={{width: '100%',display: 'flex', justifyContent: 'space-evenly', margin: '1rem 0 1rem 0'}}
            >
                <ModalCheckBox
                    label='Valid DEA Certificate'
                    value={data.validDeaCert}
                    onChange={()=> {
                        setData({...data, validDeaCert: !data.validDeaCert})
                }}
                />
            </div> */}
            </div>

            <div className="flex-form">
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox">
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-user" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            placeholder="First Name"
                            id="firstName"
                            value={userData.FirstName}
                            onChange={(e) => {
                                setUserData({ ...userData, FirstName: e.target.value })
                                setDataError('')
                            }}
                            className="loginContainer__formgroup--input"
                        />
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.FirstName}</p>
                </div>
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox">
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-user" />
                            </svg>
                        </div>
                        <input
                            type="text"
                            placeholder="Last Name"
                            id="lastName"
                            value={userData.LastName}
                            onChange={(e) => {
                                setUserData({ ...userData, LastName: e.target.value })
                                setDataError('')
                            }}
                            className="loginContainer__formgroup--input"
                        />
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.LastName}</p>
                </div>
            </div>


            <div className="flex-form" >
                <div className="formgroup--validation" >
                    <div className="registerContainer__formgroup--inputBox" style={{
                        backgroundColor: userData.IsProvider ? null : 'var(--placeholder)'
                    }}>

                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-menu" />
                            </svg>
                        </div>

                        {userData && userData.IsProvider ?
                            <input
                                type="text"
                                placeholder="NPI"
                                id="firstName"
                                value={userData.npi}
                                onChange={(e) => {
                                    setUserData({ ...userData, npi: e.target.value })
                                    setDataError('')
                                }}
                                className="loginContainer__formgroup--input"
                            />
                            :
                            <input
                                type="text"
                                disabled
                                placeholder="NPI"
                                id="firstName"
                                value={userData.npi}
                                onChange={(e) => {
                                    setUserData({ ...userData, npi: e.target.value })
                                    setDataError('')
                                }}
                                className="loginContainer__formgroup--input"
                            />
                        }

                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.npi}</p>
                </div>
                <div className="formgroup--validation">
                    <div className="registerContainer__formgroup--inputBox" style={{
                        backgroundColor: userData.IsProvider ? null : 'var(--placeholder)'
                    }}>
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-status" />
                            </svg>
                        </div>
                        {userData && userData.IsProvider ?
                            <input
                                type="text"
                                placeholder="Licensure Credentials"
                                id="lastName"
                                value={userData.credentials}
                                onChange={(e) => {
                                    setUserData({ ...userData, credentials: e.target.value })
                                    setDataError('')
                                }}
                                className="loginContainer__formgroup--input"
                            />
                            :
                            <input
                                type="text"
                                disabled
                                placeholder="Licensure Credentials"
                                id="lastName"
                                value={userData.credentials}
                                onChange={(e) => {
                                    setUserData({ ...userData, credentials: e.target.value })
                                    setDataError('')
                                }}
                                className="loginContainer__formgroup--input"
                            />
                        }
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.credentials}</p>
                </div>
            </div>

            <div className='flex-form' style={{ alignItems: 'center', gap: '5rem', marginBottom: '2rem' }}>
                <div className="formgroup--validation smallInputContainer" >
                    <div className="registerContainer__formgroup--inputBox" style={{
                        backgroundColor: userData.IsProvider ? null : 'var(--placeholder)'
                    }}>
                        <div className="logoContainer__formgroup--iconBox">
                            <svg className="loginContainer__formgroup--icon">
                                <use xlinkHref="/sprite.svg#icon-user" />
                            </svg>
                        </div>
                        {userData && userData.IsProvider ?
                            <select
                                className='filter__input--select'
                                value={userData.gender}
                                onChange={(event) => {
                                    setUserData({ ...userData, gender: event.target.value })
                                    // setInviteDataError({...inviteDataError, email: '' })
                                }}
                                id='providers'>
                                <option value=''>Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                            </select>
                            :
                            <select
                                disabled
                                className='filter__input--select'
                                value={userData.gender}
                                onChange={(event) => {
                                    setUserData({ ...userData, gender: event.target.value })
                                    // setInviteDataError({...inviteDataError, email: '' })
                                }}
                                id='providers'>
                                <option value=''>Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                                <option value='Other'>Other</option>
                            </select>}
                    </div>
                    <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.lName}</p>
                </div>
                <div>
                    {userData && userData.IsProvider ?
                        <ModalCheckBox
                            label='Valid DEA Certificate'
                            checked={userData.validDeaCert}
                            value={userData.validDeaCert}
                            onChange={() => {
                                setUserData({ ...userData, validDeaCert: !userData.validDeaCert })
                            }}
                        />
                        :
                        <ModalCheckBox
                            label='Valid DEA Certificate'
                            disabled
                        />}
                </div>
            </div>

            {
                !secondInvite ?
                    <div className='header__container' style={{ padding: '0 0 1rem 1rem' }}>
                        <button onClick={addInvite} className='invite-user-add-button'>
                            <svg className='header__container--icon'>
                                <use xlinkHref={`/sprite.svg#icon-plus`} />
                            </svg>
                        </button>
                        <h2 className='header__heading'>Add additional user</h2>
                    </div> : null
            }
        </form>
    )
};

export default InviteUserForm;
