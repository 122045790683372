import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import ContentAdmin from '../../components/ContentAdmin';
import { AddClient, EditClient } from './AddClient';

import { AddClientSkeleton, ManageClientSkeleton } from '../../components/Skeletons/ManageClient';

import DeleteClient from './DeleteClient';
import './ManageClients.css';
import {
	addClientAction,
	deleteClientAction,
	editClientAction, activeInactiveAction, getAllClientAction
} from '../../redux/actions/userActions';
import { validateEmail, validatePhone } from '../../utils/validators';
import { ADD_CLIENT_RESET, DELETE_CLIENT_RESET, EDIT_CLIENT_RESET, ACTIVEINACTIVE_CLIENT_RESET } from '../../redux/constants/userConstants'


import ActiveInactiveModal from './activeInactiveModal';

const ManageClients = () => {
	const dispatch = useDispatch();

	const userLogin = useSelector((state) => state.userLogin);
	const activeInactiveClient = useSelector((state) => state.activeInactiveClient);
	const addClient = useSelector((state) => state.addClient);
	const deleteClient = useSelector((state) => state.deleteClient);
	const editClient = useSelector((state) => state.editClient);
	const allClientDetails = useSelector((state) => state.allClientDetails);

	// Add client
	const [addData, setAddData] = useState(
		{
			clientname: "",
			cloneId: '',
			providername: "",
			emailaddress: "",
			phoneno: "",
			file: ""
		}
	)
	const [editData, setEditData] = useState(
		{
			clientId: "",
			clientname: "",
			cloneId: '',
			providername: "",
			emailaddress: "",
			phoneno: "",
			file: "",
		}
	)
	const [addDataError, setAddDataError] = useState(
		{
			clientname: "",
			cloneId: '',
			providername: "",
			emailaddress: "",
			phoneno: "",
			file: ""
		}
	)
	const [editBackend, setEditBackend] = useState("")
	const [deleteData, setDeleteData] = useState({ deleteInput: '', deleteInputError: "" })
	const [clientData, setClientData] = useState({ clientId: '', isActive: false })
	// const [clinicName, setClinicName] = useState('')
	const [previewImage, setPreviewImage] = useState('')

	//Modals
	const [addModalState, setAddModalState] = useState(false);
	const [deleteModalState, setDeleteModalState] = useState(false);
	const [editModalState, setEditModalState] = useState(false);
	const [activeInactiveClientState, setActiveInactiveClientState] = useState(false);

	//login Data
	useEffect(
		() => {
			if (userLogin && userLogin.userInfo) {
				dispatch(getAllClientAction())
			}
		},
		[userLogin, dispatch]
	);

	//Add Client
	useEffect(
		() => {
			if (addClient && addClient.token && addClient.token.success === true) {
				setAddModalState(false)
				setAddData({
					clientname: "",
					providername: "",
					emailaddress: "",
					phoneno: "",
					file: "",
				})
				setPreviewImage("")
				toast.success(addClient.token.message);
				dispatch(getAllClientAction())

			} else if (addClient && addClient.error) {
				setAddModalState(false)
				toast.error(addClient.error)
				setAddData({
					clientname: "",
					providername: "",
					emailaddress: "",
					phoneno: "",
					file: "",
				})
				setPreviewImage("")
				dispatch(getAllClientAction())
			}
		},
		[addClient, dispatch]
	);

	//Edit Client
	useEffect(
		() => {
			if (editClient && editClient.token && editClient.token.success === true) {
				setEditModalState(false)
				setEditData({
					clientname: "",
					providername: "",
					emailaddress: "",
					phoneno: "",
					file: "",
				})
				toast.success(editClient.token.message);
				dispatch(getAllClientAction())
				dispatch({ type: EDIT_CLIENT_RESET });
			}
		},
		[editClient, dispatch]
	);

	//Client Activation
	useEffect(
		() => {
			if (activeInactiveClient && activeInactiveClient.token && activeInactiveClient.token.success === true) {
				setActiveInactiveClientState(false)
				setClientData({ ...clientData, clientId: '', isActive: false })
				toast.success(activeInactiveClient.token.message);
				dispatch(getAllClientAction())
				dispatch({ type: ACTIVEINACTIVE_CLIENT_RESET });
			}
		},
		[activeInactiveClient, dispatch, clientData]
	);

	//Delete Client
	useEffect(
		() => {
			if (deleteClient && deleteClient.token && deleteClient.token.success === true) {
				setDeleteModalState(false)
				setDeleteData("")
				setClientData({ ...clientData, clientId: '' })
				toast.success(deleteClient.token.message);
				dispatch(getAllClientAction())
				dispatch({ type: DELETE_CLIENT_RESET });
			} else if (deleteClient && deleteClient.error) {
				setDeleteModalState(false)
				setDeleteData("")
				toast.error(deleteClient.error.data);
			}
		},
		[deleteClient, dispatch, clientData]
	);
	useEffect(
		() => {
			return () => {
				dispatch({ type: ADD_CLIENT_RESET });
				dispatch({ type: DELETE_CLIENT_RESET });
				dispatch({ type: EDIT_CLIENT_RESET });
				dispatch({ type: ACTIVEINACTIVE_CLIENT_RESET });
			};
		},
		[dispatch]
	);

	const onDeleteClose = () => {
		setDeleteModalState(false);
		setDeleteData({
			deleteInput: '',
			deleteInputError: ''
		})
	};
	const onCloseAddModal = () => {
		setAddModalState(false);
		setAddData({
			clientname: "",
			providername: "",
			emailaddress: "",
			phoneno: "",
			file: "",
		})
		setPreviewImage("")
		setAddDataError("")
	};
	const onEditClose = () => {
		setEditModalState(false);
		setEditData('')
		setAddDataError('')
	};

	const onActiveClose = () => {
		setActiveInactiveClientState(false);
	};

	const addBtnClick = () => {

		setAddModalState(true);
	};

	const handleAddClient = (e) => {
		//e.preventDefault();
		if (!addData.clientname.trim() || addData.clientname.trim() === '') {
			setAddDataError({ ...addDataError, clientname: 'Please enter Name' });
		} else if (!addData.cloneId.trim() || addData.cloneId.trim() === '') {
			setAddDataError({ ...addDataError, cloneId: 'Please Select One' });
		} else if (!addData.providername.trim() || addData.providername.trim() === '') {
			setAddDataError({ ...addDataError, providername: 'Please enter Company Name' });
		} else if (!addData.emailaddress.trim() || addData.emailaddress.trim() === '') {
			setAddDataError({ ...addDataError, emailaddress: 'Please enter Email' });
		} else if (!validateEmail(addData.emailaddress)) {
			setAddDataError({ ...addDataError, emailaddress: 'Please enter a valid email' });
		} else if (!addData.phoneno.trim() || addData.phoneno.trim() === '') {
			setAddDataError({ ...addDataError, phoneno: 'Please enter a phone' });
		} else if (!validatePhone(addData.phoneno)) {
			setAddDataError({ ...addDataError, phoneno: 'Phone number must be between 5 to 12 digits' })
		}
		// else if(!addData.file.trim() || addData.file.trim() === ''){
		// 	setAddDataError({ ...addDataError, file: 'Please enter a logo' });
		// }
		else {
			let formData = new FormData();
			formData.append('clientname', addData.clientname)
			formData.append('CloneId', addData.cloneId)
			formData.append('providername', addData.providername)
			formData.append('emailaddress', addData.emailaddress)
			formData.append('phoneno', addData.phoneno)
			formData.append('file', addData.file)
			dispatch(addClientAction(formData));
		}

	};

	//edit data
	const handleEditClient = (e) => {
		//e.preventDefault();
		if (!editData.clientname.trim() || editData.clientname.trim() === '') {
			setAddDataError({ ...addDataError, clientname: 'Please enter Name' });
		}
		else if (!editData.emailaddress.trim() || editData.emailaddress.trim() === '') {
			setAddDataError({ ...addDataError, emailaddress: 'Please enter Email' });
		} else if (!validateEmail(editData.emailaddress)) {
			setAddDataError({ ...addDataError, emailaddress: 'Please enter a valid email' });
		} else if (!editData.phoneno.trim() || editData.phoneno.trim() === '') {
			setAddDataError({ ...addDataError, phoneno: 'Please enter a phone' });
		} else if (!validatePhone(editData.phoneno)) {
			setAddDataError({ ...addDataError, phoneno: 'Phone number must be between 5 to 12 digits' })
		}
		else {
			let formData = new FormData();
			formData.append('clientid', editData.clientId)
			formData.append('clientname', editData.clientname)
			formData.append('providername', editData.providername)
			formData.append('emailaddress', editData.emailaddress)
			formData.append('phoneno', editData.phoneno)
			formData.append('file', editData.file)
			dispatch(editClientAction(formData))
		}
	}

	const handleDeleteClient = () => {
		// if (deleteData.deleteInput !== clinicName) { 
		// 	setDeleteData({ ...deleteData, deleteInputError: "Incorrect Input, please enter again." })
		// } else {
		var Id = clientData.clientId
		dispatch(deleteClientAction(Id))
		// }
	}

	const handleActiveInactive = () => {

		const formData = {
			clientid: clientData.clientId,
			isactive: clientData.isActive,
		}
		dispatch(activeInactiveAction(formData))
	}

	return (
		<ContentAdmin
			currentMenu="manage-clients"
			headerTitle="Manage Clients"
			addBtn={true}
			addBtnText="Add Client"
			addBtnIcon="/sprite.svg#icon-plus"
			addBtnClick={addBtnClick}
		>
			<AddClient
				data={{
					addModalState,
					onCloseAddModal,
					handleAddClient,
					addData,
					setAddData,
					AddClientSkeleton,
					addClient,
					addDataError,
					setAddDataError,
					previewImage,
					setPreviewImage,
				}}
			/>
			<EditClient
				editModalState={editModalState}
				onEditClose={onEditClose}
				data={{ setEditData, editData, editBackend, setEditBackend, handleEditClient, AddClientSkeleton, editClient, addDataError, setAddDataError }}
			/>

			<DeleteClient
				deleteModalState={deleteModalState}
				onDeleteClose={onDeleteClose}
				data={{ 
					deleteData, 
					setDeleteData, 
					handleDeleteClient, 
					// clinicName, 
					deleteClient
				}}
			/>
			<ActiveInactiveModal
				data={{ activeInactiveClientState, onActiveClose, handleActiveInactive, activeInactiveClient }}
			/>

			{allClientDetails && allClientDetails.loading ?

				<ManageClientSkeleton />
				:
				// allClientDetails.userInfo[0].issuperadmin
				<div className='ManageClients'>
					{allClientDetails &&
						allClientDetails.userInfo &&
						allClientDetails.userInfo.map((user, i) => (
							<div className='ManageClients__options--outer' key={i + 1}>
								<div className='ManageClients__option'>
									<div className='ManageClients__option--toogle'>
										<img src={`data:image/jpeg;base64,${user.clientlogo}`}
											alt='CCP Logo' className='ManageClients__option--logo' />
										<label className="switch">
											<input
												id="active"
												type="checkbox"
												className="checkbox"
												checked={!user.clientactive}
												onChange={() => {
													setActiveInactiveClientState(true);
													setClientData({ ...clientData, clientId: user.clientId, isActive: !user.clientactive })
												}}
												name="active"
											/>
											<span className="slider round" />
										</label>
									</div>

									<div>
										<h2 className="ManageClients__option--heading">{user.clientname}</h2>
										<h3 className="ManageClients__option--provider">{user.clientprovidername}</h3>
									</div>
								</div>
								<div className="Manage__option--secondrow">
									<div className="ManageClients__option--secondrow--icon-1">
										<button onClick={() => {
											setEditModalState(true)
											setEditData({
												...editData,
												clientId: user.clientId,
												clientname: user.clientname,
												providername: user.clientprovidername,
												emailaddress: user.clientemail,
												phoneno: user.clientphoneno,
												file: user.clientlogo
											})
										}} className="ManageClients__option--link">
											<svg className="ManageClients__option--linkicon">
												<use xlinkHref={`/sprite.svg#icon-edit2`} />
											</svg>
										</button>
										<button
											// onClick={() => {
											// 	setDeleteModalState(true)
											// 	setClinicName(user.clientname)
											// 	setClientData({...clientData, clientId: user.clientId})
											// }}
											className="ManageClients__option--link"
										>
											<svg className="ManageClients__option--deleteicon">
												<use xlinkHref={`/sprite.svg#icon-delete2`} />
											</svg>
										</button>
									</div>
									<div className="ManageClients__option--secondrow--icon-2">
										<Link to="/dashboard" className="ManageClients__option--link-1">
											<svg className="ManageClients__option--icon">
												<use xlinkHref={`/sprite.svg#icon-right_arrow`} />
											</svg>
										</Link>
									</div>
								</div>
							</div>
						))}
				</div>
			}
		</ContentAdmin>
	);
};

export default ManageClients;
