import React, { Fragment } from 'react';

const FilterUsers = ({ data }) => {
	const {
		showFilter,
		setShowFilter,
		clinicProviders,
		allClinics,
		filter,
		setFilter,
		textInputs,
		setTextInputs,
		handleFilterSubmit,
		handleClear
	} = data;

	return (
		<Fragment>
			{!showFilter ? (
				<div className='filter__header'>
					<button id="filterHide" className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Hide Search
					</button>
				</div>
			) : (
				<div className='filter__header'>
					<button id="filterShow" className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Search Users
					</button>
				</div>
			)}
			<div className='filter' style={{ height: showFilter ? 0 : '28rem' }}>
				<div className='filter__inputs'>
					<h2 className='filter__heading'>Filter Users</h2>
					<div className='filter__input--top'>
						<select
							className='filter__input--select'
							value={filter.provider}
							onChange={(e) => setFilter({ ...filter, provider: e.target.value })}
							name='providers'
							id='providers'>
							<option value='0'>All Providers</option>

							{
							clinicProviders &&
								clinicProviders.data &&
								clinicProviders.data.map((provider) => (
									<option value={provider.providerId} key={provider.providerId}>
										{
										provider.displayName}
									</option>
								))}
						</select>
					</div>
					<div className='filter__input--top'>
						<select
							className='filter__input--select'
							value={filter.clinic}
							onChange={(e) => setFilter({ ...filter, clinic: e.target.value })}
							name='clinics'
							id='clinics'>
							<option value='0'>All Clinics</option>
							{allClinics &&
								allClinics.data &&
								allClinics.data.map((clinic) => (
									clinic.name && !clinic.isDeleted ?
									<option value={clinic.clinicId} key={clinic.clinicId}>
										{clinic.name}
									</option>
									:
									null
								))}
						</select>
					</div>
					<div className='filter__input--top'>
						<select
							className='filter__input--select'
							value={filter.role}
							onChange={(e) => setFilter({ ...filter, role: e.target.value })}
							name='roles'
							id='roles'>
							<option value='All'>All Roles</option>
							<option value='Patient'>Patient</option>
							<option value='Clinic Provider'>Clinic Provider</option>
							<option value='Clinic Admin'>Clinic Admin</option>
						</select>
					</div>
					<div className='filter__input--top'>
						<select
							className='filter__input--select'
							value={filter.confirmed}
							onChange={(e) => setFilter({ ...filter, confirmed: e.target.value })}
							name='confirmed'
							id='confirmed'>
							<option value='All'>Either</option>
							<option value='Confirmed'>Confirmed</option>
							<option value='Not Confirmed'>Not Confirmed</option>
						</select>
					</div>
				</div>
				<div className='filter__content'>
					{/* <div className='filter__content--top'> */}
						<div className='filter__input--labels'>
							<label htmlFor='email' className='filter__input--label'>
								User Email
							</label>
							<div className='filter__input'>
								<label htmlFor='email' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-email`} />
									</svg>
								</label>
								<input
									type='text'
									id='email'
									placeholder='User Email'
									value={textInputs.email}
									onChange={(e) => setTextInputs({ ...textInputs, email: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>

						<div className='filter__input--labels'>
							<label htmlFor='firstName' className='filter__input--label'>
								First Name
							</label>
							<div className='filter__input'>
								<label htmlFor='firstName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='firstName'
									placeholder='First Name'
									value={textInputs.fName}
									onChange={(e) => setTextInputs({ ...textInputs, fName: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='lastName' className='filter__input--label'>
								Last Name
							</label>
							<div className='filter__input'>
								<label htmlFor='lastName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='lastName'
									placeholder='Last Name'
									value={textInputs.lName}
									onChange={(e) => setTextInputs({ ...textInputs, lName: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='date' className='filter__input--label'>
								Date of Birth
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='date'
									name='date'
									value={textInputs.dob}
									onChange={(e) => setTextInputs({ ...textInputs, dob: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='assessments' className='filter__input--label'>
								Recent Assessments
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='assessments'
									name='assessments'
									value={textInputs.assessments}
									onChange={(e) => setTextInputs({ ...textInputs, assessments: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--buttonContainer'>
							<button className='filter__input--button' id='searchBtn' onClick={handleFilterSubmit}>
								Search
							</button>
							<button className='filter__input--button' id='clearBtn' onClick= {handleClear}>
								Clear
							</button>
						</div>

					{/* </div> */}

				</div>
			</div>
		</Fragment>
	);
};

export default FilterUsers;
