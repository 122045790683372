import React, { Fragment } from 'react';

 export const PersonalInfo = () => {
	return (
		<Fragment>
			<div className='profileContainer__form' style={{marginTop:'2rem'}}>
				<div style={{display: 'flex', width: '100%'}}>
					<div className='profile__input--labels' style={{marginRight: '1rem'}}>
						<div className='profile__text--skeleton skeleton' />
						<div className='profile__input--skeleton skeleton' />
					</div>
					<div className='profile__input--labels'>
						<div className='profile__text--skeleton skeleton' />
						<div className='profile__input--skeleton skeleton' />
					</div>
				</div>
				
				<br/>
				<div className='profile__input--labels'>
					<div className='profile__text--skeleton skeleton' />
					<div className='filter__input--email-skeleton skeleton' />
					
				</div>
                <div className='profile--skeleton__button'>
				    <div className='filter__input--button-skeleton skeleton' />
			    </div>
			</div>
			
		</Fragment>
	);
};
 export const ResetPassword = () => {
	return (
		<Fragment>
			<div className='resetContainer__form' style={{marginTop:'2rem'}}>
				
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
				<div className='profile__input--labels'>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
                <div className='profile--skeleton__button' style={{marginTop: '1rem'}}>
				    <div className='resetPassword__button--delete-skeleton skeleton' />
			    </div>
			</div>
			
		</Fragment>
	);
};


export const MyProfile = () => {
	return (
		<Fragment>
			<div className='resetContainer__form' style={{marginTop:'2rem', maxWidth:'40vh'}}> 
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
				<div className='profile__input--labels' style={{marginBottom: '1rem'}}>
                    <div className='password__text--skeleton skeleton' />
					<div className='profile__input--skeleton skeleton' />
				</div>
                <div className='profile--skeleton__button'  style={{flexDirection: 'row',display:'flex',gap:'2rem'}}>
				    <div className='filter__input--button-skeleton skeleton' />
				    <div className='filter__input--button-skeleton skeleton' /> 
			    </div>
			</div>
			
		</Fragment>
	);
};