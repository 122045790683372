import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import ModalCheckBox from '../../components/Modal/ModalCheckBox';

export const AddInvite = ({ data }) => {
	const {
		userLogin,
		addModalState,
		onCloseAddModal,
		handleAddInvite,
		inviteData,
		setInviteData,
		inviteDataError,
		setInviteDataError
		// AddClientSkeleton,
		// addClient,
		// addDataError,
		// setAddDataError
	} = data;

	return (
		<Modal
			show={addModalState}
		>
			<ModalHeading heading="New Invite"
				onClose={onCloseAddModal}
			/>
			{/* {
				addClient && addClient.loading?
				<AddClientSkeleton/>
				: */}
			<ModalForm onSubmit={handleAddInvite} style={{ marginBottom: '2.5rem', height: '60vh', overflow: 'auto' }}>
				<div style={{}}>
					<InputsSection style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
						<ModalTextBox
							style={{ width: '45rem' }}
							type="email"
							icon="/sprite.svg#icon-email"
							label="Email Address"
							id="Email"
							placeholder="email"
							value={inviteData.email}
							onChange={(event) => {
								setInviteData({ ...inviteData, email: event.target.value })
								setInviteDataError({ ...inviteDataError, email: '' })
							}}
							error={inviteDataError.email}
						/>
						<div style={{ display: 'flex', gap: '2rem', justifyContent: 'flex-end', width: '90%' }}>
							<ModalTextBox
								type="password"  autoComplete="new-password"
								icon="/sprite.svg#icon-key"
								label="Password"
								id="Email"
								placeholder="****"
								value={inviteData.password}
								onChange={(event) => {
									setInviteData({ ...inviteData, password: event.target.value })
									setInviteDataError({ ...inviteDataError, password: '' })
								}}
							// error={inviteDataError.password}
							/>
							<ModalTextBox
								type="password"  autoComplete="new-password"
								icon="/sprite.svg#icon-key"
								label="Confirm Password"
								id="Email"
								placeholder="****"
								value={inviteData.confirmPassword}
								onChange={(event) => {
									setInviteData({ ...inviteData, confirmPassword: event.target.value })
									setInviteDataError({ ...inviteDataError, confirmPassword: '' })
								}}
								error={inviteDataError.confirmPassword}
							/>
						</div>
						<p className='error_message'>{inviteDataError.password}</p>
						<p style={{ color: 'var(--light-dark)' }}>Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character</p>
						<div style={{ display: 'flex', gap: '2rem' }}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-user"
								label="First Name"
								id="clientName"
								placeholder="Jon"
								value={inviteData.firstName}
								onChange={(event) => {
									setInviteData({ ...inviteData, firstName: event.target.value })
									setInviteDataError({ ...inviteDataError, firstName: '' })
								}}
								error={inviteDataError.firstName}
							/>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-user"
								label="Last Name"
								id="providerName"
								placeholder="Doe"
								value={inviteData.lastName}
								onChange={(event) => {
									setInviteData({ ...inviteData, lastName: event.target.value })
									setInviteDataError({ ...inviteDataError, lastName: '' })
								}}
								error={inviteDataError.lastName}
							/>
						</div>
					</InputsSection>
					<InputsSection style={{ display: 'flex', flexDirection: 'column' }}>
						<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
							<ModalCheckBox
								label='Admin'
								checked={inviteData.isAdmin}
								value={inviteData.isAdmin}
								onChange={() => {
									setInviteData({ ...inviteData, isAdmin: !inviteData.isAdmin })
									setInviteDataError({ ...inviteDataError, isAdmin: '' })
								}}
							/>
							<ModalCheckBox
								label='Provider'
								checked={inviteData.isProvider}
								value={inviteData.isProvider}
								onChange={() => {
									if (inviteData.isProvider === true) setInviteData({ ...inviteData, isProvider: !inviteData.isProvider, npi: '', credentials: '', gender: '', shownAs: '', validDEACert: false, displayAsClinic: false })
									else setInviteData({ ...inviteData, isProvider: !inviteData.isProvider })
									setInviteDataError({ ...inviteDataError, isAdmin: '' })
								}}
							/>
						</div>
						<p className='error_message__deleteClient'>{inviteDataError.isAdmin}</p>
						<div style={{ display: 'flex', gap: '2rem' }}>
							{inviteData.isProvider ?
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-menu"
									label="NPI Number"
									id="clientName"
									//placeholder="Jon"
									value={inviteData.npi}
									onChange={(event) => {
										setInviteData({ ...inviteData, npi: event.target.value })
										// setInviteDataError({...inviteDataError, email: '' })
									}}
								/>
								:
								<ModalTextBox
									style={{ backgroundColor: 'var(--placeholder)' }}
									disabled
									type="text"
									icon="/sprite.svg#icon-menu"
									label="NPI Number"
									id="clientName"
									//placeholder="Jon"
									value={inviteData.npi}
									onChange={(event) => {
										setInviteData({ ...inviteData, npi: event.target.value })
										// setInviteDataError({...inviteDataError, email: '' })
									}}
								/>}
							{inviteData.isProvider ?
								<ModalTextBox
									type="text"
									icon="/sprite.svg#icon-status"
									label="Credential"
									id="providerName"
									//placeholder="Doe"
									value={inviteData.credentials}
									onChange={(event) => {
										setInviteData({ ...inviteData, credentials: event.target.value })
										// setInviteDataError({...inviteDataError, email: '' })
									}}
								/>
								:
								<ModalTextBox
									style={{ backgroundColor: 'var(--placeholder)' }}
									disabled
									type="text"
									icon="/sprite.svg#icon-status"
									label="Credential"
									id="providerName"
									placeholder="Doe"
									value={inviteData.credentials}
									onChange={(event) => {
										setInviteData({ ...inviteData, credentials: event.target.value })
										// setInviteDataError({...inviteDataError, email: '' })
									}}
								/>}
						</div>
						<div style={{ display: 'flex', gap: '2rem', width: '90%' }}>
							{inviteData.isProvider ?
								<div className='modal_input' >
									<label htmlFor="email" className="modal_input--label">
										Gender
									</label>
									<div className='modal_input--box' style={{ width: '21rem' }}>
										<label htmlFor="email" className="modal_input--iconBox">
											<svg className="modal_input--icon">
												<use xlinkHref={`/sprite.svg#icon-user`} />
											</svg>
										</label>
										<select
											className='filter__input--select'
											value={inviteData.gender}
											onChange={(event) => {
												setInviteData({ ...inviteData, gender: event.target.value })
												// setInviteDataError({...inviteDataError, email: '' })
											}}
											name='providers'
											id='providers'>
											<option value={null}></option>
											<option value='Male'>Male</option>
											<option value='Female'>Female</option>
											<option value='Other'>Other</option>
										</select>
									</div>
								</div>
								:
								<div className='modal_input' >
									<label htmlFor="email" className="modal_input--label">
										Gender
									</label>
									<div className='modal_input--box' style={{ width: '21rem', backgroundColor: 'var(--placeholder)' }}>
										<label htmlFor="email" className="modal_input--iconBox">
											<svg className="modal_input--icon">
												<use xlinkHref={`/sprite.svg#icon-user`} />
											</svg>
										</label>
										<select
											disabled
											className='filter__input--select'
											value={inviteData.gender}
											onChange={(event) => {
												setInviteData({ ...inviteData, gender: event.target.value })
												// setInviteDataError({...inviteDataError, email: '' })
											}}
											name='providers'
											id='providers'>
											<option value={null}></option>
											<option value='Male'>Male</option>
											<option value='Female'>Female</option>
											<option value='Other'>Other</option>
										</select>
									</div>
								</div>}
							{inviteData.isProvider ?
								<ModalCheckBox
									label='Valid DEA Certificate'
									value={inviteData.validDEACert}
									onChange={() => {
										setInviteData({ ...inviteData, validDEACert: !inviteData.validDEACert })
									}
									}
								/>
								:
								<ModalCheckBox
									disabled
									label='Valid DEA Certificate'
									checked={inviteData.validDEACert}
									value={inviteData.validDEACert}
									onChange={() => {
										setInviteData({ ...inviteData, validDEACert: !inviteData.setInviteData({ ...inviteData, isProvider: !inviteData.isProvider }) })
									}
									}
								/>
							}

							{/* {inviteData.isProvider?
						<ModalTextBox
							type="text"
							icon="/sprite.svg#icon-edit"
							label="Shown As"
							id="providerName"
							placeholder="Doe"
							value={inviteData.shownAs}
							onChange={(event) => {
							setInviteData({...inviteData, shownAs: event.target.value })
							// setInviteDataError({...inviteDataError, email: '' })
							}}
						/>
						:
						<ModalTextBox
						style={{backgroundColor: 'var(--placeholder)'}}
						disabled
							type="text"
							icon="/sprite.svg#icon-edit"
							label="Shown As"
							id="providerName"
							placeholder="Doe"
							value={inviteData.shownAs}
							onChange={(event) => {
							setInviteData({...inviteData, shownAs: event.target.value })
							// setInviteDataError({...inviteDataError, email: '' })
							}}
						/>} */}
						</div>
						{/*	<div style={{ display: 'flex', gap: '2rem', justifyContent: 'space-evenly', width: '100%' }}>
							
							 {inviteData.isProvider?
							<ModalCheckBox
							label='Display name as clinic name'
							checked={inviteData.displayAsClinic}
							value={inviteData.displayAsClinic}
							onChange={()=> setInviteData({...inviteData, displayAsClinic: !inviteData.displayAsClinic}) }
							/>
							:
							<ModalCheckBox
							disabled
							label='Display name as clinic name'
							value={inviteData.displayAsClinic}
							onChange={()=> setInviteData({...inviteData, displayAsClinic: !inviteData.displayAsClinic}) }
							/>} 
						</div>*/}
					</InputsSection>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<ModalButtonPrimary
						title="Submit" onClick={handleAddInvite} />
				</div>
			</ModalForm>
			{/* } */}

		</Modal>
	);
};


export const AddNewInvite = ({ data }) => {
	const {
		addModalState,
		onCloseAddModal,
		handleAddInvite,
		inviteData,
		setInviteData,
		inviteDataError,
		setInviteDataError
		// AddClientSkeleton,
		// addClient,
		// addDataError,
		// setAddDataError
	} = data;

	return (
		<Modal
			show={addModalState}
		>
			<ModalHeading heading="New Invite"
				onClose={onCloseAddModal}
			/>
			{/* {
				addClient && addClient.loading?
				<AddClientSkeleton/>
				: */}
			<ModalForm onSubmit={handleAddInvite} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-email"
						label="Email Address"
						id="Email"
						placeholder="email"
						value={inviteData.email}
						onChange={(event) => {
							setInviteData({ ...inviteData, email: event.target.value })
							setInviteDataError({ ...inviteDataError, email: '' })
						}}
						error={inviteDataError.email}
					/>
					<ModalTextBox
						type="password"  autoComplete="new-password"
						icon="/sprite.svg#icon-phone"
						label="Password"
						id="Email"
						placeholder="****"
						value={inviteData.password}
						onChange={(event) => {
							setInviteData({ ...inviteData, password: event.target.value })
							setInviteDataError({ ...inviteDataError, password: '' })
						}}
						error={inviteDataError.password}
					/>
				</InputsSection>

				<InputsSection
					style={{
						display: 'flex',
						flexDirection: 'column'
					}}
				>
					<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
						<ModalCheckBox
							label='Admin'
							value={inviteData.isAdmin}
							onChange={() => {
								setInviteData({ ...inviteData, isAdmin: !inviteData.isAdmin })
								setInviteDataError({ ...inviteDataError, isAdmin: '' })
							}}
						//error={inviteDataError.isAdmin}
						/>

						<ModalCheckBox
							label='Provider'
							value={inviteData.isProvider}
							onChange={() => {
								setInviteData({ ...inviteData, isProvider: !inviteData.isProvider })
								setInviteDataError({ ...inviteDataError, isAdmin: '' })
							}}
						// error={inviteDataError.isAdmin}
						/>
					</div>
					<p className='error_message__deleteClient'>{inviteDataError.isAdmin}</p>

					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-name"
								label="First Name"
								id="clientName"
								placeholder="Jon"
								value={inviteData.firstName}
								onChange={(event) => {
									setInviteData({ ...inviteData, firstName: event.target.value })
									setInviteDataError({ ...inviteDataError, firstName: '' })
								}}
								error={inviteDataError.firstName}
							/>

							{/* <p className='error_message__deleteClient'>{inviteDataError.firstName}</p> */}
						</div>

						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Last Name"
								id="providerName"
								placeholder="Doe"
								value={inviteData.lastName}
								onChange={(event) => {
									setInviteData({ ...inviteData, lastName: event.target.value })
									setInviteDataError({ ...inviteDataError, lastName: '' })
								}}
								error={inviteDataError.lastName}
							/>
							{/* <p className='error_message__deleteClient'>{inviteDataError.lastName}</p> */}
						</div>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<ModalTextBox

								type="text"
								icon="/sprite.svg#icon-name"
								label="NPI Number"
								id="clientName"
								placeholder="Jon"
								value={inviteData.npi}
								onChange={(event) => {
									setInviteData({ ...inviteData, npi: event.target.value })
									// setInviteDataError({...inviteDataError, email: '' })
								}}

							/>

							{/* <p className='error_message__deleteClient'>{addDataError.clientname}</p> */}
						</div>

						<div style={{}}>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-provider"
								label="Credential"
								id="providerName"
								placeholder="Doe"
								value={inviteData.credentials}
								onChange={(event) => {
									setInviteData({ ...inviteData, credentials: event.target.value })
									// setInviteDataError({...inviteDataError, email: '' })
								}}
							/>
							{/* <p className='error_message__deleteClient'>{addDataError.providername}</p> */}
						</div>
					</div>
					<div style={{
						display: 'flex',
						justifyContent: 'flex-start',
						gap: '2rem'
					}}>
						<div style={{}}>
							<div className='modal_input'>
								<label htmlFor="email" className="modal_input--label">
									Gender
								</label>
								<div className='modal_input--box' style={{ width: '22rem' }}>
									<label htmlFor="email" className="modal_input--iconBox">
										<svg className="modal_input--icon">
											<use xlinkHref={`/sprite.svg#icon-user`} />
										</svg>
									</label>
									<select
										className='filter__input--select'
										value={inviteData.gender}
										onChange={(event) => {
											setInviteData({ ...inviteData, gender: event.target.value })
											// setInviteDataError({...inviteDataError, email: '' })
										}}
										name='providers'
										id='providers'>
										<option value='Male'>Male</option>
										<option value='Female'>Female</option>
										<option value='Other'>Other</option>
									</select>
								</div>
							</div>


							{/* <p className='error_message__deleteClient'>{addDataError.clientname}</p> */}
						</div>

						{/* <div style={{}}>
							<ModalTextBox
							type="text"
							icon="/sprite.svg#icon-provider"
							label="Shown As"
							id="providerName"
							placeholder="Doe"
							value={inviteData.shownAs}
							onChange={(event) => {
							setInviteData({...inviteData, shownAs: event.target.value })
							// setInviteDataError({...inviteDataError, email: '' })
							}}
						/>
						<p className='error_message__deleteClient'>{addDataError.providername}</p>
					</div> */}
					</div>

					<div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
						<ModalCheckBox
							label='Valid DEA Certificate'
							value={inviteData.validDEACert}
							onChange={() => {
								setInviteData({ ...inviteData, validDEACert: !inviteData.validDEACert })
							}
							}
						/>
						{/* <ModalCheckBox
							label='Display name as clinic name'
							value={inviteData.displayAsClinic}
							onChange={() => setInviteData({ ...inviteData, displayAsClinic: !inviteData.displayAsClinic })}
						/> */}
					</div>
				</InputsSection>

				<ModalButtonPrimary title="Submit" onClick={handleAddInvite} />
			</ModalForm>
			{/* } */}

		</Modal>
	);
};
