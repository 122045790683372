import React, { Fragment } from 'react';
import TableRows from '../../components/Skeletons/TableRows';
// import { baaPdfAction } from '../../redux/actions/userActions';
// import { useDispatch } from 'react-redux';

const ShowData = ({ data, loading }) => {
	// const dispatch = useDispatch();

	const { nextContentFrom, manageUserList, setProviderActiveState, setUserId, setIsDeleted } = data; 

	// const getClinicBAA = async (selectedClinic) => {
	// 	const data = await dispatch(baaPdfAction(selectedClinic))
	// 	if (data && data.status) {
	// 		window.open(data.pdfURL)
	// 	} else {
	// 		alert("Invalid Clinic Id");
	// 	}
	// };


	return (
		<Fragment>
			<div className="tableContainer" style={{ height: '55vh' }}>
				<table className="table">
					<thead>
						<tr>
							<th>#</th>
							{/* <th>Provider</th> */}
							<th>Email</th>
							<th>User F/L Name</th>
							<th>Birth Date</th>
							{/* <th>BAA Document</th> */}
							<th>Confirmed</th>
							<th>Activate/Deactivate</th>
						</tr>
					</thead>
					<tbody>
						{manageUserList && !loading ? manageUserList.length > 0 ? (
							manageUserList.map((user, index) => (
								<tr key={index + 1}>
									<td>{nextContentFrom + index + 1}</td>
									{/* <td>{user.clinicName}</td> */}
									<td>{user.email}</td>
									<td>{user.firstName + ' ' + user.lastName}</td>
									<td>{new Date(user.birthdate).toLocaleDateString()}</td>
									{/* <td>
										<button className="table__button2"
											onClick={getClinicBAA(user.clinicId)}
										>
											<svg className="table__icon primary-icon" style={{ width: '3rem', height: '3rem' }}>
												<use xlinkHref={`/sprite.svg#icon-pdf`} />
											</svg>
											<span>PDF</span>
										</button>
									</td> */}
									<td className="table__iconBox">
										{user.emailConf ? (
											<svg className="table__icon primary-icon">
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className="table__icon red-icon">
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td>
										<label className="switch">
											<input
												id="active"
												type="checkbox"
												className="checkbox"
												checked={!user.isDeleted}
												onChange={() => {
													setProviderActiveState(true);
													setUserId(user.id);
													setIsDeleted(user.isDeleted);
												}}
												name="active"
											/>
											<span className="slider round" />
										</label>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="7">No users found</td>
							</tr>
						) : (
							<TableRows columns="7" />
						)}
					</tbody>
				</table>
			</div>
		</Fragment>
	);
};

export default ShowData;
