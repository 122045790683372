import axios from 'axios';
import {
  REGISTER_CLINIC_REQUEST,
  REGISTER_CLINIC_SUCCESS,
  REGISTER_CLINIC_FAIL,
  INVITE_CLINIC_REQUEST,
  INVITE_CLINIC_SUCCESS,
  INVITE_CLINIC_FAIL,
  ADD_INVITE_CLINIC_REQUEST,
  ADD_INVITE_CLINIC_SUCCESS,
  ADD_INVITE_CLINIC_FAIL,
  DELETE_INVITE_CLINIC_REQUEST,
  DELETE_INVITE_CLINIC_SUCCESS,
  DELETE_INVITE_CLINIC_FAIL,
  GET_CLINIC_PROVIDERS_REQUEST,
  GET_CLINIC_PROVIDERS_SUCCESS,
  GET_CLINIC_PROVIDERS_FAIL,
  GET_CLINICS_REQUEST,
  GET_CLINICS_SUCCESS,
  GET_CLINICS_FAIL,
  GET_PROVIDER_BY_ID_REQUEST,
  GET_PROVIDER_BY_ID_SUCCESS,
  GET_PROVIDER_BY_ID_FAIL,
  UPDATE_INVITE_CLINIC_REQUEST,
  UPDATE_INVITE_CLINIC_SUCCESS,
  UPDATE_INVITE_CLINIC_FAIL,
  ALL_CLINICS_TOGGLE_REQUEST,
  ALL_CLINICS_TOGGLE_SUCCESS,
  ALL_CLINICS_TOGGLE_FAIL,
  PROVIDER_CLINICS_TOGGLE_REQUEST,
  PROVIDER_CLINICS_TOGGLE_SUCCESS,
  PROVIDER_CLINICS_TOGGLE_FAIL,
  REGISTER_CLINIC_NO_INVITE_REQUEST,
  REGISTER_CLINIC_NO_INVITE_SUCCESS,
  REGISTER_CLINIC_NO_INVITE_FAIL,
  REGISTER_CLINIC_INITIAL_SUCCESS,
  REGISTER_CLINIC_INITIAL_REQUEST,
  REGISTER_CLINIC_INITIAL_FAIL,
  REGISTER_CLINIC_USER_REQUEST,
  REGISTER_CLINIC_USER_SUCCESS,
  REGISTER_CLINIC_USER_FAIL,
  CLINIC_SIGN_UP_REQUEST,
  CLINIC_SIGN_UP_SUCCESS,
  CLINIC_SIGN_UP_FAIL,
  GET_NPI_NUMBER_DETAIL_SUCCESS,
  GET_NPI_NUMBER_DETAIL_REQUEST,
  GET_NPI_NUMBER_DETAIL_FAIL,
  GET_NOTIFICATION_TEMP_REQUEST,
  GET_NOTIFICATION_TEMP_SUCCESS,
  GET_NOTIFICATION_TEMP_FAIL,
} from '../constants/clinicsConstants';
import { BASE_URL, BASE_URL_LOGIN } from './ip';
import { logout } from './userActions';

export const registerClinicAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTER_CLINIC_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'custom-clinicid-header': userInfo.clinicid,
        },
      };

      const { data } = await axios.post(
        `${BASE_URL}/Clinics/Signup`,
        formData,
        config
      );

      dispatch({
        type: REGISTER_CLINIC_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: REGISTER_CLINIC_FAIL,
        payload:
          err.response && err.response.data.msg
            ? err.response.data.msg
            : err.message,
      });
    }
  };

export const registerClinicInitialAdminAction =
  (Email, Password) => async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTER_CLINIC_INITIAL_REQUEST });

      const { data } = await axios.post(
        `${BASE_URL}/auth/RegisterClinicInitialAdmin`,
        {
          Email,
          Password,
          websiteId: '98DF879A-A222-4B39-AFA0-FE4C38429EA8',
        }
      );

      dispatch({
        type: REGISTER_CLINIC_INITIAL_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: REGISTER_CLINIC_INITIAL_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const registerClinicNoInviteAction =
  (Email, Password) => async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTER_CLINIC_NO_INVITE_REQUEST });

      const config = {
        headers: {
          'custom-clinicid-header': 1,
        },
      };
      const { data } = await axios.post(
        `${BASE_URL_LOGIN}/auth/RegisterClinicInitialAdminNoInvite`,
        {
          Email,
          Password,
          websiteId: '98DF879A-A222-4B39-AFA0-FE4C38429EA8',
        },
        config
      );

      dispatch({
        type: REGISTER_CLINIC_NO_INVITE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: REGISTER_CLINIC_NO_INVITE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const registerClinicAfterPayment =
  (Email, Password) => async (dispatch, getState) => {
    try {
      const config = {
        headers: {
          'custom-clinicid-header': 1,
        },
      };

      const { data } = await axios.post(
        `${BASE_URL_LOGIN}/Auth/RegisterClinicInitialStripe`,
        {
          email: Email,
          password: Password,
          startDateTime: '2022-12-16T12:36:48.524Z',
          endDateTime: '2022-12-16T12:36:48.524Z',
          websiteId: '98DF879A-A222-4B39-AFA0-FE4C38429EA8',
        },
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    }
  };

export const clinicSignUpAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLINIC_SIGN_UP_REQUEST });
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };
    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/Clinics/SignUp`,
      formData,
      config
    );

    dispatch({
      type: CLINIC_SIGN_UP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CLINIC_SIGN_UP_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
export const registerClinicUserAction =
  (Email, Password) => async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTER_CLINIC_USER_REQUEST });

      const config = {
        headers: {
          'custom-clinicid-header': 1,
        },
      };
      const { data } = await axios.post(
        `${BASE_URL_LOGIN}/auth/RegisterClinicUser`,
        {
          Email,
          Password,
          websiteId: '98DF879A-A222-4B39-AFA0-FE4C38429EA8',
        },
        config
      );

      dispatch({
        type: REGISTER_CLINIC_USER_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: REGISTER_CLINIC_USER_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const providerClinicsToggleAction =
  (userId, isDeleted) => async (dispatch, getState) => {
    try {
      dispatch({ type: PROVIDER_CLINICS_TOGGLE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'custom-clinicid-header': userInfo.clinicid,
        },
      };

      const res = await axios.post(
        `${userInfo.apiurl}/Providers/SaveClinicEDbyprovider`,
        {
          id: userId,
          isdisabled: !isDeleted,
          type: 1,
        },
        config
      );

      dispatch({
        type: PROVIDER_CLINICS_TOGGLE_SUCCESS,
        payload: res.data,
      });
    } catch (err) { 
      dispatch({
        type: PROVIDER_CLINICS_TOGGLE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const allClinicsToggleAction =
  (userId, isDeleted) => async (dispatch, getState) => {
    try {
      dispatch({ type: ALL_CLINICS_TOGGLE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'custom-clinicid-header': userInfo.clinicid,
        },
      };

      const res = await axios.post(
        `${userInfo.apiurl}/Providers/SaveClinicEDbyprovider`,
        {
          id: userId,
          isdisabled: !isDeleted,
          type: 0,
        },
        config
      );

      dispatch({
        type: ALL_CLINICS_TOGGLE_SUCCESS,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: ALL_CLINICS_TOGGLE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const allClinicPillCountToggleAction =
  (clinicId, pillCount) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'custom-clinicid-header': userInfo.clinicid,
        },
      };

      const res = await axios.post(
        `${userInfo.apiurl}/Providers/saveClinicEDPillCountbyprovider`,
        {
          id: clinicId,
          isdisabled: !pillCount,
          type: 0,
        },
        config
      );

      return res;
    } catch (err) {
      return err.response && err.response.data.message
        ? err.response.data.message
        : err.message;
    }
  };

export const inviteClinicActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: INVITE_CLINIC_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/clinicinvites`,
      config
    );

    dispatch({
      type: INVITE_CLINIC_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: INVITE_CLINIC_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const addInviteClinicAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_INVITE_CLINIC_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'custom-clinicid-header': userInfo.clinicid,
        },
      };

      const { data } = await axios.post(
        `${userInfo.apiurl}/clinicinvites`,
        formData,
        config
      );

      dispatch({
        type: ADD_INVITE_CLINIC_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADD_INVITE_CLINIC_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const deleteInviteClinicAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_INVITE_CLINIC_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.delete(
      `${userInfo.apiurl}/clinicinvites/${id}`,
      config
    );

    dispatch({
      type: DELETE_INVITE_CLINIC_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_INVITE_CLINIC_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const clinicProvidersAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CLINIC_PROVIDERS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const {
      activeToken: { token },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/Providers/ClinicProviders`,
      config
    );

    dispatch({
      type: GET_CLINIC_PROVIDERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_CLINIC_PROVIDERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getAllClinicsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CLINICS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const {
      activeToken: { token },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Clinics`, config);

    dispatch({
      type: GET_CLINICS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_CLINICS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const getClinicProviderById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PROVIDER_BY_ID_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };
    const { data } = await axios.get(
      `${userInfo.apiurl}/Providers/GetProviderByIdList?clinicid=${id}`,
      config
    );

    dispatch({
      type: GET_PROVIDER_BY_ID_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROVIDER_BY_ID_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const editClinicEmailAction =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_INVITE_CLINIC_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          'custom-clinicid-header': userInfo.clinicid,
        },
      };
      const { data } = await axios.put(
        `${userInfo.apiurl}/ClinicInvites/${id}`,
        formData,
        config
      );

      dispatch({
        type: UPDATE_INVITE_CLINIC_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: UPDATE_INVITE_CLINIC_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const getClinicNPIDetailsAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_NPI_NUMBER_DETAIL_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const { data } = await axios.get(
      `https://npiregistry.cms.hhs.gov/api/?version=2.0&number=${id}`,
      config
    );

    dispatch({
      type: GET_NPI_NUMBER_DETAIL_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_NPI_NUMBER_DETAIL_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};


export const getNotificationTemplateAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_NOTIFICATION_TEMP_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const {
      activeToken: { token },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Notification/getTemplateList`, config);

    dispatch({
      type: GET_NOTIFICATION_TEMP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_NOTIFICATION_TEMP_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
