import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeadingVideo';

import { ModalButtonDelete, ModalButtonPrimary } from '../../components/Modal/ModalButton';


const PdfView = ({ data }) => {
    const { disclosureURL, pdfModalState, onPdfClose, handleAccept, setAcceptPrescription, acceptPrescription, PrescriptionError, setPrescriptionError } = data;

    return (
        <Modal show={pdfModalState}>
            <ModalHeading
                onClose={onPdfClose} />
            <div className='termsPdfContainer' style={{ minWidth:"750px" }}>
                <div className='PI-Container'>
                    {disclosureURL &&
                        //    <embed
                        //    src={`${disclosureURL}`}
                        //    type="application/pdf" width="100%" height="100%" />
                        <iframe
                            id='iframeTou'
                            title='disclosure-pdf'
                            type='application/pdf'
                            src={`${disclosureURL}#view=fitH`}
                            style={{ width: '100%', height: '100%' }}
                        />
                    }

                </div>

                <div className='attestContainer' style={{ gap: 10, display: 'flex' }}>
                    <input
                        type='checkbox'
                        id='acceptPrescription'
                        name='acceptPrescription'
                        value={acceptPrescription}
                        onChange={() => {
                            setAcceptPrescription(!acceptPrescription)
                            setPrescriptionError('')
                        }}
                    />
                    <label>I attest that I have read and I understand the document</label>
                </div>
                <p className='error_message'>{PrescriptionError}</p>
                <div className='pdfButtonContainer'>
                    <ModalButtonPrimary
                        title='I Accept'
                        style={{ margin: '1rem' }}
                        onClick={handleAccept}
                    />
                    <ModalButtonDelete
                        onClick={onPdfClose}
                        title='I Do not Accept'
                        style={{ margin: '1rem' }}
                    />
                </div>

            </div>
        </Modal>
    );
};

export default PdfView;
