import React from 'react';

const ModalCheckBox = ({ label, disabled, onChange, error, style, value, checked }) => {

	return (
		<div className='modal_input' style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}>

			<div className='modal__radioButtons' style={style}>
						<div className='modal__radioButton'>
							<input
								type='checkbox'
								// id={box.label}
								// name={label}
								disabled = {disabled}
								value={value}
								checked={checked}
								onChange={(e) => onChange(e)}
								className='modal__checkbox--input'
							/>
							<span className='modal__radioButton--radio' />
							<label  className='modal__radioButton--label' >
								{label}
							</label>
						</div>

			</div>
			{error && <p className='error_message'>{error}</p>}
		</div>
	);
};

export default ModalCheckBox;

// const ModalCheckBox = ({ label, boxes, onChange, error, style }) => {

// 	return (
// 		<div className='modal_input' style={{
//             display: 'flex', justifyContent: 'center', alignItems: 'center'
//         }}>
// 			<p className='modal_input--label'>{label}</p>
// 			<div className='modal__radioButtons' style={style}>
// 				{boxes &&
// 					boxes.map((box) => (
// 						<div key={box.id} className='modal__radioButton'>
// 							<input
// 								type='checkbox'
// 								id={box.label}
// 								name={label}
// 								value={box.value}
// 								// checked={checked === box.value}
// 								onChange={(e) => onChange(e)}
// 								className='modal__radioButton--input'
// 							/>
// 							<span className='modal__radioButton--radio' />
// 							<label htmlFor={box.label} className='modal__radioButton--label' >
// 								{box.label}
// 							</label>
// 						</div>
// 					))}
// 			</div>
// 			{error && <p className='error_message'>{error}</p>}
// 		</div>
// 	);
// };

// export default ModalCheckBox;
