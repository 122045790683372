import React, { Fragment } from 'react';
import ManageClinics from '../../components/Skeletons/ManageClinics';
import { baaPdfAction } from '../../redux/actions/userActions';
import { useDispatch } from 'react-redux';

const FilterClinics = ({ data }) => {
	const dispatch = useDispatch();
	const {
		allClinics,
		selectedClinic,
		setSelectedClinic,
		setModalActiveState,
		setPillCountModal,
		setUserId,
		setIsDeleted
	} = data;

	const getClinicBAA = async () => {
		const data = await dispatch(baaPdfAction(selectedClinic))
		if (data && data.status) {
			window.open(data.pdfURL)
		} else {
			alert("Invalid Clinic Id");
		}
	};

	return (
		<Fragment>
			{allClinics && allClinics.loading ? (
				<ManageClinics />
			) : (
				<div className='manageClinic__filter__main'>
					<div className='manageClinic__Clinic-enable__content'>
						<label htmlFor='clinics' className='filter__input--label'>
							Select Clinic
						</label>
						<div className='filter__input'>
							<select
								name='clinics'
								id='clinics'
								value={selectedClinic}
								onChange={(e) => {
									setSelectedClinic(e.target.value);
								}}
								className='filter__input--select'>
								{allClinics &&
									allClinics.data &&
									allClinics.data.map(
										(clinic) =>
											clinic.name ? (
												<option value={clinic.clinicId} key={clinic.clinicId}>
													{clinic.name}
												</option>
											) : null
									)}
							</select>
						</div>
					</div>
					<div className='manageClinic__Clinic-enable__content'>
						<div className='filter__content--top'>
							<div
								//className="filter__input--labels"
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									gap: '0.5rem'
								}}>
								<label htmlFor='clinics' className='filter__input--label'>
									Clinic Enabled/Disabled
								</label>
								<label className='switch'>
									{allClinics &&
										allClinics.data &&
										allClinics.data.map(
											(clinic) =>
												clinic.clinicId === selectedClinic && (
													<input
														key={clinic.clinicId}
														id='active'
														type='checkbox'
														className='checkbox'
														checked={!clinic.isDeleted}
														onChange={() => {
															setUserId(clinic.clinicId);
															setIsDeleted(clinic.isDeleted);
															setModalActiveState(true);
														}}
														name='active'
													/>
												)
										)}
									<span className='slider round' />
								</label>
							</div>
						</div>
					</div>

					{/* <div className='manageClinic__Clinic-enable__content'>
						<div className='filter__content--top'>
							<div
								//className="filter__input--labels"
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									gap: '0.5rem'
								}}>
								<label htmlFor='clinics' className='filter__input--label'>
									Pill Count Enabled/Disabled
								</label>
								<label className='switch'>
									{allClinics &&
										allClinics.data &&
										allClinics.data.map(
											(clinic) =>
												clinic.clinicId === selectedClinic && (
													<input
														key={clinic.clinicId}
														id='active'
														type='checkbox'
														className='checkbox'
														checked={clinic.isPillCount}
														onChange={() => {
															setPillCountModal({ status: true, data: clinic });
														}}
														name='active'
													/>
												)
										)}
									<span className='slider round' />
								</label>
							</div>
						</div>
					</div> */}

					<div className='manageClinic__Clinic-enable__content' style={{
						padding:'1.5rem'
					}}>
						<div className='filter__content--top'>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
									gap: '0.5rem'
								}}>
								<label htmlFor='clinics' className='filter__input--label'>
									BAA Document
								</label>
								<button className="table__button2"
									onClick={getClinicBAA}
								>
									<svg className="table__icon primary-icon" style={{ width: '3rem', height: '3rem' }}>
										<use xlinkHref={`/sprite.svg#icon-pdf`} />
									</svg>
									<span>PDF</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default FilterClinics;
