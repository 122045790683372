import React from 'react';
import { useDispatch } from 'react-redux';

import TableRows from '../../components/Skeletons/TableRows';

import {
	contentPdfAction,
} from '../../redux/actions/userActions';

const ContentTable = ({ data }) => {
	const dispatch = useDispatch();
	const { contentDetails, setVideoModalState, setContentId, setDeleteModalState, setTitle } = data;

	const extractContentType = (details) => {
		if (!!details.isVideo || details.type === 'disclosure') {
			return 'Disclosure'
		}
		if (!!details.isVideo || details.type === 'url') {
			return 'URL'
		}
		if (!!details.isVideo || details.type === 'pdf') {
			return 'PDF'
		}
		if (details.isVideo) {
			return 'Video'
		}
	}

	return (
		<div className="tableContainer" style={{ height: '75vh', marginTop: '0' }}>
			<table className="table">
				<thead>
					<tr>
						<th>#</th>
						<th>Title</th>
						<th>Partner</th>
						<th>Description</th>
						<th>Content Type</th>
						<th>Content</th>
						<th />
					</tr>
				</thead>
				<tbody>
					{
						contentDetails && contentDetails.loading ?
							<TableRows columns='13' />
							:
							contentDetails && contentDetails.token && contentDetails.token.length > 0 ?
								contentDetails.token.map((details) => (
									<tr key={details.id}>
										<td>{details.id}</td>
										<td>{details.title}</td>
										<td>{details.category}</td>
										<td style={{
											maxWidth: '35rem',
											whiteSpace: "break-spaces"
										}}>{details.description}</td>
										<td>{extractContentType(details)}</td>
										<td className="table__iconBox">
											{details.isVideo ? (
												<button className="table__buttonBig" onClick={
													() => {
														// onVideoClick(details.id)
														setContentId(details.id)
														setVideoModalState(true)
													}
												}>
													<svg className="table__button--iconBigBlack">
														<use xlinkHref={`/sprite.svg#icon-play-circle`} />
													</svg>
													<span>Video</span>
												</button>
											)
												:
												details.type === 'url' ?
													<a className='filter__heading--btn' href={details.webURL} target="_blank">link</a>
													: (
														<button className="table__buttonBig" onClick={() => {
															dispatch(contentPdfAction(details.id))
														}}>
															<svg className="table__button--iconBigBlack">
																<use xlinkHref={`/sprite.svg#icon-pdf`} />
															</svg>
															<span>PDF</span>
														</button>
													)}
										</td>
										<td>
											<div className="table__iconBox">
												<button className="table__buttonBig" onClick={
													() => {
														setTitle(details.title)
														setContentId(details.id)
														setDeleteModalState(true)
													}
												}>
													<svg className="table__button--iconBigDelete">
														<use xlinkHref={`/sprite.svg#icon-delete`} />
													</svg>
													<span>Delete</span>
												</button>
											</div>
										</td>
									</tr>
								))
								:
								<tr>
									<td style={{ textAlign: "center" }} colSpan="7">No content data found</td>
								</tr>
					}

				</tbody>
			</table>
		</div>
	);
};

export default ContentTable;
