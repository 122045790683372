import React, { Fragment } from 'react';
import InviteClinicEmails from '../../components/Skeletons/InviteClinicEmails';
import InviteClinicForm from '../../components/Skeletons/InviteClinicForm';
import EmailData from './data';
import './InviteClinics.css';
// import ReactPaginate from 'react-paginate';

function Clinics({ data, invitedClinics, inviteClinic }) {
	const { email, setEmail, url, setURL, onSendInviteHandler, allWebsites } = data;
	// const handlePageChange = () => { };

	return (
		<Fragment>
			<div className="invite-class">
				<div className="filter__content3">
					{inviteClinic && inviteClinic.loading ? (
						<InviteClinicForm />
					) : (
						<form onSubmit={onSendInviteHandler}>
							<div className="filter__content--top1">
								<div className="filter__input--labels">
									<label htmlFor="email" className="filter__input--label">
										Enter Email ID
									</label>
									<div className="filter__input">
										<label htmlFor="email" className="filter__input--iconBox">
											<svg className="filter__input--icon">
												<use xlinkHref={`/sprite.svg#icon-email`} />
											</svg>
										</label>
										<input
											type="text"
											id="email"
											placeholder="johndoe@example.com"
											value={email.value}
											onChange={(event) => {
												setEmail({ value: event.target.value });
											}}
											className="filter__input--text"
										/>
									</div>
									{email.error && <p className="error_message">{email.error}</p>}
								</div>
							</div>
							<div className="filter__content--top1">
								<div className="filter__input--labels">
									<label htmlFor="Website" className="filter__input--label">
										Website
									</label>
									<div className="filter__input">
										<label htmlFor="Website" className="filter__input--iconBox">
											<svg className="filter__input--icon">
												<use xlinkHref={`/sprite.svg#icon-website`} />
											</svg>
										</label>
										<select
											id="Website"
											value={url.value}
											onChange={(event) => {
												setURL({ value: event.target.value });
											}}
											className="filter__input--text"
										>
											<option value="select-website" disabled>
												Select Website
											</option>
											{allWebsites &&
												allWebsites.data &&
												allWebsites.data.map((website) => (
													<option value={website.websiteId} key={website.websiteId}>
														{website.name}
													</option>
												))}
										</select>
									</div>
									{url.error && <p className="error_message">{url.error}</p>}
								</div>
								<button className="filter__input--button" onClick={onSendInviteHandler}>
									Send Invitation
								</button>
							</div>
						</form>
					)}
				</div>
				{invitedClinics && invitedClinics.loading ? (
					<InviteClinicEmails />
				) : (
					<div className="filter__content2">
						<div className="pending-header">Pending Invites</div>
						<div>
							<EmailData data={data} invitedClinics={invitedClinics} />
						</div>
						{/* <div className='paginationTop'>
						<ReactPaginate
							nextClassName={''} //clientList.data.result.length < rowsPerPage && "nextClassName"
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={'4'} //clientList.data.totalPageSize / rowsPerPage
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={5}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'paginationActive'}
						/>
					</div> */}
					</div>
				)}
			</div>
		</Fragment>
	);
}

export default Clinics;
