import React from 'react';
import Header from './Header';
import AllSidebar from './Sidebar/AllSidebar.';
import { Helmet } from 'react-helmet';
// import CCPSidebar from './Sidebar/CCPSidebar';
import './Sidebar/Sidebar.css';

const Content = ({ addPartner, children, onBack, showBack, headerTitle, currentMenu, addBtn, addBtnText, addBtnIcon, addBtnClick, current = null }) => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: 'en',
        autoDisplay: false,
      },
      'google_translate_element'
    );
  };

  React.useEffect(() => {
    {
      if (current && current === 'questionare') {
        var addScript = document.createElement('script');
        if (addScript) {
          addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
          document.body.appendChild(addScript);
          window.googleTranslateElementInit = googleTranslateElementInit;
        }
      }
    }
  }, [current]);

  return (
    <main className='container'>
      <Helmet>
        <title>Perspectives in Care</title>
      </Helmet>
      <AllSidebar current={currentMenu} />
      <div className='mainContent' >
        <Header
          title={headerTitle}
          addBtn={addBtn}
          onBack={onBack}
          showBack={showBack}
          addPartner={addPartner}
          addBtnText={addBtnText}
          addBtnIcon={addBtnIcon}
          addBtnClick={addBtnClick}
          current={current}
        />
        <div className='content'>{children}</div>
      </div>
    </main>
  );
};

export default Content;
