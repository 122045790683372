import React, { Fragment } from 'react';
import './ClinicScreen.css'
import '../HomeScreen/HomeScreen.css'

const FilterUsers = ({ data }) => {
	const {
		showFilter,
		setShowFilter,
		clinicProvider,
		handleClear,
		//handleToday,
		filterData,
		setFilterData,
		handleFilter,
		// allClinics,
		// textInputs,
		// setTextInputs,
		// handleFilterSubmit,
		//
	} = data;

	return (
		<Fragment>
			{!showFilter ? (
				<div className='filter__header'>
					<button id="filterHide" className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Hide Search
					</button>
				</div>
			) : (
				<div className='filter__header'>
					<button id="filterShow" className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Search Users
					</button>
				</div>
			)}
			<div className='ProviderFilter'
            style={{ maxHeight: showFilter ? 0 : '34rem' }}
            >
				<div className='ProviderFilter__inputs'>
					<h2 className='filter__heading'>Filter Users</h2>
					<div className='filter__input--top'>

						<select
							className='filter__input--select'
							value={filterData.providerId}
							onChange={(e) => setFilterData({ ...filterData, providerId: e.target.value })}
							name='providers'
							id='providers'>
							<option value='All'>All Providers</option>
							{clinicProvider &&
								clinicProvider.data &&
								clinicProvider.data.map((provider) => (
									<option value={provider.providerId} key={provider.providerId}>
										{provider.displayName}
									</option>
								))}
						</select>
					</div>
				</div>
				<div className='filter__content'>
					<div className='Providerfilter__content--top'>
						<div className='filter__input--labels'>
							<label htmlFor='firstName' className='filter__input--label'>
								Patient First Name
							</label>
							<div className='filter__input'>
								<label htmlFor='firstName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='firstName'
									placeholder='Jon'
									value={filterData.FirstName}
									onChange={(e) => setFilterData({ ...filterData, FirstName: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='lastName' className='filter__input--label'>
								Patient Last Name
							</label>
							<div className='filter__input'>
								<label htmlFor='lastName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='lastName'
									placeholder='Doe'
									value={filterData.LastName}
									onChange={(e) => setFilterData({ ...filterData, LastName: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='date' className='filter__input--label'>
								Date of Birth
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='date'
									name='date'
									value={filterData.BirthDate}
									onChange={(e) => setFilterData({ ...filterData, BirthDate: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
					</div>
					<div className='Providerfilter__content--top' >
									<div className='filter__input--labels'>
							<label htmlFor='assessments' className='filter__input--label'>
								Start Date
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='assessments'
									name='assessments'
									value={filterData.startDate}
									onChange={(e) => setFilterData({ ...filterData, startDate: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='assessments' className='filter__input--label'>
								End Date
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='assessments'
									name='assessments'
									value={filterData.endDate}
									onChange={(e) => setFilterData({ ...filterData, endDate: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
					</div>
					<div className='Providerfilter__content--top2' >
						<div className='ProviderFilter__inputs'>
							<div className='filter__input--top'>
								<select
									className='filter__input--select'
									value={filterData.riskRating}
									onChange={(e) => setFilterData({ ...filterData, riskRating: e.target.value })}
									name='providers'
									id='providers'>
									<option value='0'>Risk Rating</option>
									<option value='3'>High</option>
									<option value='2'>Moderate</option>
									<option value='1'>Low</option>

								</select>
							</div>
						</div>
						<div className='ProviderFilter__inputs'>
							<div className='filter__input--top'>
								<select
									className='filter__input--select'
									value={filterData.assessmentrange}
									onChange={(e) => setFilterData({ ...filterData, assessmentrange: Number(e.target.value) })}
									name='providers'
									id='providers'>
									<option value='0'>Date Range</option>
									<option value='1'>Today</option>
									<option value='2'>Last 3 Days</option>
									<option value='3'>Last 7 Days</option>
									<option value='4'>Last 30 Days</option>
									<option value='5'>Last 3 months</option>
									<option value='6'>Last 6 months</option>
									<option value='7'>All Time</option>
								</select>
							</div>
						</div>
						<div style={{ display: 'flex',gap: '1rem'}}>
							{/* <button className='filter__input--button'
							onClick={handleToday}
							>
							Today
							</button> */}
							<button className='filter__input--button'
							id='searchBtn'
							onClick={handleFilter}
							>
								Search
							</button>
							<button className='filter__input--button'
							id='clearBtn'
							onClick= {handleClear}
							>
							Clear
							</button>
						</div>
					</div>


				</div>
			</div>
		</Fragment>
	);
};

export default FilterUsers;
