import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SchedulerTable from './SchedulerTable';
import SchedulerDetails from './SchedulerDetails';
import AddScheduler from './AddScheduler';

import {
	getScheduleAction, saveScheduleAction, scheduleMapAction
} from '../../redux/actions/userActions';
import {SCHEDULE_MAP_RESET} from '../../redux/constants/userConstants'
import './Scheduler.css';
import Content from '../../components/Content';

const Scheduler = () => {
	const dispatch = useDispatch();

	const scheduleDetails = useSelector((state) => state.getScheduleDetails);
	const scheduleMapDetails = useSelector((state) => state.scheduleMapDetails);
	const saveSchedule = useSelector((state) => state.saveSchedule);

	const [ modalState, setModalState ] = useState(false);
	const [ addModalState, setAddModalState ] = useState(false);

	const [ scheduleDate, setScheduleDate ] = useState({
		date: '',
		error: ''
	});
	const [ scheduleStartTime, setScheduleStartTime ] = useState({
		time: '',
		error: ''
	});
	const [ scheduleEndTime, setScheduleEndTime ] = useState({
		time: '',
		error: ''
	});
	const [ scheduleContent, setScheduleContent ] = useState({
		content: '',
		error: ''
	});

	useEffect(
		() => {
			dispatch(getScheduleAction());

		},
		[ dispatch ]
	);
	useEffect(
		() => {
			if(saveSchedule && saveSchedule.token && saveSchedule.token.success === true){
				setAddModalState(false)
				dispatch(getScheduleAction());
				setScheduleDate("")
				setScheduleContent("")
				setScheduleStartTime("")
				setScheduleEndTime("")
			}

		},
		[ saveSchedule, dispatch ]
	);

	const items = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16 ];

	const onClose = () => {
		setModalState(false);
		dispatch({ type: SCHEDULE_MAP_RESET });
	};
	const onCloseAddModal = () => {
		setAddModalState(false);
		setScheduleDate({
			date: '',
			error: ''
		});
	};

	function handleView(id) {
		setModalState(true);
		dispatch(scheduleMapAction(id))
	};

	const addBtnClick = () => {
		setAddModalState(true);
	};

	const handleSubmit = (e) => {
		//e.preventDefault();
		var formData = {
			content: scheduleContent.content ,
			date: scheduleDate.date,
			endtime: scheduleEndTime.time,
			starttime: scheduleStartTime.time
		};
		dispatch(saveScheduleAction(formData))
	};

	return (
		<Content
			currentMenu='Scheduler'
			headerTitle='Scheduler'
			addBtn={true}
			addBtnClick={addBtnClick}
			addBtnText='Add Scheduler'
			addBtnIcon='/sprite.svg#icon-plus'>
			<SchedulerTable
			data={{scheduleDetails}}
			 items={items}
			 handleView={handleView} />
			<SchedulerDetails
			 modalState={modalState}
			onClose={onClose}
			data={{scheduleMapDetails, scheduleDetails}}
			/>
			<AddScheduler
				saveSchedule = {saveSchedule}
				modalState={addModalState}
				onClose={onCloseAddModal}
				scheduleDate={scheduleDate}
				setScheduleDate={setScheduleDate}
				scheduleStartTime={scheduleStartTime}
				setScheduleStartTime={setScheduleStartTime}
				scheduleEndTime={scheduleEndTime}
				setScheduleEndTime={setScheduleEndTime}
				scheduleContent={scheduleContent}
				setScheduleContent={setScheduleContent}
				handleSubmit={handleSubmit}
			/>
		</Content>
	);
};

export default Scheduler;
