import {
	CREATE_NEW_USER_REQUEST,
	CREATE_NEW_USER_SUCCESS,
	CREATE_NEW_USER_FAIL,
	CREATE_NEW_USER_RESET,
	USER_LOGIN_REQUEST,
	USER_LOGIN_SUCCESS,
	USER_LOGIN_FAIL,
	USER_LOGOUT,
	GET_ALL_CLIENT_REQUEST,
	GET_ALL_CLIENT_SUCCESS,
	GET_ALL_CLIENT_FAIL,
	GET_ALL_CLIENT_RESET,
	MANAGE_USER_REQUEST,
	MANAGE_USER_SUCCESS,
	MANAGE_USER_FAIL,
	MANAGE_USER_RESET,
	MANAGE_CLINIC_REQUEST,
	MANAGE_CLINIC_SUCCESS,
	MANAGE_CLINIC_FAIL,
	MANAGE_CLINIC_RESET,
	ADMINLOGIN_MANAGE_USER_REQUEST,
	ADMINLOGIN_MANAGE_USER_SUCCESS,
	ADMINLOGIN_MANAGE_USER_FAIL,
	ADMINLOGIN_MANAGE_USER_RESET,
	UPDATE_MANAGE_USER_REQUEST,
	UPDATE_MANAGE_USER_SUCCESS,
	UPDATE_MANAGE_USER_FAIL,
	UPDATE_MANAGE_USER_RESET,
	ACTIVATE_MANAGE_USER_REQUEST,
	ACTIVATE_MANAGE_USER_SUCCESS,
	ACTIVATE_MANAGE_USER_FAIL,
	ACTIVATE_MANAGE_USER_RESET,
	MANAGE_UNVERIFIED_REQUEST,
	MANAGE_UNVERIFIED_SUCCESS,
	MANAGE_UNVERIFIED_FAIL,
	MANAGE_UNVERIFIED_RESET,
	DELETE_MANAGE_USER_REQUEST,
	DELETE_MANAGE_USER_SUCCESS,
	DELETE_MANAGE_USER_FAIL,
	DELETE_MANAGE_USER_RESET,
	PROFILE_REQUEST,
	PROFILE_SUCCESS,
	PROFILE_FAIL,
	PROFILE_RESET,
	POST_PROFILE_REQUEST,
	POST_PROFILE_SUCCESS,
	POST_PROFILE_FAIL,
	POST_PROFILE_RESET,
	DEACTIVATE_REQUEST,
	DEACTIVATE_SUCCESS,
	DEACTIVATE_FAIL,
	DEACTIVATE_RESET,
	RESETPASSWORDS_REQUEST,
	RESETPASSWORDS_SUCCESS,
	RESETPASSWORDS_FAIL,
	RESETPASSWORDS_RESET,
	UNVERIFIED_PASSWORD_REQUEST,
	UNVERIFIED_PASSWORD_SUCCESS,
	UNVERIFIED_PASSWORD_FAIL,
	UNVERIFIED_PASSWORD_RESET,
	VERIFY_CLINIC_REQUEST,
	VERIFY_CLINIC_SUCCESS,
	VERIFY_CLINIC_FAIL,
	VERIFY_CLINIC_RESET,
	EDIT_UNVERIFIED_USER_REQUEST,
	EDIT_UNVERIFIED_USER_SUCCESS,
	EDIT_UNVERIFIED_USER_FAIL,
	EDIT_UNVERIFIED_USER_RESET,
	ACTIVE_TOKEN_REQUEST,
	ACTIVE_TOKEN_SUCCESS,
	ACTIVE_TOKEN_FAIL,
	ACTIVE_TOKEN_RESET,
	ADD_CLIENT_REQUEST,
	ADD_CLIENT_SUCCESS,
	ADD_CLIENT_FAIL,
	ADD_CLIENT_RESET,
	EDIT_CLIENT_REQUEST,
	EDIT_CLIENT_SUCCESS,
	EDIT_CLIENT_FAIL,
	EDIT_CLIENT_RESET,
	DELETE_CLIENT_REQUEST,
	DELETE_CLIENT_SUCCESS,
	DELETE_CLIENT_FAIL,
	DELETE_CLIENT_RESET,
	ACTIVEINACTIVE_CLIENT_REQUEST,
	ACTIVEINACTIVE_CLIENT_SUCCESS,
	ACTIVEINACTIVE_CLIENT_RESET,
	ACTIVEINACTIVE_CLIENT_FAIL,
	CONTENTS_REQUEST,
	CONTENTS_SUCCESS,
	CONTENTS_FAIL,
	CONTENTS_RESET,
	UPLOAD_HISTORY_REQUEST,
	UPLOAD_HISTORY_SUCCESS,
	UPLOAD_HISTORY_FAIL,
	UPLOAD_HISTORY_RESET,
	CONTENT_PDF_REQUEST,
	CONTENT_PDF_SUCCESS,
	CONTENT_PDF_FAIL,
	CONTENT_PDF_RESET,
	CONTENT_VIDEO_REQUEST,
	CONTENT_VIDEO_SUCCESS,
	CONTENT_VIDEO_FAIL,
	CONTENT_VIDEO_RESET,
	GET_SCHEDULE_REQUEST,
	GET_SCHEDULE_SUCCESS,
	GET_SCHEDULE_FAIL,
	GET_SCHEDULE_RESET,
	SCHEDULE_MAP_REQUEST,
	SCHEDULE_MAP_SUCCESS,
	SCHEDULE_MAP_FAIL,
	SCHEDULE_MAP_RESET,
	SAVE_SCHEDULE_REQUEST,
	SAVE_SCHEDULE_SUCCESS,
	SAVE_SCHEDULE_FAIL,
	SAVE_SCHEDULE_RESET,
	UPLOAD_CONTENT_REQUEST,
	UPLOAD_CONTENT_SUCCESS,
	UPLOAD_CONTENT_FAIL,
	UPLOAD_CONTENT_RESET,
	DELETE_CONTENT_REQUEST,
	DELETE_CONTENT_SUCCESS,
	DELETE_CONTENT_FAIL,
	DELETE_CONTENT_RESET,
	CLIENT_URL,
	REGISTER_PATIENT_REQUEST,
	REGISTER_PATIENT_SUCCESS,
	REGISTER_PATIENT_RESET,
	REGISTER_PATIENT_FAIL,
	CLIENT_NAME,
	GET_MENUS_REQUEST,
	GET_MENUS_SUCCESS,
	GET_MENUS_FAIL,
	GET_MENUS_RESET,
	FORGOT_PASSWORD_REQUEST,
	FORGOT_PASSWORD_SUCCESS,
	FORGOT_PASSWORD_FAIL,
	FORGOT_PASSWORD_RESET,
	RESET_PASSWORD_REQUEST,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	RESET_PASSWORD_RESET,
	VERFIY_PATIENT_REQUEST,
	VERFIY_PATIENT_SUCCESS,
	VERFIY_PATIENT_FAIL,
	VERFIY_PATIENT_RESET,
	GET_PARTNER_CONTENTS_REQUEST,
	GET_PARTNER_CONTENTS_SUCCESS,
	GET_PARTNER_CONTENTS_FAIL,
	GET_PARTNER_CONTENTS_RESET,
} from '../constants/userConstants';

export const userLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case USER_LOGIN_REQUEST:
			return { loading: true };

		case USER_LOGIN_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case USER_LOGIN_FAIL:
			return { loading: false, error: action.payload };

		case USER_LOGOUT:
			return {};

		default:
			return state;
	}
};

export const forgotPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case FORGOT_PASSWORD_REQUEST:
			return { loading: true };
		case FORGOT_PASSWORD_SUCCESS:
			return { data: action.payload, loading: false };
		case FORGOT_PASSWORD_FAIL:
			return { loading: false, error: action.payload };
		case FORGOT_PASSWORD_RESET:
			return {};
		default:
			return state;
	}
};

export const resetPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case RESET_PASSWORD_REQUEST:
			return { loading: true };
		case RESET_PASSWORD_SUCCESS:
			return { data: action.payload, loading: false };
		case RESET_PASSWORD_FAIL:
			return { loading: false, error: action.payload };
		case RESET_PASSWORD_RESET:
			return {};
		default:
			return state;
	}
};

export const baseUrlReducer = (state = {}, action) => {
	switch (action.type) {
		case CLIENT_URL:
			return { url: action.payload };
		default:
			return state;
	}
};

export const clientIdReducer = (state = {}, action) => {
	switch (action.type) {
		case CLIENT_NAME:
			return action.payload ;
		default:
			return state;
	}
};

export const getAllClientReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_CLIENT_REQUEST:
			return { loading: true };

		case GET_ALL_CLIENT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_ALL_CLIENT_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_CLIENT_RESET:
			return {};

		default:
			return state;
	}
};

export const getMenusReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_MENUS_REQUEST:
			return { loading: true };
		case GET_MENUS_SUCCESS:
			return { data: action.payload, loading: false };
		case GET_MENUS_FAIL:
			return { loading: false, error: action.payload };
		case GET_MENUS_RESET:
			return {};
		default:
			return state;
	}
};

export const editUnverifiedUserReducer = (state = {}, action) => {
	switch (action.type) {
		case EDIT_UNVERIFIED_USER_REQUEST:
			return { loading: true };

		case EDIT_UNVERIFIED_USER_SUCCESS:
			return { data: action.payload, loading: false };
		case EDIT_UNVERIFIED_USER_FAIL:
			return { loading: false, error: action.payload };
		case EDIT_UNVERIFIED_USER_RESET:
			return {};
		default:
			return state;
	}
};

export const verifyClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case VERIFY_CLINIC_REQUEST:
			return { loading: true };

		case VERIFY_CLINIC_SUCCESS:
			return { userInfo: action.payload, loading: false };
		case VERIFY_CLINIC_FAIL:
			return { loading: false, error: action.payload };
		case VERIFY_CLINIC_RESET:
			return {};
		default:
			return state;
	}
};

export const unverifiedResetPasswordReducer = (state = {}, action) => {
	switch (action.type) {
		case UNVERIFIED_PASSWORD_REQUEST:
			return { loading: true };

		case UNVERIFIED_PASSWORD_SUCCESS:
			return { userInfo: action.payload, loading: false };
		case UNVERIFIED_PASSWORD_FAIL:
			return { loading: false, error: action.payload };
		case UNVERIFIED_PASSWORD_RESET:
			return {};
		default:
			return state;
	}
};

export const createNewUserReducer = (state = {}, action) => {
	switch (action.type) {
		case CREATE_NEW_USER_REQUEST:
			return { loading: true };

		case CREATE_NEW_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CREATE_NEW_USER_FAIL:
			return { loading: false, error: action.payload };

		case CREATE_NEW_USER_RESET:
			return {};

		default:
			return state;
	}
};

//manageUserReducer
export const manageUserReducer = (state = {}, action) => {
	switch (action.type) {
		case MANAGE_USER_REQUEST:
			return { loading: true };

		case MANAGE_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case MANAGE_USER_FAIL:
			return { loading: false, error: action.payload };

		case MANAGE_USER_RESET:
			return {};

		default:
			return state;
	}
};

//lOGIN with admin manage users
export const adminLoginReducer = (state = {}, action) => {
	switch (action.type) {
		case ADMINLOGIN_MANAGE_USER_REQUEST:
			return { loading: true };

		case ADMINLOGIN_MANAGE_USER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case ADMINLOGIN_MANAGE_USER_FAIL:
			return { loading: false, error: action.payload };

		case ADMINLOGIN_MANAGE_USER_RESET:
			return {};

		default:
			return state;
	}
};
//update edit manage users
export const updatemanageUserReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_MANAGE_USER_REQUEST:
			return { loading: true };

		case UPDATE_MANAGE_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case UPDATE_MANAGE_USER_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_MANAGE_USER_RESET:
			return {};

		default:
			return state;
	}
};

//DELETE MANAGE USER
export const deleteManageUserReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_MANAGE_USER_REQUEST:
			return { loading: true };

		case DELETE_MANAGE_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case DELETE_MANAGE_USER_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_MANAGE_USER_RESET:
			return {};

		default:
			return state;
	}
};

//Active Toggle
export const activatemanageUserReducer = (state = {}, action) => {
	switch (action.type) {
		case ACTIVATE_MANAGE_USER_REQUEST:
			return { loading: true };

		case ACTIVATE_MANAGE_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case ACTIVATE_MANAGE_USER_FAIL:
			return { loading: false, error: action.payload };

		case ACTIVATE_MANAGE_USER_RESET:
			return {};

		default:
			return state;
	}
};

//manageClinicReducer
export const manageClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case MANAGE_CLINIC_REQUEST:
			return { loading: true };

		case MANAGE_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case MANAGE_CLINIC_FAIL:
			return { loading: false, error: action.payload };
		case MANAGE_CLINIC_RESET:
			return {};
		default:
			return state;
	}
};

//manageUnverifiedReducer
export const manageUnverifiedReducer = (state = {}, action) => {
	switch (action.type) {
		case MANAGE_UNVERIFIED_REQUEST:
			return { loading: true };

		case MANAGE_UNVERIFIED_SUCCESS:
			return { data: action.payload, loading: false };

		case MANAGE_UNVERIFIED_FAIL:
			return { loading: false, error: action.payload };

		case MANAGE_UNVERIFIED_RESET:
			return {};

		default:
			return state;
	}
};

//profileReducer
export const profileReducer = (state = {}, action) => {
	switch (action.type) {
		case PROFILE_REQUEST:
			return { loading: true };

		case PROFILE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case PROFILE_FAIL:
			return { loading: false, error: action.payload };
		case PROFILE_RESET:
			return {};
		default:
			return state;
	}
};

//Update User
export const updateUserReducer = (state = {}, action) => {
	switch (action.type) {
		case POST_PROFILE_REQUEST:
			return { loading: true };

		case POST_PROFILE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case POST_PROFILE_FAIL:
			return { loading: false, error: action.payload };
		case POST_PROFILE_RESET:
			return {};

		default:
			return state;
	}
};

//DEAVTICATE ACCOUNT
export const deactivateUserReducer = (state = {}, action) => {
	switch (action.type) {
		case DEACTIVATE_REQUEST:
			return { loading: true };

		case DEACTIVATE_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case DEACTIVATE_FAIL:
			return { loading: false, error: action.payload };
		case DEACTIVATE_RESET:
			return {};

		default:
			return state;
	}
};

//Reset Password

export const passwordResetReducer = (state = {}, action) => {
	switch (action.type) {
		case RESETPASSWORDS_REQUEST:
			return { loading: true };

		case RESETPASSWORDS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case RESETPASSWORDS_FAIL:
			return { loading: false, error: action.payload };

		case RESETPASSWORDS_RESET:
			return {};

		default:
			return state;
	}
};

export const activeToeknReducer = (state = {}, action) => {
	switch (action.type) {
		case ACTIVE_TOKEN_REQUEST:
			return { loading: true };

		case ACTIVE_TOKEN_SUCCESS:
			return { token: action.payload, loading: false };

		case ACTIVE_TOKEN_FAIL:
			return { loading: false, error: action.payload };

		case ACTIVE_TOKEN_RESET:
			return {};

		default:
			return state;
	}
};

export const addClientReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_CLIENT_REQUEST:
			return { loading: true };

		case ADD_CLIENT_SUCCESS:
			return { token: action.payload, loading: false };

		case ADD_CLIENT_FAIL:
			return { loading: false, error: action.payload };

		case ADD_CLIENT_RESET:
			return {};

		default:
			return state;
	}
};

export const editClientReducer = (state = {}, action) => {
	switch (action.type) {
		case EDIT_CLIENT_REQUEST:
			return { loading: true };

		case EDIT_CLIENT_SUCCESS:
			return { token: action.payload, loading: false };

		case EDIT_CLIENT_FAIL:
			return { loading: false, error: action.payload };

		case EDIT_CLIENT_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteClientReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_CLIENT_REQUEST:
			return { loading: true };

		case DELETE_CLIENT_SUCCESS:
			return { token: action.payload, loading: false };

		case DELETE_CLIENT_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_CLIENT_RESET:
			return {};

		default:
			return state;
	}
};
export const activeInactivetReducer = (state = {}, action) => {
	switch (action.type) {
		case ACTIVEINACTIVE_CLIENT_REQUEST:
			return { loading: true };

		case ACTIVEINACTIVE_CLIENT_SUCCESS:
			return { token: action.payload, loading: false };

		case ACTIVEINACTIVE_CLIENT_FAIL:
			return { loading: false, error: action.payload };

		case ACTIVEINACTIVE_CLIENT_RESET:
			return {};

		default:
			return state;
	}
};

export const contentsReducer = (state = {}, action) => {
	switch (action.type) {
		case CONTENTS_REQUEST:
			return { loading: true };

		case CONTENTS_SUCCESS:
			return { token: action.payload, loading: false };

		case CONTENTS_FAIL:
			return { loading: false, error: action.payload };

		case CONTENTS_RESET:
			return {};

		default:
			return state;
	}
};

export const uploadHistoryReducer = (state = {}, action) => {
	switch (action.type) {
		case UPLOAD_HISTORY_REQUEST:
			return { loading: true };

		case UPLOAD_HISTORY_SUCCESS:
			return { token: action.payload, loading: false };

		case UPLOAD_HISTORY_FAIL:
			return { loading: false, error: action.payload };

		case UPLOAD_HISTORY_RESET:
			return {};

		default:
			return state;
	}
};

export const contentPdfReducer = (state = {}, action) => {
	switch (action.type) {
		case CONTENT_PDF_REQUEST:
			return { loading: true };

		case CONTENT_PDF_SUCCESS:
			return { token: action.payload, loading: false };

		case CONTENT_PDF_FAIL:
			return { loading: false, error: action.payload };

		case CONTENT_PDF_RESET:
			return {};

		default:
			return state;
	}
};

export const contentVideoReducer = (state = {}, action) => {
	switch (action.type) {
		case CONTENT_VIDEO_REQUEST:
			return { loading: true };

		case CONTENT_VIDEO_SUCCESS:
			return { token: action.payload, loading: false };

		case CONTENT_VIDEO_FAIL:
			return { loading: false, error: action.payload };

		case CONTENT_VIDEO_RESET:
			return {};

		default:
			return state;
	}
};

export const getScheduleReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_SCHEDULE_REQUEST:
			return { loading: true };

		case GET_SCHEDULE_SUCCESS:
			return { token: action.payload, loading: false };

		case GET_SCHEDULE_FAIL:
			return { loading: false, error: action.payload };

		case GET_SCHEDULE_RESET:
			return {};

		default:
			return state;
	}
};

export const scheduleMapReducer = (state = {}, action) => {
	switch (action.type) {
		case SCHEDULE_MAP_REQUEST:
			return { loading: true };

		case SCHEDULE_MAP_SUCCESS:
			return { token: action.payload, loading: false };

		case SCHEDULE_MAP_FAIL:
			return { loading: false, error: action.payload };

		case SCHEDULE_MAP_RESET:
			return {};

		default:
			return state;
	}
};

export const saveScheduleReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_SCHEDULE_REQUEST:
			return { loading: true };

		case SAVE_SCHEDULE_SUCCESS:
			return { token: action.payload, loading: false };

		case SAVE_SCHEDULE_FAIL:
			return { loading: false, error: action.payload };

		case SAVE_SCHEDULE_RESET:
			return {};

		default:
			return state;
	}
};

export const uploadContentReducer = (state = {}, action) => {
	switch (action.type) {
		case UPLOAD_CONTENT_REQUEST:
			return { loading: true };

		case UPLOAD_CONTENT_SUCCESS:
			return { token: action.payload, loading: false };

		case UPLOAD_CONTENT_FAIL:
			return { loading: false, error: action.payload };

		case UPLOAD_CONTENT_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteContentReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_CONTENT_REQUEST:
			return { loading: true };

		case DELETE_CONTENT_SUCCESS:
			return { token: action.payload, loading: false };

		case DELETE_CONTENT_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_CONTENT_RESET:
			return {};

		default:
			return state;
	}
};

export const registerPatientReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_PATIENT_REQUEST:
			return { loading: true };

		case REGISTER_PATIENT_SUCCESS:
			return { data: action.payload, loading: false };

		case REGISTER_PATIENT_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_PATIENT_RESET:
			return {};

		default:
			return state;
	}
};

  
export const verifiedUserReducer = (state = {}, action) => {
	switch (action.type) {
		case VERFIY_PATIENT_REQUEST:
			return { loading: true };

		case VERFIY_PATIENT_SUCCESS:
			return { data: action.payload, loading: false };

		case VERFIY_PATIENT_FAIL:
			return { loading: false, error: action.payload };

		case VERFIY_PATIENT_RESET:
			return {};

		default:
			return state;
	}
};


export const partnerContentsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PARTNER_CONTENTS_REQUEST:
			return { loading: true };

		case GET_PARTNER_CONTENTS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_PARTNER_CONTENTS_FAIL:
			return { loading: false, error: action.payload };

		case GET_PARTNER_CONTENTS_RESET:
			return {};

		default:
			return state;
	}
};
