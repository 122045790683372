import React, { Fragment } from "react";
import Modal from "../../../components/Modal";
import ModalHeading from "../../../components/Modal/ModalHeading";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

const TermsModal = ({ data }) => {
  const {
    termsModalState,
    onTermsClose,
    agreement,
    setAgreement,
    onTermsAgree,
    consentForm,
  } = data;
  return (
    <Modal show={termsModalState}>
      <ModalHeading onClose={onTermsClose} />
      <Fragment>
        <div>
          <div className="terms__Pagination">
            <ul className="terms__pagination-bar">
              <li className={agreement === 1 ? "active" : null}>
                <div className="stepper-text">Informed Consent</div>
              </li>
              <li className={agreement === 2 ? "active" : null}>
                <div className="stepper-text">
                  Privacy Notice And Terms Of Use
                </div>
              </li>
              {consentForm && consentForm.data && consentForm.data[2]?.status && (
                <li className={agreement === 3 ? "active" : null}>
                  <div className="stepper-text">Additional Consent Form</div>
                </li>
              )}
            </ul>
          </div>
          {agreement === 1 ? (
            <Page1
              loading={consentForm.loading}
              error={consentForm.error}
              data={{ setAgreement, consentForm: consentForm.data[0] }}
            />
          ) : agreement === 2 ? (
            <Page2
              loading={consentForm.loading}
              error={consentForm.error}
              data={{
                setAgreement,
                onTermsAgree,
                consentForm: consentForm.data[1],
              }}
            />
          ) : (
            <Page3
              loading={consentForm.loading}
              error={consentForm.error}
              data={{
                setAgreement,
                onTermsAgree,
                consentForm: consentForm.data[2],
              }}
            />
          )}
        </div>
      </Fragment>
    </Modal>
  );
};

export default TermsModal;
