import axios from 'axios';
import {
	PREVIOUS_ASSESSMENT_REQUEST,
	PREVIOUS_ASSESSMENT_SUCCESS,
	PREVIOUS_ASSESSMENT_FAIL,
	SELF_ASSESSMENT_REQUEST,
	SELF_ASSESSMENT_SUCCESS,
	SELF_ASSESSMENT_FAIL,
	NEXT_QUESTION_REQUEST,
	NEXT_QUESTION_SUCCESS,
	NEXT_QUESTION_FAIL,
	QUESTION_RESULT_REQUEST,
	QUESTION_RESULT_SUCCESS,
	QUESTION_RESULT_FAIL,
	PREVIOUS_QUESTION_REQUEST,
	PREVIOUS_QUESTION_SUCCESS,
	PREVIOUS_QUESTION_FAIL,
	SAVE_ANSWER_REQUEST,
	SAVE_ANSWER_SUCCESS,
	SAVE_ANSWER_FAIL,
	REPORT_DTO_REQUEST,
	REPORT_DTO_SUCCESS,
	REPORT_DTO_FAIL,
	GET_BMI_QUESTIONS_REQUEST,
	GET_BMI_QUESTIONS_SUCCESS,
	GET_BMI_QUESTIONS_FAIL,
	CALCULATE_BMI_REQUEST,
	CALCULATE_BMI_SUCCESS,
	CALCULATE_BMI_FAIL,
	UPDATE_USER_DETAILS_REQUEST,
	UPDATE_USER_DETAILS_SUCCESS,
	UPDATE_USER_DETAILS_FAIL,
	SUMMARY_PDF_REQUEST,
	SUMMARY_PDF_SUCCESS,
	SUMMARY_PDF_FAIL,
	GETUSERDETAILS_REQUEST,
	GETUSERDETAILS_SUCCESS,
	GETUSERDETAILS_FAIL
} from '../constants/assessmentConstants';

import store from '../store';
import { logout } from './userActions';

axios.interceptors.request.use(
	(req) => {
		const { userLogin: { userInfo } } = store.getState();

		if (userInfo && userInfo.token) {
			req.headers = {
				...req.headers,
				Authorization: 'Bearer ' + userInfo.token
			};
		}
		return req;
	},
	(err) => {
		return Promise.reject(err);
	}
);

export const getUserDetailsAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GETUSERDETAILS_REQUEST });

		const { getuserdetails: { userDetails } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userDetails.token}`,
				'custom-clinicid-header': userDetails.clinicid
			}
		};

		const { data } = await axios.get(`${userDetails.apiurl}/auth/support/getuserdetails`, config);

		dispatch({
			type: GETUSERDETAILS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GETUSERDETAILS_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const nextQuestionAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: NEXT_QUESTION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/Assessments/NextQuestion`, config);
		//const { data } = await axios.get(`${userInfo.apiurl}api/Assessments/NextQuestion`, config);

		dispatch({
			type: NEXT_QUESTION_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch(logout());
		dispatch({
			type: NEXT_QUESTION_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const questionResultAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: QUESTION_RESULT_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/Assessments/QuestionResult`, formData, config);
		//const { data } = await axios.post(`${userInfo.apiurl}api/Assessments/QuestionResult`, formData, config);

		dispatch({
			type: QUESTION_RESULT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: QUESTION_RESULT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const previousQuestionAction = (currentquestionid) => async (dispatch, getState) => {
	try {
		dispatch({ type: PREVIOUS_QUESTION_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.get(
			//`${userInfo.apiurl}api/Assessments/PreviousQuestion?currentquestionid=${currentquestionid}`,
			`${userInfo.apiurl}/Assessments/PreviousQuestion?currentquestionid=${currentquestionid}`,
			config
		);

		dispatch({
			type: PREVIOUS_QUESTION_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: PREVIOUS_QUESTION_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const reportDTOAction = ({ assessmentResultId, isadmin, isprintpdf }) => async (dispatch, getState) => {
	try {
		dispatch({ type: REPORT_DTO_REQUEST });

		const { userLogin: { userInfo } } = getState();
		// const config = {
		// 	headers: {
		// 		Authorization: `Bearer ${userInfo.token}`
		// 	}
		// };
		const config1 = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			},
			responseType: 'blob'
		};
		// const { data } = await axios.get(
		// 	`${userInfo.apiurl}/Assessments/ReportDTO?assessmentResultId=${assessmentResultId}&isadmin=${isadmin}`,
		// 	config
		// );
		axios
			.get(
				`${userInfo.apiurl}/Assessments/ReportDTO?assessmentResultId=${assessmentResultId}&isadmin=${isadmin}&isprintpdf=${isprintpdf}`,
				config1
			)
			.then((response) => {
				// const url = window.URL.createObjectURL(new Blob([response.data]));
				// const link = document.createElement('a');
				// link.href = url;
				// link.setAttribute('download', 'file.pdf'); //or any other extension
				// document.body.appendChild(link)
				// link.click();
				const file = new Blob([response.data], { type: 'application/pdf' });
				//Build a URL from the file
				const fileURL = URL.createObjectURL(file);
				//Open the URL on new Window

				const pdfWindow = window.open();
				pdfWindow.location.href = fileURL;

				dispatch({
					type: REPORT_DTO_SUCCESS,
					payload: response.data
				});
			});
	} catch (err) {
		dispatch({
			type: REPORT_DTO_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const reportDTOActionpApi = async ({ assessmentResultId, isadmin, isprintpdf }) => {
	try {
		const { userLogin: { userInfo } } = store.getState();
		const config1 = {
			headers: {
				'custom-clinicid-header': userInfo.clinicid
			},
			// responseType: 'blob'
		};

		const { data } = await axios.get(`${userInfo.apiurl}/Assessments/ReportDTO?assessmentResultId=${assessmentResultId}&isadmin=${isadmin}&isprintpdf=${isprintpdf}`, config1);

		return data;
	} catch (err) {
		return null;
	}
};


export const PharmacyReportActionpApi = async ({ assessmentResultId, isadmin, isprintpdf }) => {
	try {
		const { userLogin: { userInfo } } = store.getState();
		const config1 = {
			headers: {
				'custom-clinicid-header': userInfo.clinicid
			},
			// responseType: 'blob'
		};

		const { data } = await axios.get(`${userInfo.apiurl}/Assessments/PharmacyReportDTO?assessmentResultId=${assessmentResultId}&isadmin=${isadmin}&isprintpdf=${isprintpdf}`, config1);

		return data;
	} catch (err) {
		return null;
	}
};


export const reportDTOFooterActionpApi = async ({ assessmentResultId }) => {
	try {
		const { userLogin: { userInfo } } = store.getState();
		const config1 = {
			headers: {
				'custom-clinicid-header': userInfo.clinicid
			}
		};
		// http://44.234.119.11:5001/api/Assessments/GetBannerImageByassessmentId?assessmentResultId=FEA570F0-AE02-4859-0FCE-08DAB3575D56

		const { data } = await axios.get(
			`${userInfo.apiurl}/Assessments/GetassessmentBannerImage?AssessmentResultsID=${assessmentResultId}`,
			config1
		);

		return data;
	} catch (err) {
		return null;
	}
};

export const saveAnswerAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: SAVE_ANSWER_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/`, config);

		dispatch({
			type: SAVE_ANSWER_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: SAVE_ANSWER_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const selfAssesmentAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: SELF_ASSESSMENT_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/`, config);

		dispatch({
			type: SELF_ASSESSMENT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: SELF_ASSESSMENT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const previousAssessmentAction = (userId) => async (dispatch, getState) => {
	try {
		dispatch({ type: PREVIOUS_ASSESSMENT_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/Providers/GetAssesmentListByUser?userid=${userId}`, config);

		dispatch({
			type: PREVIOUS_ASSESSMENT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: PREVIOUS_ASSESSMENT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const getBmiQuestionsAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_BMI_QUESTIONS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/assessments/getbmiquestions`, config);

		dispatch({
			type: GET_BMI_QUESTIONS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_BMI_QUESTIONS_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const bmiCalculationAction = (formData) => async (dispatch, getState) => {
	try { 
		const { userLogin: { userInfo } } = getState(); 
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
				'Content-Type': 'application/json',
			},
		};

		const { data } = await axios.post(`${userInfo.apiurl}/assessments/savebmicalculationanswer`, formData, config);

		return data;
	} catch (error) { 
		return error.response && error.response ? error.response : error;
	}
};

export const updateUserDetailsAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_USER_DETAILS_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/Auth/support/updateuserdetails`, formData, config);

		dispatch({
			type: UPDATE_USER_DETAILS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: UPDATE_USER_DETAILS_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const pdfSummaryAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: SUMMARY_PDF_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid
			}
		};

		const { data } = await axios.get(
			`${userInfo.apiurl}/Assessments/GetPDFSummaryDetails?assessmentResultId=${id}`,
			config
		);

		dispatch({
			type: SUMMARY_PDF_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: SUMMARY_PDF_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
