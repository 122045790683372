import React from 'react';

const ModalHeading = ({ heading, onClose }) => {
	return (
		<div className='modal__heading--videoContainer'>
			<h2 className='modal__heading2'>{heading}</h2>
			<button onClick={onClose} className='modal__heading--btn'>
				<svg className='modal__heading--icon'>
					<use xlinkHref={`/sprite.svg#icon-close`} />
				</svg>
			</button>
		</div>
	);
};

export default ModalHeading;
