export const UpdateModMedTextBox = ({ label, placeholder, value, icon, onChange, error }) => {
	return (
		<div className="modal_input" style={{width:'100%'}}>
			<label htmlFor="email" className="modal_input--label">
				{label}
			</label>
			<div className="modal_input--box">
				<label htmlFor="email" className="modal_input--iconBox">
					<svg className="modal_input--icon">
						<use xlinkHref={icon} />
					</svg>
				</label>

				<input
					type="text"
					id={label}
					value={value}
					placeholder={placeholder}
					onChange={onChange}
					className="modal_input--text2"
					style={{width:'100%'}}
				/>
			</div>
			{error && <p className="error_message">{error}</p>}
		</div>
	);
};