import React from 'react';
import classes from './Notifications.module.css';
import Content from '../../components/Content';
import { Button, NotificationItem, TextArea, TextBox } from './components';
import { useGetAllPatients } from '../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNotificationTemplateAction, getPreviousNotificationList, saveNotificationTemplateAction, SendNotificationAction } from '../../redux/actions/userActions';
import NoNotifications from '../../components/Header/Notification/NoNotifications';
import moment from 'moment';
import { MultiSelectBoxNew } from '../../components/SelectBox/MultiSelectBox';
import { getNotificationTemplateAction } from '../../redux/actions/clinicActions';
import { ModalSelectBox } from '../../components/Modal/ModalTextBox';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import { DeleteButton } from './components/Button';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonDelete } from '../../components/Modal/ModalButton';

const NotificationsScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [userRole, setUserRole] = React.useState('');
  const [sendToData, setSendToData] = React.useState({ value: [], error: '' });
  const [patientData, setPatientData] = React.useState([]);
  const [notificationTemp, setNotificationTemp] = React.useState({ value: '', error: '' });

  const userLogin = useSelector(state => state.userLogin);
  const allNotficationTemp = useSelector(state => state.allNotficationTemp);
  const [state, setState] = React.useState({ sendTo: { value: '', error: '' }, title: { value: '', error: '' }, body: { value: '', error: '' } });

  const [getPreviousNotification, setGetPreviousNotificationList] = React.useState([]);
  const [getAllNotifications, setGetAllNotifications] = React.useState({
    data: null,
    loading: true,
  });
  const [sendNotificationLoading, setSendNotificationLoading] = React.useState(false);
  const [saveNotificationTemplateLoading, setSaveNotificationTemplateLoading] = React.useState(false);
  const [templateId, setTemplateId] = React.useState('');
  const [deleteModalState, setDeleteModalState] = React.useState(false);



  const { getAllPatientsLoading, getAllPatients, allPatients } = useGetAllPatients();
  const perPageList = 5;
  const totalPages = getPreviousNotification.length / perPageList;

  React.useEffect(() => {
    if (userLogin && userLogin.userInfo) {
      if (userLogin.userInfo.issuperadmin || userLogin.userInfo.isclinicadmin) {
        setUserRole(userLogin.userInfo.issuperadmin ? 'SmcAdmin' : 'ClinicAdmin');

        if (userLogin.userInfo.isclinicadmin) {
          getAllPatients(userLogin.userInfo.clinicId)
          dispatch(getNotificationTemplateAction())
        };
      } else history.goBack();
    }
    // eslint-disable-next-line
  }, [userLogin, history, dispatch]);

  const getAllNotificationsHandler = React.useCallback(async () => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
      const data = await dispatch(getPreviousNotificationList(userLogin));

      if (data?.data) {
        setGetPreviousNotificationList(data.data);
        setGetAllNotifications({ data: data.data.slice(0, perPageList), count: data?.count, loading: false });
      }
    }
  }, [userLogin]);

  React.useEffect(() => {
    getAllNotificationsHandler();
  }, [getAllNotificationsHandler]);

  React.useEffect(() => {
    let sendTo = [];
    if (!getAllPatientsLoading && allPatients.data && allPatients.data.length > 0) {
      sendTo.push({
        id: '0',
        value: 'all',
        label: 'Select All',
      }); 
      allPatients?.data
        .sort(function (a, b) {
          if (a.firstName + ' ' + a.lastName < b.firstName + ' ' + b.lastName) return -1;
          if (a.firstName + ' ' + a.lastName > b.firstName + ' ' + b.lastName) return 1;
          return 0;
        })
        .forEach(patient => {
          sendTo.push({
            id: patient.userId,
            value: patient.userId,
            label: patient.firstName + ' ' + patient.lastName + ' (' + moment(patient.birthDate).format("MM/DD/YYYY") + ')',
          });
        });
      setPatientData(sendTo);
    }
  }, [getAllPatientsLoading, allPatients]);

  const handleSendNotification = async () => {
    var res = sendToData.value.map(s => s.id);
    if (res.length == 0) {
      setSendToData({ value: [], error: "Please select patient's " });
    } else if (!state.title.value.trim()) {
      setState(prevVal => ({ ...prevVal, title: { ...prevVal.title, error: 'Please enter notification title' } }));
    } else if (!state.body.value.trim()) {
      setState(prevVal => ({ ...prevVal, body: { ...prevVal.body, error: 'Please enter a notification description' } }));
    } else {
      setSendNotificationLoading(true);
      const formData = { Title: state.title.value, Body: state.body.value, DeviceTokens: res };
      let respData = await dispatch(SendNotificationAction(formData));
      if (respData?.status) {
        setSendNotificationLoading(false);
        setState({ sendTo: { value: '', error: '' }, title: { value: '', error: '' }, body: { value: '', error: '' } });
        setSendToData({ value: '', error: '' });
        setNotificationTemp({ value: '', error: '' });
        getAllNotificationsHandler();
        toast.success('Notification sent successfully');
      } else {
        setSendNotificationLoading(false);
        toast.error('Something went wrong while sending notification.');
      }
    }
  };

  let nextContentFrom = 0;
  const handlePageChange = currentPage => {
    nextContentFrom = currentPage.selected * perPageList;
    const nextContentTo = currentPage.selected * perPageList + 5;
    setGetAllNotifications({
      data: getPreviousNotification && getPreviousNotification.slice(nextContentFrom, nextContentTo),
      loading: false,
    });
  };



  const handleSaveNotificationTemplate = async () => {
    var res = sendToData.value.map(s => s.id);
    if (!state.title.value.trim()) {
      setState(prevVal => ({ ...prevVal, title: { ...prevVal.title, error: 'Please enter notification title' } }));
    } else if (!state.body.value.trim()) {
      setState(prevVal => ({ ...prevVal, body: { ...prevVal.body, error: 'Please enter a notification description' } }));
    } else {
      setSaveNotificationTemplateLoading(true);
      const formData = { Title: state.title.value, Body: state.body.value };
      let respData = await dispatch(saveNotificationTemplateAction(formData));
      if (respData?.status) {
        setSaveNotificationTemplateLoading(false);
        dispatch(getNotificationTemplateAction())
        toast.success(respData?.message);
      } else {
        setSaveNotificationTemplateLoading(false);
        toast.error('Something went wrong while sending notification.');
      }
    }
  };



  const handleDeleteNotificationTemplate = async () => {
    let respData = await dispatch(deleteNotificationTemplateAction(templateId));
    if (respData?.status) {
      setDeleteModalState(false);
      setSaveNotificationTemplateLoading(false);
      dispatch(getNotificationTemplateAction())
      setState({ sendTo: { value: '', error: '' }, title: { value: '', error: '' }, body: { value: '', error: '' } });
      setNotificationTemp({ value: '', error: '' });
      toast.success(respData?.message);
    } else {
      setSaveNotificationTemplateLoading(false);
      toast.error('Something went wrong while delete notification template.');
    }
  };

  const onDeleteClose = () => {
    setDeleteModalState(false);
  };

  return (
    <Content current='Notifications' headerTitle='Notifications' currentMenu='Notifications'>
      <div className={classes.container}>
        <div className={classes.notification_left}>
          <MultiSelectBoxNew
            multiple={true}
            label='Select/Search Patients'
            icon={false}
            errorMessage={sendToData.error}
            value={sendToData.value.length ? [...sendToData.value] : null}
            setSendToData={setSendToData}
            onChange={event => { 
              setSendToData({ value: event, error: '' });
            }}
            children={patientData}
          ></MultiSelectBoxNew>

          <ModalSelectBox
            label='Select Notification Template'
            value={notificationTemp.value}
            onChange={event => {
              if (event.target.value != '') {
                setTemplateId(event.target.value);
                setNotificationTemp({ value: event.target.value, error: '' });
                let template = allNotficationTemp?.data?.data.filter(item => item.id == event.target.value)[0];
                setState(prevVal => ({ ...prevVal, title: { value: template.title, error: '' }, body: { value: template.body, error: '' } }))
              } else {
                setTemplateId('');
                setState({ title: { value: '', error: '' }, body: { value: '', error: '' } });
                setNotificationTemp({ value: '', error: '' });
              }
            }}
            style={{
              width: '100%',
              padding: '.8rem 1rem'
            }}
            error={notificationTemp.error && <p className='error_message'>{notificationTemp.error}</p>}
          >
            <option value=''>Select Template</option>
            {allNotficationTemp &&
              allNotficationTemp.data &&
              allNotficationTemp.data.data.length > 0 &&
              allNotficationTemp.data.data.map((item, i) =>
                <option value={item.id} key={i + 1}>
                  {item.templateName}
                </option>
              )}
          </ModalSelectBox>


          <TextBox
            label='Notification Title'
            id='notification-title'
            placeholder='Enter title'
            value={state.title.value}
            onChange={e => setState(prevVal => ({ ...prevVal, title: { value: e.target.value, error: '' } }))}
            error={state.title.error}
          />

          <TextArea
            label='Notification Body'
            id='notification-body'
            placeholder='Enter notification text'
            value={state.body.value}
            onChange={e => setState(prevVal => ({ ...prevVal, body: { value: e.target.value, error: '' } }))}
            error={state.body.error}
            rows={10}
            style={{
              resize: "none"
            }}
          />

          <div className={classes.notification_buttons}>
            <Button
              disabled={saveNotificationTemplateLoading}
              label={saveNotificationTemplateLoading ? 'Please wait..' : 'Save As Template'}
              icon='plus'
              onClick={handleSaveNotificationTemplate}
              style={{ marginTop: '2rem', maxWidth: '14rem', fontSize: '10px', padding: '5px' }}
            />

            <DeleteButton
              disabled={!templateId.trim() ? true : templateId == 0 ? true : false}
              label={'Delete Template'}
              icon='delete'
              onClick={() => {
                setDeleteModalState(true)
              }}
              style={{ marginTop: '2rem', maxWidth: '14rem', fontSize: '10px', padding: '5px' }}
            />
          </div>

          <Button
            disabled={sendNotificationLoading}
            label={sendNotificationLoading ? 'Please wait..' : 'Send Notification'}
            icon='notification-2'
            onClick={handleSendNotification}
            style={{ marginTop: '2rem', margin: 'auto' }}
          />
        </div>

        <div className={classes.notification_right}>
          <h3 className={classes.notification_title}>Previously Sent Notifications</h3>

          {getAllNotifications && getAllNotifications.loading ? (
            <NoNotifications title='Loading Notifications' />
          ) : getAllNotifications.data && getAllNotifications?.data.length ? (
            getAllNotifications.data.map((item, index) => {
              let sentTo = [];
              item.notificationToList &&
                item.notificationToList.length &&
                item.notificationToList.forEach(not => {
                  sentTo.push(not.userName);
                });

              return (
                <NotificationItem
                  key={index + 1}
                  title={item.title}
                  subTitle={item.body}
                  date={moment.utc(item.createdDate).format('DD MMM  YYYY h:mm A')}
                  read={item.isRead}
                  sentTo={sentTo}
                />
              );
            })
          ) : (
            <NoNotifications title='No Notifications Yet' />
          )}
        </div>
        {getAllNotifications && getAllNotifications.data && (
          <div className={classes.notification_pagination}>
            <ReactPaginate
              nextClassName={''}
              previousLabel={'<'}
              nextLabel={'>'}
              pageCount={getPreviousNotification.length / perPageList}
              onPageChange={handlePageChange}
              marginPagesDisplayed={1}
              pageRangeDisplayed={5}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
            />
          </div>
        )}
      </div>

      <Modal show={deleteModalState}>
        <ModalHeading heading={state.title.value} onClose={onDeleteClose} />
        <ModalForm style={{ marginBottom: '2.5rem' }}>
          <p className='modal__text' style={{ marginBottom: '1rem' }}>Are you sure you want to delete this template?</p>
          <ModalButtonDelete title='Confirm Delete' onClick={handleDeleteNotificationTemplate} />
        </ModalForm>
      </Modal>
    </Content>
  );
};

export default NotificationsScreen;
