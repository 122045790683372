import React, { Fragment } from 'react';

export const AssessmentSkeleton = () => {
	return (
		<Fragment>
			<div className="modal__actions" style={{ padding: '.1rem', paddingleft: '2rem' }}>
				<div className="filter__input--label3-skeleton skeleton" />
				<div className="modal__button--toggle-skeleton skeleton" />
			</div>

			<div className="filter__input--labellong1-skeleton skeleton" />
			<div className="modal__actions" style={{ padding: '.1rem', paddingleft: '2rem' }}>
				<div className="filter__input--label1-skeleton skeleton" />
			</div>
			<div className="modal__actions" style={{ padding: '.1rem', paddingleft: '2rem' }}>
				<div className="modal__button--toggle-skeleton skeleton" />
				<div className="filter__input--label-skeleton skeleton" />
			</div>
			<div className="modal__actions" style={{ padding: '.1rem', paddingleft: '2rem' }}>
				<div className="modal__button--toggle-skeleton skeleton" />
				<div className="filter__input--label-skeleton skeleton" />
			</div>
			<div className="modal__actions" style={{ padding: '.1rem', paddingleft: '2rem' }}>
				<div className="modal__button--toggle-skeleton skeleton" />
				<div className="filter__input--label-skeleton skeleton" />
			</div>

			<div className="modal__actions" style={{ padding: '.1rem', paddingleft: '2rem' }}>
				<div className="modal__button--update1-skeleton skeleton" />
				<div className="modal__button--update2-skeleton skeleton" />
				<div className="modal__button--update3-skeleton skeleton" />
			</div>
		</Fragment>
	);
};
export default AssessmentSkeleton;
