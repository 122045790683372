import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import './RegisterAdmin.css'
import { registerAdminAction } from '../../redux/actions/adminActions'

function Index() {
    const dispatch = useDispatch(); 
    const [formData, setformData] = useState({
        fName: '',
        lName: '',
        email: '',
        phone: '',
        cName: '',
        password: '',

    })
    const handleRegister = (e) => {
        e.preventDefault(); 
        dispatch(registerAdminAction(formData))
    }
    return (
        <main className='loginContainer'>
            <div className='loginContainer__bg' />
            <div className='registerContainer__box'>
                <div className='loginContainer__box--right'>
                    <img src='/images/logo.png' alt='Suremed Logo' className='loginContainer__box--logo' />
                    <h1 className='loginContainer__heading'>Sign Up</h1>
                    <h1 className='loginContainer__subheading'>Create Your Account</h1>
                </div>
                <div className='loginContainer__box--left'>
                    <img src='/images/ccp.png' alt='CCP Logo' className='loginContainer__box--image' />

                    <form className='registerContainer__form'>
                        <h2 className='register__admin-header'>Register your Account</h2>

                        <div className='flex-form'>
                            <div className='registerContainer__formgroup--inputBox'>
                                <div className='logoContainer__formgroup--iconBox'>
                                    <svg className='loginContainer__formgroup--icon'>
                                        <use xlinkHref='/sprite.svg#icon-key' />
                                    </svg>
                                </div>
                                <input
                                    type='text'
                                    placeholder='First Name'
                                    id='first-name'
                                    className='loginContainer__formgroup--input'
                                    value={formData.fName}
                                    onChange={(event) => {
                                        setformData({ ...formData, fName: event.target.value });
                                    }}
                                />
                            </div>

                            <div className='registerContainer__formgroup--inputBox'>
                                <div className='logoContainer__formgroup--iconBox'>
                                    <svg className='loginContainer__formgroup--icon'>
                                        <use xlinkHref='/sprite.svg#icon-key' />
                                    </svg>
                                </div>
                                <input
                                    type='text'
                                    placeholder='Last Name'
                                    id='lastName'
                                    className='loginContainer__formgroup--input'
                                    value={formData.lName}
                                    onChange={(event) => {
                                        setformData({ ...formData, lName: event.target.value });
                                    }}
                                />
                            </div>
                        </div>
                        <div className='addressContainer__formgroup--inputBox'>
                            <div className='logoContainer__formgroup--iconBox'>
                                <svg className='loginContainer__formgroup--icon'>
                                    <use xlinkHref='/sprite.svg#icon-key' />
                                </svg>
                            </div>
                            <input
                                type='email'
                                placeholder='johndoe@example.com'
                                id='email'
                                className='loginContainer__formgroup--input'
                                value='andrew@suremed.com'
                                // readOnly
                                // value={formData.fName}
                                onChange={(event) => {
                                    setformData({ ...formData, email: event.target.value });
                                }}
                            />
                        </div>
                        <div className='flex-form'>


                            <div className='registerContainer__formgroup--inputBox'>
                                <div className='logoContainer__formgroup--iconBox'>
                                    <svg className='loginContainer__formgroup--icon'>
                                        <use xlinkHref='/sprite.svg#icon-key' />
                                    </svg>
                                </div>
                                <input
                                    type='text'
                                    placeholder='Contact Phone'
                                    id='phone'
                                    className='loginContainer__formgroup--input'
                                    value={formData.phone}
                                    onChange={(event) => {
                                        setformData({ ...formData, phone: event.target.value });
                                    }}
                                />
                            </div>

                            <div className='registerContainer__formgroup--inputBox'>
                                <div className='logoContainer__formgroup--iconBox'>
                                    <svg className='loginContainer__formgroup--icon'>
                                        <use xlinkHref='/sprite.svg#icon-key' />
                                    </svg>
                                </div>
                                <input
                                    type='text'
                                    placeholder='Clinic Name'
                                    id='clinic-name'
                                    className='loginContainer__formgroup--input'
                                    value='Matt Clinic'
                                    // readOnly
                                    // value={formData.cName}
                                    onChange={(event) => {
                                        setformData({ ...formData, cName: event.target.value });
                                    }}
                                />
                            </div>

                        </div>
                        <div className='flex-form'>

                            <div className='registerContainer__formgroup--inputBox'>
                                <div className='logoContainer__formgroup--iconBox'>
                                    <svg className='loginContainer__formgroup--icon'>
                                        <use xlinkHref='/sprite.svg#icon-key' />
                                    </svg>
                                </div>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    id='password'
                                    className='loginContainer__formgroup--input'
                                />
                            </div>

                            <div className='registerContainer__formgroup--inputBox'>
                                <div className='logoContainer__formgroup--iconBox'>
                                    <svg className='loginContainer__formgroup--icon'>
                                        <use xlinkHref='/sprite.svg#icon-key' />
                                    </svg>
                                </div>
                                <input
                                    type='password'
                                    placeholder='Confirm Password'
                                    id='confirmPassword'
                                    className='loginContainer__formgroup--input'
                                    value={formData.password}
                                    onChange={(event) => {
                                        setformData({ ...formData, password: event.target.value });
                                    }}
                                />
                            </div>
                        </div>

                        <div className='registerContainer__formgroup--btn'>
                            <button className='registerContainer__button' onClick={handleRegister}>
                                <span className='loginContainer__button--text'>Register</span>
                                <div className='loginContainer__button--iconBox'>
                                    <svg className='loginContainer__button--icon'>
                                        <use xlinkHref='/sprite.svg#icon-login' />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Index
