import React, { Fragment } from "react";
import ConsentFormsRegister from "../../../components/Skeletons/ConsentFormsRegister";

const Page3 = ({ data, loading, error }) => {
  const { onTermsAgree, setAgreement, consentForm } = data;
  return (
    <Fragment>
      {loading ? (
        <ConsentFormsRegister />
      ) : (
        <div className="termsContainer">
          {consentForm.status &&
            consentForm.fileURL && (
              <iframe
                src={consentForm.fileURL + "#toolbar=0"}
                type="application/pdf"
                title="suremed-tnc"
                style={{
                  height: "calc(50vh - 4rem)",
                  minWidth: "45vw",
                  width: "100%",
                  borderRadius: "2rem",
                }}
              />
            )}
        </div>
      )}
      <div
        className="modal__actions"
        style={{ display: "flex", justifyContent: "flex-end", padding: "2rem" }}
      >
        <button
          id="page_previous_btn_3"
          className="modal__button modal__button--update"
          onClick={() => setAgreement((prevData) => prevData - 1)}
          disabled={loading || error}
        >
          Previous
        </button>
        <button
          id="page_agree_btn_3"
          className="modal__button modal__button--update"
          onClick={onTermsAgree}
        >
          I Agree
        </button>
      </div>
    </Fragment>
  );
};

export default Page3;
