import React, { Fragment } from 'react';
import './InviteAdmins.css';
import EmailData from './data';
import InviteAdminEmails from '../../components/Skeletons/InviteAdminEmails';
import InviteAdminForm from '../../components/Skeletons/InviteAdminForm';
// import ReactPaginate from 'react-paginate';

function Invite({ data, invitedAdmins, inviteAdmin }) {
	const { email, setEmail, onInviteAdminHandler } = data;

	// const handlePageChange = () => {};

	return (
		<Fragment>
			<div className="invite-class">
				<div className="filter__content1">
					{inviteAdmin && inviteAdmin.loading ? (
						<InviteAdminForm />
					) : (
						<form className="filter__content--top1" onSubmit={onInviteAdminHandler}>
							<div className="filter__input--labels">
								<label htmlFor="email" className="filter__input--label">
									Email ID
								</label>
								<div className="filter__input">
									<label htmlFor="email" className="filter__input--iconBox">
										<svg className="filter__input--icon">
											<use xlinkHref={`/sprite.svg#icon-email`} />
										</svg>
									</label>
									<input
										type="text"
										id="email"
										placeholder="johndoe@example.com"
										className="filter__input--text"
										value={email.value}
										onChange={(event) => {
											setEmail({ value: event.target.value });
										}}
									/>
								</div>
								{email.error && <p className="error_message">{email.error}</p>}
							</div>
							<button className="filter__input--button" onClick={onInviteAdminHandler}>
								Send Invitation
							</button>
						</form>
					)}
				</div>
				{invitedAdmins && invitedAdmins.loading ? (
					<InviteAdminEmails />
				) : (
					<div className="filter__content2">
						<div className="pending-header">Pending Invites</div>
						<div style={{width: '100%'}}>
							<EmailData data={data} invitedAdmins={invitedAdmins} />
						</div>
						<div />
					</div>
				)}
			</div>
			{/* <div className='paginationTop' style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<ReactPaginate
					nextClassName={''}
					previousLabel={'<'}
					nextLabel={'>'}
					pageCount={'4'}
					onPageChange={handlePageChange}
					marginPagesDisplayed={1}
					pageRangeDisplayed={5}
					containerClassName={'paginationBttns'}
					previousLinkClassName={'previousBttn'}
					nextLinkClassName={'nextBttn'}
					disabledClassName={'paginationDisabled'}
					activeClassName={'paginationActive'}
				/>
			</div> */}
		</Fragment>
	);
}

export default Invite;
