export const SAVE_PDMP_CREDENTIALS_REQUEST = 'SAVE_PDMP_CREDENTIALS_REQUEST';
export const SAVE_PDMP_CREDENTIALS_SUCCESS = 'SAVE_PDMP_CREDENTIALS_SUCESSS';
export const SAVE_PDMP_CREDENTIALS_FAIL = 'SAVE_PDMP_CREDENTIALS_FAIL';
export const SAVE_PDMP_CREDENTIALS_RESET = 'SAVE_PDMP_CREDENTIALS_RESET';



export const GET_PILL_COUNT_REQUEST = 'GET_PILL_COUNT_REQUEST';
export const GET_PILL_COUNT_SUCCESS = 'GET_PILL_COUNT_SUCESSS';
export const GET_PILL_COUNT_FAIL = 'GET_PILL_COUNT_FAIL';
export const GET_PILL_COUNT_RESET = 'GET_PILL_COUNT_RESET';


export const REQUEST_PILL_COUNT_REQUEST = 'REQUEST_PILL_COUNT_REQUEST';
export const REQUEST_PILL_COUNT_SUCCESS = 'REQUEST_PILL_COUNT_SUCESSS';
export const REQUEST_PILL_COUNT_FAIL = 'REQUEST_PILL_COUNT_FAIL';
export const REQUEST_PILL_COUNT_RESET = 'REQUEST_PILL_COUNT_RESET';


export const REFRESH_PILL_COUNT_REQUEST = 'REFRESH__PILL_COUNT_REQUEST';
export const REFRESH_PILL_COUNT_SUCCESS = 'REFRESH__PILL_COUNT_SUCESSS';
export const REFRESH_PILL_COUNT_FAIL = 'REFRESH__PILL_COUNT_FAIL';
export const REFRESH_PILL_COUNT_RESET = 'REFRESH__PILL_COUNT_RESET';



export const DELETE_PILL_COUNT_REQUEST = 'DELETE__PILL_COUNT_REQUEST';
export const DELETE_PILL_COUNT_SUCCESS = 'DELETE__PILL_COUNT_SUCESSS';
export const DELETE_PILL_COUNT_FAIL = 'DELETE__PILL_COUNT_FAIL';
export const DELETE_PILL_COUNT_RESET = 'DELETE__PILL_COUNT_RESET';  