import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { registerAdminAction } from '../../redux/actions/adminActions';
import { REGISTER_ADMIN_RESET } from '../../redux/constants/adminConstants';
import { validateEmail, validatePassword } from '../../utils/validators';

function Index({ history }) {
	const dispatch = useDispatch();
	const registerAdmin = useSelector((state) => state.registerAdmin);

	const [currentEye, setCurrentEye] = useState(false);
	const [confirmCurrentEye, setConfirmCurrentEye] = useState(false);

	const [data, setData] = useState({
		email: '',
		password: '',
		cPassword: '',
	});

	const [dataError, setDataError] = useState({
		email: '',
		password: '',
		cPassword: '',
	});

	useEffect(() => {
		if (registerAdmin && registerAdmin.data && registerAdmin.data.succeeded) {
			toast.success('Registration Successful')
			dispatch({ type: REGISTER_ADMIN_RESET });
			history.push('/user/verify-email')
		} else if (registerAdmin && registerAdmin.error) {
			toast.error(registerAdmin.error);
			setDataError({ ...dataError, cPassword: registerAdmin.error })
			dispatch({ type: REGISTER_ADMIN_RESET });
		}
	}, [registerAdmin, history, dispatch])


	const showPassword = () => {
		var x = document.getElementById('password');
		setCurrentEye(!currentEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const showConfirmPassword = () => {
		var y = document.getElementById('confirm-password');
		setConfirmCurrentEye(!confirmCurrentEye);
		if (y.type === 'password') {
			y.type = 'text';
		} else {
			y.type = 'password';
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (data.cPassword === data.password) {
			if (!data.email.trim() || data.email.trim() === '') {
				setDataError({ ...dataError, email: 'Please enter an email' });
			} else if (!validateEmail(data.email)) {
				setDataError({ ...dataError, email: 'Please enter a valid email' });
			} else if (!data.password.trim() || data.password.trim() === '') {
				setDataError({ ...dataError, password: 'Please enter a password' });
			} else if (!validatePassword(data.password)) {
				setDataError({ ...dataError, password: "Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character." });
			} else {
				const formData = {
					Email: data.email,
					Password: data.password
				}
				dispatch(registerAdminAction(formData))
			}
		} else {
			setDataError({ ...dataError, cPassword: 'Password does not match' })
		}
	};
	return (
		<main className="loginContainer">
			<div className="loginContainer__bg" />
			<div className="registerContainer__box">
				<div className="loginContainer__box--right">
					<img src="/images/logo.png" alt="Suremed Logo" className="loginContainer__box--logo" />
					<h1 className="loginContainer__heading">Admin Registraion</h1>
					<h1 className='loginContainer__subheading'>Invite Required</h1>
				</div>
				<div className="registerContainer__box--right">
					<form className="registerContainer__form" style={{ width: '60%' }}>
						<div className="addressContainer__formgroup--inputBox">
							<div className="logoContainer__formgroup--iconBox">
								<svg className="loginContainer__formgroup--icon">
									<use xlinkHref="/sprite.svg#icon-email" />
								</svg>
							</div>
							<input
								type="text"
								placeholder="Email"
								id="email"
								value={data.email}
								onChange={(e) => {
									setData({ ...data, email: e.target.value })
									setDataError('')
								}}
								className="loginContainer__formgroup--input"
							/>
						</div> <p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.email}</p>
						<div className="addressContainer__formgroup--inputBox">
							<div className="logoContainer__formgroup--iconBox">
								<svg className="loginContainer__formgroup--icon">
									<use xlinkHref="/sprite.svg#icon-key" />
								</svg>
							</div>
							<input
								type="password" autoComplete="new-password"
								placeholder="Password"
								id="password"
								value={data.password}
								onChange={(e) => {
									setData({ ...data, password: e.target.value })
									setDataError('')
								}}
								className="loginContainer__formgroup--input"
							/>
							<svg
								style={{ margin: '1rem' }}
								className="loginContainer__formgroup--icon"
								onClick={showPassword}
							>
								{currentEye ? (
									<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
								) : (
									<use xlinkHref={`/sprite.svg#icon-showPassword`} />
								)}
							</svg>
						</div>
						<p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.password}</p>
						<div className="addressContainer__formgroup--inputBox">
							<div className="logoContainer__formgroup--iconBox">
								<svg className="loginContainer__formgroup--icon">
									<use xlinkHref="/sprite.svg#icon-key" />
								</svg>
							</div>
							<input
								type="password" autoComplete="new-password"
								placeholder="Confirm Password"
								id="confirm-password"
								value={data.cPassword}
								onChange={(e) => {
									setData({ ...data, cPassword: e.target.value })
									setDataError('')
								}}
								className="loginContainer__formgroup--input"
							/>
							<svg
								style={{ margin: '1rem' }}
								className="loginContainer__formgroup--icon"
								onClick={showConfirmPassword}
							>
								{confirmCurrentEye ? (
									<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
								) : (
									<use xlinkHref={`/sprite.svg#icon-showPassword`} />
								)}
							</svg>
						</div>
						<p className="error_message" style={{ marginLeft: '1.5rem' }}>{dataError.cPassword}</p>

						<div className="registerContainer__formgroup--btn">
							<button className="registerContainer__button" onClick={handleSubmit}>
								<span className="loginContainer__button--text">Sign Up</span>
								<div className="loginContainer__button--iconBox">
									<svg className="loginContainer__button--icon">
										<use xlinkHref="/sprite.svg#icon-login" />
									</svg>
								</div>
							</button>
						</div>
					</form>
				</div>
			</div>

		</main>
	);
}

export default Index;
