import React from 'react';

const NoNotifications = ({ title }) => {
    return (
        <div className={`notification-box`}>
            <div className="notification__button--iconBox">
                <svg className="notification-icon">
                    <use xlinkHref={`/sprite.svg#icon-notification`} />
                </svg>
            </div>
            <span className='notification-box-msg'>{title}</span>
        </div>
    );
};

export default NoNotifications;
