import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import ReactPaginate from 'react-paginate';
import TableRows from '../../components/Skeletons/TableRows';
import './ConsentFormsDocument.css';
import { consentFormsDocAction, consentFormsDocViewAction } from '../../redux/actions/providerActions';
import moment from 'moment';
import { toast } from 'react-toastify';

const ConsentFormsDocument = ({ match }) => {
	const dispatch = useDispatch();
	const [userIndex, setUserIndex] = useState(1);

	const [consentFormsDocList, setPreviousAssessmentList] = useState([]);
	const consentFormsDoc = useSelector((state) => state.consentFormsDocList); //map data
	const [totalAssessmentList, setTotalAssessmentList] = useState([]);
	const perPageList = 10;
	const totalPages = totalAssessmentList / perPageList;

	useEffect(
		() => {
			dispatch(consentFormsDocAction(match.params.userId));
		},
		[dispatch, match]
	);


	useEffect(
		() => {
			if (consentFormsDoc && consentFormsDoc.userInfo && consentFormsDoc.userInfo.data && consentFormsDoc.userInfo.data.length > 0) {
				setTotalAssessmentList(consentFormsDoc.userInfo.data.length);
				setPreviousAssessmentList(consentFormsDoc.userInfo.data.slice(0, perPageList));
			} else {
				setPreviousAssessmentList([]);
			}
		},
		[consentFormsDoc]
	);

	let nextContentFrom = 0;
	const handlePageChange = (currentPage) => {
		nextContentFrom = currentPage.selected * perPageList;
		const nextContentTo = currentPage.selected * perPageList + 10;
		setPreviousAssessmentList(
			consentFormsDoc &&
			consentFormsDoc.userInfo &&
			consentFormsDoc.userInfo.data.slice(nextContentFrom, nextContentTo)
		);
		setUserIndex(currentPage.selected * perPageList + 1);
	};

	const viewPdfHandler = async (id,patientId, fileName) => {
		const pdfData = await dispatch(consentFormsDocViewAction(id,patientId, fileName));
		if (pdfData && pdfData.status) {
			window.open(pdfData.pdfURL);
		} else {
			toast.error(pdfData.data.message);
		}
	};

	return (
		<Content currentMenu="manage-users" headerTitle="Consent Forms">

			{consentFormsDoc && consentFormsDoc.userInfo && consentFormsDoc.userInfo.patientName &&
				<h2 className="filter__heading">Patient: {consentFormsDoc.userInfo.patientName}</h2>
			}

			<div className="tableContainer" style={{ height: '65vh' }}>
				<table className="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Document Name</th>
							<th>Signed Date</th>
							<th>Version</th>
							<th style={{ textAlign: 'center' }}>View Document</th>
						</tr>
					</thead>
					<tbody>
						{consentFormsDoc && !consentFormsDoc.loading ? consentFormsDocList.length > 0 ? (
							// .filter((a, i) => consentFormsDocList.findIndex((s) => a.consentFormId === s.consentFormId) === i)
							consentFormsDocList.map((user, i) => (
								<tr key={user.assesmentid}>
									<td>{userIndex + i}</td>
									<td>{user.documentName}</td>
									<td>{moment.utc(new Date(user.signedDate)).format('MM/DD/YYYY')}</td>
									<td>{user.version ? user.version : 1}</td>
									<td style={{ display: 'flex', justifyContent: 'center' }}>
										<div className='table__iconBox'
											style={{ display: 'flex', justifyContent: 'center' }}
										>
											<button
												onClick={() => viewPdfHandler(user.id, user.applicationUserId, user.documentName)}
												className='table__button2' style={{ marginRight: '0.5rem' }}>
												<svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
													<use xlinkHref={`/sprite.svg#icon-pdf`} />
												</svg>
												<span>Download Form</span>
											</button>
										</div>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="5" style={{ textAlign: "center" }}>No consent forms found</td>
							</tr>
						) : (
							<TableRows columns="6" />
						)}
					</tbody>
				</table>
			</div>
			{consentFormsDoc &&
				consentFormsDoc.userInfo &&
				consentFormsDoc.userInfo.length > 10 && (
					<div className="tableContainer--paginater">
						<ReactPaginate
							nextClassName={''}
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={totalPages}
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={5}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'paginationActive'}
						/>
					</div>
				)}
		</Content>
	);
};

export default ConsentFormsDocument;
