import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import ModalTextInput from '../../components/Modal/ModalTextInput';
import { PartnerContentSkeleton } from '../../components/Skeletons/ManageClient';

const AddPartnerContent = ({ modalState, onClose, handleUpload, data }) => {
	const {
		uploadContent,
		setUploadContent,
		uploadContentError,
		setUploadContentError,
		uploadContentDetails
	} = data;


	return (
		<Modal show={modalState}>
			<ModalHeading heading='Partner' onClose={onClose} />
			{
				uploadContentDetails && uploadContentDetails.loading ?
					<PartnerContentSkeleton />
					:
					<ModalForm onSubmit={handleUpload} style={{ marginBottom: '2.5rem' }}>
						<InputsSection
							style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', }}
						>
							<ModalTextInput
								width={"22rem"}
								label='Partner Title'
								placeholder='Partner Title'
								value={uploadContent.title}
								onChange={(e) => {
									setUploadContent({ ...uploadContent, title: e.target.value })
									setUploadContentError({ ...uploadContentError, title: '' })
								}}
								error={uploadContentError.title}
							/>
						</InputsSection>
						<ModalButtonPrimary title='Add partner' onClick={handleUpload} />
					</ModalForm>
			}
		</Modal>
	);
};

export default AddPartnerContent;
