import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import appStyle from './appScreen.module.css';
import { isAndroid, isIOS } from "react-device-detect";

const CustomQRCodeScreen = () => {

  useEffect(() => {
    if (isAndroid) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.thealgorithm.pic";
    } else if (isIOS) {
      window.location.href =
        "https://apps.apple.com/us/app/perspectives-in-care/id6446095345";
    }
  }, []);

  return (
    <main className={appStyle.appContainer}>
      <Helmet>
        <title>Perspectives in Care – app</title>
      </Helmet>
      <div className={appStyle.appContainer__box}>
        <img
          src='/images/logo.png'
          alt='Suremed Logo'
          className={appStyle.appContainer__box_logo}
        />
      </div>
      <div className={appStyle.appContainer__box}>
        <div className={appStyle.app_box}>
          <img
            src='/images/app_logo.png'
            alt='Suremed Logo'
            className={appStyle.appavatar}
          />

          <a className={appStyle.anchortag} href="https://play.google.com/store/apps/details?id=com.thealgorithm.pic" target="_blank">
            <img id='logo' className={appStyle.app_image} src="/images/google-en.png" alt="play store" />
          </a>
          <a className={appStyle.anchortag} href="https://apps.apple.com/us/app/perspectives-in-care/id6446095345" target="_blank">
            <img alt="apple store" id='logo' className={appStyle.app_image} src="/images/apple-en.png" />
          </a>
        </div>
      </div>
    </main>
  );
};

export default CustomQRCodeScreen;
