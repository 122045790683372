import React, { Fragment } from 'react';

export const AddClientSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				<div style={{display: 'flex'}}>
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
					<div className='filter__input--labels'>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
				</div>

				<br/>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='filter__input--skeleton skeleton' />

				</div>
                <br/>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='filter__input--skeleton skeleton' />

				</div>
			</div>
			<div className='modal__actions'>
				<div className='filter__input--button-skeleton skeleton' />
			</div>
		</Fragment>
	);
};

export const DashboardClientSkeleton = () => {
	return (
		<Fragment>
			<div className='clientContainer__form'>

					<div className='client__input--labels' style={{marginRight: '1rem'}}>

						<div className='client__input--skeleton skeleton' />
						<div className='dashboard__input--button-skeleton skeleton' />
					</div>
					<div className='client__input--labels' style={{marginRight: '1rem'}}>

						<div className='client__input--skeleton skeleton' />
						<div className='dashboard__input--button-skeleton skeleton' />
					</div>

			</div>
		</Fragment>
	);
};

export const ManageClientSkeleton = () => {
	return (
		<Fragment>
			<div className='clientContainer__form'>

					<div className='client__input--labels' style={{marginRight: '1rem'}}>

						<div className='client__input--skeleton skeleton' />
						<div className='client__input--button-skeleton skeleton' />
					</div>
					<div className='client__input--labels' style={{marginRight: '1rem'}}>

						<div className='client__input--skeleton skeleton' />
						<div className='client__input--button-skeleton skeleton' />
					</div>
			</div>
		</Fragment>
	);
};

export const UploadContentSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form' style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
				<div style={{display: 'flex'}}>
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
					<div className='filter__input--labels'>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
				</div>

				<div className='client__input--labels' style={{marginRight: '1rem', display:'flex', alignItems: 'flex-start'}}>
					<div className='client__input--skeleton skeleton' />
				</div>

				<div className='modal__actions'>
					<div className='filter__input--button-skeleton skeleton' />
				</div>
			</div>

		</Fragment>
	);
};


export const PartnerContentSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form' style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
				<div style={{display: 'flex'}}>
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div> 
				</div> 
				<div className='modal__actions'>
					<div className='filter__input--button-skeleton skeleton' />
				</div>
			</div>

		</Fragment>
	);
};
