import React from 'react';
import TableRows from '../../components/Skeletons/TableRows';

export default function ShowData({ data, loading, onOptionsChange }) {
	const { showFilter, usersList, pageNumber } = data;
	const [expandedRows, setExpandedRows] = React.useState(null);

	// expand table row
	const handleExpandRow = (userId) => {
		let currentExpandedRows = null;
		const isRowExpanded = currentExpandedRows === userId ? userId : null;
		const newExpandedRows = isRowExpanded
			? null
			: (currentExpandedRows = userId);
		if (expandedRows !== userId) {
			setExpandedRows(newExpandedRows);
		} else {
			setExpandedRows(null);
		}
	};
	const newIndex = (pageNumber * 10) - 9;
	
	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '23vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th />
						<th />
						<th>#</th>
						<th>First Name</th>
						<th>Last Name</th>
						<th>Birth Date</th>
						<th>Email</th>
						<th>Phone</th>
						<th>Gender</th>
						<th style={{ textAlign: "center" }}>First Name <br /> Match? </th>
						<th style={{ textAlign: "center" }}>Last Name <br /> Match? </th>
						<th style={{ textAlign: "center" }}>Birth Date <br /> Match? </th>
						<th style={{ textAlign: "center" }}>Email <br /> Match? </th>
						<th style={{ textAlign: "center" }}>Phone <br /> Match? </th>
						<th style={{ textAlign: "center" }}>Gender <br /> Match? </th>
						<th >Action </th>
					</tr>
				</thead>
				<tbody>
					{usersList && !loading ? usersList.length > 0 ? (
						usersList.map((user, index) => (
							<React.Fragment key={`${index + 1}`}>
								<tr key={index + 1} style={{ cursor: "pointer" }} onClick={() => handleExpandRow(index)}>
									<td>
										{expandedRows === index ? (
											<svg className='table__icon primary-icon' style={{ height: '2.4rem', width: '2.4rem' }}>
												<use xlinkHref={`/sprite.svg#icon-chevron-top`} />
											</svg>
										) : (
											<svg className='table__icon primary-icon' style={{ height: '2.4rem', width: '2.4rem' }}>
												<use xlinkHref={`/sprite.svg#icon-chevron-down`} />
											</svg>
										)}
									</td>
									<td style={{ fontWeight: '500', letterSpacing: '1px' }}>{expandedRows === index ? "PiC" : ""}</td>

									<td>{newIndex + index}</td>
									<td>{user.piC_FirstName}</td>
									<td>{user.piC_LastName}</td>
									<td>{user.piC_BirthDate}</td>
									<td>{user.piC_Email}</td>
									<td>{user.piC_PhoneNumber}</td>
									<td style={{ textTransform: 'capitalize' }}>{user.piC_Gender}</td>
									<td style={{ textAlign: "center" }}>
										{user.isFirstName ? (
											<svg className='table__icon green-icon'>
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className='table__icon red-icon'>
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{user.isLastName ? (
											<svg className='table__icon green-icon'>
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className='table__icon red-icon'>
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{user.isBirthDate ? (
											<svg className='table__icon green-icon'>
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className='table__icon red-icon'>
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{user.isEmail ? (
											<svg className='table__icon green-icon'>
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className='table__icon red-icon'>
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{user.isPhone ? (
											<svg className='table__icon green-icon'>
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className='table__icon red-icon'>
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td style={{ textAlign: "center" }}>
										{user.isGender ? (
											<svg className='table__icon green-icon'>
												<use xlinkHref={`/sprite.svg#icon-tick`} />
											</svg>
										) : (
											<svg className='table__icon red-icon'>
												<use xlinkHref={`/sprite.svg#icon-cross`} />
											</svg>
										)}
									</td>
									<td>
										<div className='filter__input--top'>
											<select
												className='filter__input--select'
												// value={}
												onChange={(e) => onOptionsChange(e, user)}
												name='actionStatus'
												id='actionStatus'>
												<option value=''>Select Status</option>
												{user.modMed_Id && <option value='Yes, this is a match'>Yes, this is a match</option>}
												<option value='No, but I have the EHR Id'>No, but I have the EHR Id</option>
												<option value='No, Unknown Patient'>No, Unknown Patient</option>
											</select>
										</div>
									</td>
								</tr>

								{expandedRows === index ? (
									<tr>
										<td>
											<svg className='table__icon primary-icon' style={{ height: '2.4rem', width: '2.4rem', marginLeft: '1rem' }}>
												<use xlinkHref={`/sprite.svg#icon-arrow-left`} />
											</svg>
										</td>
										<td style={{ fontWeight: '500', letterSpacing: '1px' }}>EHR</td>
										{user.modMed_Id ?
											<React.Fragment>
												<td >{user.modMed_Id}</td>
												<td >{user.modMed_FirstName}</td>
												<td >{user.modMed_LastName}</td>
												<td >{user.modMed_BirthDate}</td>
												<td >{user.modMed_Email}</td>
												<td >{user.modMed_PhoneNumber}</td>
												<td style={{ textTransform: 'capitalize' }}>{user.modMed_Gender}</td>
												<td colSpan="7"></td>
											</React.Fragment>
											: <td colSpan="10" style={{textAlign:'center'}}> Not match found</td>}
									</tr>
								) : null}
							</React.Fragment>
						))
					) : (
						<tr>
							<td colSpan='13' style={{ textAlign: 'center', padding: '2rem' }}>
								Patient information is not available.
							</td>
						</tr>
					) : (
						<TableRows columns='16' />
					)}
				</tbody>
			</table>
		</div>
	);
}
