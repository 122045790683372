import React from 'react';

const TableRows = ({ columns = '2' }) => {
	const rows = [1, 2, 3, 4, 5, 6, 7, 8, 9];

	return rows.map((row) => (
		<tr key={row}>
			{/* {rows.length > 0 &&
				rows.map((item) => (
					<td key={item}>
						<div className='table__row--skeleton skeleton' />
					</td>
				))} */}

			<td colSpan={columns}>
				<div className="table__row--skeleton skeleton" />
			</td>
		</tr>
	));
};

export default TableRows;
