import React, { Fragment } from "react";
import ConsentFormsRegister from "../../../components/Skeletons/ConsentFormsRegister";

const Page1 = ({ data, loading }) => {

	const {
		consentForm, 
		handleSubmit,
	} = data;


	return (
		<Fragment>
			{loading ? (
				<ConsentFormsRegister />
			) : (
				<div className="termsContainer">
					{consentForm && (
						<iframe
							src={consentForm + "#toolbar=0"}
							type="application/pdf"
							title="suremed-tnc"
							style={{
								height: "calc(50vh - 4rem)",
								minWidth: "45vw",
								width: "100%",
								borderRadius: "2rem",
							}}
						/>
					)}
				</div>
			)}

			<div
				className="modal__actions"
				style={{ display: "flex", justifyContent: "flex-end", padding: "2rem" }}
			>
				<button
					id="page_agree_btn"
					className="modal__button modal__button--update"
					onClick={() => handleSubmit()}
					disabled={loading}
				>
					I Agree
				</button>
			</div> 
		</Fragment>
	);
};

export default Page1;
