import React, { Fragment } from 'react';
import '../HomeScreen/HomeScreen.css'

const FilterPillCount = ({ data }) => {
	const {
		showFilter,
		setShowFilter,
		handleClear,
		filterData,
		setFilterData,
		handleFilter,
		refreshPillCountHandler,
		result
	} = data;

	return (
		<Fragment>

			<div className='filter__header' style={{ gap: '2rem', margin: '1.5rem' }}>
				{!result ? (
					<p className='error'>
						This provider's pdmp credentials are invalid.
					</p>
				) : ""}

				{!result ? (
					<button disabled className='filter__input--button' style={{ marginRight: '5rem' }} onClick={refreshPillCountHandler} >
						Refresh List
					</button>
				) : (
					<button className='filter__input--button' style={{ marginRight: '5rem' }} onClick={refreshPillCountHandler} >
						Refresh List
					</button>
				)}

				{/* {!showFilter ? (
					<button id='filterHide' className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Hide Search
					</button>
				) : (
					<button id='filterShow' className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Search Users
					</button>
				)} */}
			</div>

			{/* <div className='ProviderFilter'
				style={{ maxHeight: showFilter ? 0 : '34rem' }}
			>
				<div className='filter__content'>
					<div className='Providerfilter__content--top'>
						<div className='filter__input--labels'>
							<label htmlFor='firstName' className='filter__input--label'>
								First Name
							</label>
							<div className='filter__input'>
								<label htmlFor='firstName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='firstName'
									placeholder='Jon'
									value={filterData.firstname}
									onChange={(e) => setFilterData({ ...filterData, firstname: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='lastName' className='filter__input--label'>
								Last Name
							</label>
							<div className='filter__input'>
								<label htmlFor='lastName' className='filter__input--iconBox'>
									<svg className='filter__input--icon'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
								</label>
								<input
									type='text'
									id='lastName'
									placeholder='Doe'
									value={filterData.lastname}
									onChange={(e) => setFilterData({ ...filterData, lastname: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='date' className='filter__input--label'>
								Date of Birth
							</label>
							<div className='filter__input'>
								<input
									type='date'
									id='date'
									name='date'
									value={filterData.dateofbirth}
									onChange={(e) => setFilterData({ ...filterData, dateofbirth: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
						<div className='filter__input--labels'>
							<label htmlFor='date' className='filter__input--label'>
								Phone Number
							</label>
							<div className='filter__input'>
								<input
									type='text'
									id='phone'
									name='phone'
									value={filterData.phonenumber}
									onChange={(e) => setFilterData({ ...filterData, phonenumber: e.target.value })}
									className='filter__input--text'
								/>
							</div>
						</div>
					</div>
					<div className='Providerfilter__content--top2' >
						<div style={{ display: 'flex', gap: '1rem' }}>
							<button className='filter__input--button'
								onClick={handleFilter}
							>
								Search
							</button>
							<button className='filter__input--button'
								onClick={handleClear}
							>
								Clear
							</button>
						</div>
					</div>
				</div>
			</div> */}
		</Fragment>
	);
};

export default FilterPillCount;
