import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import ReactPaginate from 'react-paginate';

import TableRows from '../../components/Skeletons/TableRows';
import { previousAssessmentAction } from '../../redux/actions/assessmentActions';
import './PreviousAssessments.css';

const PreviousAssessments = ({ match }) => {
	const dispatch = useDispatch();
	const [userIndex, setUserIndex] = useState(1);
	// const [usersList, setUsersList] = useState([]);

	const [previousAssesmentList, setPreviousAssessmentList] = useState([]);
	const previousAssesment = useSelector((state) => state.previousAssesment); //map data
	const [totalAssessmentList, setTotalAssessmentList] = useState([]);
	const perPageList = 10;
	const totalPages = totalAssessmentList / perPageList;

	useEffect(
		() => {
			if (previousAssesment && previousAssesment.userInfo && previousAssesment.userInfo.length > 0) {
				// setUsersList(previousAssesment.userInfo.slice(0, perPageList));
				setTotalAssessmentList(previousAssesment.userInfo.length);
			}
		},
		[previousAssesment]
	);

	useEffect(
		() => {
			if (previousAssesment && previousAssesment.userInfo && previousAssesment.userInfo.length > 0) {
				setPreviousAssessmentList(previousAssesment.userInfo.slice(0, perPageList));
			} else {
				setPreviousAssessmentList([]);
			}
		},
		[previousAssesment]
	);
	useEffect(
		() => {
			dispatch(previousAssessmentAction(match.params.userId));
		},
		[dispatch, match]
	);

	let nextContentFrom = 0;
	const handlePageChange = (currentPage) => {
		nextContentFrom = currentPage.selected * perPageList;
		const nextContentTo = currentPage.selected * perPageList + 10;
		setPreviousAssessmentList(
			previousAssesment &&
			previousAssesment.userInfo &&
			previousAssesment.userInfo.slice(nextContentFrom, nextContentTo)
		);
		setUserIndex(currentPage.selected * perPageList + 1);
	};

	return (
		<Content currentMenu="manage-users" headerTitle="Previous Assessments">
			{previousAssesmentList.length > 0 && (
				<h2 className="filter__heading">Patient: {previousAssesmentList[0].patientname}</h2>
			)}

			<div className="tableContainer" style={{ height: '65vh' }}>
				<table className="table">
					<thead>
						<tr>
							<th>#</th>
							<th>Reference Number</th>
							<th>Assessment Date</th>
							<th>Device Type</th>
							<th>Time Taken</th>
							<th style={{ textAlign: 'center' }}>View Assessment</th>
							<th style={{ textAlign: 'center' }}>Export Pharmacy Report</th>
						</tr>
					</thead>
					<tbody>
						{previousAssesment && !previousAssesment.loading ? previousAssesmentList.length > 0 ? (
							previousAssesmentList.map((user, i) => (
								<tr key={user.assesmentid}>
									<td>{userIndex + i}</td>
									<td>{user.referenceno}</td>
									<td>
										<span>
											{new Date(user.datetime).toLocaleString('en-US', {
												year: 'numeric',
												month: '2-digit',
												day: '2-digit',
												hour12: true,
												hour: '2-digit',
												minute: '2-digit',
												second: '2-digit'
											})}
										</span>
									</td>
									{
										user.deviceType &&
											(user.deviceType === 'android' || user.deviceType === 'ios') ?
											<td style={{ textAlign: 'left' }}>
												<span style={{ textTransform: "capitalize" }}>
													App
												</span>
											</td>
											:
											<td style={{ textAlign: 'left' }}>
												<span>
													Portal
												</span>
											</td>
									}

									{user.sessionTimer ?
										<td>{user.sessionTimer}</td>
										: <td>NA</td>
									}

									<td style={{ display: 'flex', justifyContent: 'center' }}>
										<span className="table__iconBox">
											<Link
												to={`/pdfView/${user.assesmentid}`}
												className="table__button2"
											>
												<svg
													className="table__icon primary-icon"
													style={{ width: '2rem', height: '2rem' }}
												>
													<use xlinkHref={`/sprite.svg#icon-pdf`} />
												</svg>
												<span>Download PDF</span>
											</Link>
										</span>
									</td>

									<td >
										<span className="table__iconBox" style={{ justifyContent: 'center' }}>
											<Link
												to={`/pharmacyReportView/${user.assesmentid}`}
												className="table__button2"
											>
												<svg
													className="table__icon primary-icon"
													style={{ width: '2rem', height: '2rem' }}
												>
													<use xlinkHref={`/sprite.svg#icon-pdf`} />
												</svg>
												<span>Download PDF</span>
											</Link>
										</span>
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="5">No assessments found</td>
							</tr>
						) : (
							<TableRows columns="7" />
						)}
					</tbody>
				</table>
			</div>
			{previousAssesment &&
				previousAssesment.userInfo &&
				previousAssesment.userInfo.length > 10 && (
					<div className="tableContainer--paginater">
						<ReactPaginate
							nextClassName={''} //clientList.data.result.length < rowsPerPage && "nextClassName"
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={totalPages}
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={5}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'paginationActive'}
						/>
					</div>
				)}
		</Content>
	);
};

export default PreviousAssessments;
