import React from 'react'

const RegisterForm = ({props}) => {
    const {data, setData, dataError, setDataError, handleSubmit} = props;
    return (
        <form className="clinic--signupContainer__form" id='slide'
		//style={{width: '100%'}}
		>
			<div className="formgroup--validation">
				<div className="addressContainer__formgroup--inputBox">
					<div className="logoContainer__formgroup--iconBox">
						<svg className="loginContainer__formgroup--icon">
							<use xlinkHref="/sprite.svg#icon-clinic" />
						</svg>
					</div>
					<input
						type="text"
						placeholder="Clinic Name"
						id="clinic-name"
						value={data.cName}
						onChange={(e) => {setData({ ...data, cName: e.target.value })
						setDataError('')}}
						className="loginContainer__formgroup--input"
					/>
				</div>
				<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.cName}</p>
			</div>
			<div className="flex-form">
				<div className="formgroup--validation">
					<div className="registerContainer__formgroup--inputBox">
						<div className="logoContainer__formgroup--iconBox">
							<svg className="loginContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-user" />
							</svg>
						</div>
						<input
							type="text"
							placeholder="Contact First Name"
							id="firstName"
							value={data.fName}
							onChange={(e) => {setData({ ...data, fName: e.target.value })
							setDataError('')}}
							className="loginContainer__formgroup--input"
						/>
					</div>
					<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.fName}</p>
				</div>
				<div className="formgroup--validation">
					<div className="registerContainer__formgroup--inputBox">
						<div className="logoContainer__formgroup--iconBox">
							<svg className="loginContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-user" />
							</svg>
						</div>
						<input
							type="text"
							placeholder="Contact Last Name"
							id="lastName"
							value={data.lName}
							onChange={(e) => {setData({ ...data, lName: e.target.value })
							setDataError('')}}
							className="loginContainer__formgroup--input"
						/>
					</div>
					<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.lName}</p>
				</div>
			</div>
			<div className="flex-form">
				<div className="formgroup--validation">
					<div className="registerContainer__formgroup--inputBox">
						<div className="logoContainer__formgroup--iconBox">
							<svg className="loginContainer__formgroup--icon">
									<use xlinkHref="/sprite.svg#icon-title" />
							</svg>
						</div>
						<input
							type="text"
							placeholder="Contact Title"
							id="title"
							value={data.cTitle}
							onChange={(e) => {setData({ ...data, cTitle: e.target.value })
							setDataError('')}}
							className="loginContainer__formgroup--input"
						/>
					</div>
					<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.cTitle}</p>
				</div>
				<div className="formgroup--validation">
					<div className="registerContainer__formgroup--inputBox">
						<div className="logoContainer__formgroup--iconBox">
							<svg className="loginContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-phone" />
							</svg>
						</div>
						<input
							type="text"
							placeholder="Contact Phone"
							id="phone"
							value={data.cPhone}
							onChange={(e) => {setData({ ...data, cPhone: e.target.value })
							setDataError('')}}
							className="loginContainer__formgroup--input"
						/>
					</div>
					<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.cPhone}</p>
				</div>
			</div>
			<div className="formgroup--validation">
				<div className="addressContainer__formgroup--inputBox">
				<div className="logoContainer__formgroup--iconBox">
					<svg className="loginContainer__formgroup--icon">
						<use xlinkHref="/sprite.svg#icon-address" />
					</svg>
				</div>
				<input
					type="text"
					placeholder="Address Line 1"
					id="address-1"
					value={data.address}
					onChange={(e) => {setData({ ...data, address: e.target.value })
					setDataError('')}}
					className="loginContainer__formgroup--input"
				/>
			</div>
			<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.address}</p>
			</div>

			<div className="formgroup--validation">
				<div className="addressContainer__formgroup--inputBox">
				<div className="logoContainer__formgroup--iconBox">
					<svg className="loginContainer__formgroup--icon">
						<use xlinkHref="/sprite.svg#icon-address" />
					</svg>
				</div>
				<input
					type="text"
					placeholder="Address Line 2"
					id="address-1"
					value={data.address2}
					onChange={(e) => {setData({ ...data, address2: e.target.value })
					setDataError('')}}
					className="loginContainer__formgroup--input"
				/>
			</div>
			<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.address2}</p>
			</div>

			<div className="flex-form">
				<div className="formgroup--validation">
					<div className="registerContainer__formgroup--inputBox">
						<div className="logoContainer__formgroup--iconBox">
							<svg className="loginContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-city" />
							</svg>
						</div>
						<input
							type="text"
							placeholder="City"
							id="city"
							value={data.city}
							onChange={(e) => {setData({ ...data, city: e.target.value })
							setDataError('')}}
							className="loginContainer__formgroup--input"
						/>
					</div>
					<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.city}</p>
				</div>
				<div className="formgroup--validation">
					<div className="registerContainer__formgroup--inputBox">
						<div className="logoContainer__formgroup--iconBox">
							<svg className="loginContainer__formgroup--icon">
								<use xlinkHref="/sprite.svg#icon-state" />
							</svg>
						</div>
						<input
							type="text"
							placeholder="State"
							id="state"
							value={data.cState}
	 						onChange={(e) => {setData({ ...data, cState: e.target.value })
							 setDataError('')}}
							className="loginContainer__formgroup--input"
						/>
					</div>
					<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.cState}</p>
				</div>
			</div>
			<div className="formgroup--validation" >
				<div className="registerContainer__formgroup--inputBox smallInputContainer">
					<div className="logoContainer__formgroup--iconBox" >
						<svg className="loginContainer__formgroup--icon">
							<use xlinkHref="/sprite.svg#icon-zip"/>
						</svg>
					</div>
					<input
						type="text"
						placeholder="Zip"
						id="zip"
						value={data.zip}
						onChange={(e) => {setData({ ...data, zip: e.target.value })
						setDataError('')}}
						className="loginContainer__formgroup--input"
					/>
				</div>
				<p className="error_message" style={{marginLeft: '1.5rem'}}>{dataError.zip}</p>
			</div>
			<div className="registerContainer__formgroup--btn">
				<button className="registerContainer__button" onClick={handleSubmit}>
					<span className="loginContainer__button--text">Next</span>
					<div className="loginContainer__button--iconBox">
						<svg className="loginContainer__button--icon">
							<use xlinkHref="/sprite.svg#icon-next" />
						</svg>
					</div>
				</button>
			</div>
		</form>
    )
}

export default RegisterForm
