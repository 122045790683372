import React from 'react';

const UploadInput = ({ value, label, error, style, onChange, previewImage, setPreviewImage }) => {
	//const [previewImage, setPreviewImage] = useState('')

	return (
		<div className='modal_upload' style={style} >
			{
				value?
				<div>
					<input type='file' id={label} hidden name='file' onChange={onChange}/>
					<label htmlFor={label} className='modal_input__upload'>
						<div className="ManageClients__option--uploadContainer">
							<svg className="ManageClients__option--upload">
								<use xlinkHref={`/sprite.svg#icon-edit2`} />
							</svg>
						</div>

						<div style={{}}>
							{
							previewImage?
							<img alt='preview' id='logo' className='preview_image' src={previewImage}/>
							:
							<img alt='preview' className='preview_image' src={`data:image/jpeg/png;base64,${value}`}/>
							}
						</div>


					</label>

				</div>

				:
				<div style={{}}>
					<input type='file' id={label} name='file' hidden onChange={onChange}/>
					<label htmlFor={label} className='modal_input__upload'>
						{
							previewImage?
							<img alt='preview' id='logo' className='preview_image' src={previewImage}/>
							:
							<div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
								<svg className='modal_input__upload--icon'>
								<use xlinkHref={`/sprite.svg#icon-upload2`} />
							</svg>
							{label}
							</div>

						}
					</label>
				</div>
			}
			{error && <p className='error_message'>{error}</p>}
		</div>
	);
};

export default UploadInput;
