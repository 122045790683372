import React, { Fragment } from 'react';

 export const DeleteInvite = () => {
	return (
		<Fragment>
			<div className="modal__actions">
				<div className="filter__input--label-skeleton skeleton" />
				<div className="modal__button--cancel-skeleton skeleton" />
			</div>
			<div className="modal__actions">
				<div className="filter__input--labellong-skeleton skeleton" />
			</div>
			<div className="modal__actions">
				<div className="filter__input--email-skeleton skeleton" />
			</div>
			<div className="modal__actions">
				<div className="modal__button--delete-skeleton skeleton" />
			</div>
		</Fragment>
	);
};



export default DeleteInvite;