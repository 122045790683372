import React from 'react';
import './Skeletons.css';

const InviteAdminEmails = () => {
	const items = [ 1, 2, 3, 4, 5 ];
	return (
		<div className='filter__content2'>
			<div className='pending-header'>Pending Invites</div>
			{items &&
				items.map((item) => (
					<div className='pending-email' key={item}>
						<div className='pending__email--skeleton skeleton' />
						<div className='pending__button--skeleton skeleton' />
					</div>
				))}
		</div>
	);
};

export default InviteAdminEmails;
