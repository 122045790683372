import React from 'react';
import NotificationItem from './NotificationItem';
import { GetNotifications, readAllNotifications } from '../../../redux/actions/userActions';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import NoNotifications from './NoNotifications';

const Notification = () => {
  const [notificationCheck, setNotificationCheck] = React.useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();

  const [getAllNotifications, setGetAllNotifications] = React.useState({
    data: null,
    loading: true,
  });

  const getAllNotificationsHandler = React.useCallback(async () => {
    if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
      const data = await dispatch(GetNotifications(userLogin));

      if (data?.data) {
        setGetAllNotifications({ data: data.data, count: data?.count, loading: false });
      }
    }
  }, [userLogin]);

  React.useEffect(() => {
    getAllNotificationsHandler();
  }, [getAllNotificationsHandler]);


  const handleReadAll = async () => {
    const data = await dispatch(readAllNotifications());
    if (data?.status) {
      getAllNotificationsHandler();
    }
  };


  return (
    <div className='dropdown' onClick={() => setNotificationCheck(!notificationCheck)}>
      <div className='dropdown-a'>
        <svg>
          <use xlinkHref={`/sprite.svg#icon-notification`} />
        </svg>
        <p>{getAllNotifications.count > 0 ? getAllNotifications.count : '0'}</p>
      </div>
      <input
        type='checkbox'
        checked={notificationCheck}
        onChange={() => { }} 
      />
      <div className='dropdown-c'>
        <ul>
          <li className='downdown-c_heading'>
            <span className='downdown-c_head'>Notifications</span>
            <button onClick={handleReadAll}>Mark all as read</button>
          </li>
          <li
            style={{
              maxHeight: '60vh',
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >

            {getAllNotifications && getAllNotifications.loading ? (
              <NoNotifications title="Loading Notifications" />
            ) : getAllNotifications.data && getAllNotifications?.data.length ? (
              getAllNotifications.data.map((item, index) => (
                <NotificationItem
                  key={index + 1}
                  title={item.title}
                  subTitle={item.body}
                  date={moment.utc(item.createdDate).format('DD MMM  YYYY h:mm A')}
                  read={item.isRead}
                />
              ))) :
              <NoNotifications title="No Notifications Yet" /> 
            } 
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Notification;
