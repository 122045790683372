import React, { Fragment } from "react";
import ConsentFormsRegister from "../../../components/Skeletons/ConsentFormsRegister";

const Page2 = ({ data, loading, error }) => {
	const { onTermsAgree, setAgreement, consentForm } = data;
	return (
		<Fragment>
			{loading ? (
				<ConsentFormsRegister />
			) : (
				<div className="termsContainer">
					<iframe
						src={consentForm.fileURL + "#toolbar=0"}
						type="application/pdf"
						title="suremed-tnc"
						style={{
							height: "calc(50vh - 4rem)",
							minWidth: "45vw",
							width: "100%",
							borderRadius: "2rem",
						}}
					/>
				</div>
			)}
			<div
				className="modal__actions"
				style={{ display: "flex", justifyContent: "flex-end", padding: "2rem" }}
			>
				<button
					id="page_previous_btn_2"
					className="modal__button modal__button--update"
					onClick={() => setAgreement((prevData) => prevData - 1)}
				>
					Previous
				</button>
				<button
					id="page_agree_btn_2"
					className="modal__button modal__button--update"
					onClick={onTermsAgree}
					disabled={loading || error}
				>
					I Agree
				</button>
			</div>
		</Fragment>
	);
};

export default Page2;
