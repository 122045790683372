import React, { useState, Fragment } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { ResetUserSkeleton } from '../../../components/Skeletons/manageUsers';

const ResetPassword = ({ data }) => {
	const { modalResetState, onEditClose, handleReset, manageUsers, resetPassword, setResetPassword } = data;
	const [ newEye, setNewEye ] = useState(false);
	const [ confirmEye, setConfirmEye ] = useState(false);

	const showNewPassword = (e) => {
		var x = document.getElementById('newPassword');
		setNewEye(!newEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const showConfirmPassword = (e) => {
		var x = document.getElementById('confirmPassword');
		setConfirmEye(!confirmEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};

	return (
		<Modal show={modalResetState}>
			<ModalHeading heading="Reset Password" onClose={onEditClose} />

			{manageUsers && manageUsers.loading ? (
				<ResetUserSkeleton />
			) : (
				<Fragment>
					<form className="modalContainer__form">
						<div className="filter__input--labels">
							<label htmlFor="email" className="modal__text">
								New Password
							</label>
							<div className="filter__input">
								<label htmlFor="email" className="filter__input--iconBox">
									<svg className="filter__input--icon">
										<use xlinkHref={`/sprite.svg#icon-key`} />
									</svg>
								</label>
								<input
									type="password"  autoComplete="new-password"
									id="newPassword"
									placeholder="********"
									className="filter__input--text"
									value={resetPassword.newPassword}
									onChange={(event) => {
										setResetPassword({
											...resetPassword,
											newPassword: event.target.value,
											newError: ''
										});
									}}
								/>
								<svg className="loginContainer__formgroup--icon" onClick={showNewPassword}>
									{newEye ? (
										<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
									) : (
										<use xlinkHref={`/sprite.svg#icon-showPassword`} />
									)}
								</svg>
							</div>
							<p>{resetPassword.newError}</p>
						</div>
						<div className="filter__input--labels">
							<label htmlFor="email" className="modal__text">
								Confirm Password
							</label>
							<div className="filter__input">
								<label htmlFor="email" className="filter__input--iconBox">
									<svg className="filter__input--icon">
										<use xlinkHref={`/sprite.svg#icon-key`} />
									</svg>
								</label>
								<input
									type="password"  autoComplete="new-password"
									id="confirmPassword"
									placeholder="********"
									className="filter__input--text"
									value={resetPassword.confirmPassword}
									onChange={(event) => {
										setResetPassword({
											...resetPassword,
											confirmPassword: event.target.value,
											confirmError: ''
										});
									}}
								/>
								<svg className="loginContainer__formgroup--icon" onClick={showConfirmPassword}>
									{confirmEye ? (
										<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
									) : (
										<use xlinkHref={`/sprite.svg#icon-showPassword`} />
									)}
								</svg>
							</div>
						</div>
						<p>{resetPassword.confirmError}</p>
					</form>
					<div className="modal__actions">
						<button className="modal__button modal__button--update" onClick={handleReset}>
							Confirm
						</button>
					</div>
				</Fragment>
			)}
		</Modal>
	);
};

export default ResetPassword;
