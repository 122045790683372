import React, { Fragment } from 'react';
import SubscriptionCardStyle from './SubscriptionCard.module.css';
import SubscriptionItem from './SubscriptionItem';

const SubscriptionCard = ({
  data,
  selectPlan,
  supportPlan,
  chooseHandler,
  hideBtn = false,
  customHeader = '',
  handleClick = () => { },
  style,
}) => {
  // console.log(supportPlan, data);
  React.useEffect(() => {
    let heading = document.getElementsByClassName(
      SubscriptionCardStyle.subscription_custom_heading
    );
    if (heading) {
      for (let i = 0; i < heading.length; i++) {
        heading[i].classList.toggle(SubscriptionCardStyle.active);
        heading[i].parentElement.classList.toggle(SubscriptionCardStyle.show);
        heading[i].onclick = function () {
          this.classList.toggle(SubscriptionCardStyle.active);
          this.parentElement.classList.toggle(SubscriptionCardStyle.show);
        };
      }
    }
  }, []);

  return (
    <Fragment>
      {data &&
        data.metadata &&
        data.metadata.id === "3" ?
        <div
          className={
            selectPlan && selectPlan.id === data.id
              ? SubscriptionCardStyle.subscription_card_active
              : SubscriptionCardStyle.subscription_card
          }
          style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}
        >
          <div className={SubscriptionCardStyle.subscription_price} style={{ fontSize:"18px", maxWidth: "300px", display: "flex", alignItems: "center",textAlign: "center" }}>
            {data?.metadata?.disabled_text}
          </div>
        </div>
        :
        <div
          className={
            selectPlan && selectPlan.id === data.id
              ? SubscriptionCardStyle.subscription_card_active
              : SubscriptionCardStyle.subscription_card
          }
          style={style}
        >
          {!hideBtn ? (
            <Fragment>
              {data.name === 'First 250 Clinics' &&
                <div className={SubscriptionCardStyle.card_pdf_default_text2}>
                  <p>BEST DEAL</p>
                </div>
              }

              <h1 className={SubscriptionCardStyle.subscription_title}>
                {data.name}
              </h1>
              {data.name === 'Ad-Supported Monthly' ?
                <h1 className={SubscriptionCardStyle.subscription_price}>
                  {data?.metadata?.top_heading} <span>per year</span>
                </h1> :
                <h1 className={SubscriptionCardStyle.subscription_price}>
                  {data?.metadata?.price} <span>per year</span>
                </h1>
              }
            </Fragment>
          ) : (
            <h1 className={SubscriptionCardStyle.subscription_custom_heading}>
              {customHeader}
            </h1>
          )}

          <div className={SubscriptionCardStyle.subscription_items}>
            <SubscriptionItem
              text={data?.metadata?.subscription_fee_text}
              value={data?.metadata?.subscription_fee_value}
              active={selectPlan && selectPlan.id === data.id}
            />

            {supportPlan && data.name === 'Ad-Supported Monthly' ?
              <SubscriptionItem
                text={data?.metadata?.implementation_fee_text}
                value={'$' + supportPlan.unit_amount / 100 + '.#one-time fee'}
                active={selectPlan && selectPlan.id === data.id}
              /> :
              <SubscriptionItem
                text={data?.metadata?.implementation_fee_text}
                value={data?.metadata?.implementation_fee_value}
                active={selectPlan && selectPlan.id === data.id}
              />
            }

            <SubscriptionItem
              text={data?.metadata?.annual_service_fee_first_year_text}
              value={data?.metadata?.annual_service_fee_first_year_value}
              active={selectPlan && selectPlan.id === data.id}
            />
            <SubscriptionItem
              text={data?.metadata?.annual_service_fee_thereafter_text}
              value={data?.metadata?.annual_service_fee_thereafter_value}
              active={selectPlan && selectPlan.id === data.id}
            />
            <SubscriptionItem
              text={data?.metadata?.ehr_emr_integration_text}
              value={data?.metadata?.ehr_emr_integration_value}
              active={selectPlan && selectPlan.id === data.id}
            />
            <SubscriptionItem
              text={data?.metadata?.patients_enrollment_text
                ?.split('/')
                .join(' / ')}
              value={data?.metadata?.patients_enrollment_value}
              active={selectPlan && selectPlan.id === data.id}
            />

            {data?.metadata?.PDMP_integration_value && (
              <div className={SubscriptionCardStyle.subscription_item}>
                <svg
                  className={
                    selectPlan && selectPlan.id === data.id
                      ? SubscriptionCardStyle.subscription_icon_white
                      : SubscriptionCardStyle.subscription_icon
                  }
                >
                  <use xlinkHref='/sprite.svg#icon-check' />
                </svg>
                <p className={SubscriptionCardStyle.subscription_meta}>
                  {data?.metadata?.PDMP_integration_text}
                </p>
              </div>
            )}

            {data?.metadata?.remote_pill_counts_value && (
              <div className={SubscriptionCardStyle.subscription_item}>
                <svg
                  className={
                    selectPlan && selectPlan.id === data.id
                      ? SubscriptionCardStyle.subscription_icon_white
                      : SubscriptionCardStyle.subscription_icon
                  }
                >
                  <use xlinkHref='/sprite.svg#icon-check' />
                </svg>
                <p className={SubscriptionCardStyle.subscription_meta}>
                  {data?.metadata?.remote_pill_counts_text}
                </p>
              </div>
            )}

            {data?.metadata?.medical_necessity_value && (
              <div className={SubscriptionCardStyle.subscription_item}>
                <svg
                  className={
                    selectPlan && selectPlan.id === data.id
                      ? SubscriptionCardStyle.subscription_icon_white
                      : SubscriptionCardStyle.subscription_icon
                  }
                >
                  <use xlinkHref='/sprite.svg#icon-check' />
                </svg>
                <p className={SubscriptionCardStyle.subscription_meta}>
                  {data?.metadata?.medical_necessity_text}
                </p>
              </div>
            )}
          </div>

          <h3 className={SubscriptionCardStyle.subscription_total_heading}>
            {data?.description?.split('\\n')[0]}
          </h3>
          <h3 className={SubscriptionCardStyle.subscription_total_subheading}>
            {data?.description?.split('\\n')[1]}
            <br />
            {data?.description?.split('\\n')[2]}
          </h3>


          {data?.metadata?.disabled ?
            <div className={SubscriptionCardStyle.subscription_total_heading}>
              {data?.metadata?.disabled_text}
            </div>
            : !hideBtn ? (
              <div
                className={SubscriptionCardStyle.loginContainer__button_layout}
                onClick={() => chooseHandler(data)}
              >
                <button
                  className={
                    selectPlan && selectPlan.id === data.id
                      ? SubscriptionCardStyle.outline__button_active
                      : SubscriptionCardStyle.outline__button
                  }
                >
                  {selectPlan && selectPlan.id === data.id
                    ? 'SELECTED PLAN'
                    : 'CHOOSE PLAN'}
                </button>
              </div>
            ) : (
              <div className={SubscriptionCardStyle.loginContainer__button_layout}>
                <button
                  className={SubscriptionCardStyle.outline__button}
                  onClick={handleClick}
                >
                  CHANGE PLAN
                </button>
              </div>
            )
          }


        </div>}
    </Fragment>
  );
};

export default SubscriptionCard;
