import React, { Fragment } from 'react';
import Modal from '../../../src/components/Modal';
import ModalHeading from '../../../src/components/Modal/ModalHeading';
import { ToggleUserSkeleton } from '../../../src/components/Skeletons/manageUsers';

const ActiveInactiveModal = ({ data }) => {
	const { activeInactiveClientState, onActiveClose, handleActiveInactive, activeInactiveClient} = data;

	return (

		<Modal show={activeInactiveClientState}>
			<ModalHeading
			heading='Confirm Deactivation?' onClose={onActiveClose}
			/>
			{
				activeInactiveClient && activeInactiveClient.loading?
				(<ToggleUserSkeleton />)
					:
				(
				<Fragment>
					<div className='modalContainer__form'>
						<p style={{fontWeight: '400', fontSize: '13px'}}>Are you sure you want to de-activate client?</p>
					</div>
					<div className='modal__actions'>

						<button className='modal__button modal__button--update' onClick={handleActiveInactive}>
							Confirm
						</button>
					</div>
				</Fragment>
				)
			}


		</Modal>
	);
};

export default ActiveInactiveModal;
