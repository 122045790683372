import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import AddPartnerContent from './AddPartnerContent';
import ContentTable from './SchedulerTable';
import './ContentScreen.css';
import {
	getPartnerContentsAction,
	addPartnerContentAction,
	deletePartnerContentAction
} from '../../redux/actions/userActions';
import DeletePartnerContentModal from './DeletePartnerContentModal';
import Content from '../../components/Content';
import { DELETE_CONTENT_RESET, UPLOAD_CONTENT_RESET } from '../../redux/constants/userConstants';

const PartnerContentScreen = ({ history }) => {
	const dispatch = useDispatch();

	const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14];
	const contentDetails = useSelector((state) => state.partnerContentDetails);
	const uploadContentDetails = useSelector((state) => state.uploadContent);
	const deleteContentDetails = useSelector((state) => state.deleteContent);
	const [uploadModalState, setUploadModalState] = useState(false);
	const [deleteModalState, setDeleteModalState] = useState(false);
	const [addPartner, setAddPartner] = useState(true);

	const [contentId, setContentId] = useState('');
	const [title, setTitle] = useState('');

	const [uploadContent, setUploadContent] = useState({
		title: ''
	});

	const [uploadContentError, setUploadContentError] = useState({
		title: ''
	});

	useEffect(
		() => {
			dispatch(getPartnerContentsAction());
		},
		[dispatch]
	);


	//upload Content
	useEffect(
		() => {
			if (uploadContentDetails && uploadContentDetails.token && uploadContentDetails.token.statusCode === true) {
				setUploadModalState(false);
				setUploadContent({ ...uploadContent, title: '' })
				setUploadContentError({ ...uploadContentError, title: '' })
				dispatch(getPartnerContentsAction())
				toast.success(uploadContentDetails.token.message);
				dispatch(dispatch({ type: UPLOAD_CONTENT_RESET }))
			} else if (uploadContentDetails && uploadContentDetails.error && uploadContentDetails.error.data) {
				toast.error(uploadContentDetails.error.data)
				dispatch(dispatch({ type: UPLOAD_CONTENT_RESET }))
			}
		},
		[uploadContentDetails, dispatch]
	);

	//delete Content
	useEffect(
		() => {
			if (deleteContentDetails && deleteContentDetails.token && deleteContentDetails.token.issuccess === true) {
				setDeleteModalState(false);
				setTitle('')
				setContentId('')
				dispatch(getPartnerContentsAction())
				toast.success(deleteContentDetails.token.message);
				dispatch(dispatch({ type: DELETE_CONTENT_RESET }))
			}
		},
		[deleteContentDetails, dispatch]
	);

	const onClose = () => {
		setUploadModalState(false);
		setUploadContentError('')
	};
	const addBtnClick = () => {
		setUploadModalState(true);
	};

	const handleUpload = (e) => {
		//e.preventDefault();
		if (!uploadContent.title.trim() || uploadContent.title.trim() === '') {
			setUploadContentError({ ...uploadContentError, title: 'Please enter a Title' });
		}
		// else if (!uploadContent.category.trim() || uploadContent.category.trim() === '') {
		// 	setUploadContentError({ ...uploadContentError, category: 'Please select Content Partner' });
		// } 
		else {
			dispatch(addPartnerContentAction({
				Title: uploadContent.title
			}))
		}
	};

	const handleDeleteContent = (e) => {
		e.preventDefault()
		dispatch(deletePartnerContentAction(contentId))
	};

	const onDeleteClose = () => {
		setDeleteModalState(false);
	};

	return (
		<Content
			currentMenu='Partner Contents'
			headerTitle='Partner'
			addBtn={true}
			addBtnClick={addBtnClick}
			addBtnText='Add Partner'
			addBtnIcon='/sprite.svg#icon-plus'>
			<div className='flex_column_button'>
				<ContentTable
					items={items}
					data={{ contentDetails, setContentId, setDeleteModalState, setTitle }}
				/>

				<AddPartnerContent
					modalState={uploadModalState}
					onClose={onClose}
					handleUpload={handleUpload}
					data={{
						uploadContent,
						setUploadContent,
						uploadContentError,
						setUploadContentError,
						uploadContentDetails
					}}
				/>

				<DeletePartnerContentModal
					data={{
						contentDetails,
						handleDeleteContent,
						deleteModalState,
						onDeleteClose,
						title
					}}
				/>

			</div>

		</Content>
	);
};

export default PartnerContentScreen;
