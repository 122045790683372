import {
	SAVE_PDMP_CREDENTIALS_REQUEST,
	SAVE_PDMP_CREDENTIALS_SUCCESS,
	SAVE_PDMP_CREDENTIALS_FAIL,
	SAVE_PDMP_CREDENTIALS_RESET,
	GET_PILL_COUNT_REQUEST,
	GET_PILL_COUNT_SUCCESS,
	GET_PILL_COUNT_FAIL,
	GET_PILL_COUNT_RESET,
	REQUEST_PILL_COUNT_REQUEST,
	REQUEST_PILL_COUNT_SUCCESS,
	REQUEST_PILL_COUNT_FAIL,
	REFRESH_PILL_COUNT_REQUEST,
	REFRESH_PILL_COUNT_SUCCESS,
	REFRESH_PILL_COUNT_FAIL,
	REQUEST_PILL_COUNT_RESET,
	REFRESH_PILL_COUNT_RESET,

	DELETE_PILL_COUNT_REQUEST,
	DELETE_PILL_COUNT_SUCCESS,
	DELETE_PILL_COUNT_FAIL,
	DELETE_PILL_COUNT_RESET,
	
} from "../constants/PillCountConstants";

export const savePdmpCredentialsReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_PDMP_CREDENTIALS_REQUEST:
			return { loading: true };

		case SAVE_PDMP_CREDENTIALS_SUCCESS:
			return { data: action.payload, loading: false };

		case SAVE_PDMP_CREDENTIALS_FAIL:
			return { loading: false, error: action.payload };

		case SAVE_PDMP_CREDENTIALS_RESET:
			return {};

		default:
			return state;
	}
};


export const getPillCountReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PILL_COUNT_REQUEST:
			return { loading: true };

		case GET_PILL_COUNT_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_PILL_COUNT_FAIL:
			return { loading: false, error: action.payload };

		case GET_PILL_COUNT_RESET:
			return {};

		default:
			return state;
	}
};

export const refreshPillCountReducer = (state = {}, action) => {
	switch (action.type) {
		case REFRESH_PILL_COUNT_REQUEST:
			return { loading: true };

		case REFRESH_PILL_COUNT_SUCCESS:
			return { data: action.payload, loading: false };

		case REFRESH_PILL_COUNT_FAIL:
			return { loading: false, error: action.payload };

		case REFRESH_PILL_COUNT_RESET:
			return {};

		default:
			return state;
	}
};





export const deletePillCountReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_PILL_COUNT_REQUEST:
			return { loading: true };

		case DELETE_PILL_COUNT_SUCCESS:
			return { data: !action.payload, loading: false };

		case DELETE_PILL_COUNT_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_PILL_COUNT_RESET:
			return {};

		default:
			return state;
	}
};






export const requestPillCountReducer = (state = {}, action) => {
	switch (action.type) {
		case REQUEST_PILL_COUNT_REQUEST:
			return { loading: true };

		case REQUEST_PILL_COUNT_SUCCESS:
			return { data: action.payload, loading: false };

		case REQUEST_PILL_COUNT_FAIL:
			return { loading: false, error: action.payload };

		case REQUEST_PILL_COUNT_RESET:
			return {};

		default:
			return state;
	}
};