import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import DateInput from '../../components/Modal/DateInput';
import TimeInput from '../../components/Modal/TimeInput';
import TextAreaInput from '../../components/Modal/TextAreaInput';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';

import {AddScheduleSkeleton} from '../../components/Skeletons/Scheduler'


const AddScheduler = ({
	modalState,
	onClose,
	scheduleDate,
	setScheduleDate,
	scheduleStartTime,
	setScheduleStartTime,
	scheduleEndTime,
	setScheduleEndTime,
	scheduleContent,
	setScheduleContent,
	handleSubmit,
	saveSchedule

}) => 
{
	const onChangeDate = (e) => {
		setScheduleDate({
			...scheduleDate,
			date: e.target.value
		});
	};
	const onChangeStartTime = (e) => {
		setScheduleStartTime({
			...scheduleStartTime,
			time: e.target.value
		});
	};
	const onChangeEndTime = (e) => {
		setScheduleEndTime({
			...scheduleEndTime,
			time: e.target.value
		});
	};
	const onChangeContent = (e) => {
		setScheduleContent({
			...scheduleContent,
			content: e.target.value
		});
	};

	return (
		<Modal show={modalState}>
			<ModalHeading heading="Add Schedule" onClose={onClose} />
			{
				saveSchedule && saveSchedule.loading ?
				<AddScheduleSkeleton />
				:
			
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<InputsSection>
					<DateInput
						label="Date"
						value={scheduleDate.date}
						onChange={onChangeDate}
						error={scheduleDate.error}
					/>
					<TimeInput
						label="Start Time"
						value={scheduleStartTime.time}
						onChange={onChangeStartTime}
						error={scheduleStartTime.error}
					/>
					<TimeInput
						label="End Time"
						value={scheduleEndTime.time}
						onChange={onChangeEndTime}
						error={scheduleEndTime.error}
					/>
				</InputsSection>
				<InputsSection>
					<TextAreaInput
						label="Content"
						placeholder="Write some line about this schedule..."
						value={scheduleContent.content}
						onChange={onChangeContent}
						error={scheduleContent.error}
						style={{ width: '30vw', height: '10rem' }}
					/>
				</InputsSection>
				<ModalButtonPrimary title="Add Schedule" onClick={handleSubmit} />
			</ModalForm>
			}
		</Modal>
	);
};

export default AddScheduler;
