import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import { InputsSection } from '../../../components/Modal/InputsSection';
import { ModalButtonPrimary } from '../../../components/Modal/ModalButton';
import ModalForm from '../../../components/Modal/ModalForm';
import ModalHeading from '../../../components/Modal/ModalHeading';

const ConfirmationModal = ({ data }) => {
	const { showModal, onEditClose, handleSubmit, textMsg, loadingBtn } = data;
	return (
		<Modal show={showModal}>
			<ModalHeading
				heading='Update EHR ID' onClose={onEditClose}
			/>
			<Fragment>
				<ModalForm>
					<InputsSection style={{ justifyContent: 'inherit' }}>
						<span className="modal__text" dangerouslySetInnerHTML={{ __html: textMsg }} />
					</InputsSection>

					{loadingBtn ?
						<div className='modal__actions'>
							<div className='login__input--button-skeleton skeleton' />
						</div>
						:
						<ModalButtonPrimary className='modal__actions' title='Yes' onClick={handleSubmit} />
					}
				</ModalForm>
			</Fragment>
		</Modal>
	);
};

export default ConfirmationModal;
