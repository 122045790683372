import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import SimpleButton from '../../components/Basic/SimpleButton';
import UploadContent from './UploadContent';
import ContentTable from './SchedulerTable';

import './ContentScreen.css';

import {
	contentsAction,
	uploadContentAction,
	addDocumentHistoryAction,
	deleteContentAction,
	getPartnerContentsAction
} from '../../redux/actions/userActions';
import VideoModal from './VideoModal';
import DeleteContentModal from './DeleteContentModal';
import Content from '../../components/Content';
import { DELETE_CONTENT_RESET, UPLOAD_CONTENT_RESET } from '../../redux/constants/userConstants';

const ContentScreen = ({ history }) => {
	const dispatch = useDispatch();

	const contentDetails = useSelector((state) => state.contentDetails);
	const partnerContentDetails = useSelector((state) => state.partnerContentDetails);
	console.log(partnerContentDetails, 'partnerContentDetails');
	const contentPdf = useSelector((state) => state.contentPdf);
	const contentVideo = useSelector((state) => state.contentVideo);
	const uploadContentDetails = useSelector((state) => state.uploadContent);
	const deleteContentDetails = useSelector((state) => state.deleteContent);
	const [uploadModalState, setUploadModalState] = useState(false);
	const [videoModalState, setVideoModalState] = useState(false);
	const [deleteModalState, setDeleteModalState] = useState(false);

	const [contentId, setContentId] = useState('');
	const [title, setTitle] = useState('');
	const [mediaName, setMediaName] = useState('')

	const [uploadContent, setUploadContent] = useState({
		title: '',
		category: '',
		isVideo: '',
		desc: '',
		file: '',
		url: '',
	});
	const [uploadContentError, setUploadContentError] = useState({
		title: '',
		category: '',
		isVideo: '',
		desc: '',
		file: '',
		url: '',
	});
	const [shortDesc, setShortDesc] = useState({
		content: '',
		error: ''
	});

	const [contentType, setContentType] = useState({
		type: '',
		error: ''
	});

	const [filterData, setFilterData] = useState({
		Partner: "",
		Type: "",
	})


	useEffect(
		() => {
			dispatch(contentsAction());
			dispatch(getPartnerContentsAction());
		},
		[dispatch]
	);


	useEffect(
		() => {
			if (contentPdf && contentPdf.token) {
				const url = window.URL.createObjectURL(new Blob([contentPdf.token]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'file.pdf'); //or any other extension
				document.body.appendChild(link)
				link.click();
			}
		},
		[contentPdf, contentVideo]
	);

	//upload Content
	useEffect(
		() => {
			if (uploadContentDetails && uploadContentDetails.token && uploadContentDetails.token.statusCode === true) {
				setUploadModalState(false);
				setUploadContent('')
				dispatch(contentsAction())
				toast.success(uploadContentDetails.token.message);
				dispatch(dispatch({ type: UPLOAD_CONTENT_RESET }))
			} else if (uploadContentDetails && uploadContentDetails.error && uploadContentDetails.error.data) {
				toast.error(uploadContentDetails.error.data)
				dispatch(dispatch({ type: UPLOAD_CONTENT_RESET }))
			}
		},
		[uploadContentDetails, dispatch]
	);

	//delete Content
	useEffect(
		() => {
			if (deleteContentDetails && deleteContentDetails.token && deleteContentDetails.token.issuccess === true) {
				setDeleteModalState(false);
				setTitle('')
				setContentId('')
				dispatch(contentsAction())
				toast.success(deleteContentDetails.token.message);
				dispatch(dispatch({ type: DELETE_CONTENT_RESET }))
			}
		},
		[deleteContentDetails, dispatch]
	);
	const items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14];

	const onClose = () => {
		setUploadModalState(false);
		setUploadContent('')
		setUploadContentError('')
		setMediaName('')
	};

	const onVideoClose = () => {
		var formData = {
			videoid: contentId,
			isvideo: true,
			isstart: false
		};
		dispatch(addDocumentHistoryAction(formData))
		setVideoModalState(false);

	};
	const onDeleteClose = () => {
		setDeleteModalState(false);

	};

	const handleOnPlay = () => {
		var formData = {
			videoid: contentId,
			isvideo: true,
			isstart: true
		};
		dispatch(addDocumentHistoryAction(formData))
	}

	const handleOnPause = () => {
		var formData = {
			videoid: contentId,
			isvideo: true,
			isstart: false
		};
		dispatch(addDocumentHistoryAction(formData))
	};
	const handleOnEnded = () => {
		var formData = {
			videoid: contentId,
			isvideo: true,
			isstart: false
		};
		dispatch(addDocumentHistoryAction(formData))
		setVideoModalState(false);
	};

	const addBtnClick = () => {
		setUploadModalState(true);
	};

	const handleUpload = (e) => {
		//e.preventDefault();
		if (!uploadContent.title.trim() || uploadContent.title.trim() === '') {
			setUploadContentError({ ...uploadContentError, title: 'Please enter a Title' });
		} else if (!uploadContent.category.trim() || uploadContent.category.trim() === '') {
			setUploadContentError({ ...uploadContentError, category: 'Please select Content Partner' });
		} else if (!uploadContent.desc.trim() || uploadContent.desc.trim() === '') {
			setUploadContentError({ ...uploadContentError, desc: 'Please enter a description' });
		} else if (!uploadContent.isVideo.trim() || uploadContent.isVideo.trim() === '') {
			setUploadContentError({ ...uploadContentError, isVideo: 'Enter media type' });
		} else if (uploadContent.isVideo === 'url' && (!uploadContent.url.trim() || uploadContent.url.trim() === '')) {
			setUploadContentError({ ...uploadContentError, url: 'Please enter a URL' });
		} else if (uploadContent.isVideo !== 'url' && uploadContent.file === '') {
			setUploadContentError({ ...uploadContentError, file: 'Media Needed ' });
		} else {
			let formData = new FormData();
			formData.append('Title', uploadContent.title)
			formData.append('Category', uploadContent.category)
			formData.append('Description', uploadContent.desc)
			formData.append('Type', uploadContent.isVideo)
			if (uploadContent.isVideo === 'url') {
				formData.append('WebURL', uploadContent.url)
			} else {
				formData.append('file', uploadContent.file)
				formData.append('isActive', true)
				if (uploadContent.isVideo === 'video') {
					formData.append('isVideo', true)
				} else {
					formData.append('isVideo', false)
				}
			}
			dispatch(uploadContentAction(formData))
		}
	};
	const handleDeleteContent = (e) => {
		e.preventDefault()
		dispatch(deleteContentAction(contentId))
	};


	const handleClear = () => {
		setFilterData({
			...filterData,
			Partner: "",
			Type: "",
		})

		dispatch(contentsAction());
	}

	const handleFilter = () => {
		dispatch(contentsAction(filterData.Partner, filterData.Type));
	}

	return (
		<Content
			currentMenu='Partner Contents'
			headerTitle='Partner Contents'
			addBtn={true}
			addBtnClick={addBtnClick}
			addPartner={true}
			addBtnText='Upload Content'
			addBtnIcon='/sprite.svg#icon-upload'>
			<div className='flex_column_button'>
				<div className='filter'>
					<div className='filter__inputs'>
						<h2 className='filter__heading' style={{ alignItems: "center" }}>Filter </h2>
						<div className='filter__input--labels'>
							<label htmlFor='partner' className='filter__input--label'>
								Content Partner
							</label>
							<div className='flex'>
								<div className='filter__input'>
									<select
										className='filter__input--select'
										name='partner'
										id='partner'
										value={filterData.Partner}
										onChange={(e) => {
											setFilterData({ ...filterData, Partner: e.target.value });
										}}
									>
										<option value='' disabled>Select Partner</option>
										{partnerContentDetails &&
											partnerContentDetails.data &&
											partnerContentDetails.data.length > 0 &&
											partnerContentDetails.data.map((item) => (
												<option value={item.title}>{item.title} </option>
											))}
										{/* <option value='ProAssurance'>ProAssurance </option>
										<option value='Norgesic'>Norgesic </option>
										<option value='Youturn Health'>Youturn Health </option> */}
									</select>
								</div>
								<h2 className='unverified-filter-h2'>OR</h2>
							</div>
						</div>

						<div className='filter__input--labels'>
							<label htmlFor='type' className='filter__input--label'>
								Content Type
							</label>
							<div className='flex'>
								<div className='filter__input'>
									<select
										className='filter__input--select'
										name='type'
										id='type'
										value={filterData.Type}
										onChange={(e) => {
											setFilterData({ ...filterData, Type: e.target.value });
										}}
									>
										<option value='' disabled>
											Select Type
										</option>
										<option value='video'>Video</option>
										<option value='pdf'>PDF</option>
										<option value='url'>URL</option>
										<option value='disclosure'>Disclosure</option>
									</select>
								</div>
							</div>
						</div>
						<div className='filter__input--buttonContainer' >
							<button id='searchBtn' className='filter__input--button'
								onClick={handleFilter}
							>
								Filter
							</button>
							<button id='clearBtn' className='filter__input--button'
								onClick={handleClear}
							>
								Clear
							</button>
							<SimpleButton
								title='View Upload History'
								icon='/sprite.svg#icon-history'
								onClick={() => history.push('/content-history')}
							/>
						</div>
					</div>
				</div>


				<ContentTable
					items={items}
					data={{ contentDetails, setVideoModalState, setContentId, setDeleteModalState, setTitle }}
				/>
				<UploadContent
					modalState={uploadModalState}
					onClose={onClose}
					data={{ uploadContent, setUploadContent, uploadContentError, setUploadContentError, mediaName, setMediaName, uploadContentDetails, partnerContentDetails }}
					shortDesc={shortDesc}
					setShortDesc={setShortDesc}
					contentType={contentType}
					setContentType={setContentType}
					handleUpload={handleUpload}
				/>
				<VideoModal
					data={{
						videoModalState,
						onVideoClose,
						contentId,
						handleOnPlay,
						handleOnPause,
						handleOnEnded
					}}
				/>
				<DeleteContentModal
					data={{
						contentDetails,
						handleDeleteContent,
						deleteModalState,
						onDeleteClose,
						title
					}}
				/>

			</div>

		</Content>
	);
};

export default ContentScreen;
