import React from 'react';
import './Skeletons.css';

const InviteClinicEmails = () => {
	const items = [ 1, 2, 3, 4, 5 ];
	return (
		<div className='filter__content2'>
			<div className='pending-header'>Pending Invites</div>
			{items &&
				items.map((item) => (
					<div className='pending-email' key={item}>
						<div>
							<div className='pending__email--skeleton skeleton' style={{ height: '1.2rem' }} />
							<div
								className='pending__email--skeleton skeleton'
								style={{ marginTop: '.5rem', height: '1rem', width: '15rem' }}
							/>
						</div>
						<div className='pending__button--skeleton skeleton' />
					</div>
				))}
		</div>
	);
};

export default InviteClinicEmails;
