import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeadingVideo';
import { BASE_URL_LOGIN } from '../../redux/actions/ip';

const DemoVideoModal = ({data}) => {
    const {videoModalState, onVideoClose, handleOnPlay, handleOnPause, handleOnEnded} = data; 
	var videoURL = `${BASE_URL_LOGIN}/VideoMaster/getdemovideo`
	return (
		<Modal show={videoModalState} >
			<ModalHeading
            onClose={onVideoClose} />
			<div className='videoContainer' style={{ marginBottom: '2.5rem' }}>

				<video style={{padding: '1rem'}} width="100%" height="400" controls="controls" seeking="true" preload='none' onPlay= {handleOnPlay} onPause = {handleOnPause} onEnded={handleOnEnded} autoPlay >
						<source id='mp4' src={videoURL} type="video/mp4" />
				</video>
			</div>
		</Modal>
	);
};

export default DemoVideoModal;
