import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { CLINIC_FILTER_RESET, CLINIC_PROVIDER_RESET } from '../../redux/constants/providerConstants'

import './ClinicScreen.css'
import FilterUsers from './FilterUsers'
import ShowData from './ShowData'
import { clinicFilterAction, clinicProvidersAction, providerAssessmentAction } from '../../redux/actions/providerActions'
import Content from '../../components/Content';
import { logout } from '../../redux/actions/userActions';

function Index({ history }) {
    const dispatch = useDispatch();

    const clinicProvider = useSelector((state) => state.clinicProvider);
    const clinicFilter = useSelector((state) => state.clinicFilter);
    const userLogin = useSelector((state) => state.userLogin);
	const activeToken = useSelector((state) => state.activeToken);

    const [userIndex, setUserIndex] = useState(1);
    const [usersList, setUsersList] = useState([]);
    const [totalusersList, setTotalUsersList] = useState([]);
    const perPageUser = 10;
    const totalPages = totalusersList / perPageUser;
    const [showFilter, setShowFilter] = useState(true);

    const [filterData, setFilterData] = useState({
        BirthDate: "",
        FirstName: "",
        LastName: "",
        assessmentrange: 0,
        endDate: "",
        providerId: "",
        riskRating: "",
        startDate: ""
    })


    useEffect(
		() => {
			if (!activeToken) {
				history.push('/login');
			} else if (!activeToken.token) {
				history.push('/login');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.applicationUser &&
				userLogin.userInfo.applicationUser.isPatient === true &&
				userLogin.userInfo.applicationUser.isSuperAdmin === false
			) {
				history.push('/questionaires');
			}
		},
		[activeToken, userLogin, history]
	);


    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');
        const tokenInfo = localStorage.getItem('token');
        const clientIdInfo = localStorage.getItem('clientId');
        const getMenusInfo = localStorage.getItem('getMenus');

        if (userInfo === "undefined" || userInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        } else if (tokenInfo === "undefined" || tokenInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        } else if (clientIdInfo === "undefined" || clientIdInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        } else if (getMenusInfo === "undefined" || getMenusInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        }
    }, [dispatch])


    useEffect(
        () => {
            if (userLogin && userLogin.userInfo && !userLogin.userInfo.token) {
                dispatch(logout())
                history.push('/login');
            } else if (!localStorage.getItem('userInfo')) {
                dispatch(logout())
                history.push('/login');
            } else {
                dispatch(clinicProvidersAction());
                var formData = {
                    BirthDate: "",
                    FirstName: "",
                    LastName: "",
                    assessmentrange: 0,
                    endDate: "",
                    providerId: "All",
                    riskRating: "",
                    startDate: ""
                }
                dispatch(clinicFilterAction(formData))
            }

        }, [userLogin, history, dispatch]
    )

    useEffect(
        () => {
            if (clinicFilter && clinicFilter.data && clinicFilter.data.length > 0) {
                setUsersList(clinicFilter.data.slice(0, perPageUser));
                setTotalUsersList(clinicFilter.data.length);

            } else if (clinicFilter && clinicFilter.data && clinicFilter.data.length === 0) {

                setUsersList(clinicFilter.data.slice(0, perPageUser));
            }
        },
        [clinicFilter]
    );
    useEffect(() => {

        return () => {
            dispatch({ type: CLINIC_PROVIDER_RESET })
            dispatch({ type: CLINIC_FILTER_RESET })
        }
    }, [dispatch])

    const handleAssessment = (id) => {
        dispatch(providerAssessmentAction(id))
    }

    const handleClear = () => {
        setFilterData({
            ...filterData,
            BirthDate: "",
            FirstName: "",
            LastName: "",
            assessmentrange: 0,
            endDate: "",
            providerId: "",
            riskRating: "",
            startDate: ""
        })
        var formData = {
            BirthDate: "",
            FirstName: "",
            LastName: "",
            assessmentrange: 0,
            endDate: "",
            providerId: "All",
            riskRating: "",
            startDate: "",
        }
        setUserIndex(1);
        dispatch(clinicFilterAction(formData));

    }
    const handleToday = () => {
        var currentTime = new Date();
        var formData = {
            BirthDate: "",
            FirstName: "",
            LastName: "",
            assessmentrange: 0,
            endDate: "",
            providerId: "All",
            riskRating: "",
            startDate: "",
            todayDate: currentTime
        }
        setUserIndex(1); 
        dispatch(clinicFilterAction(formData));

    }
    const handleFilter = () => {
        var formData = {
            BirthDate: filterData.BirthDate,
            FirstName: filterData.FirstName,
            LastName: filterData.LastName,
            assessmentrange: filterData.assessmentrange,
            endDate: filterData.endDate,
            providerId: filterData.providerId,
            riskRating: filterData.riskRating,
            startDate: filterData.startDate,
        }
        setUserIndex(1); 
        dispatch(clinicFilterAction(formData)); 
    }
    
    let nextContentFrom = 0;
    const handlePageChange = (currentPage) => {
        nextContentFrom = currentPage.selected * perPageUser;
        const nextContentTo = currentPage.selected * perPageUser + 10;
        setUsersList(clinicFilter && clinicFilter.data && clinicFilter.data.slice(nextContentFrom, nextContentTo));
        setUserIndex(currentPage.selected * perPageUser + 1);
    };

    return (
        <Content
            current="Dashboard" headerTitle="Dashboard" currentMenu="Dashboard"
        >
            <FilterUsers
                data={{
                    showFilter,
                    setShowFilter,
                    clinicProvider,
                    handleClear,
                    handleToday,
                    filterData,
                    setFilterData,
                    handleFilter
                }}
            />
            <ShowData
                data={{
                    clinicFilter,
                    showFilter,
                    handleAssessment,
                    usersList,
                    userIndex
                }}
                loading={clinicFilter && clinicFilter.loading} />

            {clinicFilter &&
                clinicFilter.data &&
                clinicFilter.data.length > 10 &&
                (
                    <div className="tableContainer--paginater">
                        <ReactPaginate
                            nextClassName={''} //clientList.data.result.length < rowsPerPage && "nextClassName"
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={totalPages}
                            onPageChange={handlePageChange}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            containerClassName={'paginationBttns'}
                            previousLinkClassName={'previousBttn'}
                            nextLinkClassName={'nextBttn'}
                            disabledClassName={'paginationDisabled'}
                            activeClassName={'paginationActive'}
                        />
                    </div>
                )}
        </Content>
    )
}

export default Index
