import React from 'react';
import SubscriptionCardStyle from '../../screens/SubscriptionScreen/SubscriptionCard/SubscriptionCard.module.css';
import SubscriptionStyle from '../../screens/SubscriptionScreen/SubscriptionScreen.module.css';
import styles from '../../screens/SubscriptionScreen/StripePayment/StripePayment.module.css';

const InputSkeleton = ({
  className = styles.stripePayment__formgroup1,
  icon = 'email',
  width = '5vw',
}) => {
  return (
    <div className={styles.stripePayment__formgroup + ' ' + className}>
      <div
        className='stripePayment__formgroup_label_skeleton skeleton'
        style={{ width: width }}
      ></div>
      <div className={styles.stripePayment__formgroup_inputBox}>
        <div className={styles.stripePayment__formgroup_iconBox}>
          <svg className={styles.stripePayment__formgroup_icon}>
            <use xlinkHref={`/sprite.svg#icon-${icon}`} />
          </svg>
        </div>
        <div className={styles.paymentCardNumber + ' skeleton'}></div>
      </div>
    </div>
  );
};

const SubscriptionPaymentSkeleton = () => {
  const metas = [1, 3, 4, 2, 8, 6, 5, 7, 9];
  const inputs = [
    {
      id: '8vw',
      className: styles.stripePayment__formgroup1,
      icon: 'user',
    },
    {
      id: '9vw',
      className: styles.stripePayment__formgroup2,
      icon: 'credit',
    },
    {
      id: '5vw',
      className: styles.stripePayment__formgroup3,
      icon: 'calendar',
    },
    {
      id: '5vw',
      className: styles.stripePayment__formgroup4,
      icon: 'cvc',
    },
    {
      id: '7vw',
      className: styles.stripePayment__formgroup5,
      icon: 'email',
    },
  ];

  return (
    <div className={styles.paymentPage}>
      <div
        className={SubscriptionCardStyle.subscription_card}
        style={{
          border: 'none',
          gridColumn: '1 / 2',
          gridRow: '1 / 2',
        }}
      >
        <div className='subscription_title_skeleton skeleton'></div>
        <div className='subscription_price_skeleton skeleton'></div>

        <div
          className={SubscriptionCardStyle.subscription_items}
          style={{ gap: '1.2rem' }}
        >
          {metas.map((meta) => (
            <div key={meta} className={SubscriptionCardStyle.subscription_item}>
              <svg className={SubscriptionCardStyle.subscription_icon}>
                <use xlinkHref='/sprite.svg#icon-check' />
              </svg>
              <div>
                <span
                  className='subscription_meta_skeleton skeleton'
                  style={{ width: `${meta + 10 + 'rem'}` }}
                ></span>
                <span className='subscription_meta2_skeleton skeleton'></span>
              </div>
            </div>
          ))}
        </div>

        <div className='subscription_total_skeleton'>
          <div className='subscription_total_heading_skeleton skeleton'></div>
          <div className='subscription_total_subheading_skeleton skeleton'></div>
          <div
            className='subscription_total_subheading_skeleton skeleton'
            style={{ width: '14rem' }}
          ></div>
        </div>

        <div className={SubscriptionCardStyle.loginContainer__button_layout}>
          <button className='outline__button_skeleton skeleton'>
            CHANGE PLAN
          </button>
        </div>
      </div>

      <div className={styles.paymentContainer}>
        <div className={SubscriptionStyle.order_summary_container}>
          <div className={SubscriptionStyle.summary_item}>
            <div>
              <div className='item_title_skeleton skeleton'></div>
              <div className='item_description_skeleton skeleton'></div>
            </div>
            <div className='payment_card_price_skeleton skeleton'></div>
          </div>
          <div
            className={SubscriptionStyle.summary_item}
            style={{
              padding: '2rem 0',
              borderTop: '1px solid rgba(0, 0, 0, 0.1)',
              borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            <div className='item_promo_skeleton skeleton'></div>
            <div className='item_promo_plus_skeleton skeleton'></div>
          </div>

          <div className={SubscriptionStyle.dis_item}>
            <div className='dis_item_title_skeleton skeleton'></div>
            <div className='dis_price_skeleton skeleton'></div>
          </div>

          <div className={SubscriptionStyle.total_price}>
            <div className='order_summary_total_skeleton skeleton'></div>
            <h1 className='order_summary_total_price_skeleton skeleton'></h1>
          </div>
        </div>
        <div className={styles.stripePayment__form}>
          <div className={styles.paymentCardContainer}>
            {inputs.map((input, index) => (
              <InputSkeleton
                key={index}
                className={input.className}
                icon={input.icon}
                width={input.id}
              />
            ))}
          </div>
        </div>
      </div>
      <div className={styles.stripePayment__button_layout}>
        <button className={styles.stripePayment__button + ' skeleton'} disabled>
          <span className={styles.stripePayment__button_text}>
            PROCESSING PAYMENT
          </span>
          <div className={styles.stripePayment__button_iconBox}>
            <svg className={styles.stripePayment__button_icon}>
              <use xlinkHref='/sprite.svg#icon-login' />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default SubscriptionPaymentSkeleton;
