import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { validateEmail, validatePassword } from '../../utils/validators';
import ShowData from './ShowData'

import { manageUserListAction, saveClinicUserAction, deleteUserAction } from '../../redux/actions/providerActions'
import { providerClinicsToggleAction } from '../../redux/actions/clinicActions'
import ActiveInactiveModal, { AddNewProvider, DeleteModal, EditProvider } from './Modals';
import Content from '../../components/Content';
import { DELETE_USER_RESET, SAVE_CLINIC_USER_RESET, USER_LIST_RESET } from '../../redux/constants/providerConstants';
import { PROVIDER_CLINICS_TOGGLE_RESET } from '../../redux/constants/clinicsConstants';

function Index({ history }) {
    const dispatch = useDispatch();

    const userLogin = useSelector((state) => state.userLogin.userInfo);
    const activeToken = useSelector((state) => state.activeToken);

    const manageUsersList = useSelector((state) => state.manageUsersList);
    const saveClinicUser = useSelector((state) => state.saveClinicUser);
    const providerClinicsToggle = useSelector((state) => state.providerClinicsToggle);
    const deleteUser = useSelector((state) => state.deleteUser);

    const [modalAddState, setModalAddState] = useState(false)
    const [modalEditState, setModalEditState] = useState(false)
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [modalActiveState, setModalActiveState] = useState(false)

    const [userId, setUserId] = useState("")
    const [isDeleted, setIsDeleted] = useState(false)

    const [newUserData, setNewUserData] = useState({
        credentials: "",
        displayAsClinic: false,
        email: "",
        firstName: "",
        gender: "",
        userId: "",
        isAdmin: false,
        isProvider: true,
        lastName: "",
        npi: "",
        password: "",
        confirmPassword: "",
        shownAs: "",
        validDEACert: false,
        providerId: '',
        clinicId: '',
        pdmpEmail: "",
        pdmpPassword: "",
        pdmpConfirmPassword: "",
        pdmpState: ""
    });
    const [newUserDataError, setNewUserDataError] = useState({
        credentials: "",
        displayAsClinic: false,
        email: "",
        firstName: "",
        gender: "",
        userId: "",
        isAdmin: false,
        isProvider: false,
        lastName: "",
        npi: "",
        password: "",
        confirmPassword: "",
        shownAs: "",
        validDEACert: false,
        pdmpEmail: "",
        pdmpPassword: "",
        pdmpConfirmPassword: "",
        pdmpState: ""
    });


    useEffect(
        () => {
            if (!activeToken) {
                history.push('/login');
            } else if (!activeToken.token) {
                history.push('/login');
            } else if (
                userLogin &&
                userLogin.userInfo &&
                userLogin.userInfo.applicationUser &&
                userLogin.userInfo.applicationUser.isPatient === true &&
                userLogin.userInfo.applicationUser.isSuperAdmin === false
            ) {
                history.push('/questionaires');
            }
        },
        [activeToken, userLogin, history]
    );

    useEffect(
        () => {
            dispatch(manageUserListAction())
        },
        [dispatch]
    );

    useEffect(() => {
        if (saveClinicUser && saveClinicUser.data && saveClinicUser.data.statusCode === true) {
            dispatch(manageUserListAction())
            setModalAddState(false);
            setModalEditState(false)
            toast.success(saveClinicUser.data.message);
            setNewUserData({
                ...newUserData,
                credentials: "",
                displayAsClinic: false,
                email: "",
                firstName: "",
                gender: "",
                userId: "",
                isAdmin: false,
                isProvider: true,
                lastName: "",
                npi: "",
                password: "",
                shownAs: "",
                validDEACert: false,
                providerId: '',
                clinicId: '',
                pdmpEmail: "",
                pdmpPassword: "",
                pdmpConfirmPassword: "",
                pdmpState: ""
            });
        }
        else if (saveClinicUser && saveClinicUser.error && saveClinicUser.error.data && saveClinicUser.error.data.statusCode === false) {
            setModalAddState(false)
            toast.error(saveClinicUser.error.data.message)
        }
        //eslint-disable-next-line
    }, [saveClinicUser, dispatch])

    useEffect(() => {
        if (providerClinicsToggle && providerClinicsToggle.data && providerClinicsToggle.data.statusCode === true) {

            dispatch(manageUserListAction())
            setModalActiveState(false);
            setUserId('')
            setIsDeleted(false)
            toast.success(providerClinicsToggle.data.message);

        }
    }, [providerClinicsToggle, dispatch])

    useEffect(() => {
        if (deleteUser && deleteUser.data && deleteUser.data.success === true) {
            dispatch(manageUserListAction())
            setDeleteModalState(false)
            toast.success(deleteUser.data.message);
        }
        //else if(deleteUser && deleteUser.error){}
    }, [deleteUser, dispatch])

    useEffect(
        () => {
            return () => {
                dispatch({ type: USER_LIST_RESET });
                dispatch({ type: SAVE_CLINIC_USER_RESET });
                dispatch({ type: PROVIDER_CLINICS_TOGGLE_RESET });
                dispatch({ type: DELETE_USER_RESET });
            };
        },
        [dispatch]
    );

    const addBtnClick = () => {
        setModalAddState(true)
        //setNewUserData({...newUserData, clinicName: userLogin && userLogin.clinic.name })
    }
    const onCloseAddModal = () => {
        setModalAddState(false);
        setNewUserData({
            ...newUserData,
            credentials: "",
            displayAsClinic: false,
            email: "",
            firstName: "",
            gender: "",
            userId: "",
            isAdmin: false,
            isProvider: true,
            lastName: "",
            npi: "",
            password: "",
            confirmPassword: '',
            shownAs: "",
            validDEACert: false,
            pdmpEmail: "",
            pdmpPassword: "",
            pdmpConfirmPassword: "",
            pdmpState: ""
        });
        setNewUserDataError('')
    };

    const handleAddInvite = () => {
        if (!newUserData.email.trim() || newUserData.email.trim() === '') {
            setNewUserDataError({ ...newUserDataError, email: 'Please enter email' });
        } else if (!validateEmail(newUserData.email)) {
            setNewUserDataError({ ...newUserDataError, email: 'Please enter a valid email' });
        } else if (!newUserData.password.trim() || newUserData.password.trim() === '') {
            setNewUserDataError({ ...newUserDataError, password: 'Please enter password' });
        } else if (!validatePassword(newUserData.password)) {
            setNewUserDataError({ ...newUserDataError, password: "Password should have uppercase, lowercase, symbol and 8 characters long." });
        } else if (!newUserData.confirmPassword.trim() || newUserData.confirmPassword.trim() === '') {
            setNewUserDataError({ ...newUserDataError, confirmPassword: 'Please confirm your password' });
        } else if (newUserData.confirmPassword !== newUserData.password) {
            setNewUserDataError({ ...newUserDataError, confirmPassword: 'Password does not match' });
        } else if (!newUserData.firstName.trim() || newUserData.firstName.trim() === '') {
            setNewUserDataError({ ...newUserDataError, firstName: 'Please enter first name' });
        } else if (!newUserData.lastName.trim() || newUserData.lastName.trim() === '') {
            setNewUserDataError({ ...newUserDataError, lastName: 'Please enter last name' });
        } else if (newUserData.isAdmin === false && newUserData.isProvider === false) {
            setNewUserDataError({ ...newUserDataError, isAdmin: 'Please select atleast one user role' })
        }
        // else if(userLogin && userLogin.clinicid === 1){
        //      if(!newUserData.pdmpEmail.trim() || newUserData.pdmpEmail.trim() === ''){
        //         setNewUserDataError({...newUserDataError, pdmpEmail: 'Please enter PDMP Credentials'})
        //     } else if(!newUserData.pdmpPassword.trim() || newUserData.pdmpPassword.trim() === ''){
        //         setNewUserDataError({...newUserDataError, pdmpPassword: 'Please enter a password'})
        //     } else if(!newUserData.pdmpConfirmPassword.trim() || newUserData.pdmpConfirmPassword.trim() === ''){
        //         setNewUserDataError({...newUserDataError, pdmpConfirmPassword: 'Please confirm your password'})
        //     } else if(newUserData.pdmpConfirmPassword !== newUserData.pdmpPassword){
        //         setNewUserDataError({...newUserDataError, pdmpConfirmPassword: 'Password does not match.'})
        //     } else if(!newUserData.pdmpState.trim() || newUserData.pdmpState.trim() === ''){
        //         setNewUserDataError({...newUserDataError, pdmpState: 'Please confirm your password'})
        //     } else {
        //         const formData = {
        //             email:newUserData.email,
        //             isClinicAdmin: newUserData.isAdmin,
        //             isClinicProvider: newUserData.isProvider ,
        //             password:newUserData.password ,
        //             provider: {
        //                 credentials:newUserData.credentials === '' ? null : newUserData.credentials ,
        //                 displayAsClinic: newUserData.displayAsClinic,
        //                 firstName: newUserData.firstName,
        //                 gender: newUserData.gender === '' ? null : newUserData.gender,
        //                 lastName:newUserData.lastName,
        //                 npi: newUserData.npi === '' ? null : newUserData.npi,
        //                 providerId:newUserData.providerId,
        //                 shownAs: newUserData.shownAs === '' ? null : newUserData.shownAs,
        //                 validDEACert:newUserData.validDEACert,
        //             },
        //             userId: newUserData.userId
        //         }
        //         dispatch(saveClinicUserAction(formData))
        //     }}
        else {
            const formData = {
                email: newUserData.email,
                isClinicAdmin: newUserData.isAdmin,
                isClinicProvider: newUserData.isProvider,
                password: newUserData.password,
                provider: {
                    credentials: newUserData.credentials === '' ? null : newUserData.credentials,
                    displayAsClinic: newUserData.displayAsClinic,
                    firstName: newUserData.firstName,
                    gender: newUserData.gender === '' ? null : newUserData.gender,
                    lastName: newUserData.lastName,
                    npi: newUserData.npi === '' ? null : newUserData.npi,
                    pdmpemail: newUserData.pdmpEmail,
                    pdmppassword: newUserData.pdmpPassword,
                    pdmpstate: newUserData.pdmpState,
                    providerId: newUserData.providerId,
                    shownAs: newUserData.shownAs === '' ? null : newUserData.shownAs,
                    validDEACert: newUserData.validDEACert,
                },
                userId: newUserData.userId
            }
            dispatch(saveClinicUserAction(formData))
        }
    }

    const handleEditInvite = (e) => {
        //e.preventDefault();
        const formData = {
            email: newUserData.email,
            isClinicAdmin: newUserData.isAdmin,
            isClinicProvider: newUserData.isProvider,
            password: newUserData.password,
            provider: {
                credentials: newUserData.credentials === '' ? null : newUserData.credentials,
                displayAsClinic: newUserData.displayAsClinic,
                firstName: newUserData.firstName,
                gender: newUserData.gender === '' ? null : newUserData.gender,
                lastName: newUserData.lastName,
                npi: newUserData.npi === '' ? null : newUserData.npi,
                pdmpemail: newUserData.pdmpEmail,
                pdmppassword: newUserData.pdmpPassword,
                pdmpstate: newUserData.pdmpState,
                providerId: newUserData.providerId,
                shownAs: newUserData.shownAs === '' ? null : newUserData.shownAs,
                validDEACert: newUserData.validDEACert,
            },
            userId: newUserData.userId
        }
        dispatch(saveClinicUserAction(formData))
    }
    const onCloseEditModal = () => {
        setModalEditState(false);
        setNewUserData({
            ...newUserData,
            credentials: "",
            displayAsClinic: false,
            email: "",
            firstName: "",
            gender: "",
            userId: "",
            isAdmin: false,
            isProvider: true,
            lastName: "",
            npi: "",
            password: "",
            confirmPassword: '',
            shownAs: "",
            validDEACert: false,
            pdmpEmail: "",
            pdmpPassword: "",
            pdmpConfirmPassword: "",
            pdmpState: ""
        });

    };

    const onCloseDeletetModal = () => {
        setDeleteModalState(false);
    };

    const handleDelete = () => {

        const formData = {
            clinicId: newUserData.clinicId,
            userId: newUserData.userId
        }
        dispatch(deleteUserAction(formData))
    }
    const handleActiveInactive = () => {
        dispatch(providerClinicsToggleAction(userId, isDeleted))
    }

    const onActiveClose = () => {
        setModalActiveState(false);
    };
    return (

        <Content
            current="Manage Users"
            headerTitle="Manage Users"
            currentMenu="Manage Users"
            addBtn={true}
            addBtnText="Add New"
            addBtnIcon="/sprite.svg#icon-plus"
            addBtnClick={addBtnClick}
        >
            <ShowData
                data={{
                    manageUsersList,
                    setModalEditState,
                    setDeleteModalState,
                    newUserData,
                    setNewUserData,
                    setModalActiveState,
                    setIsDeleted,
                    setUserId
                }} />

            <AddNewProvider
                data={{
                    userLogin,
                    modalAddState,
                    onCloseAddModal,
                    handleAddInvite,
                    newUserData,
                    setNewUserData,
                    newUserDataError,
                    setNewUserDataError
                }}
            />
            {/* <AddNewModal
            data={{
                modalAddState,
                onCloseAddModal,
                handleAddInvite,
                newUserData,
                setNewUserData,
                newUserDataError,
                setNewUserDataError
            }}
            /> */}
            <EditProvider
                data={{
                    userLogin,
                    modalEditState,
                    onCloseEditModal,
                    handleEditInvite,
                    newUserData,
                    setNewUserData,
                    newUserDataError,
                    setNewUserDataError
                }}
            />
            {/* <EditModal
            data={{
                modalEditState,
                onCloseEditModal,
                handleAddInvite,
                newUserData,
                setNewUserData,
                newUserData,
                setNewUserData
            }}
            /> */}
            <DeleteModal
                data={{ deleteModalState, onCloseDeletetModal, handleDelete }}
            />
            <ActiveInactiveModal
                data={{ modalActiveState, onActiveClose, handleActiveInactive, providerClinicsToggle }}
            />

        </Content>
    )
}

export default Index
