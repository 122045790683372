import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout } from '../../redux/actions/userActions'
import './VerifyEmail.css'

const Index = () => {
    const dispatch = useDispatch();

    return (
        // <Content>
            <div className='verifyEmail__Container'>
                {/* <div className='rightBar'></div> */}
                {/* <img src='/images/logo.png'/> */}
                <h1>Please Verify</h1>
                <p>Please check your email to verify your address. </p>
                <p>If you do not see a verification email, please check your spam or trash folders.</p>
                <Link className='verifyEmail__Button' to='/login' onClick={() => dispatch(logout())}>Back to Log in</Link>
            </div>
        // </Content>
    )
}

export default Index
