import React from 'react';
import TableRows from '../../components/Skeletons/TableRows';
import './Scheduler.css'

const SchedulerTable = ({ items, handleView, data }) => {
	const { scheduleDetails } = data;
	return (
		<div className='tableContainer' style={{ height: '75vh', marginTop: '0' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Schedule Date</th>
						<th>Time</th>
						<th>Content</th>
						<th>Status</th>
						<th>Action</th>
					</tr>
				</thead>
				<tbody>
					{
						scheduleDetails && scheduleDetails.loading ?
							<TableRows columns='13' />
							:
							scheduleDetails && scheduleDetails.token &&
							scheduleDetails.token.map((details, index) => (
								<tr key={index + 1}>
									<td>{details.id}</td>
									<td>{details.date}</td>
									<td>{details.time}</td>
									<td>{details.content}</td>
									<td className='tableStatus_Container' >
										{details.status}
										<svg className='table__icon primary-icon' style={{ padding: '1px' }}>
											<use xlinkHref={`/sprite.svg#icon-tick`} />
										</svg>
									</td>

									<td className='icon-schedulerContainer'>
										<div className='table_iconBox'>
											<button onClick={() => {
												handleView(details.id)


											}} className='table__buttonBig'>
												<svg className='table__button--iconBig'>
													<use xlinkHref={`/sprite.svg#icon-view_doc`} />
												</svg>
												<span>View Details</span>
											</button>
										</div>
										<div className='table_iconBox'>
											<button onClick={() => {
												handleView(details.id)


											}} className='table__buttonBig'>
												<svg className='table__button--iconBig' style={{ fill: 'red' }}>
													<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
												</svg>
												<span>Activate Schedule</span>
											</button>
										</div>
										<div className='table_iconBox'>
											<button onClick={() => {
												handleView(details.id)


											}} className='table__buttonBig'>
												<svg className='table__button--iconBig'>
													<use xlinkHref={`/sprite.svg#icon-calendar`} />
												</svg>
												<span>Complete Schedule</span>
											</button>
										</div>
										<div className='table_iconBox'>
											<button onClick={() => {
												handleView(details.id)


											}} className='table__buttonBig'>
												<svg className='table__button--iconBig' style={{ fill: 'red' }}>
													<use xlinkHref={`/sprite.svg#icon-delete2`} />
												</svg>
												<span>Delete Schedule</span>
											</button>
										</div>
									</td>


								</tr>
							))}
				</tbody>
			</table>
		</div>
	);
};

export default SchedulerTable;
