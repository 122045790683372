import axios from "axios";

import {
  CURRENT_USER_REQUEST,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_FAIL,
  CLINIC_FILTER_REQUEST,
  CLINIC_FILTER_SUCCESS,
  CLINIC_FILTER_FAIL,
  CLINIC_PROVIDER_REQUEST,
  CLINIC_PROVIDER_SUCCESS,
  CLINIC_PROVIDER_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  PROVIDER_ASSESSMENT_REQUEST,
  PROVIDER_ASSESSMENT_SUCCESS,
  PROVIDER_ASSESSMENT_FAIL,
  ADD_INVITE_REQUEST,
  ADD_INVITE_SUCCESS,
  ADD_INVITE_FAIL,
  SAVE_CLINIC_USER_REQUEST,
  SAVE_CLINIC_USER_SUCCESS,
  SAVE_CLINIC_USER_FAIL,
  VIDEO_DETAILS_REQUEST,
  VIDEO_DETAILS_SUCCESS,
  VIDEO_DETAILS_FAIL,
  VIDEO_THUMB_REQUEST,
  VIDEO_THUMB_SUCCESS,
  VIDEO_THUMB_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  TERMS_ACCEPT_REQUEST,
  TERMS_ACCEPT_SUCCESS,
  TERMS_ACCEPT_FAIL,
  EHR_CLINIC_FILTER_REQUEST,
  EHR_CLINIC_FILTER_SUCCESS,
  EHR_CLINIC_FILTER_FAIL,
} from "../constants/providerConstants";
import { logout } from "./userActions";
import { CHANGE_CONSENT_DOCUMENTS_STATUS_FAIL, CHANGE_CONSENT_DOCUMENTS_STATUS_REQUEST, CHANGE_CONSENT_DOCUMENTS_STATUS_SUCCESS, CONSENT_DOCUMENTS_FAIL, CONSENT_DOCUMENTS_REQUEST, CONSENT_DOCUMENTS_SUCCESS } from "../constants/consentConstants";
import { toast } from 'react-toastify';

export const getCurrentUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CURRENT_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/auth/getcurrentuser`,
      config
    );

    dispatch({
      type: CURRENT_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CURRENT_USER_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const clinicFilterAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: CLINIC_FILTER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/Providers/GetClinicFilterList`,
      formData,
      config
    );

    dispatch({
      type: CLINIC_FILTER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: CLINIC_FILTER_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const clinicProvidersAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CLINIC_PROVIDER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/Providers/ClinicProviders`,
      config
    );

    dispatch({
      type: CLINIC_PROVIDER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CLINIC_PROVIDER_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const manageUserListAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/Providers/UserListByClinic`,
      config
    );

    dispatch({
      type: USER_LIST_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const signupPdfAction = (id, pin) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
      responseType: "blob",
    };

    const fileName = pin ? `PIC 2-Page Patient Welcome Letter-${pin}.pdf` : `PIC 2-Page Patient Welcome Letter.pdf`

    axios.get(
      `${userInfo.apiurl}/Providers/GetSignupPDF?ProviderId=${id}`,
      config
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName); //or any other extension
      document.body.appendChild(link)
      link.click();
      dispatch({
        type: USER_LIST_SUCCESS,
        payload: response.data
      });
    })


  } catch (err) {
    dispatch({
      type: USER_LIST_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
    });
  }
};

export const providerAssessmentAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROVIDER_ASSESSMENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/Providers/GetAssesmentListByUser?userid=${id}`,
      config
    );

    dispatch({
      type: PROVIDER_ASSESSMENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: PROVIDER_ASSESSMENT_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const newInviteAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_INVITE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/Clinics/saveprovider`,
      formData,
      config
    );

    dispatch({
      type: ADD_INVITE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADD_INVITE_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const saveClinicUserAction =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: SAVE_CLINIC_USER_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "custom-clinicid-header": userInfo.clinicid,
        },
      };

      const { data } = await axios.post(
        `${userInfo.apiurl}/clinics/saveclinicuser`,
        formData,
        config
      );

      dispatch({
        type: SAVE_CLINIC_USER_SUCCESS,
        payload: data,
      });
    } catch (err) {
      if (err.response && err.response.status === 401) {
        dispatch(logout());
      }
      dispatch({
        type: SAVE_CLINIC_USER_FAIL,
        payload: err.response,
      });
    }
  };

export const videoDetailsAction = (title) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_DETAILS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/VideoMaster/GetVideoDetails?title=${title}`,
      config
    );

    dispatch({
      type: VIDEO_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VIDEO_DETAILS_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};
export const videothumbAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: VIDEO_THUMB_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/VideoMaster/getThumbFileById?fileId=${id}`,
      config
    );

    dispatch({
      type: VIDEO_THUMB_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VIDEO_THUMB_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const deleteUserAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/clinics/deleteuser`,
      formData,
      config
    );

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: err.response,
    });
  }
};
export const termsAcceptAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: TERMS_ACCEPT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/auth/updateistermsaccepted`,
      formData,
      config
    );

    dispatch({
      type: TERMS_ACCEPT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: TERMS_ACCEPT_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};

export const getConsentFormsAction = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/ConsentForm/GetConsentFormsFileByClinicId`,
      config
    );

    const { data: cData } = await axios.get(
      `${userInfo.apiurl}/ConsentForm/GetConsentFormsFileByClinicId`,
      config
    );

    const returnData = { ...data, consentForm: cData };

    return returnData;
  } catch (err) {
    return err.response && err.response.data.msg
      ? err.response.data.msg
      : err.message;
  }
};

export const uploadConsentFormsAction =
  (formData, query = "") =>
    async (dispatch, getState) => {
      try {
        const {
          userLogin: { userInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            "custom-clinicid-header": userInfo.clinicid,
          },
        };

        let uri = `${userInfo.apiurl}/ConsentForm/UploadConsentFormsFiles`;

        if (query) {
          uri = `${userInfo.apiurl}/ConsentForm/UploadConsentFormsFiles?${query}`;
        }

        const { data } = await axios.post(uri, formData, config);

        return data;
      } catch (err) {
        return err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message;
      }
    };

export const uploadConsentFormAction =
  (formData) => async (dispatch, getState) => {
    try {
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
          "custom-clinicid-header": userInfo.clinicid,
        },
      };

      const { data } = await axios.post(
        `${userInfo.apiurl}/ConsentForm/UploadConsentForm`,
        formData,
        config
      );

      return data;
    } catch (err) {
      return err.response && err.response.data.msg
        ? err.response.data.msg
        : err.message;
    }
  };


export const consentFormsDocAction = (userId) => async (dispatch, getState) => {
  try {
    dispatch({ type: CONSENT_DOCUMENTS_REQUEST });

    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid
      }
    };

    const { data } = await axios.get(`${userInfo.apiurl}/ConsentForm/getConsentFormsList?patientId=${userId}`, config);

    dispatch({
      type: CONSENT_DOCUMENTS_SUCCESS,
      payload: data
    });
  } catch (err) {
    dispatch({
      type: CONSENT_DOCUMENTS_FAIL,
      payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
    });
    toast.error(err.response.data.message);
  }
};


export const consentFormsDocViewAction = (id, userId, fileName) => async (dispatch, getState) => {
  try {
    const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.get(
      `${userInfo.apiurl}/ConsentForm/getConsentFormByConsentId?fileId=${id}&patientId=${userId}&fileName=${fileName}`,
      config
    );
    return data
  } catch (err) {
    toast.error(err.response.data.message);
  }
};


export const changeConsentFormStatusAction = (enableStatus) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHANGE_CONSENT_DOCUMENTS_STATUS_REQUEST });

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json'
      }
    };

    const { data } = await axios.patch(`${userInfo.apiurl}/ConsentForm/EnableDisableVersion`, enableStatus, config);

    dispatch({
      type: CHANGE_CONSENT_DOCUMENTS_STATUS_SUCCESS,
      payload: data
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: CHANGE_CONSENT_DOCUMENTS_STATUS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message
    });
  }
};


export const ModMedFilterAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: EHR_CLINIC_FILTER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "custom-clinicid-header": userInfo.clinicid,
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/ModMed/GetProbableMatchedData`,
      formData,
      config
    );

    dispatch({
      type: EHR_CLINIC_FILTER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: EHR_CLINIC_FILTER_FAIL,
      payload:
        err.response && err.response.data.msg
          ? err.response.data.msg
          : err.message,
    });
  }
};
