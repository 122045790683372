
import axios from 'axios';
import {
	GET_ALL_APP_VERSION_REQUEST,
	GET_ALL_APP_VERSION_SUCCESS,
	GET_ALL_APP_VERSION_FAIL,
	ADD_APP_VERSION_REQUEST,
	ADD_APP_VERSION_SUCCESS,
	ADD_APP_VERSION_FAIL,
	CHANGE_APP_VERSION_STATUS_REQUEST,
	CHANGE_APP_VERSION_STATUS_SUCCESS,
	CHANGE_APP_VERSION_STATUS_FAIL,
	DELETE_APP_VERSION_REQUEST,
	DELETE_APP_VERSION_SUCCESS,
	DELETE_APP_VERSION_FAIL,
} from '../constants/appVersionConstants';
import { BASE_URL_LOGIN } from './ip';
import { logout } from './userActions';


export const getAllAppVersionAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: GET_ALL_APP_VERSION_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.get(`${BASE_URL_LOGIN}/Mobile/GetAppVersions`, config);
		// ?&pageNumber=${formData.pageNumber}&pageSize=${formData.pageSize}&filterValue=${formData.filter}

		dispatch({
			type: GET_ALL_APP_VERSION_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: GET_ALL_APP_VERSION_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const createAppVersionAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_APP_VERSION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header':userInfo.clinicid,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.post(`${BASE_URL_LOGIN}/Mobile/AddAppVersion`, formData, config);

		dispatch({
			type: ADD_APP_VERSION_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: ADD_APP_VERSION_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const changeAppVersionStatusAction = (enableStatus) => async (dispatch, getState) => {
	try {
		dispatch({ type: CHANGE_APP_VERSION_STATUS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
				'Content-Type': 'application/json'
			}
		};

		const { data } = await axios.patch(`${BASE_URL_LOGIN}/Mobile/EnableDisableVersion`, enableStatus, config);

		dispatch({
			type: CHANGE_APP_VERSION_STATUS_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: CHANGE_APP_VERSION_STATUS_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


export const deleteAppVersionAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_APP_VERSION_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
				'Content-Type': 'application/json'
			}
		};


		const { data } = await axios.get(`${BASE_URL_LOGIN}/Mobile/DeleteAppVersion?Id=${id}`, config);

		dispatch({
			type: DELETE_APP_VERSION_SUCCESS,
			payload: data
		});
	} catch (err) {
		if (err.response?.status === 401) {
			dispatch(logout());
		}
		dispatch({
			type: DELETE_APP_VERSION_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};


