import React from 'react';
import classes from '../Notifications.module.css';

const NotificationItem = ({ title, subTitle, date, sentTo }) => {
  const [showviewAll, setShowViewAll] = React.useState(false);
  const [viewAll, setViewAll] = React.useState(false);
  const [sentByData, setsentByData] = React.useState([]);

  React.useEffect(() => {
    if (viewAll) setsentByData(sentTo);
    else setsentByData(sentTo.slice(0, 5));
  }, [sentTo, viewAll]);

  React.useEffect(() => {
    if (sentTo && sentTo.length <= 5) setShowViewAll(true);
  }, [sentTo]);

  return (
    <div className={classes.notification_item}>
      <h5 className={classes.notification_item_heading}>{title}</h5>
      {sentTo && (
        <p className={classes.notification_item_user}>
          To: {sentByData.join(', ')}
          {!showviewAll && <span onClick={() => setViewAll(prevVal => !prevVal)}>{viewAll ? 'Hide' : 'View All'}</span>}
        </p>
      )}
      <p className={classes.notification_item_desc}>{subTitle}</p>
      <p className={classes.notification_item_date}>Sent on {date}</p>
    </div>
  );
};

export default NotificationItem;
