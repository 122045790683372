import React, { Fragment } from 'react';
import SubscriptionCardStyle from './SubscriptionCard.module.css';

const SubscriptionItem = ({ text, value, active }) => {
  const [formattedValue, setFormattedValue] = React.useState('');
  const [infoExists, setInfoExists] = React.useState(false);
  const [infoText, setInfoText] = React.useState('');

  React.useEffect(() => {
    if (value) {
      const newValue = String(value);
      setFormattedValue(newValue?.split('.#')[0]);
      setInfoExists(newValue?.split('.#').length > 1 ? true : false);
      setInfoText(
        newValue?.split('.#').length > 1 ? newValue?.split('.#').join(' ') : ''
      );
    }
  }, [value]);

  return (
    <div className={SubscriptionCardStyle.subscription_item}>
      {text && (
        <svg
          className={
            active
              ? SubscriptionCardStyle.subscription_icon_white
              : SubscriptionCardStyle.subscription_icon
          }
        >
          <use xlinkHref='/sprite.svg#icon-check' />
        </svg>
      )}

      <div>
        <p className={SubscriptionCardStyle.subscription_meta}>{text}</p>
        <p className={SubscriptionCardStyle.subscription_meta}>
          {formattedValue}
          {infoExists && (
            <Fragment>
              <svg
                className={
                  active
                    ? SubscriptionCardStyle.subscription_meta_info_white
                    : SubscriptionCardStyle.subscription_meta_info
                }
              >
                <use xlinkHref='/sprite.svg#icon-info' />
              </svg>

              <span
                className={SubscriptionCardStyle.subscription_meta_info_data}
              >
                {infoText}
              </span>
            </Fragment>
          )}
        </p>
      </div>
    </div>
  );
};

export default SubscriptionItem;
