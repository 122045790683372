import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import ContentAdmin from '../../components/ContentAdmin';

import { DashboardClientSkeleton } from '../../components/Skeletons/ManageClient'

import { getAllClientAction, logout, setClientUrlAction, updateLoginInfo } from '../../redux/actions/userActions';
import './Dasboard.css';

const Dashboard = ({ history }) => {

	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const activeToken = useSelector((state) => state.activeToken);

	const allClientDetails = useSelector((state) => state.allClientDetails);

	
    useEffect(
		() => {
			if (!activeToken) {
				dispatch(logout())
				history.push('/login');
			} else if (!activeToken.token) {
				dispatch(logout())
				history.push('/login');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.applicationUser &&
				userLogin.userInfo.applicationUser.isPatient === true &&
				userLogin.userInfo.applicationUser.isSuperAdmin === false
			) {
				history.push('/questionaires');
			}
		},
		[activeToken, userLogin, history]
	);


	useEffect(
		() => {
			if (userLogin
				&& userLogin.userInfo
				&& !userLogin.userInfo.token) {
				dispatch(logout())
				history.push('/login');
			} else if (!localStorage.getItem('userInfo')) {
				dispatch(logout())
				history.push('/login');
			} else {
				dispatch(getAllClientAction())
			}
		},
		[userLogin, dispatch, history]
	);

	useEffect(
		() => {
			if (allClientDetails && allClientDetails.error && allClientDetails.error.status === 501) {
				dispatch(logout())
				history.push('/login');
			}
		},
		[allClientDetails, dispatch, history]
	);

	return (
		<ContentAdmin currentMenu="dashboard" headerTitle="Dashboard" >  
			<div className="dashboard">
				<h3 className="dashboard__heading">Where do you want to proceed?</h3>
				<div className="dashboard__options">
					{
						allClientDetails &&
							allClientDetails.loading ? 
							<DashboardClientSkeleton />
							:
							allClientDetails &&
							allClientDetails.userInfo &&
							allClientDetails.userInfo.length > 0 &&
							allClientDetails.userInfo.map((user, index) => (
								<div key={index + 1} className="dashboard__option">
									<img
										src={`data:image/jpeg;base64,${user.clientlogo}`}
										alt="CCP Logo"
										className="dashboard__option--logo"
									/>
									<div>
										<h2 className="dashboard__option--heading">{user.clientname}</h2>
										<h3 className="dashboard__option--provider">{user.clientprovidername}</h3>
									</div>
									<Link
										to="#"
										id='PICLinkBtn'
										onClick={() => {
											localStorage.setItem('clientUrl', user.apiurl)
											localStorage.setItem('clientId', user.clientId)
											dispatch(updateLoginInfo(user))
											dispatch(setClientUrlAction(user.apiurl, history))
											user.apiurl ? history.push('/admin/user-manager') : alert('Backend is not connected. Please wait.')
										}}
										className="dashboard__option--link"
									>
										<svg className="dashboard__option--icon">
											<use xlinkHref={`/sprite.svg#icon-right_arrow`} />
										</svg>
									</Link>
								</div>
							))}
				</div>
			</div>
		</ContentAdmin>
	);
};

export default Dashboard;
