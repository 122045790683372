import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

const useApi = ({ url, method = 'POST' }) => {
  const userLogin = useSelector(state => state.userLogin);
  const [loading, setLoading] = useState(false);

  const makeApiCall = useCallback(
    async input => {
      if (userLogin.userInfo) {
        setLoading(true);
        try {
          const config = {
            url: `${userLogin.userInfo.apiurl + url}`,
            method,
            data: input,
            headers: {
              Authorization: `Bearer ${userLogin.userInfo.token}`,
              'custom-clinicid-header': userLogin.userInfo.clinicid,
            },
          };

          const response = await axios(config);
          return { response: response.data, loading, error: undefined };
        } catch (e) {
          const error = e.response && e.response && e.response.data ? e.response.data : e;

          return {
            response: undefined,
            loading,
            error,
          };
        } finally {
          setLoading(false);
        }
      }
    },
    [loading, method, url, userLogin]
  );

  return { loading, makeApiCall };
};

export default useApi;
