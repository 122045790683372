import React, { Fragment } from 'react';

export const DeleteUserSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='filter__input--skeleton skeleton' />
				</div>
			</div>
			<div className='modal__actions'>
			
				<div className='modal__button--delete-skeleton skeleton' />
			</div>
		</Fragment>
	);
};

export const EditUserSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				<div style={{display: 'flex'}}>
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
					<div className='filter__input--labels'>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
				</div>
				
				<br/>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='filter__input--skeleton skeleton' />
					
				</div>
			</div>
			<div className='modal__actions'>
				<div className='filter__input--button-skeleton skeleton' />
			</div>
		</Fragment>
	);
};
export const LoginUserSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text1--skeleton skeleton' />
					</div>
			</div>
			<div className='modal__actions'>
				<div className='modal__button--delete-skeleton skeleton' />
			</div>
		</Fragment>
	);
};
export const ToggleUserSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				
				<div className='filter__input--labels' style={{marginRight: '1rem'}}>
					<div className='modal__text1--skeleton skeleton' />
				</div>
		</div>
		<div className='modal__actions'>
			<div className='filter__input--skeleton skeleton' />
		</div>
		</Fragment>
	);
};
export const ResetUserSkeleton = () => {
	return (
		<Fragment>
			<div className='modalContainer__form'>
				<div>
					<div className='filter__input--labels' style={{marginRight: '1rem'}}>
						<div className='modal__text--skeleton skeleton' />
						<div className='filter__input--skeleton skeleton' />
					</div>
					
				</div>
				
				<br/>
				<div className='filter__input--labels'>
					<div className='modal__text--skeleton skeleton' />
					<div className='filter__input--skeleton skeleton' />
					
				</div>
			</div>
			<div className='modal__actions'>
			
				<div className='filter__input--button-skeleton skeleton' />
			</div>
		</Fragment>
	);
};