export const GET_ALL_APP_VERSION_REQUEST = 'GET_ALL_APP_VERSION_REQUEST';
export const GET_ALL_APP_VERSION_SUCCESS = 'GET_ALL_APP_VERSION_SUCCESS';
export const GET_ALL_APP_VERSION_FAIL = 'GET_ALL_APP_VERSION_FAIL';
export const GET_ALL_APP_VERSION_RESET = 'GET_ALL_APP_VERSION_RESET';

export const ADD_APP_VERSION_REQUEST = 'ADD_APP_VERSION_REQUEST';
export const ADD_APP_VERSION_SUCCESS = 'ADD_APP_VERSION_SUCCESS';
export const ADD_APP_VERSION_FAIL = 'ADD_APP_VERSION_FAIL';
export const ADD_APP_VERSION_RESET = 'ADD_APP_VERSION_RESET'; 

export const CHANGE_APP_VERSION_STATUS_REQUEST = 'CHANGE_APP_VERSION_STATUS_REQUEST';
export const CHANGE_APP_VERSION_STATUS_SUCCESS = 'CHANGE_APP_VERSION_STATUS_SUCCESS';
export const CHANGE_APP_VERSION_STATUS_FAIL = 'CHANGE_APP_VERSION_STATUS_FAIL';
export const CHANGE_APP_VERSION_STATUS_RESET = 'CHANGE_APP_VERSION_STATUS_RESET';  

export const DELETE_APP_VERSION_REQUEST = 'DELETE_APP_VERSION_REQUEST';
export const DELETE_APP_VERSION_SUCCESS = 'DELETE_APP_VERSION_SUCCESS';
export const DELETE_APP_VERSION_FAIL = 'DELETE_APP_VERSION_FAIL';
export const DELETE_APP_VERSION_RESET = 'DELETE_APP_VERSION_RESET';
