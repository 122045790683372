import React, { Fragment, useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { 
	logout
} from '../../redux/actions/userActions';
import { toggleMenu } from '../Header';
import { useSelector } from 'react-redux';
import { CLINIC_PROVIDER_RESET } from '../../redux/constants/providerConstants'; 

const AllSidebar = ({ current = 'none' }) => {

	const history = useHistory();
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const getMenus = useSelector((state) => state.getMenus); 
	const [userRole, setUserRole] = useState('');

	useEffect(
		() => {
			if (userLogin && userLogin.userInfo
				//&& userLogin.userInfo.token
				&& userLogin.userInfo.issuccess
				&& userLogin.userInfo.issuperadmin
				&& !userLogin.userInfo.isclinicadmin
				&& !userLogin.userInfo.isprovider
				&& !userLogin.userInfo.ispatient
			) {
				//alert('superadmin')
				setUserRole('superadmin')
			} else if (userLogin && userLogin.userInfo
				&& userLogin.userInfo.token
				&& userLogin.userInfo.issuccess
				&& userLogin.userInfo.ispatient
				&& !userLogin.userInfo.isclinicadmin
				&& !userLogin.userInfo.isprovider
			) {
				///alert('patient')
				setUserRole('patient')
			} else if (userLogin && userLogin.userInfo
				&& userLogin.userInfo.token
				&& userLogin.userInfo.issuccess
				&& userLogin.userInfo.isprovider
				&& !userLogin.userInfo.isclinicadmin
				&& !userLogin.userInfo.ispatient
			) {
				//alert('provider')
				setUserRole('provider')
			} else if (userLogin && userLogin.userInfo
				&& userLogin.userInfo.token
				&& userLogin.userInfo.issuccess
				&& (userLogin.userInfo.isprovider || userLogin.userInfo.isclinicadmin)
				&& !userLogin.userInfo.ispatient
			) {
				//alert('provider-admin')
				setUserRole('provider-admin')
			}
		},
		[userLogin, history]
	);
	useEffect(
		() => {
			if (getMenus && getMenus.data && userRole !== '') {

				let subMenu = document.getElementsByClassName('hasChildren');
				let navSub = document.querySelector('.navigation__sub__active');
				if (navSub) {
					const parentNavSub = navSub.parentElement.parentElement;
					parentNavSub.classList.toggle('subMenuActive');
				}

				for (let i = 0; i < subMenu.length; i++) {

					subMenu[i].addEventListener('click', function () {
						this.classList.toggle('subMenuActive');
					});
				}
			}
		},
		[getMenus, userRole]
	);

	const handleDocument = (menuName) => {
		if (menuName === 'Documents') {
			dispatch({
				type: CLINIC_PROVIDER_RESET
			})
		}
	}

	return (
		<Fragment>
			<nav className='navigation' style={{ zIndex: 500 }}>
				<div>
					<div className='navigation__logo--container'>
						<img src='/images/logo-dark.png' alt='logo' className='navigation__logo' />
						<button className='sidebarnav__button' onClick={toggleMenu}>
							<svg className='sidebarnav__icon'>
								<use xlinkHref={`/sprite.svg#icon-menu`} />
							</svg>
						</button>
					</div>
					<img src='/images/favicon.png' alt='logo' className='navigation__logo2' />

					<ul className='navigation__list'>
						{
							getMenus && getMenus.data && getMenus.data.filter(item =>
								item.clientId === (userLogin && userLogin.userInfo && userLogin.userInfo.clientId === "0779f084-7187-4353-990c-445a56b56ee2" ? 1
									: userLogin && userLogin.userInfo && userLogin.userInfo.clientId === "cbc03e68-c5f8-4191-b33b-e7a2d2b53cf5" ? 2 : 1)
								&& (userRole === 'superadmin' ? item.isSuperAdmin && !item.isClinicAdmin && !item.isClinicProvider && !item.isPatient :
									userRole === 'patient' ? !item.isSuperAdmin && !item.isClinicAdmin && !item.isClinicProvider && item.isPatient :
										userRole === 'provider' ? !item.isSuperAdmin && item.isClinicAdmin && item.isClinicProvider && !item.isPatient :
											userRole === 'provider-admin' ? !item.isSuperAdmin && (item.isClinicAdmin || item.isClinicProvider) && !item.isPatient : null)
							)
								.map((menu, i) => ( 
									menu.childmenu.length <= 0 ?
										<div className={`${current === `${menu.name}` && 'currentNav'}`} key={i + 1}>
											<li className='navigation__list--item'>
												<Link id={menu.name.replace(' ', '') + 'Icon'} to={menu.url === null ? '#' : menu.url.replace(/['"]+/g, '')} className='navigation__list--link' dangerouslySetInnerHTML={{ __html: menu.icon }}></Link>
											</li>
											<li className='navigation__list--itemHover'>
												<Link id={menu.name.replace(' ', '') + 'link'} onClick={() => handleDocument(menu.name)} to={menu.url === null ? '#' : menu.url.replace(/['"]+/g, '')}
													className='navigation__list--link'
													dangerouslySetInnerHTML={{ __html: menu.icon + `<span>${menu.name}</span>` }} 
												> 
												</Link>
											</li>
											{current === `${menu.name}` && <div className='activeNav' />}
										</div>
										:
										<div className={`${current === `${menu.name}` ? 'currentNav' : ''}`} key={i + 1}>
											<li className='navigation__list--item'>
												<Link to='' className='navigation__list--link' dangerouslySetInnerHTML={{ __html: menu.icon }} >
												</Link>
											</li>
											<li className='navigation__list--itemHover hasChildren' id='dropdown' >
												<div className='navigation__list--link navigation__list-parent--link'
													dangerouslySetInnerHTML={{ __html: menu.icon + `<span>${menu.name}</span>` }}
												>
												</div>
												<ul>
													{menu && menu.childmenu && menu.childmenu.map((list, index) => (
														<li key={index + 1}>
															<NavLink id={list.name.replace(' ', '') + 'link'}
																to={list.url === null ? '/clinic/videolist/' + list.name : list.url.replace(/['"]+/g, '')}
																activeClassName='navigation__sub__active'
																dangerouslySetInnerHTML={{ __html: `<span>${list.name}</span>` }}
															// onClick={() => list.url === null ? SponserEventHandler(list) : null}
															>
															</NavLink>
														</li>
													))}
												</ul>
											</li>
											{current === `${menu.name}` && <div className='activeNav' />}
										</div>
								))}
					</ul>
				</div>

				<ul className='navigation__list' style={{ marginTop: '0', marginBottom: '3rem' }}>
					{userLogin && userLogin.userInfo && userLogin.userInfo.ispatient &&
						<div className={`${current === 'my-profile' && 'currentNav'}`}>
							<li className='navigation__list--item'>
								<Link to='/patient/my-profile' className='navigation__list--link'>
									<svg className='navigation__icon2'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
									&nbsp;
								</Link>
							</li>
							<li className='navigation__list--itemHover'>
								<Link to='/patient/my-profile' className='navigation__list--link2'>
									<svg className='navigation__icon2'>
										<use xlinkHref={`/sprite.svg#icon-user`} />
									</svg>
									<span>My Profile</span>
								</Link>
							</li>
							{current === 'my-profile' && <div className='activeNav' />}
						</div>}
					<div>
						<li className='navigation__list--item'>
							<Link id='logoutIcon' to='/login' className='navigation__list--link' onClick={() => dispatch(logout())}>
								<svg className='navigation__icon2'>
									<use xlinkHref={`/sprite.svg#icon-logout`} />
								</svg>
								&nbsp;
							</Link>
						</li>
						<li className='navigation__list--itemHover'>
							<Link id='logoutLink' to='/login' onClick={() => dispatch(logout())} className='navigation__list--link2'>
								<svg className='navigation__icon2'>
									<use xlinkHref={`/sprite.svg#icon-logout`} />
								</svg>
								<span>Logout</span>
							</Link>
						</li>
					</div>
					<div className='navigation__list--copyrightHidden'>
						<span>&nbsp;</span>
					</div>
					<div className='navigation__list--copyright'>
						<span>&copy;{new Date().getFullYear()} Sure Med Compliance</span>
					</div>
				</ul>
			</nav>
		</Fragment>
	);
};

export default AllSidebar;
