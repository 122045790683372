import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';

import Content from '../../components/Content';
import FilterUsers from './FilterUsers';
import ShowData from './ShowData';
import LoginAs from './Modals/LoginAs';
import EditModal from './Modals/EditModal';
import UserToggleModal from './Modals/UserToggleModal';
import ResetPassword from './Modals/ResetPassword';
import DeleteUser from './Modals/DeleteUser';
import { getDayDiff, validateEmail } from '../../utils/validators';
import { clinicProvidersAction, getAllClinicsAction } from '../../redux/actions/clinicActions';
import {
  managerUserAction,
  updatemanageUserAction,
  activatemanageUserAction,
  passwordResetAction,
  deleteManageUserAction,
  adminLoginAction,
  logout,
  GetSFTPGenerateByPatientIdPDFAction,
  getMenusAction,
} from '../../redux/actions/userActions';
import {
  ACTIVATE_MANAGE_USER_RESET,
  RESETPASSWORDS_RESET,
  DELETE_MANAGE_USER_RESET,
  MANAGE_USER_RESET,
  UPDATE_MANAGE_USER_RESET,
  ADMINLOGIN_MANAGE_USER_RESET,
  GET_MENUS_RESET,
} from '../../redux/constants/userConstants';
import { GET_CLINIC_PROVIDERS_RESET, GET_CLINICS_RESET } from '../../redux/constants/clinicsConstants';

import './HomeScreen.css';
import EditConfirmation from './Modals/EditConfirmation';

const HomeScreen = ({ history }) => {
  const dispatch = useDispatch();

  // Manage Users Basic
  // const [userIndex, setUserIndex] = useState(1);
  const [usersList, setUsersList] = useState([]);

  const [totalPageSize, setTotalPageSize] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const pageLimit = 10;

  let pageNo = totalPageSize / pageLimit;

  const [showFilter, setShowFilter] = useState(true);
  const [filter, setFilter] = useState({
    provider: '0',
    clinic: '0',
    role: 'All',
    confirmed: 'All',
  });
  const [textInputs, setTextInputs] = useState({
    userId: '',
    email: '',
    fName: '',
    lName: '',
    dob: '',
    assessments: '',
  });

  // Toggle User Active
  const [userId, setUserId] = useState('');
  const [Highestrole, setRole] = useState('');
  const [isDeleted, setIsDeleted] = useState(false);
  const [modalActiveState, setModalActiveState] = useState(false);

  //Login as Admin
  const [modalLoginAdminState, setmodalLoginAdminState] = useState(false);
  const [loginAsUserData, setLoginAsUserData] = useState('');

  // Edit User
  const [modalEditState, setModalEditState] = useState(false);
  const [updateUser, setupdateUser] = useState({
    userId: '',
    fName: '',
    lName: '',
    email: '',
    error: '',
    birthDate: '',
    birthDateerror: '',
    clinic: '',
    clinicError: '',
    provider: '',
    providerError: '',
  });
  const [updateUserConfirm, setupdateUserConfirm] = useState({ status: false, newClinic: '', oldClinic: '', newProvider: '', oldProvider: '' });

  //Reset Password
  const [modalResetState, setModalResetState] = useState(false);
  const [resetPassword, setResetPassword] = useState({
    newPassword: '',
    confirmPassword: '',
    newError: '',
    confirmError: '',
  });

  // Delete User
  const [modalDeleteState, setmodalDeleteState] = useState(false);
  const [deleteUserData, setDeleteUserData] = useState('');
  const [deleteEmail, setDeleteEmail] = useState({
    value: '',
    error: '',
  });

  const userLogin = useSelector(state => state.userLogin);
  const adminLogin = useSelector(state => state.adminLogin);
  const manageUsers = useSelector(state => state.manageUsers);
  const deleteManageUser = useSelector(state => state.deleteManageUser);
  const clinicProviders = useSelector(state => state.clinicProviders);
  const allClinics = useSelector(state => state.allClinics);
  const updatemanageUser = useSelector(state => state.updatemanageuser);
  const activatemanageUser = useSelector(state => state.activatemanageUser);
  const passwordData = useSelector(state => state.passwordData);

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    const tokenInfo = localStorage.getItem('token');
    const clientIdInfo = localStorage.getItem('clientId');
    const getMenusInfo = localStorage.getItem('getMenus');

    if (userInfo === 'undefined' || userInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push('/login');
      dispatch(logout());
    } else if (tokenInfo === 'undefined' || tokenInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push('/login');
      dispatch(logout());
    } else if (clientIdInfo === 'undefined' || clientIdInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push('/login');
      dispatch(logout());
    } else if (getMenusInfo === 'undefined' || getMenusInfo === undefined) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('token');
      localStorage.removeItem('clientId');
      localStorage.removeItem('clientUrl');
      localStorage.removeItem('getMenus');
      history.push('/login');
      dispatch(logout());
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (userLogin && userLogin.userInfo && !userLogin.userInfo.token) {
      dispatch(logout());
      history.push('/login');
    } else if (!localStorage.getItem('userInfo')) {
      dispatch(logout());
      history.push('/login');
    } else if (
      userLogin &&
      userLogin.userInfo &&
      userLogin.userInfo.issuccess &&
      userLogin.userInfo.issuperadmin &&
      !userLogin.userInfo.isclinicadmin &&
      !userLogin.userInfo.isprovider &&
      !userLogin.userInfo.ispatient
    ) {
      getManageUsers(pageNumber);
      dispatch(clinicProvidersAction());
      dispatch(getAllClinicsAction());
    }
  }, [userLogin, history, dispatch]);

  const getManageUsers = selectedPage => {
    let data = {};
    if (filter.provider) {
      data.providerId = filter.provider;
    }
    if (filter.clinic) {
      data.clinicId = filter.clinic;
    }
    if (filter.role) {
      data.role = filter.role;
    }
    if (filter.confirmed) {
      data.emailConf = filter.confirmed;
    }
    if (textInputs.email) {
      data.Email = textInputs.email;
    }
    if (textInputs.fName) {
      data.FirstName = textInputs.fName;
    }
    if (textInputs.lName) {
      data.LastName = textInputs.lName;
    }
    if (textInputs.dob) {
      data.BirthDate = textInputs.dob;
    }
    if (textInputs.assessments) {
      data.recentAssessments = textInputs.assessments;
    }
    data.pageNumber = selectedPage;
    data.rowsOfPage = pageLimit;
    dispatch(managerUserAction(true, data));
  };

  useEffect(() => {
    if (
      adminLogin &&
      adminLogin.userInfo &&
      adminLogin.userInfo.token &&
      adminLogin.userInfo.applicationUser &&
      !adminLogin.userInfo.applicationUser.isClinicAdmin &&
      !adminLogin.userInfo.applicationUser.isClinicProvider &&
      adminLogin.userInfo.applicationUser.isPatient
    ) {
      history.push('/patient/questionaire');
    } else if (
      adminLogin &&
      adminLogin.userInfo &&
      adminLogin.userInfo.token &&
      adminLogin.userInfo.applicationUser &&
      (adminLogin.userInfo.applicationUser.isClinicProvider || adminLogin.userInfo.applicationUser.isClinicAdmin) &&
      !adminLogin.userInfo.applicationUser.isPatient
    ) {
      dispatch({ type: GET_MENUS_RESET });
      dispatch(getMenusAction());
      history.push('/clinic/home');
    } else if (adminLogin && adminLogin.error) {
      setmodalLoginAdminState(false);
      toast.error(adminLogin.error);
    }
  }, [adminLogin, history, dispatch]);

  useEffect(() => {
    if (manageUsers && manageUsers.data && manageUsers.data.length > 0) {
      setUsersList(manageUsers.data);
      setTotalPageSize(manageUsers.data[0].totalCount);
    } else if (manageUsers && manageUsers.error && manageUsers.error.status === 501) {
      dispatch(logout());
      history.push('/login');
    }
  }, [manageUsers, dispatch, history]);

  //Active User Toggle
  useEffect(() => {
    if (activatemanageUser && activatemanageUser.data && activatemanageUser.data.message === 'Success') {
      getManageUsers(pageNumber);
      toast.success(activatemanageUser.data.message);
      dispatch({ type: ACTIVATE_MANAGE_USER_RESET });
      setModalActiveState(false);
      setUserId('');
      setIsDeleted(false);
    }
  }, [activatemanageUser, dispatch]);

  //Login as Admin
  // useEffect(
  // 	() => {
  // 		if (adminLogin && adminLogin.data && adminLogin.data.message === 'Success') {
  // 			dispatch({ type: ADMINLOGIN_MANAGE_USER_RESET });
  // 			setmodalLoginAdminState(false);
  // 		}
  // 	},
  // 	[ adminLogin, dispatch ]
  // );

  //Reset Password
  useEffect(() => {
    if (passwordData && passwordData.userInfo && passwordData.userInfo.succeeded === true) {
      getManageUsers(pageNumber);
      dispatch({ type: RESETPASSWORDS_RESET });
      setModalResetState(false);
      toast.success('Password changed Successfully');
    } else if (passwordData && passwordData.error !== '') {
      toast.error(passwordData.error);
    }
  }, [passwordData, dispatch]);


  const uploadAssessment = React.useCallback(async () => {
    if (updatemanageUser && updatemanageUser.data && updatemanageUser.data.statusCode === true) {
      let data = dispatch(GetSFTPGenerateByPatientIdPDFAction(updateUser.userId));
      console.log(data, 'data', userId);
      if (data && data.data && data.data.status) {
        getManageUsers(pageNumber);
        dispatch({ type: UPDATE_MANAGE_USER_RESET });
        setModalEditState(false);
        setupdateUser({
          ...updateUser,
          userId: '',
          fName: '',
          lName: '',
          email: '',
          error: '',
          birthDate: '',
          birthDateerror: '',
          clinic: '',
          clinicError: '',
          provider: '',
          providerError: '',
        });
        setupdateUserConfirm({ status: false, newClinic: '', oldClinic: '', newProvider: '', oldProvider: '' });
        toast.success(updatemanageUser.data.message);
      } else {
        getManageUsers(pageNumber);
        dispatch({ type: UPDATE_MANAGE_USER_RESET });
        setModalEditState(false);
        setupdateUser({
          ...updateUser,
          userId: '',
          fName: '',
          lName: '',
          email: '',
          error: '',
          birthDate: '',
          birthDateerror: '',
          clinic: '',
          clinicError: '',
          provider: '',
          providerError: '',
        });
        setupdateUserConfirm({ status: false, newClinic: '', oldClinic: '', newProvider: '', oldProvider: '' });
        toast.success(updatemanageUser.data.message);
      }
    }
  }, [updatemanageUser, dispatch, userId]
  );


  // Update User Details
  useEffect(() => {
    if (updatemanageUser && updatemanageUser.data && updatemanageUser.data.statusCode === true) {
      uploadAssessment();
    }
  }, [updatemanageUser, uploadAssessment]);

  //Delete User
  useEffect(() => {
    if (deleteManageUser && deleteManageUser.data && deleteManageUser.data.success === true) {
      setmodalDeleteState(false);
      setDeleteEmail({ value: '', error: '' });
      setDeleteUserData('');
      getManageUsers(pageNumber);
      dispatch({ type: DELETE_MANAGE_USER_RESET });
      toast.success(deleteManageUser.data.message);

      setFilter({
        provider: '0',
        clinic: '0',
        role: 'All',
        confirmed: 'All',
      });

      setTextInputs({
        email: '',
        fName: '',
        lName: '',
        dob: '',
        assessments: '',
      });
    } else if (deleteManageUser && deleteManageUser.error !== '') {
      setmodalDeleteState(false);
      toast.error(deleteManageUser.error);
    }
  }, [deleteManageUser, dispatch]);

  useEffect(() => {
    return () => {
      dispatch({ type: MANAGE_USER_RESET });
      dispatch({ type: GET_CLINIC_PROVIDERS_RESET });
      dispatch({ type: GET_CLINICS_RESET });
      dispatch({ type: DELETE_MANAGE_USER_RESET });
      dispatch({ type: ADMINLOGIN_MANAGE_USER_RESET });
    };
  }, [dispatch]);

  const handlePageChange = async currentPage => {
    const selectedPage = currentPage.selected;
    getManageUsers(selectedPage + 1);
    setPageNumber(selectedPage + 1);
  };

  const handleClear = () => {
    setPageNumber(1);
    setFilter({
      provider: '0',
      clinic: '0',
      role: 'All',
      confirmed: 'All',
    });
    setTextInputs({
      email: '',
      fName: '',
      lName: '',
      dob: '',
      assessments: '',
    });

    dispatch(managerUserAction(true, { pageNumber: 1, rowsOfPage: pageLimit }));
  };

  const handleFilterSubmit = e => {
    //e.preventDefault();
    if (
      filter.provider === '0' &&
      filter.clinic === '0' &&
      filter.role === 'All' &&
      filter.confirmed === 'All' &&
      textInputs.email.trim() === '' &&
      textInputs.fName.trim() === '' &&
      textInputs.lName.trim() === '' &&
      textInputs.dob.trim() === '' &&
      textInputs.assessments.trim() === ''
    ) {
      alert('Enter filter details');
    } else {
      setUsersList([]);
      const isFilter = true;
      setPageNumber(1);
      const data = {
        providerId: filter.provider,
        clinicId: filter.clinic,
        role: filter.role,
        emailConf: filter.confirmed,
        Email: textInputs.email,
        FirstName: textInputs.fName,
        LastName: textInputs.lName,
        BirthDate: textInputs.dob,
        recentAssessments: textInputs.assessments,
        pageNumber: 1,
        rowsOfPage: pageLimit,
      };
      dispatch(managerUserAction(isFilter, data));
    }
  };
  const onActive = () => {
    if (userId !== '') {
      dispatch(activatemanageUserAction(userId, isDeleted));
    }
  };
  const editEmailHandler = () => {
    if (!updateUser.email.trim() || updateUser.email.trim() === '') {
      setupdateUser({ ...updateUser, error: 'Please enter an email address' });
    } else if (!validateEmail(updateUser.email)) {
      setupdateUser({ ...updateUser, error: 'Please enter a valid email address' });
    } else if (updateUser.birthDate == null || updateUser.birthDate == undefined || updateUser.birthDate == '') {
      setupdateUser({ ...updateUser, birthDateerror: 'Please enter date of birth' });
    } else if (getDayDiff(updateUser.birthDate) < 365) {
      setupdateUser({
        ...updateUser,
        birthDateerror:
          'It seems you’ve entered your date of birth as a day within the last year. Please ensure that you enter your birthday correctly before proceeding.',
      });
    } else if (updateUser.clinic === '') {
      setupdateUser({ ...updateUser, clinicError: 'Please select clinic' });
    } else if (updateUser.provider === '') {
      setupdateUser({ ...updateUser, providerError: 'Please select provider' });
    } else {
      if (updateUserConfirm.oldClinic !== updateUserConfirm.newClinic) {
        setupdateUserConfirm(prevVal => ({ ...prevVal, status: true }));
      } else {
        handleEditUserConfirmation();
      }
    }
  };

  const adminLoginHandler = e => {
    //e.preventDefault();
    dispatch(adminLoginAction(loginAsUserData.patientEmail));
    // dispatch({
    // 	type: HIGHEST_ROLE_SUCCESS,
    // 	payload: Highestrole
    // });
  };

  const handleReset = e => {
    //e.preventDefault();
    if (!resetPassword.newPassword.trim() || resetPassword.newPassword.trim() === '') {
      setResetPassword({ ...resetPassword, newError: 'please enter a password' });
    } else if (!resetPassword.confirmPassword.trim() || resetPassword.confirmPassword.trim() === '') {
      setResetPassword({ ...resetPassword, confirmError: 'please enter a password' });
    } else if (resetPassword.newPassword !== resetPassword.confirmPassword) {
      setResetPassword({ ...resetPassword, confirmError: 'Password does not match' });
    } else {
      const formData = {
        password: resetPassword.newPassword,
        userName: deleteUserData.patientEmail,
      };
      dispatch(passwordResetAction(formData));
    }
  };

  const onDeleteHandler = e => {
    //e.preventDefault();
    if (!deleteEmail.value.trim() || deleteEmail.value.trim() === '') {
      setDeleteEmail({ ...deleteEmail, error: 'Please enter email address' });
    } else if (!validateEmail(deleteEmail.value)) {
      setDeleteEmail({ ...deleteEmail, error: 'Please enter valid email address' });
    } else if (deleteUserData && deleteUserData.patientEmail !== deleteEmail.value) {
      setDeleteEmail({ ...deleteEmail, error: `Please enter ${deleteUserData.patientEmail} in above text box` });
    } else {
      const formData = {
        clinicId: deleteUserData.clinicId,
        userId: deleteUserData.applicationUserId,
      };
      dispatch(deleteManageUserAction(formData));
    }
  };

  const onEditClose = () => {
    setModalEditState(false);
    setupdateUser({ ...updateUser, error: '' });
    setModalActiveState(false);
    setModalResetState(false);
    setmodalLoginAdminState(false);
    setUserId('');
    setIsDeleted(false);
    setupdateUserConfirm({ status: false, newClinic: '', oldClinic: '', newProvider: '', oldProvider: '' });
  };

  const onDeleteModalClose = () => {
    setmodalDeleteState(false);
    setDeleteUserData('');
    setDeleteEmail({ value: '', error: '' });
  };

  const closeConfirmationModal = () => {
    setupdateUserConfirm(prevVal => ({ ...prevVal, status: false }));
  };

  const handleEditUserConfirmation = () => {
    const formData = {
      userId: updateUser.userId,
      email: updateUser.email,
      firstName: updateUser.fName,
      lastName: updateUser.lName,
      birthDate: updateUser.birthDate,
      clinicId: updateUser.clinic,
      providerId: updateUser.provider,
    };
    dispatch(updatemanageUserAction(formData));
  };

  return (
    <Content currentMenu='Manage Users' headerTitle='Manage Users'>
      <LoginAs
        data={{
          modalLoginAdminState,
          loginAsUserData,
          setLoginAsUserData,
          onEditClose,
          adminLoginHandler,
          adminLogin,
          Highestrole,
        }}
      />
      <EditModal
        data={{ modalEditState, updateUser, setupdateUser, onEditClose, editEmailHandler, updatemanageUser, clinicProviders, allClinics, setupdateUserConfirm }}
      />
      <EditConfirmation
        showModal={updateUserConfirm.status}
        currentClinic={updateUserConfirm.newClinic}
        prevClinic={updateUserConfirm.oldClinic}
        onConfirm={handleEditUserConfirmation}
        onClose={closeConfirmationModal}
      />
      <UserToggleModal data={{ modalActiveState, onEditClose, onActive, activatemanageUser }} />
      <ResetPassword data={{ modalResetState, onEditClose, handleReset, manageUsers, resetPassword, setResetPassword }} />
      <DeleteUser
        data={{
          modalDeleteState,
          deleteUserData,
          deleteEmail,
          setDeleteEmail,
          onDeleteModalClose,
          onDeleteHandler,
          deleteManageUser,
        }}
      />
      <FilterUsers
        data={{
          showFilter,
          setShowFilter,
          clinicProviders,
          allClinics,
          filter,
          setFilter,
          textInputs,
          setTextInputs,
          handleFilterSubmit,
          handleClear,
        }}
      />
      <ShowData
        data={{
          setModalEditState,
          setModalActiveState,
          setModalResetState,
          setmodalDeleteState,
          setUserId,
          setIsDeleted,
          setDeleteUserData,
          setmodalLoginAdminState,
          setLoginAsUserData,
          setupdateUser,
          updateUser,
          setupdateUserConfirm,
          setRole,
          pageNumber,
        }}
        showFilter={showFilter}
        usersList={usersList}
        loading={manageUsers && manageUsers.loading}
      />

      {manageUsers && manageUsers.data && manageUsers.data[0] && manageUsers.data[0].totalCount > 10 && (
        <div className='tableContainer--paginater'>
          <ReactPaginate
            nextClassName={''}
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={pageNo}
            onPageChange={handlePageChange}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'paginationActive'}
            forcePage={pageNumber - 1}
          />
        </div>
      )}
    </Content>
  );
};

export default HomeScreen;
