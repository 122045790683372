import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeConsentFormStatusAction,
  getConsentFormsAction,
  uploadConsentFormAction,
} from '../../redux/actions/providerActions';

import Content from '../../components/Content';
import ContestForms from '../../components/Skeletons/ConsentForms';
import styles from './consentForm.module.css';
import PDFDetailsModal from './PDFDetailsModal'; 
import { CHANGE_CONSENT_DOCUMENTS_STATUS_RESET } from '../../redux/constants/consentConstants';
import { toast } from 'react-toastify';

function ConsentForms() {
  const dispatch = useDispatch();

  const [consentFiles, setConsentFiles] = React.useState({
    loading: false,
    data: {},
    error: '',
  });

  const [pdfLargeSizeModal, setpdfLargeSizeModal] = React.useState({
    status: false,
    pdfLink: '',
    title: '',
  });


  const [consentFormFile, setConsentFormFile] = React.useState({
    file: null,
    error: '',
    savingData: false,
  });

  const [tncFile, setTncFile] = React.useState({
    file: null,
    error: '',
    savingData: false,
  });

  const [aditionalConsentFormFile, setAdditionalConsentFormFile] = React.useState({
    file: null,
    error: '',
    savingData: false,
  });
 
	const chaneConsentFormStatus = useSelector((state) => state.chaneConsentFormStatus);

  const getData = React.useCallback(async () => {
    setConsentFiles((oldData) => ({ ...oldData, loading: true }));
    const data = await dispatch(getConsentFormsAction());
    if (data && data.status) {
      setConsentFiles({ data: data.data, loading: false, error: '' });
    } else {
      setConsentFiles({
        data: {},
        loading: false,
        error: 'Something went wrong while fetching data.',
      });
    }
  }, [dispatch]);

  React.useEffect(() => {
    getData();
  }, [getData]);


  React.useEffect(
		() => {
			if (chaneConsentFormStatus && chaneConsentFormStatus.data && chaneConsentFormStatus.data.status) { 
				toast.success(chaneConsentFormStatus.data.message);
				dispatch({ type: CHANGE_CONSENT_DOCUMENTS_STATUS_RESET });
			  getData();
			} else if (chaneConsentFormStatus && chaneConsentFormStatus.data && !chaneConsentFormStatus.data.status) {
				dispatch({ type: CHANGE_CONSENT_DOCUMENTS_STATUS_RESET });
				toast.error(chaneConsentFormStatus.data.message);
			}
		},
		[chaneConsentFormStatus, dispatch]
	);


  const handleUpload = async (type) => {
    try {
      const formData = new FormData();
      formData.append('FormType', type);

      if (type === 'informed_consent') {
        if (!consentFormFile.file) {
          setConsentFormFile((oldData) => ({
            ...oldData,
            error: 'Please select file',
            savingData: true,
          }));
          return
        } else {
          formData.append('files', consentFormFile.file);
        }

      } else if (type === 'terms_of_use') {
        if (!tncFile.file) {
          setTncFile((oldData) => ({
            ...oldData,
            error: 'Please select file',
            savingData: true,
          }));
          return
        } else {
          formData.append('files', tncFile.file);
        }
      } else if (type === 'additional_consent') {
        if (!aditionalConsentFormFile.file) {
          setAdditionalConsentFormFile((oldData) => ({
            ...oldData,
            error: 'Please select file',
            savingData: true,
          }));
          return
        } else {
          formData.append('files', aditionalConsentFormFile.file);
        }
      }
      let data = await dispatch(uploadConsentFormAction(formData));
      if (data && data.status) {
        setConsentFormFile({ file: null, error: '', savingData: false });
        setTncFile({ file: null, error: '', savingData: false });
        setAdditionalConsentFormFile({ file: null, error: '', savingData: false });
        getData();
      }
    } catch (error) {
      alert('Something went wrong while uploading file');
    }
  }


  const onChangeHandler = (d,type) => {
    dispatch(
      changeConsentFormStatusAction({
        id: d.id,
        enable: d.enable ? false : true,
        FormType:type
      })
    );
  };

  return (
    <Content
      currentMenu='consent-forms'
      headerTitle='Consent Forms'
      addBtn={false}
    >
      <div className={styles.content}>
        {consentFiles.loading ? (
          <ContestForms />
        ) : consentFiles.error ? (
          <p className={styles.error_data}>{consentFiles.error}</p>
        ) : (
          consentFiles.data &&
          <React.Fragment>
            <div className={styles.card}>
              <h3 className={styles.card_heading}>Informed Consent</h3>
              <div className={styles.card_content}>
                <div
                  className={styles.card_upload}
                  style={{ alignSelf: 'center' }}
                >
                  <p className={styles.card_upload_title}>
                    Want to upload Informed Consent?
                  </p>
                  <div className={styles.card_upload_input}>
                    <label htmlFor='upload-informed_consent'>Choose PDF document</label>
                    <input
                      id='upload-informed_consent'
                      type='file'
                      accept='.pdf'
                      onChange={(e) => {
                        if (
                          e.target.files.length > 0 &&
                          e.target.files[0].type === 'application/pdf'
                        ) {
                          setConsentFormFile({
                            file: e.target.files[0],
                            error: '',
                            savingData: false,
                          });
                        } else {
                          setConsentFormFile({
                            file: null,
                            error: 'Please upload a PDF file',
                            savingData: false,
                          });
                        }
                      }}
                    />

                    {consentFormFile && consentFormFile.error && (
                      <p className={styles.card_upload_error}>
                        {consentFormFile.error}
                      </p>
                    )}

                  </div>

                  <button
                    className={styles.card_upload_btn}
                    disabled={
                      !consentFormFile.file ||
                      consentFormFile.error
                    }
                    onClick={() => { handleUpload("informed_consent") }}
                  >
                    {consentFormFile.savingData ? 'Uploading Data' : 'Upload'}
                  </button>

                </div>

                <div
                  className={styles.card_line}
                  style={{
                    height: '20rem'
                  }}
                />

                {
                  consentFiles.data.length > 0 &&
                  consentFiles.data.map((item, index) => (
                    item.formType === "informed_consent" &&
                    <React.Fragment>
                      {index > 1 && <div
                        className={styles.card_line}
                        style={{
                          height: '20rem'
                        }}
                      />}
                      <div className={styles.card_default}>
                        <div
                          className={styles.card_pdf}
                          style={{
                            opacity: item.status
                              ? '100%'
                              : '50%',
                          }}
                        >
                          <div className={styles.pdf_container}>
                            <iframe
                              src={item.fileURL}
                              type='application/pdf'
                              className={styles.card_pdf_frame}
                              title='suremed-tnc'
                            />

                            {item.status && (
                              <div className={styles.card_pdf_default_text2}>
                                <p>Currently showing</p>
                              </div>
                            )}

                          </div>
                          <div className={styles.card_pdf_content}>
                            <div>
                              <p>{item.documentName}</p>
                              <p>Document version: {item.version}</p>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  setpdfLargeSizeModal({
                                    status: true,
                                    pdfLink: item.fileURL,
                                    title: 'Sure Med Informed Consent',
                                  })
                                }
                              >
                                View PDF in large size
                              </button>
                              <div style={{
                                textAlign: "center",
                                display: "flex",
                                alignContent: "center",
                                marginTop: "20px"
                              }}>
                                <label className="switch">
                                  <input
                                    id="active"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={item.status}
                                    onChange={() => {
                                      onChangeHandler(item,"informed_consent");
                                    }} 
                                    name="active"
                                  />
                                  <span className="slider round" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>

            <div className={styles.card}>
              <h3 className={styles.card_heading}>
                Privacy Notice & Terms of Use
              </h3>
              <div className={styles.card_content}>
                {/* <div
                  className={styles.card_upload}
                  style={{ alignSelf: 'center' }}
                >
                  <p className={styles.card_upload_title}>
                    Want to upload Privacy Notice & Terms of Use?
                  </p>
                  <div className={styles.card_upload_input}>
                    <label htmlFor='upload-tnc'>Choose PDF document</label>
                    <input
                      id='upload-tnc'
                      type='file'
                      accept='.pdf'
                      onChange={(e) => {
                        if (
                          e.target.files.length > 0 &&
                          e.target.files[0].type === 'application/pdf'
                        ) {
                          setTncFile({
                            file: e.target.files[0],
                            error: '',
                            savingData: false,
                          });
                        } else {
                          setTncFile({
                            file: null,
                            error: 'Please upload a PDF file',
                            savingData: false,
                          });
                        }
                      }}
                    />
                    {tncFile.error && (
                      <p className={styles.card_upload_error}>
                        {tncFile.error}
                      </p>
                    )}
                  </div>
                  <button
                    className={styles.card_upload_btn}
                    disabled={
                      !tncFile.file ||
                      tncFile.error
                    }
                    onClick={() => { handleUpload("terms_of_use") }}
                  >
                    {tncFile.savingData ? 'Uploading Data' : 'Upload'}
                  </button>
                </div>

                <div
                  className={styles.card_line}
                  style={{
                    height: '20rem'
                  }}
                /> */}

                {
                  consentFiles.data.length > 0 &&
                  consentFiles.data.map((item, index) => (
                    item.formType === "terms_of_use" &&
                    <React.Fragment>
                      {index > 1 && <div
                        className={styles.card_line}
                        style={{
                          height: '20rem'
                        }}
                      />}
                      <div className={styles.card_default}>
                        <div
                          className={styles.card_pdf}
                          style={{
                            opacity: item.status
                              ? '100%'
                              : '50%',
                          }}
                        >
                          <div className={styles.pdf_container}>
                            <iframe
                              src={item.fileURL}
                              type='application/pdf'
                              className={styles.card_pdf_frame}
                              title='suremed-tnc'
                            />

                            {item.status && (
                              <div className={styles.card_pdf_default_text2}>
                                <p>Currently showing</p>
                              </div>
                            )}

                          </div>
                          <div className={styles.card_pdf_content}>
                            <div>
                              <p>{item.documentName}</p>
                              <p>Document version: {item.version}</p>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  setpdfLargeSizeModal({
                                    status: true,
                                    pdfLink: item.fileURL,
                                    title: 'Sure Med Informed Consent',
                                  })
                                }
                              >
                                View PDF in large size
                              </button>
                              {/* <div style={{
                                textAlign: "center",
                                display: "flex",
                                alignContent: "center",
                                marginTop: "20px"
                              }}>
                                <label className="switch">
                                  <input
                                    id="active"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={item.status}
                                    onChange={() => {
                                      onChangeHandler(item,"terms_of_use");
                                    }} 
                                    name="active"
                                  />
                                  <span className="slider round" />
                                </label>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>

            <div className={styles.card}>
              <h3 className={styles.card_heading}>
                Additional Consent Form
              </h3>
              <div className={styles.card_content}>
                <div
                  className={styles.card_upload}
                  style={{ alignSelf: 'center' }}
                >
                  <p className={styles.card_upload_title}>
                    Want to upload Additional Consent Form?
                  </p>
                  <div className={styles.card_upload_input}>
                    <label htmlFor='upload-additional_consent'>Choose PDF document</label>
                    <input
                      id='upload-additional_consent'
                      type='file'
                      accept='.pdf'
                      onChange={(e) => {
                        if (
                          e.target.files.length > 0 &&
                          e.target.files[0].type === 'application/pdf'
                        ) {
                          setAdditionalConsentFormFile({
                            file: e.target.files[0],
                            error: '',
                            savingData: false,
                          });
                        } else {
                          setAdditionalConsentFormFile({
                            file: null,
                            error: 'Please upload a PDF file',
                            savingData: false,
                          });
                        }
                      }}
                    />
                    {aditionalConsentFormFile.error && (
                      <p className={styles.card_upload_error}>
                        {aditionalConsentFormFile.error}
                      </p>
                    )}
                  </div>
                  <button
                    className={styles.card_upload_btn}
                    disabled={
                      !aditionalConsentFormFile.file ||
                      aditionalConsentFormFile.error
                    }
                    onClick={() => { handleUpload("additional_consent") }}
                  >
                    {aditionalConsentFormFile.savingData ? 'Uploading Data' : 'Upload'}
                  </button>
                </div>

                <div
                  className={styles.card_line}
                  style={{
                    height: '20rem'
                  }}
                />

                {
                  consentFiles.data.length > 0 &&
                  consentFiles.data.map((item, index) => (
                    item.formType === "additional_consent" &&
                    <React.Fragment>
                      {index > 1 && <div
                        className={styles.card_line}
                        style={{
                          height: '20rem'
                        }}
                      />}
                      <div className={styles.card_default}>
                        <div
                          className={styles.card_pdf}
                          style={{
                            opacity: item.status
                              ? '100%'
                              : '50%',
                          }}
                        >
                          <div className={styles.pdf_container}>
                            <iframe
                              src={item.fileURL}
                              type='application/pdf'
                              className={styles.card_pdf_frame}
                              title='suremed-tnc'
                            />

                            {item.status && (
                              <div className={styles.card_pdf_default_text2}>
                                <p>Currently showing</p>
                              </div>
                            )}

                          </div>
                          <div className={styles.card_pdf_content}>
                            <div>
                              <p>{item.documentName}</p>
                              <p>Document version: {item.version}</p>
                            </div>
                            <div>
                              <button
                                onClick={() =>
                                  setpdfLargeSizeModal({
                                    status: true,
                                    pdfLink: item.fileURL,
                                    title: 'Sure Med Informed Consent',
                                  })
                                }
                              >
                                View PDF in large size
                              </button>
                              <div style={{
                                textAlign: "center",
                                display: "flex",
                                alignContent: "center",
                                marginTop: "20px"
                              }}>
                                <label className="switch">
                                  <input
                                    id="active"
                                    type="checkbox"
                                    className="checkbox"
                                    checked={item.status}
                                    onChange={() => {
                                      onChangeHandler(item,"additional_consent");
                                    }} 
                                    name="active"
                                  />
                                  <span className="slider round" />
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))
                }
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      {pdfLargeSizeModal.status && (
        <PDFDetailsModal
          data={pdfLargeSizeModal}
          onClose={() =>
            setpdfLargeSizeModal({ status: false, pdfLink: '', title: '' })
          }
        />
      )}
    </Content>
  );
}
export default ConsentForms;

