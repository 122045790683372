import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';

import Content from '../../components/Content';
import FilterClinics from './FilterClinics';
import ShowData from './ShowData';
import ManageClinicsToggle from '../../components/Skeletons/ManageClinicsToggle';

import {
  getAllClinicsAction,
  getClinicProviderById,
  allClinicsToggleAction,
  providerClinicsToggleAction,
  allClinicPillCountToggleAction,
} from '../../redux/actions/clinicActions';
import './ManageClinics.css';
import {
  ALL_CLINICS_TOGGLE_RESET,
  PROVIDER_CLINICS_TOGGLE_RESET,
} from '../../redux/constants/clinicsConstants';

const ManageClinics = ({ history }) => {
  const dispatch = useDispatch();
  const [ selectedClinic, setSelectedClinic ] = useState('');
  const [ clinicEnabled, setClinicEnabled ] = useState(false);
  const [ modalActiveState, setModalActiveState ] = useState(false);
  const [ pillCountModal, setPillCountModal ] = useState({
    loading: false,
    status: false,
    data: {},
  });
  const [ providerActiveState, setProviderActiveState ] = useState(false);

  const [ isDeleted, setIsDeleted ] = useState(false);
  const [ userId, setUserId ] = useState('');

  const [ manageUserList, setManageUsersList ] = useState([]);
  const [ totalmanageusersList, setTotalManageUsersList ] = useState(0);
  const perPageUser = 10;
  const totalPages = totalmanageusersList / perPageUser;

  const userLogin = useSelector((state) => state.userLogin);
  const activeToken = useSelector((state) => state.activeToken);

  const providerById = useSelector((state) => state.providerById);
  const allClinics = useSelector((state) => state.allClinics);
  const allClinicsToggle = useSelector((state) => state.allClinicsToggle);
  const providerClinicsToggle = useSelector(
    (state) => state.providerClinicsToggle
  );

  useEffect(() => {
    if (!activeToken) {
      history.push('/login');
    } else if (!activeToken.token) {
      history.push('/login');
    } else if (
      userLogin &&
      userLogin.userInfo &&
      userLogin.userInfo.applicationUser &&
      userLogin.userInfo.applicationUser.isPatient === true &&
      userLogin.userInfo.applicationUser.isSuperAdmin === false
    ) {
      history.push('/questionaires');
    }
  }, [ activeToken, userLogin, history ]);

  useEffect(() => {
    dispatch(getAllClinicsAction());
  }, [ dispatch ]);

  useEffect(() => {
    if (allClinics && allClinics.data && allClinics.data[0]) {
      setSelectedClinic(allClinics.data[0].clinicId);
      setClinicEnabled(allClinics.data[0].isDeleted);
    }
  }, [ allClinics ]);

  useEffect(() => {
    if (
      allClinics &&
      allClinics.data &&
      allClinics.data.length > 0 &&
      selectedClinic !== ''
    ) {
      dispatch(getClinicProviderById(selectedClinic));
    }
  }, [ dispatch, selectedClinic, allClinics ]);

  useEffect(() => {
    if (providerById && providerById.data && providerById.data.length > 0) {
      setManageUsersList(providerById.data.slice(0, perPageUser));
      setTotalManageUsersList(providerById.data.length);
    } else {
      setManageUsersList([]);
      setTotalManageUsersList(0);
    }
  }, [ providerById ]);

  useEffect(() => {
    if (
      providerClinicsToggle &&
      providerClinicsToggle.data &&
      providerClinicsToggle.data.message === 'Success'
    ) {
      setProviderActiveState(false);
      dispatch({ type: PROVIDER_CLINICS_TOGGLE_RESET });
      dispatch(getAllClinicsAction());
    }
  }, [ providerClinicsToggle, dispatch ]);

  useEffect(() => {
    if (
      allClinicsToggle &&
      allClinicsToggle.data &&
      allClinicsToggle.data.data &&
      allClinicsToggle.data.data.message === 'Success'
    ) {
      setModalActiveState(false);
      dispatch({ type: ALL_CLINICS_TOGGLE_RESET });
      dispatch(getAllClinicsAction());
    }
  }, [ allClinicsToggle, dispatch ]);

  let nextContentFrom = 0;

  const handlePageChange = (currentPage) => {
    nextContentFrom = currentPage.selected * perPageUser;
    const nextContentTo = currentPage.selected * perPageUser + 10;
    setManageUsersList(
      providerById &&
      providerById.data &&
      providerById.data.slice(nextContentFrom, nextContentTo)
    );
  };

  const handleEnableDisableClinic = () => {
    setClinicEnabled(!clinicEnabled);
  };
  const onEditClose = () => {
    setModalActiveState(false);
  };

  const onActive = () => {
    dispatch(allClinicsToggleAction(userId, isDeleted));
    onEditClose();
  };

  const onProviderClose = () => {
    setProviderActiveState(false);
  };
  const onProviderActive = () => {
    // setIsDeleted(true);
    dispatch(providerClinicsToggleAction(userId, isDeleted));

    onProviderClose();
  };

  const onTogglePillCount = async () => {
    setPillCountModal(oldData => ({ ...oldData, loading: true }));
    const res = await dispatch(allClinicPillCountToggleAction(pillCountModal.data.clinicId, pillCountModal.data.isPillCount));

    if (res && res.data && res.data.statusCode) {
      dispatch(getAllClinicsAction());
      closePillCountModal();
    }
  };

  const closePillCountModal = () => { 
    setPillCountModal({ loading: false, status: false, data: {} });
  };

  return (
    <Content currentMenu='Manage Clinics' headerTitle='Manage Clinics'>
      <FilterClinics
        data={{
          allClinics,
          selectedClinic,
          setSelectedClinic,
          clinicEnabled,
          handleEnableDisableClinic,
          setModalActiveState,
          setPillCountModal,
          setUserId,
          setIsDeleted,
        }}
      />
      <Modal show={modalActiveState}>
        {allClinics && allClinics.loading ? (
          <ManageClinicsToggle />
        ) : (
          <Fragment>
            <ModalHeading
              heading='Confirm Clinic Activation'
              onClose={onEditClose}
            />
            <form className='modalContainer__form'>
              <p className='activateToggle--p'>
                Are you sure you want to{' '}
                {isDeleted ? (
                  <span className='activateToggle-span'>Activate</span>
                ) : (
                  <span className='activateToggle-span'>Deactivate</span>
                )}{' '}
                the clinic?
              </p>
            </form>
            <div className='modal__actions'>
              <button
                className='modal__button modal__button--update'
                onClick={onActive}
              >
                Confirm
              </button>
            </div>
          </Fragment>
        )}
      </Modal>

      {/* <Modal show={pillCountModal.status}>
        {pillCountModal && pillCountModal.loading ? (
          <ManageClinicsToggle />
        ) : (
          <Fragment>
            <ModalHeading
              heading='Confirm Clinic Pill Count Activation'
              onClose={closePillCountModal}
            />
            <form className='modalContainer__form'>
              <p className='activateToggle--p'>
                Are you sure you want to{' '}
                {!pillCountModal.data.isPillCount ? (
                  <span className='activateToggle-span'>Activate</span>
                ) : (
                  <span className='activateToggle-span'>Deactivate</span>
                )}{' '}
                the clinic pill count?
              </p>
            </form>
            <div className='modal__actions'>
              <button
                className='modal__button modal__button--update'
                onClick={onTogglePillCount}
              >
                Confirm
              </button>
            </div>
          </Fragment>
        )}
      </Modal> */}

      <Modal show={providerActiveState}>
        {selectedClinic && selectedClinic.loading ? (
          <ManageClinicsToggle />
        ) : (
          <Fragment>
            <ModalHeading
              heading='Confirm provider activation'
              onClose={onProviderClose}
            />

            {/* <h2 className="modal__heading text-red">Confirm Provider Activation?</h2> */}
            <form className='modalContainer__form'>
              {isDeleted ? (
                <p>Are you sure you want to Activate Provider?</p>
              ) : (
                <p>Are you sure you want to Deactivate Provider?</p>
              )}
            </form>
            <div className='modal__actions'>
              {/* <button className="modal__button--cancel" onClick={onProviderClose}>
							Cancel
						</button> */}
              <button
                className='modal__button modal__button--update'
                onClick={onProviderActive}
              >
                Confirm
              </button>
            </div>
          </Fragment>
        )}
      </Modal>

      <ShowData
        loading={providerById && providerById.loading}
        data={{
          nextContentFrom,
          manageUserList,
          setIsDeleted,
          setUserId,
          setProviderActiveState,
        }}
      />

      {providerById && providerById.data && providerById.data.length > 10 && (
        <div className='tableContainer--paginater'>
          <ReactPaginate
            nextClassName={''} //clientList.data.result.length < rowsPerPage && "nextClassName"
            previousLabel={'<'}
            nextLabel={'>'}
            pageCount={totalPages} //clientList.data.totalPageSize / rowsPerPage
            onPageChange={handlePageChange}
            marginPagesDisplayed={1}
            pageRangeDisplayed={5}
            containerClassName={'paginationBttns'}
            previousLinkClassName={'previousBttn'}
            nextLinkClassName={'nextBttn'}
            disabledClassName={'paginationDisabled'}
            activeClassName={'paginationActive'}
          />
        </div>
      )}
    </Content>
  );
};

export default ManageClinics;
