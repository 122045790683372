import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getMenusAction, login } from '../../redux/actions/userActions';
import './LoginScreen.css';
import { validateEmail } from '../../utils/validators';
import { UserLoginSkeleton } from '../../components/Skeletons/userLogin';
import { setUrl } from '../../redux/actions/ip';
import { Helmet } from 'react-helmet';

const LoginScreen = ({ history }) => {
	const dispatch = useDispatch();
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState('');
	const [password, setPassword] = useState('');
	const [currentEye, setCurrentEye] = useState(false);
	const [passwordError, setPasswordError] = useState('');
	const userLogin = useSelector((state) => state.userLogin);

	useEffect(
		() => {
			if (userLogin && userLogin.userInfo) {
				setUrl(userLogin.userInfo.apiurl);
			}
			if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.token &&
				userLogin.userInfo.issuccess &&
				userLogin.userInfo.issuperadmin &&
				!userLogin.userInfo.isclinicadmin &&
				!userLogin.userInfo.isprovider &&
				!userLogin.userInfo.ispatient
			) {
				dispatch(getMenusAction());
				history.push('/dashboard');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.token &&
				userLogin.userInfo.issuccess &&
				userLogin.userInfo.signUpComplete &&
				userLogin.userInfo.ispatient &&
				!userLogin.userInfo.isclinicadmin &&
				!userLogin.userInfo.isprovider
			) {
				dispatch(getMenusAction());
				history.push('/patient/questionaire');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				(userLogin.userInfo.isprovider || userLogin.userInfo.isclinicadmin) &&
				userLogin.userInfo.signUpComplete &&
				userLogin.userInfo.token &&
				userLogin.userInfo.issuccess &&
				!userLogin.userInfo.ispatient
			) {
				dispatch(getMenusAction());
				history.push('/clinic/home');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.token &&
				userLogin.userInfo.issuccess &&
				!userLogin.userInfo.ispatient &&
				userLogin.userInfo.isclinicadmin &&
				(Object.keys(userLogin.userInfo).includes('signUpComplete') && !userLogin.userInfo.signUpComplete)
			) {
				dispatch(getMenusAction());
				history.push(`/clinic-signup/${userLogin.userInfo.email}`);
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.token &&
				userLogin.userInfo.issuccess &&
				!userLogin.userInfo.ispatient &&
				userLogin.userInfo.isclinicadmin
			) {
				dispatch(getMenusAction());
				history.push(`/clinic/home`);
			} else if (userLogin && !userLogin.loading && userLogin.error) {
				setPasswordError(userLogin.error.data);
			} else if (userLogin && userLogin.userInfo && !userLogin.userInfo.issuccess && userLogin.userInfo.message) {
				setPasswordError(userLogin.userInfo.message);
			} else if (userLogin && !userLogin.userInfo && userLogin.loading === false) {
				setPasswordError('Invalid Username or Password');
			}
		},
		[userLogin, dispatch, history]
	);
	const showPassword = () => {
		var x = document.getElementById('password');
		setCurrentEye(!currentEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (!email.trim() || email.trim() === '') {
			setEmail('');
			setEmailError('Please enter your email');
		} else if (!validateEmail(email)) {
			setEmailError('Please enter a valid email');
		} else if (!password || password.trim() === '') {
			setPasswordError('Please enter password');
		} else {
			//ManageClients(email, password)
			dispatch(login(email, password));
		}
	};

	return (
		<main className="loginContainer">
			<Helmet>
				<title>Perspectives in Care – Login</title>
			</Helmet>
			<div className="loginContainer__bg" />
			<div className="loginContainer__box">
				<div className="loginContainer__box--right">
					<img src="/images/logo.png" alt="Suremed Logo" className="loginContainer__box--logo" />
					<h1 className="loginContainer__heading">Welcome Back</h1>
					<h1 className="loginContainer__subheading">Log in to your account</h1>
				</div>
				<div className="loginContainer__box--left">
					<p className='loginContainer__form error_message'>	ATTENTION: Our Help Desk will now be open from Monday-Wednesday, 8am-5pm CT. If you require assistance for an assessment due on Thursday or Friday, please plan ahead to call during the available times. Thank you</p>
					{userLogin && userLogin.loading ? (
						<UserLoginSkeleton />
					) : (
						<form className="loginContainer__form" onSubmit={handleSubmit}>
							<div className="loginContainer__formgroup">
								<label htmlFor="email" className="loginContainer__formgroup--label">
									Email
								</label>
								<div className="loginContainer__formgroup--inputBox">
									<div className="logoContainer__formgroup--iconBox">
										<svg className="loginContainer__formgroup--icon">
											<use xlinkHref="/sprite.svg#icon-email" />
										</svg>
									</div>
									<input
										type="text"
										placeholder="johndoe@example.com"
										id="email"
										autoComplete="off"
										value={email}
										onChange={(e) => {
											setEmailError('');
											setPasswordError('');
											setEmail(e.target.value);
										}}
										className="loginContainer__formgroup--input"
									/>
								</div>
								<p className="error_message">{emailError}</p>
							</div>
							<div className="loginContainer__formgroup">
								<label htmlFor="password" className="loginContainer__formgroup--label">
									Password
								</label>
								<div className="loginContainer__formgroup--inputBox">
									<div className="logoContainer__formgroup--iconBox">
										<svg className="loginContainer__formgroup--icon">
											<use xlinkHref="/sprite.svg#icon-key" />
										</svg>
									</div>
									<input
										type="password"
										autoComplete="new-password"
										placeholder="••••••••••••••••••••"
										id="password"
										value={password}
										onChange={(e) => {
											setPasswordError('');
											setPassword(e.target.value);
										}}
										className="loginContainer__formgroup--input"
									/>

									<svg
										style={{ margin: '1rem' }}
										className="loginContainer__formgroup--icon"
										onClick={showPassword}
									>
										{currentEye ? (
											<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
										) : (
											<use xlinkHref={`/sprite.svg#icon-showPassword`} />
										)}
									</svg>
								</div>
								<p className="loginError error_message">{passwordError}</p>
								<p className="loginError">
									Please note, passwords are case sensitive, and must contain both an uppercase and
									lowercase letter.
								</p>
								<div className="loginContainer__formgroup--forgotPassword">
									<Link
										to="/forgot-password"
										className="loginContainer__formgroup--forgotPasswordBtn"
									>
										Forgot Password?
									</Link>
								</div>
							</div>
							<div className="loginContainer__formgroup--btn">
								{userLogin && userLogin.loading ? (
									<div className="loginContainer__button">
										<span className="loginContainer__button--text">Logging in</span>
										<div className="loginContainer__button--iconBox">
											<svg className="loginContainer__button--icon">
												<use xlinkHref="/sprite.svg#icon-login" />
											</svg>
										</div>
									</div>
								) : (
									<button className="loginContainer__button" onClick={handleSubmit}>
										<span className="loginContainer__button--text">Log in</span>
										<div className="loginContainer__button--iconBox">
											<svg className="loginContainer__button--icon">
												<use xlinkHref="/sprite.svg#icon-login" />
											</svg>
										</div>
									</button>
								)}
							</div>
						</form>
					)}
				</div>
			</div>
		</main>
	);
};

export default LoginScreen;
