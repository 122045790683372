import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { ToggleUserSkeleton } from '../../../components/Skeletons/manageUsers';

const UserToggleModal = ({ data }) => {
	const { modalActiveState, onEditClose, onActive, activatemanageUser } = data;
	
	return (
		<Modal show={modalActiveState}>
			<ModalHeading
			heading='Confirm Activation?' onClose={onEditClose}
			/>
			{activatemanageUser && activatemanageUser.loading ? (
				<ToggleUserSkeleton />
				) : (
			<Fragment>
				<div className='modalContainer__form'>
					<p>Are you sure you want to activate or deactivate user?</p>
				</div>
				<div className='modal__actions'>
					
					<button className='modal__button modal__button--update' onClick={onActive}>
						Confirm
					</button>
				</div>
			</Fragment>
				)}
		</Modal>
	);
};

export default UserToggleModal;
