import React from 'react';
import { Link } from 'react-router-dom';
import TableRows from '../../components/Skeletons/TableRows';

const ShowData = ({ showFilter, usersList, loading, data }) => {
  const {
    setModalEditState,
    setUserId,
    setIsDeleted,
    setModalActiveState,
    setModalResetState,
    setmodalDeleteState,
    setDeleteUserData,
    setmodalLoginAdminState,
    setLoginAsUserData,
    setupdateUser,
    updateUser,
    setupdateUserConfirm,
    setRole,
    pageNumber,
  } = data;
  const newIndex = pageNumber * 10 - 9;
  return (
    <div className='tableContainer' style={{ height: showFilter ? '63vh' : '23vh' }}>
      <table className='table'>
        <thead>
          <tr>
            <th>#</th>
            <th>Provider</th>
            <th>Clinic Name</th>
            <th>Email</th>
            <th>Confirmed</th>
            <th>User F/L Name</th>
            <th>Birth Date</th>
            {/* <th>Device Type</th> */}
            <th style={{ textAlign: 'center' }}>Recent Assessment</th>
            <th />
            <th style={{ textAlign: 'center' }}>
              {' '}
              Previous <br /> Assessments
            </th>
            <th style={{ textAlign: 'center' }}>
              {' '}
              Consent <br /> Forms
            </th>
            <th>Role</th>
            <th>Active</th>
            <th>Options</th>
          </tr>
        </thead>
        <tbody>
          {usersList && !loading ? (
            usersList.length > 0 ? (
              usersList.map((user, index) => (
                <tr key={index + 1}>
                  <td>{newIndex + index}</td>
                  <td style={{ textAlign: 'left' }}> {user.providerName}</td>
                  <td>{user.clinicName}</td>
                  <td>{user.patientEmail}</td>
                  <td className='table__iconBox' style={{ display: 'flex', justifyContent: 'center' }}>
                    {user.emailConf ? (
                      <svg className='table__icon primary-icon'>
                        <use xlinkHref={`/sprite.svg#icon-tick`} />
                      </svg>
                    ) : (
                      <svg className='table__icon red-icon'>
                        <use xlinkHref={`/sprite.svg#icon-cross`} />
                      </svg>
                    )}
                  </td>
                  <td>{user.patientFirstName + ' ' + user.patientLastName}</td>
                  <td>
                    {new Date(user.birthDate).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })}
                  </td>
                  {/* { 
										user.deviceType ?
											<td style={{ textAlign: 'left' }}>
												<span style={{textTransform:"capitalize"}}>
													{user.deviceType}
												</span>
											</td>
											:
											<td style={{ textAlign: 'left' }}>
												<span>
													Web
												</span>
											</td>
									} */}
                  {user.mostRecentAss !== 'None' ? (
                    <td style={{ display: 'flex', alignItems: 'center', whiteSpace: 'normal', maxWidth: '215px' }}>
                      <Link to={`/pdfView/${user.assessmentresid}`} className='table__button2' style={{ marginRight: '0.5rem' }}>
                        <svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
                          <use xlinkHref={`/sprite.svg#icon-pdf`} />
                        </svg>
                        <span>Recent Assessment</span>
                      </Link>
                      <span className='table__iconBox'>{user.mostRecentAss}</span>
                    </td>
                  ) : (
                    <td style={{ display: 'flex', alignItems: 'center' }}></td>
                  )}
                  <td></td>
                  <td>
                    <Link
                      to={`/previous-assessments/${user.applicationUserId}`}
                      //  className='table__btn'
                      className='table__button2'
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      {/* Prev Assessments */}
                      <svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
                        <use xlinkHref={`/sprite.svg#icon-view_doc`} />
                      </svg>
                      <span>Prev Assessment</span>
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/consent-forms-documents/${user.applicationUserId}`}
                      className='table__button2'
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
                        <use xlinkHref={`/sprite.svg#icon-view_doc`} />
                      </svg>
                      <span>Consent Forms</span>
                    </Link>
                  </td>

                  {/* <td>
									<span className='table__iconBox'>
										Ref. No.: {user.assessmentrefno}
										<Link to= {`/pdfView/${user.assessmentresid}`} className='table__button2'>
											<svg className='table__icon primary-icon'>
												<use xlinkHref={`/sprite.svg#icon-pdf`} />
											</svg>
											<span>Download PDF</span>
										</Link>
									</span>
								</td> */}
                  <td>{user.highestrole}</td>
                  <td>
                    <label className='switch'>
                      <input
                        id='active'
                        type='checkbox'
                        className='checkbox'
                        checked={!user.isDeleted}
                        onChange={() => {
                          setModalActiveState(true);
                          setUserId(user.applicationUserId);
                          setIsDeleted(user.isDeleted);
                        }}
                        name='active'
                      />
                      <span className='slider round' />
                    </label>
                  </td>
                  <td>
                    <div className='table__iconBox'>
                      <button
                        className='table__button'
                        onClick={() => {
                          setmodalLoginAdminState(true);
                          setLoginAsUserData(user);
                          setRole(user.highestrole);
                        }}
                      >
                        <svg className='table__button--icon'>
                          <use xlinkHref={`/sprite.svg#icon-login`} />
                        </svg>
                        <span>Login as {user.patientFirstName + ' ' + user.patientLastName}</span>
                      </button>
                      <button
                        className='table__button'
                        onClick={() => {
                          setModalResetState(true);
                          setDeleteUserData(user);
                        }}
                      >
                        <svg className='table__button--icon'>
                          <use xlinkHref={`/sprite.svg#icon-change_password`} />
                        </svg>
                        <span>Change Password</span>
                      </button>

                      <button
                        className='table__button'
                        onClick={() => {
                          setModalEditState(true);
                          setupdateUserConfirm({
                            status: false,
                            newClinic: user.clinicName,
                            oldClinic: user.clinicName,
                            newProvider: user.providerName,
                            oldProvider: user.providerName,
                          });
                          setupdateUser({
                            ...updateUser,
                            userId: user.applicationUserId,
                            fName: user.patientFirstName,
                            lName: user.patientLastName,
                            email: user.patientEmail,
                            clinic: user.clinicId,
                            provider: user.providerId,
                            birthDate:
                              new Date(user.birthDate).getFullYear() +
                              '-' +
                              ('0' + (new Date(user.birthDate).getMonth() + 1)).slice(-2) +
                              '-' +
                              String(new Date(user.birthDate).getDate()).padStart(2, '0'),
                          });
                        }}
                      >
                        <svg className='table__button--icon'>
                          <use xlinkHref={`/sprite.svg#icon-edit`} />
                        </svg>
                        <span>Edit User</span>
                      </button>
                      <button
                        className='table__button table__button--delete'
                        onClick={() => {
                          setmodalDeleteState(true);
                          setDeleteUserData(user);
                        }}
                      >
                        <svg className='table__button--icon-red'>
                          <use xlinkHref={`/sprite.svg#icon-delete`} />
                        </svg>
                        <span>Delete User</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan='14'>No users found</td>
              </tr>
            )
          ) : (
            <TableRows columns='14' />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShowData;
