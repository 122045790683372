import React from 'react'
import InviteUserForm from './InviteUserForm';
import InviteUserForm2 from './InviteUserForm2';
import './RegisterClinic.css'

const InviteUsers = ({ props, inviteEmail }) => {
	const { pagination, setPagination, inviteSubmit, data, setData, setCloneInvite, secondInvite, setSecondInvite } = props;

	const addInvite = (e) => {
		e.preventDefault();
		setSecondInvite(true)
	}

	const deleteInvite = () => {
		setSecondInvite(false)
		setCloneInvite({
			email: '',
			IsAdmin: false,
			IsProvider: false,
			FirstName: '',
			LastName: '',
			npi: '',
			credentials: '',
			gender: '',
			validDeaCert: false
		})
	}
	
	return (
		<>
			<div className='invite-form-container' dt>
				<InviteUserForm
					props={props}
					addInvite={addInvite}
					inviteEmail={inviteEmail}
				/>

				{/* { [...Array(inputForm)].map((_, i) =>
			<InviteUserForm2
			key = {i}
			props = {props}
			inputForm = {inputForm}
			setInputForm = {setInputForm}
			addInvite = {addInvite}
			/>) } */}

				{secondInvite ?
					<InviteUserForm2
						//key = {i}
						props={props}
						deleteInvite={deleteInvite}
					/>
					:
					null
				}
				<div className='registerContainer__formgroup--btn'  >
					<button onClick={() => {
						setPagination(pagination - 1)
						setData({ ...data, email: '' })
					}} className='registerContainer__button--previous'>
						<div className='loginContainer__button--iconBox'>
							<svg className='loginContainer__button--icon'>
								<use xlinkHref='/sprite.svg#icon-previous' />
							</svg>
						</div>
						<span className='loginContainer__button--text'>Previous</span>
					</button>
					<button onClick={inviteSubmit} className='registerContainer__button'>
						<span className='loginContainer__button--text'>Next</span>
						<div className='loginContainer__button--iconBox'>
							<svg className='loginContainer__button--icon'>
								<use xlinkHref='/sprite.svg#icon-next' />
							</svg>
						</div>
					</button>
				</div>
			</div>
		</>

	)
}

export default InviteUsers
