
export const CLIENT_URL = 'CLIENT_URL';
export const CLIENT_NAME = 'CLIENT_NAME';

export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGOUT = 'USER_LOGOUT';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD_RESET';

export const GET_ALL_CLIENT_REQUEST = 'GET_ALL_CLIENT_REQUEST';
export const GET_ALL_CLIENT_SUCCESS = 'GET_ALL_CLIENT_SUCCESS';
export const GET_ALL_CLIENT_FAIL = 'GET_ALL_CLIENT_FAIL';
export const GET_ALL_CLIENT_RESET = 'GET_ALL_CLIENT_RESET';

export const GET_MENUS_REQUEST = 'GET_MENUS_REQUEST';
export const GET_MENUS_SUCCESS = 'GET_MENUS_SUCCESS';
export const GET_MENUS_FAIL = 'GET_MENUS_FAIL';
export const GET_MENUS_RESET = 'GET_MENUS_RESET';

export const CREATE_NEW_USER_REQUEST = 'CREATE_NEW_USER_REQUEST';
export const CREATE_NEW_USER_SUCCESS = 'CREATE_NEW_USER_SUCCESS';
export const CREATE_NEW_USER_FAIL = 'CREATE_NEW_USER_FAIL';
export const CREATE_NEW_USER_RESET = 'CREATE_NEW_USER_RESET';

export const MANAGE_USER_REQUEST = 'MANAGE_USER_REQUEST';
export const MANAGE_USER_SUCCESS = 'MANAGE_USER_SUCESSS';
export const MANAGE_USER_FAIL = 'MANAGE_USER_FAIL';
export const MANAGE_USER_RESET = 'MANAGE_USER_RESET';

export const ADMINLOGIN_MANAGE_USER_REQUEST = 'ADMINLOGIN_MANAGE_USER_REQUEST';
export const ADMINLOGIN_MANAGE_USER_SUCCESS = 'ADMINLOGIN_MANAGE_USER_SUCESSS';
export const ADMINLOGIN_MANAGE_USER_FAIL = 'ADMINLOGIN_MANAGE_USER_FAIL';
export const ADMINLOGIN_MANAGE_USER_RESET = 'ADMINLOGIN_MANAGE_USER_RESET';

export const UPDATE_MANAGE_USER_REQUEST = 'UPDATE_MANAGE_USER_REQUEST';
export const UPDATE_MANAGE_USER_SUCCESS = 'UPDATE_MANAGE_USER_SUCESSS';
export const UPDATE_MANAGE_USER_FAIL = 'UPDATE_MANAGE_USER_FAIL';
export const UPDATE_MANAGE_USER_RESET = 'UPDATE_MANAGE_USER_RESET';

export const DELETE_MANAGE_USER_REQUEST = 'DELETE_MANAGE_USER_REQUEST';
export const DELETE_MANAGE_USER_SUCCESS = 'DELETE_MANAGE_USER_SUCESSS';
export const DELETE_MANAGE_USER_FAIL = 'DELETE_MANAGE_USER_FAIL';
export const DELETE_MANAGE_USER_RESET = 'DELETE_MANAGE_USER_RESET';

export const ACTIVATE_MANAGE_USER_REQUEST = 'ACTIVATE_MANAGE_USER_REQUEST';
export const ACTIVATE_MANAGE_USER_SUCCESS = 'ACTIVATE_MANAGE_USER_SUCESSS';
export const ACTIVATE_MANAGE_USER_FAIL = 'ACTIVATE_MANAGE_USER_FAIL';
export const ACTIVATE_MANAGE_USER_RESET = 'ACTIVATE_MANAGE_USER_RESET';

export const MANAGE_CLINIC_REQUEST = 'MANAGE_CLINIC_REQUEST';
export const MANAGE_CLINIC_SUCCESS = 'MANAGE_CLINIC_SUCESSS';
export const MANAGE_CLINIC_FAIL = 'MANAGE_CLINIC_FAIL';
export const MANAGE_CLINIC_RESET = 'MANAGE_CLINIC_RESET';

export const MANAGE_UNVERIFIED_REQUEST = 'MANAGE_UNVERIFIED_REQUEST';
export const MANAGE_UNVERIFIED_SUCCESS = 'MANAGE_UNVERIFIED_SUCESSS';
export const MANAGE_UNVERIFIED_FAIL = 'MANAGE_UNVERIFIED_FAIL';
export const MANAGE_UNVERIFIED_RESET = 'MANAGE_UNVERIFIED_RESET';

export const PROFILE_REQUEST = 'PROFILE_REQUEST';
export const PROFILE_SUCCESS = 'PROFILE_SUCESSS';
export const PROFILE_FAIL = 'PROFILE_FAIL';
export const PROFILE_RESET = 'PROFILE_RESET';

export const POST_PROFILE_REQUEST = 'POST_PROFILE_REQUEST';
export const POST_PROFILE_SUCCESS = 'POST_PROFILE_SUCESSS';
export const POST_PROFILE_FAIL = 'POST_PROFILE_FAIL';
export const POST_PROFILE_RESET = 'POST_PROFILE_RESET';

export const DEACTIVATE_REQUEST = 'DEACTIVATE_REQUEST';
export const DEACTIVATE_SUCCESS = 'DEACTIVATE_SUCESSS';
export const DEACTIVATE_FAIL = 'DEACTIVATE_FAIL';
export const DEACTIVATE_RESET = 'DEACTIVATE_RESET';

export const RESETPASSWORDS_REQUEST = 'RESETPASSWORDS_REQUEST';
export const RESETPASSWORDS_SUCCESS = 'RESETPASSWORDS_SUCESSS';
export const RESETPASSWORDS_FAIL = 'RESETPASSWORDS_FAIL';
export const RESETPASSWORDS_RESET = 'RESETPASSWORDS_RESET';

export const UNVERIFIED_PASSWORD_REQUEST = 'UNVERIFIED_PASSWORD_REQUEST';
export const UNVERIFIED_PASSWORD_SUCCESS = 'UNVERIFIED_PASSWORD_SUCCESS';
export const UNVERIFIED_PASSWORD_FAIL = 'UNVERIFIED_PASSWORD_FAIL';
export const UNVERIFIED_PASSWORD_RESET = 'UNVERIFIED_PASSWORD_RESET';

export const VERIFY_CLINIC_REQUEST = 'VERIFY_CLINIC_REQUEST';
export const VERIFY_CLINIC_SUCCESS = 'VERIFY_CLINIC_SUCCESS';
export const VERIFY_CLINIC_FAIL = 'VERIFY_CLINIC_FAIL';
export const VERIFY_CLINIC_RESET = 'VERIFY_CLINIC_RESET ';

export const EDIT_UNVERIFIED_USER_REQUEST = 'EDIT_UNVERIFIED_USER_REQUEST';
export const EDIT_UNVERIFIED_USER_SUCCESS = 'EDIT_UNVERIFIED_USER_SUCCESS';
export const EDIT_UNVERIFIED_USER_FAIL = 'EDIT_UNVERIFIED_USER_FAIL';
export const EDIT_UNVERIFIED_USER_RESET = 'EDIT_UNVERIFIED_USER_RESET';

export const ACTIVE_TOKEN_REQUEST = 'ACTIVE_TOKEN_REQUEST';
export const ACTIVE_TOKEN_SUCCESS = 'ACTIVE_TOKEN_SUCCESS';
export const ACTIVE_TOKEN_FAIL = 'ACTIVE_TOKEN_FAIL';
export const ACTIVE_TOKEN_RESET = 'ACTIVE_TOKEN_RESET';

export const ADD_CLIENT_REQUEST = 'ADD_CLIENT_REQUEST';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAIL = 'ADD_CLIENT_FAIL';
export const ADD_CLIENT_RESET = 'ADD_CLIENT_RESET';

export const DELETE_CLIENT_REQUEST = 'DELETE_CLIENT_REQUEST';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAIL = 'DELETE_CLIENT_FAIL';
export const DELETE_CLIENT_RESET = 'DELETE_CLIENT_RESET';

export const EDIT_CLIENT_REQUEST = 'EDIT_CLIENT_REQUEST';
export const EDIT_CLIENT_SUCCESS = 'EDIT_CLIENT_SUCCESS';
export const EDIT_CLIENT_FAIL = 'EDIT_CLIENT_FAIL';
export const EDIT_CLIENT_RESET = 'EDIT_CLIENT_RESET';

export const ACTIVEINACTIVE_CLIENT_REQUEST = 'ACTIVEINACTIVE_CLIENT_REQUEST';
export const ACTIVEINACTIVE_CLIENT_SUCCESS = 'ACTIVEINACTIVE_CLIENT_SUCCESS';
export const ACTIVEINACTIVE_CLIENT_FAIL = 'ACTIVEINACTIVE_CLIENT_FAIL';
export const ACTIVEINACTIVE_CLIENT_RESET = 'ACTIVEINACTIVE_CLIENT_RESET';

export const CONTENTS_REQUEST = 'CONTENTS_REQUEST';
export const CONTENTS_SUCCESS = 'CONTENTS_SUCCESS';
export const CONTENTS_FAIL = 'CONTENTS_FAIL';
export const CONTENTS_RESET = 'CONTENTS_RESET';

export const UPLOAD_HISTORY_REQUEST = 'UPLOAD_HISTORY_REQUEST';
export const UPLOAD_HISTORY_SUCCESS = 'UPLOAD_HISTORY_SUCCESS';
export const UPLOAD_HISTORY_FAIL = 'UPLOAD_HISTORY_FAIL';
export const UPLOAD_HISTORY_RESET = 'UPLOAD_HISTORY_RESET';

export const CONTENT_PDF_REQUEST = 'CONTENT_PDF_REQUEST';
export const CONTENT_PDF_SUCCESS = 'CONTENT_PDF_SUCCESS';
export const CONTENT_PDF_FAIL = 'CONTENT_PDF_FAIL';
export const CONTENT_PDF_RESET = 'CONTENT_PDF_RESET';

export const CONTENT_VIDEO_REQUEST = 'CONTENT_VIDEO_REQUEST';
export const CONTENT_VIDEO_SUCCESS = 'CONTENT_VIDEO_SUCCESS';
export const CONTENT_VIDEO_FAIL = 'CONTENT_VIDEO_FAIL';
export const CONTENT_VIDEO_RESET = 'CONTENT_VIDEO_RESET';

export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL';
export const GET_SCHEDULE_RESET = 'GET_SCHEDULE_RESET';

export const SCHEDULE_MAP_REQUEST = 'SCHEDULE_MAP_REQUEST';
export const SCHEDULE_MAP_SUCCESS = 'SCHEDULE_MAP_SUCCESS';
export const SCHEDULE_MAP_FAIL = 'SCHEDULE_MAP_FAIL';
export const SCHEDULE_MAP_RESET = 'SCHEDULE_MAP_RESET';

export const SAVE_SCHEDULE_REQUEST = 'SAVE_SCHEDULE_REQUEST';
export const SAVE_SCHEDULE_SUCCESS = 'SAVE_SCHEDULE_SUCCESS';
export const SAVE_SCHEDULE_FAIL = 'SAVE_SCHEDULE_FAIL';
export const SAVE_SCHEDULE_RESET = 'SAVE_SCHEDULE_RESET';

export const UPLOAD_CONTENT_REQUEST = 'UPLOAD_CONTENT_REQUEST';
export const UPLOAD_CONTENT_SUCCESS = 'UPLOAD_CONTENT_SUCCESS';
export const UPLOAD_CONTENT_FAIL = 'UPLOAD_CONTENT_FAIL';
export const UPLOAD_CONTENT_RESET = 'UPLOAD_CONTENT_RESET';

export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAIL = 'DELETE_CONTENT_FAIL';
export const DELETE_CONTENT_RESET = 'DELETE_CONTENT_RESET';

export const REGISTER_PATIENT_REQUEST = 'REGISTER_PATIENT_REQUEST';
export const REGISTER_PATIENT_SUCCESS = 'REGISTER_PATIENT_SUCCESS';
export const REGISTER_PATIENT_FAIL = 'REGISTER_PATIENT_FAIL';
export const REGISTER_PATIENT_RESET = 'REGISTER_PATIENT_RESET';


export const VERFIY_PATIENT_REQUEST = 'VERFIY_PATIENT_REQUEST';
export const VERFIY_PATIENT_SUCCESS = 'VERFIY_PATIENT_SUCCESS';
export const VERFIY_PATIENT_FAIL = 'VERFIY_PATIENT_FAIL';
export const VERFIY_PATIENT_RESET = 'VERFIY_PATIENT_RESET';

export const GET_PARTNER_CONTENTS_REQUEST = 'GET_PARTNER_CONTENTS_REQUEST';
export const GET_PARTNER_CONTENTS_SUCCESS = 'GET_PARTNER_CONTENTS_SUCCESS';
export const GET_PARTNER_CONTENTS_FAIL = 'GET_PARTNER_CONTENTS_FAIL';
export const GET_PARTNER_CONTENTS_RESET = 'GET_PARTNER_CONTENTS_RESET';