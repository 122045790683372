import React, { Fragment } from "react";
import Modal from "../../../components/Modal";
import ModalHeading from "../../../components/Modal/ModalHeading";
import Page1 from "./Page1";

const ConsentFormModal = ({
  modalData,
  consentForm,
  handleCancel,
  handleSubmit
}) => {

  const [pdfLoading, setPdfLoading] = React.useState(true);
  const [pdfLink, setPdfLink] = React.useState('');
  const [documentName, setDocumentName] = React.useState('Informed Consent');

  React.useEffect(() => {
    if (modalData.step === 1) {
      setPdfLink(consentForm && consentForm[0]?.fileURL);
      setDocumentName(consentForm && consentForm[0]?.documentName) 
    } else if (modalData.step === 2) {
      setPdfLink(consentForm && consentForm[1]?.fileURL);
      setDocumentName(consentForm && consentForm[1]?.documentName) 
    } else if (modalData.step === 3 && consentForm[2]?.fileURL) {
      setPdfLink(consentForm && consentForm[2]?.fileURL);
      setDocumentName(consentForm && consentForm[2]?.documentName) 
    }
    setPdfLoading(consentForm.loading);
  }, [consentForm,modalData]);


  return (
    <Modal show={modalData.status}>
      <ModalHeading onClose={handleCancel} />
      <Fragment>
        <div>
          <div className="terms__Pagination">
            <ul className="terms__pagination-bar">
              {consentForm &&
                consentForm.length > 0 &&
                consentForm.map((item, index) => (
                  <li key={index + 1} className={item.documentName === documentName ? "active" : null}>
                    <div className="stepper-text">{item.documentName}</div>
                  </li>
                )
                )}
            </ul>
          </div>

          <Page1
            loading={pdfLoading}
            data={{
              handleSubmit,
              consentForm: pdfLink
            }}
          />
        </div>
      </Fragment>
    </Modal>
  );
};

export default ConsentFormModal;
