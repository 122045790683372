import React from 'react';
import TableRows from '../../components/Skeletons/TableRows';

import './UnverifiedUsers.css';

const emailData = ({ loading, data }) => {
	const {
		setEmailPreview,
		showFilter,
		setModalState,
		setResetPasswordState,
		setModalVerify,
		setVerifyEmailConfirm,
		userIndex,
		unverifiedList,
		setLastName,
		setFirstName,
		setUserId,
	} = data;
	return (
		<div className="tableContainer" style={{ height: showFilter ? '63vh' : '43vh' }}>
			<table className="table">
				<thead>
					<tr>
						<th>#</th>
						<th>Email</th>
						<th>User F/L Name</th>
						<th>Registered Date</th>
						<th>Actions</th>
					</tr>
				</thead>
				<tbody>
					{unverifiedList && !loading ? unverifiedList.length > 0 ? (
						unverifiedList.map((user, index) => (
							<tr key={index + 1}>
								<td>{userIndex + index}</td>
								<td>{user.email}</td>
								<td>{user.firstName + ' ' + user.lastName}</td>
								<td>
									<span>
										{new Date(user.registeredDate).toLocaleString('en-US', {
											year: 'numeric',
											month: '2-digit',
											day: '2-digit',
											hour12: true,
											hour: '2-digit',
											minute: '2-digit',
											second: '2-digit'
										})}
									</span>
								</td>

								<td>
									<div className="table__iconBox">
										<button
											className="table__button--verify"
											onClick={() => {
												setModalVerify(true);
												setVerifyEmailConfirm(user.email);
											}}
										>
											<span>Verify</span>
											<div className="table__button--verifyIconContainer">
												<svg className="table__button--verifyIcon">
													<use xlinkHref={`/sprite.svg#icon-verify`} />
												</svg>
											</div>
										</button>
										<button
											className="table__button"
											onClick={() => {
												setModalState(true);
												setUserId(user.userId);
												setEmailPreview(user.email);
												setFirstName(user.firstName);
												setLastName(user.lastName); 
											}}
										>
											<svg className="table__button--icon">
												<use xlinkHref={`/sprite.svg#icon-edit`} />
											</svg>
											<span>Edit user</span>
										</button>
										<button
											className="table__button"
											onClick={() => {
												setResetPasswordState(true);
												setEmailPreview(user.email);
											}}
										>
											<svg className="table__button--icon">
												<use xlinkHref={`/sprite.svg#icon-change_password`} />
											</svg>
											<span>Change Password</span>
										</button>
									</div>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="5">No users found</td>
						</tr>
					) : (
						<TableRows columns="5" />
					)}
				</tbody>
			</table>
		</div>
	);
};

export default emailData;
