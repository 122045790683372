import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import SubscriptionCard from './SubscriptionCard';
import SubscriptionStyle from './SubscriptionScreen.module.css';
import { getProductsListAction } from '../../redux/actions/paymentAction';
import { STRIPE_PUBLISHABLE_KEY } from '../../redux/actions/ip';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import StripePayment from './StripePayment/StripePayment';
import SubscriptionCardSkeleton from '../../components/Skeletons/SubscriptionCardSkeleton';
import PaymentSuccess from './PaymentSuccess';
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const SubscriptionScreen = ({ history }) => {
  const dispatch = useDispatch();
  const [selectPlan, setSelectPlan] = useState(null);
  const [supportPlan, setSupportPlan] = useState(null);

  const [showCardOption, setShowCardOption] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const getProductsList = useSelector((state) => state.getProductsList);

  useEffect(() => {
    dispatch(getProductsListAction());
  }, [dispatch]);

  const chooseHandler = (props) => {
    setSelectPlan(props);
    if(props.name !== 'First 250 Clinics'){
      setSupportPlan(JSON.parse(getProductsList.data.setupObject)); 
    }else{
      setSupportPlan(null)
    }

    // console.log(supportPlan,selectPlan);
  }; 
  
  const makePaymentHandler = () => {
    setShowCardOption(true);
    setShowOrderSummary(false);
  };

  const handleBack = () => {
    setShowCardOption(false);
    setShowOrderSummary(true);
    setSelectPlan(null);
  };

  return (
    <main className={SubscriptionStyle.subscriptionContainer}>
      <Helmet>
        <title>Perspectives in Care – Subscription</title>
      </Helmet>
      <div className={SubscriptionStyle.subscriptionContainer__bg} />
      <div className={SubscriptionStyle.subscriptionContainer__box}>
        <div className={SubscriptionStyle.subscriptionContainer__box_right}>
          <img
            src='/images/logo.png'
            alt='Suremed Logo'
            className={SubscriptionStyle.subscriptionContainer__box_logo}
          />
          <h1 className={SubscriptionStyle.subscriptionContainer__heading}>
            PACKAGES
            <span>THAT SUIT YOU BEST</span>
          </h1>
        </div>
        {showCardOption && showOrderSummary ? (
          <PaymentSuccess URL={paymentDetails.link} />
        ) : showCardOption && !showOrderSummary ? (
          <div className={SubscriptionStyle.subscriptionContainer__box_left}>
            <Elements stripe={stripePromise}>
              <StripePayment
                data={{
                  handleBack,
                  selectPlan,
                  supportPlan,
                  setShowOrderSummary,
                  setPaymentDetails
                }}
              />
            </Elements>
          </div>
        ) : (
          <div className={SubscriptionStyle.subscriptionContainer__box_left}>
            <h3 className={SubscriptionStyle.subscription_heading}>
              Select Package
            </h3>
            <div className={SubscriptionStyle.subscription_cards}>
              {getProductsList && getProductsList.loading ? (
                <SubscriptionCardSkeleton />
              ) : (
                getProductsList &&
                getProductsList.data &&
                getProductsList.data.data.length > 0 &&
                getProductsList.data.data.sort((a, b) => JSON.parse(a)?.metadata?.id - JSON.parse(b)?.metadata?.id).map((item, index) => (
                  <SubscriptionCard
                    data={JSON.parse(item)}
                    key={index + 1}
                    chooseHandler={chooseHandler}
                    selectPlan={selectPlan}
                    supportPlan={JSON.parse(getProductsList.data.setupObject)}
                    style={{ height: '100%' }}
                  />
                ))
              )}
            </div>
            <div
              className={SubscriptionStyle.subscriptionContainer__button_layout}
            >
              <button
                className={SubscriptionStyle.subscriptionContainer__button}
                disabled={!selectPlan}
                onClick={makePaymentHandler}
              >
                <span
                  className={
                    SubscriptionStyle.subscriptionContainer__button_text
                  }
                >
                  NEXT
                </span>
                <div
                  className={
                    SubscriptionStyle.subscriptionContainer__button_iconBox
                  }
                >
                  <svg
                    className={
                      SubscriptionStyle.subscriptionContainer__button_icon
                    }
                  >
                    <use xlinkHref='/sprite.svg#icon-login' />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        )}
      </div>
    </main>
  );
};

export default SubscriptionScreen;
