import axios from 'axios';
import {
	REGISTER_ADMIN_REQUEST,
	REGISTER_ADMIN_SUCCESS,
	REGISTER_ADMIN_FAIL,
	INVITE_ADMIN_REQUEST,
	INVITE_ADMIN_SUCCESS,
	INVITE_ADMIN_FAIL,
	ADD_INVITE_ADMIN_REQUEST,
	ADD_INVITE_ADMIN_SUCCESS,
	ADD_INVITE_ADMIN_FAIL,
	DELETE_INVITE_ADMIN_REQUEST,
	DELETE_INVITE_ADMIN_SUCCESS,
	DELETE_INVITE_ADMIN_FAIL,
	UPDATE_INVITE_ADMIN_REQUEST,
	UPDATE_INVITE_ADMIN_SUCCESS,
	UPDATE_INVITE_ADMIN_FAIL
} from '../constants/adminConstants';
import { BASE_URL_LOGIN } from './ip';

export const registerAdminAction = (formData) => async (dispatch) => {
	try {
		dispatch({ type: REGISTER_ADMIN_REQUEST });
		const config = {
			headers: {
				'custom-clinicid-header': 1,
			}
		};
		const { data } = await axios.post(`${BASE_URL_LOGIN}/Auth/RegisterAdmin`, formData, config);

		dispatch({
			type: REGISTER_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: REGISTER_ADMIN_FAIL,
			payload: err.response && err.response.data.message ? err.response.data.message : err.message
		});
	}
};
export const inviteAdminAction = () => async (dispatch, getState) => {
	try {
		dispatch({ type: INVITE_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();
		
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/admininvites`, config);

		dispatch({
			type: INVITE_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: INVITE_ADMIN_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const createInviteAdminAction = (emailAddress) => async (dispatch, getState) => {
	try {
		dispatch({ type: ADD_INVITE_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();
		

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/admininvites`, { emailAddress }, config);

		dispatch({
			type: ADD_INVITE_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: ADD_INVITE_ADMIN_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};

export const deleteInviteAdminAction = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: DELETE_INVITE_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();
		
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.delete(`${userInfo.apiurl}/admininvites/${id}`, config);

		dispatch({
			type: DELETE_INVITE_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: DELETE_INVITE_ADMIN_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
export const updateEmailAdminAction = (id, fromData) => async (dispatch, getState) => {
	try {
		dispatch({ type: UPDATE_INVITE_ADMIN_REQUEST });

		const { userLogin: { userInfo } } = getState();
		
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.put(`${userInfo.apiurl}/admininvites/${id}`, fromData, config);

		dispatch({
			type: UPDATE_INVITE_ADMIN_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: UPDATE_INVITE_ADMIN_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
