import {
	ASSESSMENT_REQUEST,
	ASSESSMENT_SUCCESS,
	ASSESSMENT_FAIL,
	ASSESSMENT_RESET,
	PREVIOUS_ASSESSMENT_REQUEST,
	PREVIOUS_ASSESSMENT_SUCCESS,
	PREVIOUS_ASSESSMENT_FAIL,
	PREVIOUS_ASSESSMENT_RESET,
	SELF_ASSESSMENT_REQUEST,
	SELF_ASSESSMENT_SUCCESS,
	SELF_ASSESSMENT_FAIL,
	SELF_ASSESSMENT_RESET,
	NEXT_QUESTION_REQUEST,
	NEXT_QUESTION_SUCCESS,
	NEXT_QUESTION_FAIL,
	NEXT_QUESTION_RESET,
	QUESTION_RESULT_REQUEST,
	QUESTION_RESULT_SUCCESS,
	QUESTION_RESULT_FAIL,
	QUESTION_RESULT_RESET,
	PREVIOUS_QUESTION_REQUEST,
	PREVIOUS_QUESTION_SUCCESS,
	PREVIOUS_QUESTION_FAIL,
	PREVIOUS_QUESTION_RESET,
	SAVE_ANSWER_REQUEST,
	SAVE_ANSWER_SUCCESS,
	SAVE_ANSWER_FAIL,
	SAVE_ANSWER_RESET,
	REPORT_DTO_REQUEST,
	REPORT_DTO_SUCCESS,
	REPORT_DTO_FAIL,
	REPORT_DTO_RESET,
	GET_BMI_QUESTIONS_REQUEST,
	GET_BMI_QUESTIONS_SUCCESS,
	GET_BMI_QUESTIONS_FAIL,
	GET_BMI_QUESTIONS_RESET,
	CALCULATE_BMI_REQUEST,
	CALCULATE_BMI_SUCCESS,
	CALCULATE_BMI_FAIL,
	CALCULATE_BMI_RESET,
	UPDATE_USER_DETAILS_REQUEST,
	UPDATE_USER_DETAILS_SUCCESS,
	UPDATE_USER_DETAILS_FAIL,
	UPDATE_USER_DETAILS_RESET,
	SUMMARY_PDF_REQUEST,
	SUMMARY_PDF_SUCCESS,
	SUMMARY_PDF_FAIL,
	SUMMARY_PDF_RESET
} from '../constants/assessmentConstants';

export const reportDtoReducer = (state = {}, action) => {
	switch (action.type) {
		case REPORT_DTO_REQUEST:
			return { loading: true };

		case REPORT_DTO_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case REPORT_DTO_FAIL:
			return { loading: false, error: action.payload };
		case REPORT_DTO_RESET:
			return {};
		default:
			return state;
	}
};

export const nextQuestionReducer = (state = {}, action) => {
	switch (action.type) {
		case NEXT_QUESTION_REQUEST:
			return { loading: true };

		case NEXT_QUESTION_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case NEXT_QUESTION_FAIL:
			return { loading: false, error: action.payload };
		case NEXT_QUESTION_RESET:
			return {};
		default:
			return state;
	}
};

export const questionResultReducer = (state = {}, action) => {
	switch (action.type) {
		case QUESTION_RESULT_REQUEST:
			return { loading: true };

		case QUESTION_RESULT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case QUESTION_RESULT_FAIL:
			return { loading: false, error: action.payload };
		case QUESTION_RESULT_RESET:
			return {};

		default:
			return state;
	}
};

export const saveAnswerReducer = (state = {}, action) => {
	switch (action.type) {
		case SAVE_ANSWER_REQUEST:
			return { loading: true };

		case SAVE_ANSWER_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case SAVE_ANSWER_FAIL:
			return { loading: false, error: action.payload };

		case SAVE_ANSWER_RESET:
			return {};
		default:
			return state;
	}
};

export const previousQuestionReducer = (state = {}, action) => {
	switch (action.type) {
		case PREVIOUS_QUESTION_REQUEST:
			return { loading: true };

		case PREVIOUS_QUESTION_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case PREVIOUS_QUESTION_FAIL:
			return { loading: false, error: action.payload };
		case PREVIOUS_QUESTION_RESET:
			return {};
		default:
			return state;
	}
};

export const selfAssessmentReducer = (state = {}, action) => {
	switch (action.type) {
		case SELF_ASSESSMENT_REQUEST:
			return { loading: true };

		case SELF_ASSESSMENT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case SELF_ASSESSMENT_FAIL:
			return { loading: false, error: action.payload };
		case SELF_ASSESSMENT_RESET:
			return {};
		default:
			return state;
	}
};

export const assessmentReducer = (state = {}, action) => {
	switch (action.type) {
		case ASSESSMENT_REQUEST:
			return { loading: true };

		case ASSESSMENT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case ASSESSMENT_FAIL:
			return { loading: false, error: action.payload };
		case ASSESSMENT_RESET:
			return {};
		default:
			return state;
	}
};

export const prevoiusAssessmentReducer = (state = {}, action) => {
	switch (action.type) {
		case PREVIOUS_ASSESSMENT_REQUEST:
			return { loading: true };

		case PREVIOUS_ASSESSMENT_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case PREVIOUS_ASSESSMENT_FAIL:
			return { loading: false, error: action.payload };
		case PREVIOUS_ASSESSMENT_RESET:
			return {};
		default:
			return state;
	}
};

export const getbmiQuestionsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_BMI_QUESTIONS_REQUEST:
			return { loading: true };

		case GET_BMI_QUESTIONS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case GET_BMI_QUESTIONS_FAIL:
			return { loading: false, error: action.payload };
		case GET_BMI_QUESTIONS_RESET:
			return {};
		default:
			return state;
	}
};

export const bmiCalculationReducer = (state = {}, action) => {
	switch (action.type) {
		case CALCULATE_BMI_REQUEST:
			return { loading: true };

		case CALCULATE_BMI_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case CALCULATE_BMI_FAIL:
			return { loading: false, error: action.payload };
		case CALCULATE_BMI_RESET:
			return {};
		default:
			return state;
	}
};

export const updateUserDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_USER_DETAILS_REQUEST:
			return { loading: true };

		case UPDATE_USER_DETAILS_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case UPDATE_USER_DETAILS_FAIL:
			return { loading: false, error: action.payload };
		case UPDATE_USER_DETAILS_RESET:
			return {};
		default:
			return state;
	}
};

export const summaryPdfReducer = (state = {}, action) => {
	switch (action.type) {
		case SUMMARY_PDF_REQUEST:
			return { loading: true };

		case SUMMARY_PDF_SUCCESS:
			return { userInfo: action.payload, loading: false };

		case SUMMARY_PDF_FAIL:
			return { loading: false, error: action.payload };
		case SUMMARY_PDF_RESET:
			return {};
		default:
			return state;
	}
};
