import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';

const SchedulerDetails = ({ modalState, onClose, data }) => {
	const { scheduleMapDetails, scheduleDetails } = data; 
	return (
		scheduleMapDetails && scheduleMapDetails.token ?
			scheduleMapDetails && scheduleMapDetails.token &&
			scheduleMapDetails.token.map((details) => (

				scheduleDetails && scheduleDetails.token &&
				scheduleDetails.token.map((data) => (
					<Modal show={modalState}>
						<ModalHeading heading='Schedule Details' onClose={onClose} />

						<div className='modal__data'>
							<div className='modal__data--completed'>
								<svg className='modal__data--completedIcon'>
									<use xlinkHref={`/sprite.svg#icon-status`} />
								</svg>
								<p>{data.status}</p>
							</div>
							<div className='modal__data--timedate'>
								<svg className='modal__data--timedateIcon'>
									<use xlinkHref={`/sprite.svg#icon-calender`} />
								</svg>
								<p>{data.date}</p>
							</div>
							<div className='modal__data--timedate'>
								<svg className='modal__data--timedateIcon'>
									<use xlinkHref={`/sprite.svg#icon-time`} />
								</svg>
								<p>{data.time}</p>
							</div>
							{/* <div className='modal__data--content'>
					<div>
						<svg className='modal__data--contentIcon'>
							<use xlinkHref={`/sprite.svg#icon-content`} />
						</svg>
					</div>
					<p>
						Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
						dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
						ea commodo consequat. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
						incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
						laboris nisi ut aliquip ex ea commodo consequat.
					</p>
				</div> */}
						</div>


						<div className='modal__data--user'>
							<p>{details.name} | {details.email}</p>
						</div>
					</Modal>
				))
			)) : null


	);
};

export default SchedulerDetails;
