import React from 'react';

const ManageClinics = () => {
	return (
		<div className="modal__actions">
			<div className="modal__actions__background">
				<div className="filter__input--label-skeleton skeleton" />
				<div className="filter__input--textBoxlong-skeleton skeleton" />
			</div>
			<div className="modal__actions__background">
				<div className="filter__input--label-skeleton skeleton" />
				<div className="modal__button--toggle-skeleton skeleton" />
			</div>
		</div>
	);
};
export default ManageClinics;
