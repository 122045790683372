import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import TableRows from '../../components/Skeletons/TableRows';

export default function ShowData({ data, loading }) {
	const userLogin = useSelector((state) => state.userLogin);

	const { showFilter, usersList, userIndex } = data;

	return (
		<div className='tableContainer' style={{ height: showFilter ? '63vh' : '23vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>Provider</th>
						<th>Clinic Name</th>
						<th>Email</th>
						<th>Confirmed</th>
						<th>User F/L Name</th>
						<th>Birth Date</th>
						<th>Assessments</th>
						<th>View</th>
						{/* {userLogin &&
						userLogin.userInfo &&
						userLogin.userInfo.isenablepillcount && <th style={{ width: '10rem' }}>Pill Count Request</th>} */}
						<th>Role</th>
					</tr>
				</thead>
				<tbody>
					{usersList && !loading ? usersList.length > 0 ? (
						usersList.map((user, index) => (
							<tr key={index + 1}>
								<td>{userIndex + index}</td>
								<td style={{ textAlign: 'left' }}>{user.ProviderName}</td>
								<td>{user.clinic}</td>
								<td>{user.email}</td>
								{user.isconfirmed ? <td>TRUE</td> : <td>FALSE</td>}
								<td>{user.patientFirstName + ' ' + user.patientLastName}</td>
								<td>
									{new Date(user.BirthDate).toLocaleDateString('en-US', {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit'
									})}
								</td>

								<td>
									<Link to={`/previous-assessments/${user.ApplicationUserId}`} className='table__btn'>
										Prev Assessments
									</Link>
								</td>
								<td>
									<span className='table__iconBox'>
										{/* Ref. No.: {user.assessmentrefno} */}
										<Link to={`/pdfView/${user.AssessmentResultId}`} className='table__button2'>
											<svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
												<use xlinkHref={`/sprite.svg#icon-pdf`} />
											</svg>
											<span>View</span>
										</Link>
									</span>
								</td>
								{/* {userLogin &&
								userLogin.userInfo &&
								userLogin.userInfo.isenablepillcount && (
									<td>
										<Link
											style={{ justifyContent: 'center' }}
											className='table__button2'
											to={`/pill-count-view/${user.ApplicationUserId}`}>
											<svg className='table__icon primary-icon' style={{ width: '2rem', height: '2rem' }}>
												<use xlinkHref={`/sprite.svg#icon-view_doc`} />
											</svg>
											<span>Pill Count Request</span>
										</Link>
									</td>
								)} */}

								<td>{user.highestrole}</td>

								{/* <td>
									<div className='table__iconBox'>
										<button
											className='table__button'
											// onClick={() => {
											// 	setmodalLoginAdminState(true);
											// 	setLoginAsUserData(user);
											// }}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-login`} />
											</svg>
											<span>Login as {user.patientFirstName + ' ' + user.patientLastName}</span>
										</button>
										<button
											className='table__button'
											// onClick={() => {
											// 	setModalResetState(true);
											// 	setDeleteUserData(user);
											// }}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-change_password`} />
											</svg>
											<span>Change Password</span>
										</button>

										<button
											className='table__button'
											// onClick={() => {
											// 	setModalEditState(true);
											// 	setupdateUser({
											// 		...updateUser,
											// 		userId: user.applicationUserId,
											// 		fName: user.patientFirstName,
											// 		lName: user.patientLastName,
											// 		email: user.patientEmail
											// 	});
											// }}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-edit`} />
											</svg>
											<span>Edit User</span>
										</button>
										<button
											className='table__button table__button--delete'
											// onClick={() => {
											// 	setmodalDeleteState(true);
											// 	setDeleteUserData(user);
											// }}
											>
											<svg className='table__button--icon-red'>
												<use xlinkHref={`/sprite.svg#icon-delete`} />
											</svg>
											<span>Delete User</span>
										</button>
									</div>
								</td> */}
							</tr>
						))
					) : (
						<tr>
							<td colSpan='13' style={{ textAlign: 'center', padding: '2rem' }}>
								Patient information will be displayed here as they enroll and complete their questionnaires.
							</td>
						</tr>
					) : (
						<TableRows columns='13' />
					)}
				</tbody>
			</table>
		</div>
	);
}
