export const CURRENT_USER_REQUEST = 'CURRENT_USER_REQUEST';
export const CURRENT_USER_SUCCESS = 'CURRENT_USER_SUCCESS';
export const CURRENT_USER_FAIL = 'CURRENT_USER_FAIL';
export const CURRENT_USER_RESET = 'CURRENT_USER_RESET';

export const CLINIC_FILTER_REQUEST = 'CLINIC_FILTER_REQUEST';
export const CLINIC_FILTER_SUCCESS = 'CLINIC_FILTER_SUCCESS';
export const CLINIC_FILTER_FAIL = 'CLINIC_FILTER_FAIL';
export const CLINIC_FILTER_RESET = 'CLINIC_FILTER_RESET';

export const CLINIC_PROVIDER_REQUEST = 'CLINIC_PROVIDER_REQUEST';
export const CLINIC_PROVIDER_SUCCESS = 'CLINIC_PROVIDER_SUCCESS';
export const CLINIC_PROVIDER_FAIL = 'CLINIC_PROVIDER_FAIL';
export const CLINIC_PROVIDER_RESET = 'CLINIC_PROVIDER_RESET';

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST';
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS';
export const USER_LIST_FAIL = 'USER_LIST_FAIL';
export const USER_LIST_RESET = 'USER_LIST_RESET';

export const PROVIDER_ASSESSMENT_REQUEST = 'PROVIDER_ASSESSMENT_REQUEST';
export const PROVIDER_ASSESSMENT_SUCCESS = 'PROVIDER_ASSESSMENT_SUCCESS';
export const PROVIDER_ASSESSMENT_FAIL = 'PROVIDER_ASSESSMENT_FAIL';
export const PROVIDER_ASSESSMENT_RESET = 'PROVIDER_ASSESSMENT_RESET';

export const ADD_INVITE_REQUEST = 'ADD_INVITE_REQUEST';
export const ADD_INVITE_SUCCESS = 'ADD_INVITE_SUCCESS';
export const ADD_INVITE_FAIL = 'ADD_INVITE_FAIL';
export const ADD_INVITE_RESET = 'ADD_INVITE_RESET';

export const SAVE_CLINIC_USER_REQUEST = 'SAVE_CLINIC_USER_REQUEST';
export const SAVE_CLINIC_USER_SUCCESS = 'SAVE_CLINIC_USER_SUCCESS';
export const SAVE_CLINIC_USER_FAIL = 'SAVE_CLINIC_USER_FAIL';
export const SAVE_CLINIC_USER_RESET = 'SAVE_CLINIC_USER_RESET';

export const VIDEO_DETAILS_REQUEST = 'VIDEO_DETAILS_REQUEST';
export const VIDEO_DETAILS_SUCCESS = 'VIDEO_DETAILS_SUCCESS';
export const VIDEO_DETAILS_FAIL = 'VIDEO_DETAILS_FAIL';
export const VIDEO_DETAILS_RESET = 'VIDEO_DETAILS_RESET';

export const VIDEO_THUMB_REQUEST = 'VIDEO_THUMB_REQUEST';
export const VIDEO_THUMB_SUCCESS = 'VIDEO_THUMB_SUCCESS';
export const VIDEO_THUMB_FAIL = 'VIDEO_THUMB_FAIL';
export const VIDEO_THUMB_RESET = 'VIDEO_THUMB_RESET';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
export const DELETE_USER_RESET = 'DELETE_USER_RESET';

export const TERMS_ACCEPT_REQUEST = 'TERMS_ACCEPT_REQUEST';
export const TERMS_ACCEPT_SUCCESS = 'TERMS_ACCEPT_SUCCESS';
export const TERMS_ACCEPT_FAIL = 'TERMS_ACCEPT_FAIL';
export const TERMS_ACCEPT_RESET = 'TERMS_ACCEPT_RESET';

export const EHR_CLINIC_FILTER_REQUEST = 'EHR_CLINIC_FILTER_REQUEST';
export const EHR_CLINIC_FILTER_SUCCESS = 'EHR_CLINIC_FILTER_SUCCESS';
export const EHR_CLINIC_FILTER_FAIL = 'EHR_CLINIC_FILTER_FAIL';
export const EHR_CLINIC_FILTER_RESET = 'EHR_CLINIC_FILTER_RESET';