import React, { useState } from 'react';

const ModalTextBox = ({ label, placeholder, value, icon, onChange, error, type = 'text', disabled = false, style }) => {
  const [newEye, setNewEye] = useState(false);
  const [inputType, setInputType] = useState(type);
  const showPassword = () => {
    setNewEye(!newEye);
    if (inputType === 'password') {
      setInputType('text');
    } else {
      setInputType('password');
    }
  };
  return (
    <div className='modal_input'>
      <label htmlFor='email' className='modal_input--label'>
        {label}
      </label>
      <div className='modal_input--box' style={style}>
        <label htmlFor='email' className='modal_input--iconBox'>
          <svg className='modal_input--icon'>
            <use xlinkHref={icon} />
          </svg>
        </label>
        <input disabled={disabled} type={inputType} id={label} value={value} placeholder={placeholder} onChange={e => onChange(e)} className='modal_input--text2' />
        {type === 'password' && (
          <svg className='loginContainer__formgroup--icon' onClick={showPassword}>
            {newEye ? <use xlinkHref={`/sprite.svg#icon-hidePassword`} /> : <use xlinkHref={`/sprite.svg#icon-showPassword`} />}
          </svg>
        )}
      </div>
      {error && <p className='error_message'>{error}</p>}
    </div>
  );
};

export const ModalTextBox1 = ({ label, placeholder, value, icon, onChange, error }) => {
  return (
    <div className='modal_input'>
      <label htmlFor='email' className='modal_input--label'>
        {label}
      </label>
      <div className='modal_input--box'>
        <label htmlFor='email' className='modal_input--iconBox'>
          <svg className='modal_input--icon'>
            <use xlinkHref={icon} />
          </svg>
        </label>

        <input type='text' id={label} value={value} placeholder={placeholder} onChange={onChange} className='modal_input--text2' />
      </div>
      {error && <p className='error_message'>{error}</p>}
    </div>
  );
};

export const ModalDateBox = ({ label, placeholder, value, icon, onChange, error }) => {
  return (
    <div className='modal_input'>
      <label htmlFor='email' className='modal_input--label'>
        {label}
      </label>
      <div className='modal_input--box' style={{ padding: '.8rem 1rem' }}>
        <input type='date' id={label} value={value} onChange={onChange} className='modal_input--text2' />
      </div>
      {error && <p className='error_message' style={{ maxWidth: '32vh' }}>{error}</p>}
    </div>
  );
};

export const ModalSelectBox = ({ label, value, onChange, error, children ,style={ padding: '.8rem 1rem' }}) => {
  return (
    <div className='modal_input' >
      <label htmlFor='email' className='modal_input--label'>
        {label}
      </label>
      <div className='modal_input--box' style={style}>
        <select className='modal_select-box' value={value} onChange={onChange} name={label} id={label} >
          {children}
        </select>
      </div>
      {error && <p className='error_message'>{error}</p>}
    </div>
  );
};

export default ModalTextBox;
