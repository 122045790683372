import React, { useState } from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import ModalForm from '../../components/Modal/ModalForm';
import { useSelector } from 'react-redux';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton'; 
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import range from "lodash/range";
import { getDayDiff, inputPhoneMasking } from '../../utils/validators';

const EditProfile = ({ data }) => {
	const userData = useSelector((state) => state.getUserInfo);
	const {
		firstName,
		setFirstName,
		lastName,
		setLastName,
		phoneNumber,
		setPhoneNumber,
		DOB,
		setDOB,
		IsCellIncluded,
		setIsCellIncluded,
		email,
		setEmail,
		handleEditModalClose,
		handleSubmit,
		showEditModal,
		setShowEditModal,
	} = data;

	const years = range(1920, getYear(new Date()) + 1, 1);
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];
	const [component, setComponent] = useState(null);
	const [focus, setFocus] = useState(true);

	return (
		<Modal show={showEditModal}>
			<ModalHeading heading='Update your profile information' onClose={handleEditModalClose} />
			<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
				<div className="profile__content patient_profile__content">
					<div className='modal_container'>
						<div className="modal_input" >
							<label htmlFor="fName" className="modal_input--label">
								First Name
							</label>
							<div className="modal_input--box">
								<label htmlFor="fName" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref='/sprite.svg#icon-user' />
									</svg>
								</label>
								<input
									type='text'
									id='fName'
									placeholder='John'
									value={firstName.value}
									onChange={(e) => setFirstName({ value: e.target.value, error: '' })}
									className="modal_input--text2"
								/>
							</div>
							<p className="error_message">{firstName?.error}</p>
						</div>
						<div className="modal_input" >
							<label htmlFor="lName" className="modal_input--label">
								Last Name
							</label>
							<div className="modal_input--box">
								<label htmlFor="lName" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref='/sprite.svg#icon-user' />
									</svg>
								</label>
								<input
									type='text'
									id='lName'
									placeholder='Doe'
									value={lastName.value}
									onChange={(e) => setLastName({ value: e.target.value, error: '' })}
									className="modal_input--text2"
								/>
							</div>
							<p className="error_message">{lastName?.error}</p>
						</div>
					</div>
					<div className='modal_container'>
						<div className="modal_input" >
							<label htmlFor="email" className="modal_input--label">
								Email
							</label>
							<div className="modal_input--box email-input">
								<label htmlFor="email" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref='/sprite.svg#icon-email' />
									</svg>
								</label>
								<input
									type='text'
									id='email'
									placeholder="Email"
									disabled={true}
									value={email.value}
									onChange={(e) =>
										setEmail({
											value: e.target.value,
											error: '',
										})
									}
									className="modal_input--text2"
								/>
							</div>
							<p className="error_message">{email?.error}</p>
						</div>
					</div>

					<div className='modal_container'>
						<div className="modal_input" >
							<label htmlFor="phoneNumber" className="modal_input--label">
								Phone Number
							</label>
							<div className="modal_input--box">
								<label htmlFor="phoneNumber" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref='/sprite.svg#icon-phone' />
									</svg>
								</label>
								<input
									type='text'
									id='phoneNumber'
									placeholder="123 456 7890"
									maxLength={12}
									value={phoneNumber.value}
									onChange={(e) =>
										setPhoneNumber({
											value: inputPhoneMasking(e.target.value.trim()),
											error: '',
										})
									}
									className="modal_input--text2"
								/>
							</div>
							<p className="error_message">{phoneNumber?.error}</p>
						</div>

						<div className="modal_input" >
							<label htmlFor="dob" className="modal_input--label">
								Date of Birth
							</label>
							<div className="modal_input--box" style={{ height: '3.8rem' }}>
								<label htmlFor="dob" className="modal_input--iconBox">
									<svg className="modal_input--icon">
										<use xlinkHref='/sprite.svg#icon-calendar' />
									</svg>
								</label>
								<DatePicker
									key="birthDate"
									renderCustomHeader={({
										date,
										changeYear,
										changeMonth,
										decreaseMonth,
										increaseMonth,
										prevMonthButtonDisabled,
										nextMonthButtonDisabled,
									}) => (
										<div
											style={{
												margin: 10,
												display: "flex",
												justifyContent: "center",
											}}
										>
											<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
												{"<"}
											</button>
											<select
												value={getYear(date)}
												onChange={({ target: { value } }) => changeYear(value)}
											>
												{years.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>

											<select
												value={months[getMonth(date)]}
												onChange={({ target: { value } }) =>
													changeMonth(months.indexOf(value))
												}
											>
												{months.map((option) => (
													<option key={option} value={option}>
														{option}
													</option>
												))}
											</select>

											<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
												{">"}
											</button>
										</div>
									)}
									selected={DOB && DOB.value ? new Date(DOB.value) : new Date(moment().subtract(3, 'years'))}
									dateFormat="MM-dd-yyyy"
									ref={(r) => {
										setComponent(r);
									}}
									onChange={(date) => {
										setDOB({
											error: false,
											value: moment(date).format("YYYY-MM-DD") // "2013-03-10" 
										});

										if (getDayDiff(date) < 365) {
											setDOB({
												error: true,
												value: moment(date).format("YYYY-MM-DD") // "2013-03-10" 
											});;
										} else {
											setDOB({
												error: false,
												value: moment(date).format("YYYY-MM-DD") // "2013-03-10" 
											});;
										};
									}}
									className="loginContainer__formgroup--input"
									placeholderText="Date of Birth"
								/>
								<svg
									style={{ marginRight: "1.6rem", height: "3rem", width: "3rem", fill: "#4b4b4b" }}
									className="loginContainer__formgroup--icon"
									onClick={() => {
										setFocus(focus ? false : true);
										component.setOpen(focus);
									}}
								>
									<use xlinkHref="/sprite.svg#icon-calendar" />
								</svg>
							</div>
						</div>
					</div>


					{DOB &&
						DOB.error &&
						<p
							className="error_message"
							style={{ marginLeft: "1.5rem", maxWidth: '60vh' }}
						>
							It seems you’ve entered your date of birth as a day within the last year. Please ensure that you enter your birthday correctly before proceeding.
						</p>
					}

					<div className="cellphoneContainer">
						<p className="modal__text">
							Is this a cell phone?
						</p>
						<div className="cellphoneContainer--yesno">
							<label className="cellphone-yesno">
								<span>Yes</span>
								<input
									id="active"
									type="radio"
									value={IsCellIncluded.value == 'Yes'}
									checked={IsCellIncluded.value == 'Yes'}
									onChange={() => {
										setIsCellIncluded({ value: 'Yes', error: '' });
									}}
									name="active"
								/>
							</label>
							<label className="cellphone-yesno">
								<span>No</span>
								<input
									id="active"
									type="radio"
									value={IsCellIncluded.value == 'No'}
									checked={IsCellIncluded.value == 'No'}
									onChange={() => {
										setIsCellIncluded({ value: 'No', error: '' });
									}}
									name="active"
								/>
							</label>
						</div>
						<p className="error_message" style={{ marginLeft: "1rem" }}>
							{IsCellIncluded.error}
						</p>
					</div>

				</div>

				<ModalButtonPrimary
					style={{ marginTop: '2rem' }}
					title='Update Profile'
					icon='edit'
					onClick={handleSubmit}
				/>
			</ModalForm>
		</Modal>
	);
};

export default EditProfile;
