import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getConsentFormsAction,
  registerPatientAction,
} from "../../redux/actions/userActions";
import { REGISTER_PATIENT_RESET } from "../../redux/constants/userConstants";
import {
  getDayDiff,
  inputPhoneMasking,
  unMasking,
  validateEmail,
  validateOnlyChacters,
  validatePassword,
  validatePhone,
} from "../../utils/validators";

import "./RegisterPatient.css";
import TermsModal from "./TermsModal/termsModal";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import axios from "axios"; 
import PatienteSignModal from "./PatienteSignModal";

function Index({ history }) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [component, setComponent] = useState(null);
  const [birthDateError, setBirthDateError] = useState(false);
  const [focus, setFocus] = useState(true);
  const registerPatient = useSelector((state) => state.registerPatient);
  const years = range(1920, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (
      registerPatient &&
      registerPatient.data &&
      registerPatient.data.succeeded === true
    ) {
      setformData("");
      setESignModalState(false);
      setTermsModalState(false);
      toast.success("Patient Registration Successful");
      history.push("/user/verify-email");
    } else if (registerPatient && registerPatient.error !== "") {
      setESignModalState(false);
      setTermsModalState(false);
      setAgreement(1);
      toast.error(registerPatient.error);
    }
  }, [registerPatient, history]);

  useEffect(() => {
    return () => {
      dispatch({ type: REGISTER_PATIENT_RESET });
    };
  }, [dispatch]);

  const [formData, setformData] = useState({
    fName: "",
    lName: "",
    birthDate: "",
    gender: "",
    email: "",
    phone: "",
    ethnicity: "",
    clinicPin: "",
    IsCellIncluded: null,
    password: "",
    confirmPassword: "",
    PatientGuardianName: "",
    PatientGuardianRelation: "",
    IseSignature:""
  });
  const [consentForm, setConsentForm] = useState({
    data: {},
    loading: false,
    error: "",
  });
  const [formDataError, setformDataError] = useState({
    fName: "",
    lName: "",
    birthDate: "",
    gender: "",
    email: "",
    phone: "",
    ethnicity: "",
    clinicPin: "",
    IsCellIncluded: null,
    password: "",
    confirmPassword: "",
    IseSignature:""
  });
  const [currentEye, setCurrentEye] = useState(false);
  const [confirmCurrentEye, setConfirmCurrentEye] = useState(false);

  const [termsModalState, setTermsModalState] = useState(false);

  const [eSignModalState, setESignModalState] = useState(false);

  const [agreement, setAgreement] = useState(1);
  const [ip, setIP] = useState("");

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    console.log(res.data);
    setIP(res.data.ip);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  const showPassword = () => {
    var x = document.getElementById("password");
    setCurrentEye(!currentEye);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const showConfirmPassword = () => {
    var y = document.getElementById("confirm-password");
    setConfirmCurrentEye(!confirmCurrentEye);
    if (y.type === "password") {
      y.type = "text";
    } else {
      y.type = "password";
    }
  };

  const onTermsClose = () => {
    setTermsModalState(false);
    setAgreement(1);
    setConsentForm({ data: {}, loading: false, error: "" });
  };

  const onTermsAgree = (e) => {
    e.preventDefault();

    if (consentForm?.data && agreement < consentForm.data.length) {
      setAgreement((prevData) => prevData + 1);
    }else if (!formData.IseSignature && !eSignModalState) {
      // setTermsModalState(false);
      setESignModalState(true);
      // setformDataError({ ...formDataError, IseSignature: "*required" }); 
    }else if (!formData.IseSignature ) {
      setformDataError({ ...formDataError, IseSignature: "*Please enter your eSignature" }); 
    } else {
      let SignedConsentForms = [];
      consentForm.data.map((item) => {
        SignedConsentForms.push({
          ConsentFormId: item.id,
          fileName: item.documentName,
          FormType: item.formType
        })
      });

      const formDataInput = {
        BirthDate: formData.birthDate,
        Email: formData.email,
        FirstName: formData.fName,
        Gender: formData.gender,
        IsCellIncluded: formData.IsCellIncluded,
        LastName: formData.lName,
        Password: formData.password,
        PhoneNo: unMasking(formData.phone),
        Ethnicity: formData.ethnicity,
        ProviderCode: formData.clinicPin,
        websiteId: "98DF879A-A222-4B39-AFA0-FE4C38429EA8",
        SignedConsentForms: SignedConsentForms,
        IPAddress: ip,
        eSignature: formData.IseSignature,
      };
      dispatch(registerPatientAction(formDataInput));
    }
  };

  const getConsentForms = async () => {
    if (formData.clinicPin) {
      setConsentForm({ data: {}, loading: true, error: "" });
      const data = await dispatch(getConsentFormsAction(formData.clinicPin));
      if (data && data.status) {
        setAgreement(1);
        setConsentForm({ data: data.data, loading: false, error: "" });
      } else {
        alert("Invalid Clinic PIN");
        setConsentForm({
          data: {},
          loading: false,
          error: "Invalid Clinic PIN",
        });
        onTermsClose();
      }
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();
    //setTermsModalState(true)
    setBirthDateError(false);
    if (!formData.fName.trim() || formData.fName.trim() === "") {
      setformDataError({
        ...formDataError,
        fName: "Please enter your first name",
      });
    } else if (!validateOnlyChacters(formData.fName)) {
      setformDataError({
        ...formDataError,
        fName: "Name can only be characters",
      });
    } else if (!formData.lName.trim() || formData.lName.trim() === "") {
      setformDataError({
        ...formDataError,
        lName: "Please enter your last name",
      });
    } else if (!validateOnlyChacters(formData.lName)) {
      setformDataError({
        ...formDataError,
        lName: "Name can only be characters",
      });
    } else if (!formData.birthDate.trim() || formData.birthDate.trim() === "" || formData.birthDate.trim() === "Invalid date") {
      setformDataError({ ...formDataError, birthDate: "Please select date of birth" });
    } else if (getDayDiff(formData.birthDate) < 365) {
      setBirthDateError(true);
    } else if (!formData.gender.trim() || formData.gender.trim() === "") {
      setformDataError({
        ...formDataError,
        gender: "Please select gender",
      });
    } else if (!formData.email.trim() || formData.email.trim() === "") {
      setformDataError({ ...formDataError, email: "Please enter an email" });
    } else if (!validateEmail(formData.email)) {
      setformDataError({
        ...formDataError,
        email: "Please enter a valid email",
      });
    } else if (!formData.phone.trim() || formData.phone.trim() === "") {
      setformDataError({
        ...formDataError,
        phone: "Please enter a phone number",
      });
    } else if (!validatePhone(unMasking(formData.phone))) {
      setformDataError({ ...formDataError, phone: "Invalid Phone Number" });
    } else if (!formData.clinicPin.trim() || formData.clinicPin.trim() === "") {
      setformDataError({
        ...formDataError,
        clinicPin: "Please enter Clinic Pin",
      });
    } else if (!formData.ethnicity.trim() || formData.ethnicity.trim() === "") {
      setformDataError({
        ...formDataError,
        ethnicity: "Please select ethnicity",
      });
    } else if (formData.clinicPin && formData.clinicPin.length < 8) {
      setformDataError({
        ...formDataError,
        clinicPin: "Minimum 8 characters required.",
      });
    } else if (!validatePhone(formData.clinicPin)) {
      setformDataError({
        ...formDataError,
        clinicPin: "Clinic PIN must be number.",
      });
    } else if (formData.IsCellIncluded === null) {
      setformDataError({ ...formDataError, IsCellIncluded: "*required" });
    } else if (!formData.password.trim() || formData.password.trim() === "") {
      setformDataError({ ...formDataError, password: "Please enter password" });
    } else if (!validatePassword(formData.password)) {
      setformDataError({
        ...formDataError,
        password:
          "Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.",
      });
    } else if (
      !formData.confirmPassword.trim() ||
      formData.confirmPassword.trim() === ""
    ) {
      setformDataError({
        ...formDataError,
        confirmPassword: "Please confirm your password",
      });
    } else if (formData.confirmPassword !== formData.password) {
      setformDataError({
        ...formDataError,
        confirmPassword: "Password does not match",
      });
    }  else {
      getConsentForms();
      setTermsModalState(true);
    }
    // } else{
    //     setformDataError({...formDataError, confirmPassword: 'Password does not match'})
    // }

    //dispatch(registerPatientAction(formData))
  };



  const onESignModalClose = () => {
    setESignModalState(false); 
  };

  return (
    <main className="loginContainer">
      <div className="clinicContainer__bg" />
      <div className="registerContainer__box">
        <div className="loginContainer__box--right">
          <img
            src="/images/logo.png"
            alt="Suremed Logo"
            className="loginContainer__box--logo"
          />
          <h1 className="loginContainer__heading">REGISTER AS A PATIENT</h1>
          {/* <h1 className='loginContainer__subheading'>Register Patient</h1> */}
        </div>
        <div className="registerPatient__box--right">
          <form className="registerPatient__form">
            <p className="patient-warning__message">
              This page is ONLY for first-time enrollees. If you have
              <span style={{ fontWeight: "bold" }}>
                {" "}
                previously registered for Perspectives in Care
              </span>
              , please visit this
              <a
                className="Patient--warning--link"
                href="https://myportal.perspectivesincare.com/#/login"
              >
                {" "}
                link
              </a>{" "}
              to log in to your account and complete your questionnaire.
              {/* <a className='Patient--warning--link' href='https://portal.perspectivesincare.com/user/login'> link</a> to login to your account and complete your questionnaire. */}
            </p>  

            {/* <h2 className='register__admin-header'>Patient Registration</h2> */}
            <div className="patient__flex-form">
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-user" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="First Name"
                    id="firstName"
                    className="loginContainer__formgroup--input"
                    value={formData.fName}
                    onChange={(event) => {
                      setformData({ ...formData, fName: event.target.value });
                      setformDataError("");
                    }}
                  />
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.fName}
                </p>
              </div>
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-user" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Last Name"
                    id="lastName"
                    className="loginContainer__formgroup--input"
                    value={formData.lName}
                    onChange={(event) => {
                      setformData({ ...formData, lName: event.target.value });
                      setformDataError("");
                    }}
                  />
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.lName}
                </p>
              </div>
            </div>

            <div className="patient__flex-form date-flex">
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-calendar" />
                    </svg>
                  </div>

                  <DatePicker
                    key="birthDate"
                    renderCustomHeader={({
                      date,
                      changeYear,
                      changeMonth,
                      decreaseMonth,
                      increaseMonth,
                      prevMonthButtonDisabled,
                      nextMonthButtonDisabled,
                    }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                          {"<"}
                        </button>
                        <select
                          value={getYear(date)}
                          onChange={({ target: { value } }) => changeYear(value)}
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <select
                          value={months[getMonth(date)]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>

                        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                          {">"}
                        </button>
                      </div>
                    )}
                    selected={startDate}
                    dateFormat="MM-dd-yyyy"
                    ref={(r) => {
                      setComponent(r);
                    }}
                    onChange={(date) => {
                      setStartDate(date);
                      setformData({
                        ...formData,
                        birthDate: moment(date).format("YYYY-MM-DD") // "2013-03-10" 
                      });
                      setformDataError("");
                      if (getDayDiff(date) < 365) {
                        setBirthDateError(true);
                      } else setBirthDateError(false);
                    }}
                    className="loginContainer__formgroup--input"
                    placeholderText="Date of Birth"
                  />
                  <svg
                    style={{ marginRight: "1.6rem", height: "3rem", width: "3rem", fill: "#4b4b4b" }}
                    className="loginContainer__formgroup--icon"
                    onClick={() => {
                      setFocus(focus ? false : true);
                      component.setOpen(focus);
                    }}
                  >
                    <use xlinkHref="/sprite.svg#icon-calendar" />
                  </svg>
                  {/* <input
                    style={{ paddingRight: "1rem" }}
                    type="text"
                    placeholder="Date of Birth"
                    onFocus={(e) => (e.currentTarget.type = "date")}
                    id='birthDate'
                    name='birthDate'
                    className="loginContainer__formgroup--input"
                    value={formData.birthDate}
                    onChange={(event) => {
                      setformData({
                        ...formData,
                        birthDate: event.target.value,
                      });
                      setformDataError("");
                    }}
                  /> */}
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.birthDate}
                </p>
              </div>
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-user" />
                    </svg>
                  </div>
                  <select
                    style={{ marginRight: "1rem" }}
                    type="text"
                    placeholder="Gender"
                    id="gender"
                    name="gender"
                    className="loginContainer__formgroup--input"
                    value={formData.gender}
                    onChange={(event) => {
                      setformData({ ...formData, gender: event.target.value });
                      setformDataError("");
                    }}
                  >
                    <option value="" selected>
                      Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.gender}
                </p>
              </div>
            </div>

            {
              birthDateError &&
              <div className="patient__flex-form date-flex">
                <p
                  className="error_message"
                  style={{ marginLeft: "1.5rem", width: "100%" }}
                >
                  It seems you’ve entered your date of birth as a day within the last year. Please ensure that you enter your birthday correctly before proceeding.
                </p>
              </div>
            }

            <div className="patient__flex-form">
              <div
                className="formgroup--validation"
              >
                <div className="addressContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-email" />
                    </svg>
                  </div>
                  <input
                    type="email"
                    placeholder="johndoe@example.com"
                    id="email"
                    className="loginContainer__formgroup--input"
                    value={formData.email}
                    onChange={(event) => {
                      setformData({ ...formData, email: event.target.value });
                      setformDataError("");
                    }}
                  />
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.email}
                </p>
              </div>
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-phone" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Phone Number"
                    id="phone"
                    className="loginContainer__formgroup--input"
                    value={formData.phone}
                    onChange={(event) => {
                      setformData({
                        ...formData,
                        phone: inputPhoneMasking(event.target.value),
                      });
                      setformDataError("");
                    }}
                  />
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.phone}
                </p>
              </div>
            </div>

            <div className="patient__flex-form">
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-city" />
                    </svg>
                  </div>
                  <select
                    style={{ marginRight: "1rem" }}
                    type="text"
                    placeholder="Ethnicity"
                    id="ethnicity"
                    name="ethnicity"
                    className="loginContainer__formgroup--input"
                    value={formData.ethnicity}
                    onChange={(event) => {
                      setformData({ ...formData, ethnicity: event.target.value });
                      setformDataError("");
                    }}
                  >
                    <option value="" selected>
                      Select Ethnicity
                    </option>
                    <option value="Asian or Pacific Islander"> Asian or Pacific Islander </option>
                    <option value="Black or African American"> Black or African American </option>
                    <option value="Hispanic or Latino"> Hispanic or Latino </option>
                    <option value="Native American or Alaskan Native"> Native American or Alaskan Native </option>
                    <option value="White or Caucasian"> White or Caucasian </option>
                    <option value="Multiracial or Biracial"> Multiracial or Biracial </option>
                    <option value="A race/ethnicity not listed here"> A race/ethnicity not listed here </option>
                  </select>
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.ethnicity}
                </p>
              </div>
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-clinic" />
                    </svg>
                  </div>
                  <input
                    type="text"
                    placeholder="Clinic Pin"
                    id="clinic-pin"
                    className="loginContainer__formgroup--input"
                    value={formData.clinicPin}
                    onChange={(event) => {
                      setformData({
                        ...formData,
                        clinicPin: event.target.value,
                      });
                      setformDataError("");
                    }}
                  />
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.clinicPin}
                </p>
              </div>
            </div>


            <div className="cellphoneContainer">
              <p className="modal__text">
                Is this a cell phone?
              </p>
              <div className="cellphoneContainer--yesno">
                <label className="cellphone-yesno">
                  <span>Yes</span>
                  <input
                    id="active"
                    type="radio"
                    // className="checkbox"
                    value={formData.IsCellIncluded}
                    onChange={() => {
                      setformData({ ...formData, IsCellIncluded: true });
                      setformDataError("");
                    }}
                    name="active"
                  />
                </label>
                <label className="cellphone-yesno">
                  <span>No</span>
                  <input
                    id="active"
                    type="radio"
                    // className="checkbox"
                    value={formData.IsCellIncluded}
                    onChange={() => {
                      setformData({ ...formData, IsCellIncluded: false });
                      setformDataError("");
                    }}
                    name="active"
                  />
                </label>
              </div>

              <p className="error_message" style={{ marginLeft: "1rem" }}>
                {formDataError.IsCellIncluded}
              </p>
            </div>

            <div className="patient__flex-form" style={{ paddingBottom: "0" }}>
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-key" />
                    </svg>
                  </div>
                  <input
                    type="password"
                    placeholder="Password"
                    id="password"
                    className="loginContainer__formgroup--input"
                    value={formData.password}
                    onChange={(event) => {
                      setformData({
                        ...formData,
                        password: event.target.value,
                      });
                      setformDataError("");
                    }}
                  />
                  <svg
                    style={{ margin: "1rem" }}
                    className="loginContainer__formgroup--icon"
                    onClick={showPassword}
                  >
                    {currentEye ? (
                      <use xlinkHref={`/sprite.svg#icon-hidePassword`} />
                    ) : (
                      <use xlinkHref={`/sprite.svg#icon-showPassword`} />
                    )}
                  </svg>
                </div>
                {/* <p className="error_message" style={{ marginLeft: '1.5rem' }}>{formDataError.password}</p> */}
              </div>
              <div className="formgroup--validation">
                <div className="registerContainer__formgroup--inputBox">
                  <div className="logoContainer__formgroup--iconBox">
                    <svg className="loginContainer__formgroup--icon">
                      <use xlinkHref="/sprite.svg#icon-key" />
                    </svg>
                  </div>
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    id="confirm-password"
                    className="loginContainer__formgroup--input"
                    value={formData.confirmPassword}
                    onChange={(event) => {
                      setformData({
                        ...formData,
                        confirmPassword: event.target.value,
                      });
                      setformDataError("");
                    }}
                  />
                  <svg
                    style={{ margin: "1rem" }}
                    className="loginContainer__formgroup--icon"
                    onClick={showConfirmPassword}
                  >
                    {confirmCurrentEye ? (
                      <use xlinkHref={`/sprite.svg#icon-hidePassword`} />
                    ) : (
                      <use xlinkHref={`/sprite.svg#icon-showPassword`} />
                    )}
                  </svg>
                </div>
                <p className="error_message" style={{ marginLeft: "1.5rem" }}>
                  {formDataError.confirmPassword}
                </p>
              </div>
            </div>
            <p
              className={
                formDataError.password ? "error_message" : "loginError"
              }
              style={{ marginLeft: "1.5rem", width: "100%" }}
            >
              Please note that passwords must be at least 8 characters long and
              must include an uppercase letter, lowercase letter, a number, and
              a special character ($, !, #, etc.). <br />
              <hr />
              <span className="error_message" > IMPORTANT: Before proceeding, please double check that your birthdate is correct, and you have entered your full legal name. </span>
            </p>

            <div className="registerContainer__formgroup--btn">
              <button
                id="registerBtn"
                className="registerContainer__button"
                onClick={handleRegister}
                disabled={birthDateError}
              >
                <span className="loginContainer__button--text">Sign Up</span>
                <div className="loginContainer__button--iconBox">
                  <svg className="loginContainer__button--icon">
                    <use xlinkHref="/sprite.svg#icon-login" />
                  </svg>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>

      <PatienteSignModal
        data={{
          eSignModalState,   
          formData,
          formDataError,
          setformData,
          setformDataError,
          onESignModalClose, 
          onSubmitHandler:onTermsAgree,
        }}
      />

      <TermsModal
        data={{
          termsModalState,
          onTermsClose,
          agreement,
          setAgreement,
          onTermsAgree,
          consentForm,
        }}
      />
    </main>
  );
}

export default Index;
