import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import Clinics from './Clinics';

import { validateEmail } from '../../utils/validators';
import {
	addInviteClinicAction,
	deleteInviteClinicAction,
	inviteClinicActions,
	editClinicEmailAction
} from '../../redux/actions/clinicActions';
import { getAllWebsitesAction } from '../../redux/actions/websiteActions';
import {
	INVITE_CLINIC_RESET,
	ADD_INVITE_CLINIC_RESET,
	DELETE_INVITE_CLINIC_RESET,
	UPDATE_INVITE_CLINIC_RESET
} from '../../redux/constants/clinicsConstants';
import { GET_ALL_WEBSITES_RESET } from '../../redux/constants/websiteConstants';

import './InviteClinics.css';
import DeleteInvite from '../../components/Skeletons/DeleteInvite';
import EditInviteEmail from '../../components/Skeletons/EditInviteEmail';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import { toast } from 'react-toastify';

const InviteClinics = ({ history }) => {
	const dispatch = useDispatch();
	const [modalState, setModalState] = useState(false);
	const [modalEditState, setModalEditState] = useState(false);
	const [selectUpdateEmailInvite, setselectUpdateEmailInvite] = useState({});

	const [selectedInvite, setSelectedInvite] = useState({});
	const [deleteEmail, setDeleteEmail] = useState({ value: '', error: '' });
	const [email, setEmail] = useState({ value: '', error: '' });
	const [url, setURL] = useState({ value: 'select-website', error: '' });
	const [editClinicEmail, setEditClinicEmail] = useState({
		value: '',
		error: ''
	});

	const userLogin = useSelector((state) => state.userLogin);
	const activeToken = useSelector((state) => state.activeToken);

	const invitedClinics = useSelector((state) => state.invitedClinics);
	const allWebsites = useSelector((state) => state.allWebsites);
	const inviteClinic = useSelector((state) => state.inviteClinic);
	const deleteInviteClinic = useSelector((state) => state.deleteInviteClinic);
	const editEmailClinic = useSelector((state) => state.editEmailClinic);


	useEffect(
		() => {
			if (!activeToken) {
				history.push('/login');
			} else if (!activeToken.token) {
				history.push('/login');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.applicationUser &&
				userLogin.userInfo.applicationUser.isPatient === true &&
				userLogin.userInfo.applicationUser.isSuperAdmin === false
			) {
				history.push('/questionaires');
			}
		},
		[activeToken, userLogin, history]
	);

	useEffect(
		() => {
			dispatch(inviteClinicActions());
			dispatch(getAllWebsitesAction());
		},
		[dispatch]
	);

	useEffect(
		() => {
			if (inviteClinic && inviteClinic.data) {
				toast.success('Invite Sent')
				dispatch({ type: ADD_INVITE_CLINIC_RESET });
				setEmail({ value: '', error: '' });
				setURL({ value: 'select-website', error: '' });
				dispatch(inviteClinicActions());
			}
		},
		[inviteClinic, dispatch]
	);

	useEffect(
		() => {
			if (deleteInviteClinic && deleteInviteClinic.data) {

				toast.success('Success')
				setDeleteEmail({ value: '', error: '' });
				setModalState(false);
				dispatch(inviteClinicActions());
				dispatch({ type: DELETE_INVITE_CLINIC_RESET });
			}
		},
		[deleteInviteClinic, dispatch]
	);
	useEffect(
		() => {
			if (editEmailClinic && editEmailClinic.data && editEmailClinic.data.adminInviteId) {
				dispatch({ type: UPDATE_INVITE_CLINIC_RESET });
				setEditClinicEmail({ value: '', error: '' });

				setModalEditState(false);
				dispatch(inviteClinicActions());
			}
		},
		[editEmailClinic, dispatch]
	);
	useEffect(
		() => {
			if (selectUpdateEmailInvite && selectUpdateEmailInvite.emailAddress) {
				setEditClinicEmail({ value: selectUpdateEmailInvite.emailAddress });
			}
		},
		[selectUpdateEmailInvite]
	);
	useEffect(
		() => {
			return () => {
				dispatch({ type: INVITE_CLINIC_RESET });
				dispatch({ type: GET_ALL_WEBSITES_RESET });
			};
		},
		[dispatch]
	);

	const onSendInviteHandler = (e) => {
		//e.preventDefault();
		if (!email.value.trim() || email.value.trim() === '') {
			setEmail({ ...email, error: 'Please enter email address' });
		} else if (!validateEmail(email.value)) {
			setEmail({ ...email, error: 'Please enter valid email' });
		} else if (!url.value.trim() || url.value.trim() === '') {
			setURL({ ...url, error: 'Please select website' });
		} else {
			const formData = {
				emailAddress: email.value,
				websiteId: url.value,
				clinicInviteId: '00000000-0000-0000-0000-000000000000',
				website: null
			};
			dispatch(addInviteClinicAction(formData));
		}
	};
	// for edit email
	const onEditClose = () => {
		setModalEditState(false);
		setEditClinicEmail({ value: '', error: '' });
	};

	const onClose = () => {
		setModalState(false);
		setDeleteEmail({ value: '', error: '' });
	};

	const onEditClinicEmailHandler = (e) => {
		//e.preventDefault();
		if (!editClinicEmail.value.trim() || editClinicEmail.value.trim() === '') {
			setEditClinicEmail({ ...editClinicEmail, error: 'Please enter email address' });
		} else if (!validateEmail(editClinicEmail.value)) {
			setEditClinicEmail({ ...editClinicEmail, error: 'Please enter valid email' });
		} else {
			dispatch(
				editClinicEmailAction(selectUpdateEmailInvite.clinicInviteId, {
					emailAddress: editClinicEmail.value
				})
			);
		}
	};
	const onDeleteInviteHandler = (e) => {
		//e.preventDefault();
		if (!deleteEmail.value.trim() || deleteEmail.value.trim() === '') {
			setDeleteEmail({ ...deleteEmail, error: 'Please enter email address' });
		} else if (!validateEmail(deleteEmail.value)) {
			setDeleteEmail({ ...deleteEmail, error: 'Please enter valid email' });
		} else if (selectedInvite && selectedInvite.emailAddress !== deleteEmail.value) {
			setDeleteEmail({ ...deleteEmail, error: `Please enter ${selectedInvite.emailAddress} in above text box` });
		} else {
			dispatch(deleteInviteClinicAction(selectedInvite.clinicInviteId));
		}
	};

	return (
		<Content currentMenu="Invite Clinics" headerTitle="Invite Clinics">
			<Modal show={modalEditState}>
				{editEmailClinic && editEmailClinic.loading ? (
					<EditInviteEmail />
				) : (
					<Fragment>
						<ModalHeading heading="Edit email" onClose={onEditClose} />

						<form className="modalContainer__form" onSubmit={onEditClinicEmailHandler}>
							<ModalTextBox
								type="text"
								label="Email"
								placeholder="johndoe@example.com"
								value={editClinicEmail.value}
								icon="/sprite.svg#icon-email"
								onChange={(event) => {
									setEditClinicEmail({ value: event.target.value });
								}}
								error={editClinicEmail.error}
							/>
						</form>
						<div className="modal__actions">
							<button className="modal__button modal__button--update" onClick={onEditClinicEmailHandler}>
								Update
							</button>
						</div>
					</Fragment>
				)}
			</Modal>

			<Modal show={modalState}>
				{deleteInviteClinic && deleteInviteClinic.loading ? (
					<DeleteInvite />
				) : (
					<Fragment>
						<ModalHeading heading="Delete invite" onClose={onClose} />

						<form className="modalContainer__form" onSubmit={onDeleteInviteHandler}>
							<ModalTextBox
								label={
									<span className="modal__text">
										Please enter <span>{selectedInvite.emailAddress}</span> in text box
									</span>
								}
								type="text"
								placeholder="johndoe@example.com"
								value={deleteEmail.value}
								icon="/sprite.svg#icon-email"
								onChange={(event) => {
									setDeleteEmail({ value: event.target.value });
								}}
								error={deleteEmail.error}
							/>
						</form>
						<div className="modal__actions">
							<button className="modal__button modal__button--delete" onClick={onDeleteInviteHandler}>
								Delete
							</button>
						</div>
					</Fragment>
				)}
			</Modal>
			<Clinics
				data={{
					setModalState,
					setModalEditState,
					email,
					setEmail,
					url,
					setURL,
					onSendInviteHandler,
					allWebsites,
					setSelectedInvite,
					setselectUpdateEmailInvite
				}}
				invitedClinics={invitedClinics}
				inviteClinic={inviteClinic}
			/>
		</Content>
	);
};

export default InviteClinics;
