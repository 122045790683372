
export const CONSENT_DOCUMENTS_REQUEST = 'CONSENT_DOCUMENTS_REQUEST';
export const CONSENT_DOCUMENTS_SUCCESS = 'CONSENT_DOCUMENTS_SUCESSS';
export const CONSENT_DOCUMENTS_FAIL = 'CONSENT_DOCUMENTS_FAIL';
export const CONSENT_DOCUMENTS_RESET = 'CONSENT_DOCUMENTS_RESET'; 

export const CHANGE_CONSENT_DOCUMENTS_STATUS_REQUEST = 'CHANGE_CONSENT_DOCUMENTS_STATUS_REQUEST';
export const CHANGE_CONSENT_DOCUMENTS_STATUS_SUCCESS = 'CHANGE_CONSENT_DOCUMENTS_STATUS_SUCCESS';
export const CHANGE_CONSENT_DOCUMENTS_STATUS_FAIL = 'CHANGE_CONSENT_DOCUMENTS_STATUS_FAIL';
export const CHANGE_CONSENT_DOCUMENTS_STATUS_RESET = 'CHANGE_CONSENT_DOCUMENTS_STATUS_RESET';  
