import React, { Fragment, useState } from 'react';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { formatter, validateEmail } from '../../../utils/validators';
import PromoCodeAccordion from '../PromoCodeAccordion/PromoCodeAccordion';
import {
  addPaymentMethodAction,
  confirmPayment,
  validatePromoCodeAction,
} from '../../../redux/actions/paymentAction';
import { toast } from 'react-toastify';
import SubscriptionCard from '../SubscriptionCard';
import SubscriptionPaymentSkeleton from '../../../components/Skeletons/SubscriptionPaymentSkeleton';
import SubscriptionStyle from '../SubscriptionScreen.module.css';
import styles from './StripePayment.module.css';
import { promo_code } from '../../../redux/actions/ip';

const StripePayment = ({ data }) => {
  const { handleBack, selectPlan, supportPlan, setShowOrderSummary, setPaymentDetails } = data;

  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [nameOnCardError, setNameOnCardError] = useState('');

  const markedPrice = selectPlan?.metadata?.price
    ? selectPlan?.metadata?.price?.split('$')[1].split(',').join('')
    : 0;

  const [promoCodeText, setPromoCodeText] = React.useState('');
  const [promoCodeDiscount, setPromoCodeDiscount] = React.useState(0);
  const [promoCodeDetail, setPromoCodeDetail] = React.useState({});
  const [processingPayment, setProcessingPayment] = React.useState(false);

  React.useEffect(() => {
    if (promoCodeDetail) {
      const discountAmount =
        promoCodeDetail?.coupon?.metadata?.value_type &&
          promoCodeDetail?.coupon?.metadata?.value_type === 'percentage'
          ? percentage(promoCodeDetail.coupon.percent_off, markedPrice)
          : promoCodeDetail &&
            promoCodeDetail?.coupon?.metadata?.value_type &&
            promoCodeDetail?.coupon?.metadata?.value_type === 'fixed_amount'
            ? promoCodeDetail.coupon.amount_off / 100
            : 0;

      setPromoCodeDiscount(discountAmount);
    }
  }, [promoCodeDetail, markedPrice]);

  const handleOnChangePromo = (e) =>
    setPromoCodeText(e.target.value?.toUpperCase());

  const handleSubmitPromo = async () => {
    setPromoCodeDetail({});
    if (promoCodeText.trim() === '') {
      alert('Please enter promo code');
    } else {
      const data = await validatePromoCodeAction(promoCodeText);
      if (data?.status) {
        const resData = JSON.parse(data?.data);
        const promoCodeData =
          resData?.data &&
          resData.data.length > 0 &&
          resData.data[0] &&
          resData.data[0];
        setPromoCodeDetail(promoCodeData);
        toast.success('Promo code applied');
      } else if (!data?.status) {
        toast.error(data?.message);
        setPromoCodeText('');
      } else {
        alert('Something went wrong, please try again later');
      }
    }
  };

  const addNewPaymentMethodHandler = async () => {
    if (nameOnCard.trim() === '') {
      setNameOnCardError('Please enter your name on card');
    } else if (email.trim() === '') {
      setEmailError('Please enter email address');
    } else if (!validateEmail(email)) {
      setEmailError('Please enter valid email address');
    } else {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: {
          address: {
            // city: state.city,
            // country: countryObj.iso2,
            // line1: state.address,
            // line2: state.appartment,
            // postal_code: state.zip,
            // state: state.state
          },
          name: nameOnCard,
          email: email,
          // phone: null
        },
      });

      if (!error) {
        setProcessingPayment(true);
        let paymentData = {};
        const { id } = paymentMethod;
        paymentData.id = id;
        paymentData.isDefault = true;
        if (paymentData.id) {
          const formData = {
            payment_method: paymentData.id,
            email: email,
            name: nameOnCard,
            plan: selectPlan?.default_price,
            supportPlan: supportPlan?.id,
          };

          if (Object.keys(promoCodeDetail).length > 0 && promoCodeDetail?.id) {
            formData.promotion_code = promoCodeDetail.id;
          }

          if (selectPlan && selectPlan.name === 'First 250 Clinics') {
            formData.promotion_code = promo_code;
          }

          const data = await addPaymentMethodAction(formData);
          if (data.status) {
            const {
              dataList,
              status,
            } = data;
            var SubscriptionObj = dataList.filter(item => item.type === "payment_subscription");
            if (dataList[0].status === 'succeeded' || dataList[1].status === 'succeeded') {
              const confirmFormData = {
                email,
                nameOnCard,
                stripeCustomerID: SubscriptionObj[0].customerStripeID,
                stripeSubscriptionID: SubscriptionObj[0].stripeSubscriptionID,
                paymentStatus: true,
              };
              let confirmResData = await confirmPayment(confirmFormData);
              if (confirmResData.statusCode) {
                setPaymentDetails(confirmResData)
              }
              setNameOnCard('');
              setEmail('');
              setProcessingPayment(false);
              setShowOrderSummary(true);
              toast.success('Your payment is successful');
            } else if (dataList[0].status === 'requires_action' || dataList[1].status === 'requires_action') {
              if (dataList[0] && dataList[0].client_secret) {
                stripe
                  .confirmCardPayment(dataList[0].client_secret)
                  .then(async function (result) {
                    if (result.error) {
                      toast.error(result.error?.message);
                    } else {
                      if (dataList[1] && dataList[1].client_secret) {
                        stripe
                          .confirmCardPayment(dataList[1].client_secret)
                          .then(async function (result) {
                            if (result.error) {
                              toast.error(result.error?.message);
                              setNameOnCard('');
                              setEmail('');
                              setProcessingPayment(false);
                            } else {
                              const confirmFormData = {
                                email,
                                nameOnCard,
                                stripeCustomerID: SubscriptionObj[0].customerStripeID,
                                stripeSubscriptionID: SubscriptionObj[0].stripeSubscriptionID,
                                paymentStatus: true,
                              };
                              let confirmResData = await confirmPayment(confirmFormData);
                              if (confirmResData.statusCode) {
                                setPaymentDetails(confirmResData)
                              }
                              setShowOrderSummary(true);
                              setNameOnCard('');
                              setEmail('');
                              setProcessingPayment(false);
                              toast.success('Your payment is successful');
                            }
                          });
                      } else {
                        const confirmFormData = {
                          email,
                          nameOnCard,
                          stripeCustomerID: SubscriptionObj[0].customerStripeID,
                          stripeSubscriptionID: SubscriptionObj[0].stripeSubscriptionID,
                          paymentStatus: true,
                        };
                        let confirmResData = await confirmPayment(confirmFormData);
                        if (confirmResData.statusCode) {
                          setPaymentDetails(confirmResData)
                        }
                        setShowOrderSummary(true);
                        setNameOnCard('');
                        setEmail('');
                        setProcessingPayment(false);
                        toast.success('Your payment is successful');
                      }
                    }
                  });
              }
            } else {
              toast.error(data.message);
              setNameOnCard('');
              setEmail('');
              setProcessingPayment(false);
            }
          }
        }
      }
    }
  };

  const percentage = (percent, total) => {
    return ((percent / 100) * total).toFixed(2);
  };

  return (
    <React.Fragment>
      {processingPayment ? (
        <SubscriptionPaymentSkeleton />
      ) : (
        <div className={styles.paymentPage}>
          <SubscriptionCard
            data={selectPlan}
            hideBtn={true}
            customHeader='Selected Plan Details'
            chooseHandler={() => { }}
            handleClick={handleBack}
            style={{
              backgroundColor: 'var(--white)',
              border: 'none',
              gridColumn: '1 / 2',
              gridRow: '1 / 2',
            }}
          />
          <div className={styles.paymentContainer}>
            <div className={SubscriptionStyle.order_summary_container}>
              <div className={SubscriptionStyle.summary_item}>
                <div>
                  <h3 className={SubscriptionStyle.item_title}>
                    {selectPlan?.name}
                  </h3>
                  <p className={SubscriptionStyle.item_description}>
                    {selectPlan?.description?.split('\\n').join(' ')}
                  </p>
                </div>
                <h3 className={SubscriptionStyle.payment_card_price}>
                  {selectPlan?.metadata?.price}
                </h3>
              </div>
              {
                supportPlan &&
                <div className={SubscriptionStyle.summary_item}>
                  <div>
                    <h3 className={SubscriptionStyle.item_title}>
                      {selectPlan?.metadata?.implementation_fee_text}
                    </h3>
                  </div>
                  <h3 className={SubscriptionStyle.payment_card_price}>
                    ${supportPlan?.unit_amount / 100}
                  </h3>
                </div>}

              {markedPrice > 0 && (
                <Fragment>
                  {/* <div className={SubscriptionStyle.promo_code}>
                    <PromoCodeAccordion
                      title={'Promo Code?'}
                      style={{ marginBottom: '1rem', display: 'flex' }}
                      data={{
                        promoCode: promoCodeText,
                        handleOnChange: handleOnChangePromo,
                        handleOnSubmit: handleSubmitPromo,
                        disable: promoCodeText.length === 0,
                      }}
                    />
                  </div> */}

                  {/* <div className={SubscriptionStyle.dis_item}>
                    <div>
                      <p className={SubscriptionStyle.dis_item_title}>
                        Discount
                      </p>
                      {promoCodeDiscount > 0 && (
                        <p className={SubscriptionStyle.dis_item_dis}>
                          Promocode discount is applied
                        </p>
                      )}
                    </div>
                    <h3 className={SubscriptionStyle.dis_price}>
                      ${promoCodeDiscount}
                    </h3>
                  </div> */}

                  <div className={SubscriptionStyle.total_price}>
                    <p className={SubscriptionStyle.order_summary_total}>
                      Total amount to be paid today
                    </p>
                    <h1 className={SubscriptionStyle.order_summary_heading}>
                      <span>USD</span>{' '}
                      {supportPlan ?
                        formatter.format(Number(markedPrice) - Number(promoCodeDiscount) + Number(supportPlan.unit_amount / 100))
                        :
                        formatter.format(
                          Number(markedPrice) - Number(promoCodeDiscount)
                        )}
                    </h1>
                  </div>
                </Fragment>
              )}
            </div>

            <div className={styles.stripePayment__form}>
              <div className={styles.paymentCardContainer}>
                <div
                  className={
                    styles.stripePayment__formgroup +
                    ' ' +
                    styles.stripePayment__formgroup1
                  }
                >
                  <label
                    htmlFor='nameOnCard'
                    className={styles.stripePayment__formgroup_label}
                  >
                    Name on card
                  </label>
                  <div className={styles.stripePayment__formgroup_inputBox}>
                    <div className={styles.stripePayment__formgroup_iconBox}>
                      <svg className={styles.stripePayment__formgroup_icon}>
                        <use xlinkHref='/sprite.svg#icon-user' />
                      </svg>
                    </div>
                    <input
                      type='text'
                      placeholder='John Smith'
                      id='nameOnCard'
                      autoComplete='off'
                      value={nameOnCard}
                      onChange={(e) => {
                        setNameOnCardError('');
                        setNameOnCard(e.target.value);
                      }}
                      className={styles.stripePayment__formgroup_input}
                    />
                  </div>
                  <p className='error_message'>{nameOnCardError}</p>
                </div>

                <div
                  className={
                    styles.stripePayment__formgroup +
                    ' ' +
                    styles.stripePayment__formgroup2
                  }
                >
                  <label
                    htmlFor='cardNumber'
                    className={styles.stripePayment__formgroup_label}
                  >
                    Card Number
                  </label>
                  <div className={styles.stripePayment__formgroup_inputBox}>
                    <div className={styles.stripePayment__formgroup_iconBox}>
                      <svg className={styles.stripePayment__formgroup_icon}>
                        <use xlinkHref='/sprite.svg#icon-credit' />
                      </svg>
                    </div>
                    <div className={styles.paymentCardNumber}>
                      <CardNumberElement
                        options={{
                          showIcon: false,
                          style: {
                            base: {
                              iconColor: '#fff',
                              color: '#000',
                              fontFamily: 'inherit',
                              fontSize: '14px',
                              fontWeight: '400',
                              fontSmoothing: 'antialiased',
                              ':-webkit-autofill': {
                                color: '#000',
                              },
                              '::placeholder': {
                                color: '#00000085',
                                fontWeight: '400',
                                fontFamily: 'inherit',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    styles.stripePayment__formgroup +
                    ' ' +
                    styles.stripePayment__formgroup3
                  }
                >
                  <label
                    htmlFor='cardNumber'
                    className={styles.stripePayment__formgroup_label}
                  >
                    Expiration Date
                  </label>
                  <div className={styles.stripePayment__formgroup_inputBox}>
                    <div className={styles.stripePayment__formgroup_iconBox}>
                      <svg className={styles.stripePayment__formgroup_icon}>
                        <use xlinkHref='/sprite.svg#icon-calendar' />
                      </svg>
                    </div>
                    <div className={styles.paymentCardNumber}>
                      <CardExpiryElement
                        options={{
                          style: {
                            base: {
                              iconColor: '#fff',
                              color: '#000',
                              fontFamily: 'inherit',
                              fontSize: '14px',
                              fontWeight: '400',
                              fontSmoothing: 'antialiased',
                              ':-webkit-autofill': {
                                color: '#000',
                              },
                              '::placeholder': {
                                color: '#00000085',
                                fontWeight: '400',
                                fontFamily: 'inherit',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    styles.stripePayment__formgroup +
                    ' ' +
                    styles.stripePayment__formgroup4
                  }
                >
                  <label
                    htmlFor='cardNumber'
                    className={styles.stripePayment__formgroup_label}
                  >
                    CVC Number
                  </label>
                  <div
                    className={styles.stripePayment__formgroup_inputBox}
                  // style={{ width: '8vw' }}
                  >
                    <div className={styles.stripePayment__formgroup_iconBox}>
                      <svg className={styles.stripePayment__formgroup_icon}>
                        <use xlinkHref='/sprite.svg#icon-cvc' />
                      </svg>
                    </div>
                    <div className={styles.paymentCardNumber}>
                      <CardCvcElement
                        options={{
                          style: {
                            base: {
                              iconColor: '#fff',
                              color: '#000',
                              fontFamily: 'inherit',
                              fontSize: '14px',
                              fontWeight: '400',
                              fontSmoothing: 'antialiased',
                              ':-webkit-autofill': {
                                color: '#000',
                              },
                              '::placeholder': {
                                color: '#00000085',
                                fontWeight: '400',
                                fontFamily: 'inherit',
                              },
                            },
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className={
                    styles.stripePayment__formgroup +
                    ' ' +
                    styles.stripePayment__formgroup5
                  }
                >
                  <label
                    htmlFor='email'
                    className={styles.stripePayment__formgroup_label}
                  >
                    Email Address
                  </label>
                  <div className={styles.stripePayment__formgroup_inputBox}>
                    <div className={styles.stripePayment__formgroup_iconBox}>
                      <svg className={styles.stripePayment__formgroup_icon}>
                        <use xlinkHref='/sprite.svg#icon-email' />
                      </svg>
                    </div>
                    <input
                      type='text'
                      placeholder='johndoe@example.com'
                      id='email'
                      autoComplete='off'
                      value={email}
                      onChange={(e) => {
                        setEmailError('');
                        setEmail(e.target.value);
                      }}
                      className={styles.stripePayment__formgroup_input}
                    />
                  </div>
                  <p className='error_message'>{emailError}</p>
                </div>
              </div>
            </div>
            {markedPrice <= 0 && (
              <div className={styles.stripePayment__button_layout}>
                <button
                  className={styles.stripePayment__button}
                  onClick={addNewPaymentMethodHandler}
                >
                  <span className={styles.stripePayment__button_text}>
                    SAVE CARD
                  </span>
                  <div className={styles.stripePayment__button_iconBox}>
                    <svg className={styles.stripePayment__button_icon}>
                      <use xlinkHref='/sprite.svg#icon-login' />
                    </svg>
                  </div>
                </button>
              </div>
            )}
          </div>

          {markedPrice > 0 && (
            <div className={styles.stripePayment__button_layout}>
              <button
                className={styles.stripePayment__button}
                onClick={addNewPaymentMethodHandler}
                disabled={!nameOnCard || !email || !validateEmail(email)}
              >
                <span className={styles.stripePayment__button_text}>
                  MAKE PAYMENT
                </span>
                <div className={styles.stripePayment__button_iconBox}>
                  <svg className={styles.stripePayment__button_icon}>
                    <use xlinkHref='/sprite.svg#icon-login' />
                  </svg>
                </div>
              </button>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default StripePayment;
