import React, { useState } from 'react';
import classes from './SelectBox.module.css';
import Select, { components } from 'react-select';

const MultiSelectBox = ({ children, icon, name, label, value, onChange, errorMessage, multiple, inputValue, onInputChange }) => {
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      backgroundColor: '#FFF',
      zIndex: 999,
      width: '100%',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? '#000' : '#000',
      backgroundColor: isSelected ? '#FFF' : '#FFF',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 999,
    }),
  };

  const NoOptionsMessage = props => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>No users found with name</span>
      </components.NoOptionsMessage>
    );
  };
  return (
    <div className={classes.form_input}>
      {label && (
        <label htmlFor={label} className={classes.form_input__label}>
          {label}
        </label>
      )}
      <div className={classes.textBox}>
        {icon && (
          <label className={classes.textBox__label} style={{ top: '0rem' }}>
            <svg className={classes.textBox__label_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${icon}`} />
            </svg>
          </label>
        )}
        <div className={classes.textBox__input}>
          <Select
            value={value}
            isMulti={multiple}
            options={children}
            inputValue={inputValue}
            onChange={onChange}
            styles={colourStyles}
            placeholder={label + ' - [Patient Name (DOB)]'}
            className={classes.textBox__input_box}
            onInputChange={onInputChange}
          />
        </div>
      </div>

      {errorMessage && <p className={classes.form_input__error}>{errorMessage}</p>}
    </div>
  );
};



export const MultiSelectBoxNew = (props) => {
  const colourStyles = {
    menuList: styles => ({
      ...styles,
      backgroundColor: '#FFF',
      zIndex: 999,
      width: '100%',
    }),
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: isSelected ? '#000' : '#000',
      backgroundColor: isSelected ? '#FFF' : '#FFF',
      zIndex: 1,
    }),
    menu: base => ({
      ...base,
      zIndex: 999,
    }),
  }; 
  return (
    <div className={classes.form_input}>
      {props.label && (
        <label htmlFor={props.label} className={classes.form_input__label}>
          {props.label}
        </label>
      )}
      <div className={classes.textBox}>
        {props.icon && (
          <label className={classes.textBox__label} style={{ top: '0rem' }}>
            <svg className={classes.textBox__label_icon}>
              <use xlinkHref={`/assets/sprite.svg#icon-${props.icon}`} />
            </svg>
          </label>
        )}
        <div className={classes.textBox__input}>
          <Select 
            isMulti={props.multiple} 
            options={props.children}
            inputValue={props.inputValue} 
            styles={colourStyles}
            placeholder={props.label + ' - [Patient Name (DOB)]'}
            className={classes.textBox__input_box}
            onInputChange={props.onInputChange}
            value={props.value ? props.value : null}
            onChange={(selected) => { 
              props.multiple &&
                selected.length &&
                selected.find((option) => option.value === "all")
                ? props.onChange(props.children.slice(1)) 
                : props.onChange(selected);  
            }}
          />
        </div>
      </div>

      {props.errorMessage && <p className={classes.form_input__error}>{props.errorMessage}</p>}
    </div>
  );
};


export default MultiSelectBox;

