import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import './videoList.css'
import { addDocumentHistoryAction, contentPdfViewAction, getSponserDisclosureAction } from '../../redux/actions/userActions';
import { videoDetailsAction } from '../../redux/actions/providerActions'
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import VideoModal from '../ContentScreen/VideoModal';
import PlayVideoModal from './PlayVideoModal';
import Content from '../../components/Content';
import { VIDEO_DETAILS_RESET } from '../../redux/constants/providerConstants';
import { CONTENT_VIDEO_RESET } from '../../redux/constants/userConstants';
import { BASE_URL_LOGIN } from '../../redux/actions/ip';
import { useHistory } from 'react-router-dom';
import PdfView from './pdfView';


const Index = ({ match }) => {
    const name = match.params.name;
    const dispatch = useDispatch();
    const history = useHistory();
    const videoDetails = useSelector((state) => state.videoDetails);

    const [videoModalState, setVideoModalState] = useState(false)
    const [playVideoModalState, setPlayVideoModalState] = useState(false)
    const [contentId, setContentId] = useState('');


    const [pdfModalState, setPdfModalState] = useState(false)
    const [acceptPrescription, setAcceptPrescription] = useState(false)
    const [PrescriptionError, setPrescriptionError] = useState('')
    // const [selectSponser, setSelectSponser] = useState(null);
    const [disclosureURL, setDisclosureURL] = useState(null);

    useEffect(
        () => {
            if (name) {
                dispatch(videoDetailsAction(name))
            }
        },
        [name, dispatch]
    );

    useEffect(
        () => {
            if (videoDetails && videoDetails.data && videoDetails.data.length > 0) {
                SponserEventHandler(videoDetails)
            }
        },
        [videoDetails]
    );

    const videoWarningClose = () => {
        setPlayVideoModalState(false)
    }

    const onVideoClose = () => {
        var formData = {
            videoid: contentId,
            isvideo: true,
            isstart: false
        };
        dispatch(addDocumentHistoryAction(formData))
        setVideoModalState(false)
    }

    const playVideo = () => {
        setVideoModalState(true)
        setPlayVideoModalState(false)
        // setContentId(video.id)
    }

    const handleOnPlay = () => {
        var formData = {
            videoid: contentId,
            isvideo: true,
            isstart: true
        };
        dispatch(addDocumentHistoryAction(formData))
    }

    const handleOnPause = () => {
        var formData = {
            videoid: contentId,
            isvideo: true,
            isstart: false
        };
        dispatch(addDocumentHistoryAction(formData))
    };
    const handleOnEnded = () => {
        var formData = {
            videoid: contentId,
            isvideo: true,
            isstart: false
        };
        dispatch(addDocumentHistoryAction(formData))
        setVideoModalState(false);
    };
    useEffect(
        () => {
            return () => {
                dispatch({ type: VIDEO_DETAILS_RESET });
                dispatch({ type: CONTENT_VIDEO_RESET });
            };
        },
        [dispatch]
    );



    const onPdfClose = () => {
        if (acceptPrescription) {
            setAcceptPrescription(false)
            setPrescriptionError('')
            setPdfModalState(false)
        } else {
            history.push('/clinic/home')
        }
    }

    const SponserEventHandler = async (parent) => {
        // setSelectSponser(parent);
        const resp = await dispatch(getSponserDisclosureAction(name, "disclosure"));
        if (resp && resp.length > 0) {
            const disclosure_data = await dispatch(contentPdfViewAction(resp[0].id));
            if (disclosure_data && disclosure_data.status) {
                setAcceptPrescription(false)
                setPrescriptionError('')
                setDisclosureURL(disclosure_data.url);
                setPdfModalState(true)
            }
        }
        //  else if (acceptPrescription && parent.parentId === 12) {
        //     history.push('/clinic/videolist/' + name)
        //     setPdfModalState(false)
        // } else if (parent.parentId === 12) {
        //     history.push('/clinic/videolist/' + name)
        //     setPdfModalState(false)
        // } else {
        //     setPrescriptionError('Please attest above.')
        // }
    }

    const handleAccept = () => {
        if (acceptPrescription) {
            setPdfModalState(false)
        } else {
            setPrescriptionError('Please attest above.')
        }
    }
    return (
        <Content
            current="partner" headerTitle={name && name}
            currentMenu='Partner Resources'
        >
            <VideoModal
                data={{
                    videoModalState,
                    onVideoClose,
                    contentId,
                    handleOnPlay,
                    handleOnPause,
                    handleOnEnded
                }}

            />
            <PlayVideoModal
                data={{
                    playVideoModalState,
                    videoWarningClose,
                    playVideo
                }}
            />
            <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', marginBottom: '2rem' }}>
                <h2>Videos</h2>
                <div style={{ display: 'flex', gap: '5rem' }}>
                    {videoDetails && videoDetails.data &&
                        videoDetails.data.map((video, index) => (video.isVideo ?
                            <div
                                key={index + 1}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '1rem'
                                }}>
                                <img style={{maxWidth:'40rem'}} alt={video.id} src={`${BASE_URL_LOGIN}/VideoMaster/getThumbFileById?fileId=${video.id}`} />
                                <h2>{video.title}</h2>
                                <span>{video.description}</span>
                                <ModalButtonPrimary
                                    style={{
                                        width: '15rem',
                                        marginTop: '1rem'
                                    }}
                                    title='Watch Video'
                                    onClick={() => {
                                        //handleVideo()
                                        setContentId(video.id)
                                        setPlayVideoModalState(true)
                                    }} />
                            </div>
                            : null
                        ))}
                </div>
            </div>
            <div style={{ display: 'flex', gap: '2rem', flexDirection: 'column', paddingBottom: '2rem' }}>
                <h2>Document</h2>
                <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                    {videoDetails && videoDetails.data &&
                        videoDetails.data.map((video, index) => (
                            (video.type==="disclosure" || video.webURL) ?
                            null
                            :
                            !video.isVideo ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '2rem'
                                    }}
                                    key={index + 1}
                                >
                                    <svg className='videoDoc__icon' >
                                        <use xlinkHref={`/sprite.svg#icon-pdf`} />
                                    </svg>
                                    <h3 dangerouslySetInnerHTML={{ __html: video.title }} />
                                    {
                                        video.description !== 'null' ?
                                            <span>{video.description}</span> :
                                            <span> &nbsp;</span>
                                    }

                                    <a href={`${BASE_URL_LOGIN}/VideoMaster/getFileById?fileId=${video.id}`}>
                                        <ModalButtonPrimary
                                            style={{
                                                marginTop: '1rem'
                                            }}
                                            title='Download PDF'
                                            onClick=''
                                        />
                                    </a>
                                </div>
                                : null))}
                </div>
            </div>
            <div className='urls-text' style={{ display: 'flex', gap: '2rem', flexDirection: 'column', paddingBottom: '2rem' }}>
                <h2>URLs</h2>
                <div style={{ display: 'flex', gap: '2rem', alignItems: 'center' }}>
                    {videoDetails && videoDetails.data &&
                        videoDetails.data.map((video, index) => (
                            !video.isVideo && video.webURL ?
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '2rem'
                                    }}
                                    key={index + 1}
                                >
                                    <a className='filter__heading--btn' href={video.webURL} target="_blank">{video.title}</a>
                                </div>
                                : null))}
                </div>
            </div>


            <PdfView
                data={{
                    disclosureURL,
                    pdfModalState,
                    onPdfClose,
                    handleAccept,
                    setAcceptPrescription,
                    acceptPrescription,
                    PrescriptionError,
                    setPrescriptionError
                }}
            />
        </Content>
    )
}

export default Index
