import React, { Fragment } from "react";

const ConsentFormsRegister = () => {
  return (
    <Fragment>
      <div className="termsContainer">
        <div className="pdf_frame_register skeleton" />
        {/* <div className={styles.card_content}>
          <div className={styles.card_default}>
            <div className={styles.card_pdf}>
              <div
                className={styles.card_pdf_frame + " card_pdf_frame skeleton"}
              />
              <div className={styles.card_pdf_content}>
                <div className="pdf_heading skeleton" />
                <div className="pdf_button skeleton" />
              </div>
            </div>

            <div
              className={
                styles.card_select_default + " " + styles.card_upload_input
              }
            >
              <div className="select_default_heading skeleton" />
              <div className={styles.card_default_content}>
                <div className="select_default_dropdown skeleton" />
                <div className="select_default_button skeleton" />
              </div>
            </div>
          </div>

          <div
            className={styles.card_line}
            style={{
              height: "40rem",
            }}
          />

          <div className={styles.card_uploaded}>
            <div className={styles.card_pdf}>
              <div
                className={styles.card_pdf_frame + " card_pdf_frame_2 skeleton"}
              />
              <div className={styles.card_pdf_content}>
                <div className="pdf_heading_2 skeleton" />
                <div className="pdf_button_2 skeleton" />
              </div>
            </div>

            <div className={styles.card_upload}>
              <div className="select_default_heading skeleton" />
              <div className={styles.card_upload_input}>
                <div className="card_upload_label skeleton" />
                <div className="card_upload_input skeleton" />
              </div>
              <div className="card_upload_btn skeleton" />
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );
};
export default ConsentFormsRegister;
