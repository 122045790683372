import React, {useState} from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection, InputsSection2 } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import UploadInput from '../../components/Modal/UploadInput';

export const AddClient = ({ data }) => {
	const {
		addModalState,
		onCloseAddModal,
		handleAddClient,
		addData,
		setAddData,
		AddClientSkeleton,
		addClient,
		addDataError,
		setAddDataError,
		previewImage,
		setPreviewImage
	} = data;

	return (
		<Modal show={addModalState}>
			<ModalHeading heading="Add Client" onClose={onCloseAddModal} />
			{
				addClient && addClient.loading?
				<AddClientSkeleton/>
				:
				<ModalForm onSubmit={handleAddClient} style={{ marginBottom: '2.5rem' }}>
				<InputsSection
				style = {{display: 'flex',
				justifyContent: 'flex-start', flexDirection: 'row'}}
				>
					<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-user"
						label="Name"
						id="clientName"
						placeholder="Name"
						value={addData.clientname}
						onChange={(event) => {
							setAddData({...addData, clientname: event.target.value })
							setAddDataError({...addDataError, clientname: '' })
						}}
						error={addDataError.clientname}
					/>
						{/* <ModalTextBox
						type="text"
						icon="/sprite.svg#icon-provider"
						label="Company Name"
						id="Email"
						placeholder="Jon's Clinic"
						error={addDataError.providername}
						value={addData.providername}
						onChange={(e)=> setAddData({...addData ,providername: e.target.value})}
					/> */}
					{/* <p className='error_message__deleteClient'>{addDataError.clientname}</p> */}

					<div className='modal_input' >
						<label htmlFor="email" className="modal_input--label">
							Based On
						</label>
						<div className='modal_input--box'>
							<label htmlFor="email" className="modal_input--iconBox">
								<svg className="modal_input--icon">
									<use xlinkHref="/sprite.svg#icon-login" />
								</svg>
							</label>
							<select
								className='filter__input--select'
								value={addData.cloneId}
								onChange={(e) => {
									setAddData({ ...addData, cloneId: e.target.value })
									setAddDataError({...addDataError, cloneId: '' })
								}}
								name='partner'
								id='partner'>
								<option value=''>Please Select</option>
								<option value='1'>Perspectives In Care</option>
								<option value='2'>Care Continuity Program</option>
							 </select>
						</div>
						<p className='error_message__deleteClient'>{addDataError.cloneId}</p>
					</div>
				</InputsSection>
				<div className="input__uploadContainer" style={{ display: 'flex', gap: '2rem' }}>
					<InputsSection2 style={{width: '50%'}}>
						<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-provider"
						label="Company Name"
						id="Email"
						placeholder="Jon's Clinic"
						error={addDataError.providername}
						value={addData.providername}
						onChange={(e)=> {setAddData({...addData ,providername: e.target.value})
						setAddDataError({...addDataError, providername: '' })	}}

						/>
						<ModalTextBox
							type="text"
							icon="/sprite.svg#icon-email"
							label="Contact Person Email"
							id="Email"
							placeholder="jondoe@example.com"
							error={addDataError.emailaddress}
							value={addData.emailaddress}
						onChange={(event) => {
							setAddData({...addData, emailaddress: event.target.value })
							setAddDataError({...addDataError, emailaddress: '' })
						}}
						/>

						<ModalTextBox
							type="text"
							icon="/sprite.svg#icon-phone"
							label="Contact Person Phone"
							id="Email"
							placeholder="123 456 7890"
							error={addDataError.phoneno}
							value={addData.phoneno}
						onChange={(event) => {
							setAddData({...addData, phoneno: event.target.value })
							setAddDataError({...addDataError, phoneno: '' })
						}}
						/>

					</InputsSection2>

					<InputsSection style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
						{/* {previewImage?
						<div className="ManageClients__option--uploadContainer">
							<svg className="ManageClients__option--upload">
								<use xlinkHref={`/sprite.svg#icon-edit2`} />
							</svg>
						</div>
						:
						null
						} */}
						<UploadInput
							previewImage={previewImage}
							value={addData.file}
							style={{ alignItems: 'center', justifyContent: 'center', width: '15vw', height: '15rem' }}
							label="Upload a logo"
							error=""
							onChange={(event)=>
								{
									setAddDataError({...addDataError, file: '' })
									const sizeLimit = 100_000; // 100 kilobyte
									const {size:fileSize } = event.target.files[0];

									if(fileSize > sizeLimit){
										setAddDataError({...addDataError, file: 'size upto 100Kb is allowed' })
									 }
									//   else if(event.target.files[0] !== '') {

									// 	var img = new Image();
									// 	var objectUrl = _URL.createObjectURL(event.target.files[0]);
									// 	img.onload = function() {
									// 		alert('inside')
									// 		// alert(this.width + " " + this.height);
									// 		_URL.revokeObjectURL(objectUrl);
									// 	}
									// 	img.src = objectUrl;
									// }
									 else {
										const reader = new FileReader();
										reader.onload = () =>{

									  if(reader.readyState === 2){
										setPreviewImage(reader.result);
										setAddData({...addData, file: event.target.files[0]})

									  }
									}
									reader.readAsDataURL(event.target.files[0])
									}
								}
							}
						></UploadInput>
						{previewImage === ''?
						<p className='error_message__deleteClient'>Max Resolution allowed 290x110</p>
						:
						null}
						<p className='error_message__deleteClient'>{addDataError.file}</p>
					</InputsSection>
				</div>

				<ModalButtonPrimary title="Add Client" onClick={handleAddClient} />
			</ModalForm>
			}

		</Modal>
	);
};
export const EditClient = ({ editModalState, onEditClose, data}) => {
	const {setEditData, editData, handleEditClient, AddClientSkeleton, editClient, addDataError, setAddDataError} = data;
	const [previewImage, setPreviewImage] = useState('')
	return (
		<Modal show={editModalState}>
			<ModalHeading heading="Edit Client" onClose={onEditClose} />
			{editClient && editClient.loading?

			<AddClientSkeleton/> :

			<ModalForm onSubmit={handleEditClient} style={{ marginBottom: '2.5rem'}}>
				<InputsSection
				style = {{display: 'flex',
				justifyContent: 'flex-start',
				}}>
					<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-name"
						label="Name"
						id="Email"
						placeholder="Jon Doe"

						value={editData.clientname}
						onChange={(e)=> setEditData({...editData, clientname: e.target.value})}
					/>
					<p className='error_message__deleteClient'>{addDataError.clientname}</p>
					<div className='modal_input' >
						<label htmlFor="email" className="modal_input--label">
							Based On
						</label>
						<div className='modal_input--box'>
							<label htmlFor="email" className="modal_input--iconBox">
								<svg className="modal_input--icon">
									<use xlinkHref="/sprite.svg#icon-login" />
								</svg>
							</label>
							<p
								className='filter__input--select'
								style={{fontWeight: '400', fontSize: '1.3rem', minWidth: '15rem'}}
								name='partner'
								id='partner'>
									{editData.clientname}
							 </p>
						</div>
						<p className='error_message__deleteClient'>{addDataError.cloneId}</p>
					</div>

					<p className='error_message__deleteClient'>{addDataError.providername}</p>
				</InputsSection>
				<div className="input__uploadContainer" style={{ display: 'flex', gap: '2rem' }}>
					<InputsSection2>
						<ModalTextBox
						type="text"
						icon="/sprite.svg#icon-provider"
						label="Company Name"
						id="Email"
						placeholder="Jon's Clinic"
						error={addDataError.providername}
						value={editData.providername}
						onChange={(e)=> {setEditData({...editData ,providername: e.target.value})
						setAddDataError({...addDataError, providername: '' })	}}
						/>
						<ModalTextBox
							type="text"
							icon="/sprite.svg#icon-email"
							label="Email Address"
							id="Email"
							placeholder="jondoe@example.com"
							error={addDataError.emailaddress}
							value={editData.emailaddress}
							onChange={(e)=> setEditData({...editData, emailaddress: e.target.value})}
						/>
						<ModalTextBox
							type="text"
							icon="/sprite.svg#icon-phone"
							label="Phone Number"
							id="Email"
							placeholder="(876)-8768764"
							value={editData.phoneno}
							onChange={(e)=> setEditData({...editData, phoneno: e.target.value})}
						/>
						<p className='error_message__deleteClient'>{addDataError.phoneno}</p>
					</InputsSection2>
					<InputsSection style={{display: 'flex', flexDirection: 'column'}}>

						<UploadInput
							value={editData.file}
							label="Upload a logo"
							previewImage={previewImage}
							setPreviewImage = {setPreviewImage}
							error=""
							style={{ alignItems: 'center', justifyContent: 'center', width: '15vw', height: '12.5rem' }}
							onChange={(event)=>
								{
									setAddDataError({...addDataError, file: '' })
									const sizeLimit = 100_000; // 100 kilobyte
									const {size:fileSize } = event.target.files[0];

									if(fileSize > sizeLimit){
										setAddDataError({...addDataError, file: 'size upto 100Kb is allowed' })
									 } else{
										 const reader = new FileReader();
									reader.onload = () =>{
									  if(reader.readyState === 2){
										setPreviewImage(reader.result);
										setEditData({...editData, file: event.target.files[0]})
									  }
									}
									reader.readAsDataURL(event.target.files[0])
									 }
								}
							}
						/>

						<p className='error_message__deleteClient'>{addDataError.file}</p>
					</InputsSection>
				</div>

				<ModalButtonPrimary title="Update Client" />
			</ModalForm>
			}
		</Modal>
	);
};
