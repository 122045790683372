import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Content from '../../components/Content';
import { AddAppVersion } from './AddAppVersion';
import DeleteAppVersion from './DeleteAppVersion';
import { changeAppVersionStatusAction, createAppVersionAction, deleteAppVersionAction, getAllAppVersionAction } from '../../redux/actions/appVersionActions';
import { ADD_APP_VERSION_RESET, CHANGE_APP_VERSION_STATUS_RESET, DELETE_APP_VERSION_RESET } from '../../redux/constants/appVersionConstants';
import TableRows from '../../components/Skeletons/TableRows';
import './AppVersions.css';

const AppVersions = () => {
	const dispatch = useDispatch();
	const [addModalActive, setAddModalActive] = useState(false);

	const userLogin = useSelector((state) => state.userLogin);
	const appVersionList = useSelector((state) => state.appVersionList);
	const createAppVersion = useSelector((state) => state.createAppVersion);
	const changeAppVersionStatus = useSelector((state) => state.changeAppVersionStatus);
	const deleteAppVersion = useSelector((state) => state.deleteAppVersion);
	const [modalDeleteState, setModalDeleteState] = useState(false);
	const [selectUpdateModel, setSelectUpdateModel] = useState({});

	const [versionTitleAndroid, setVersionTitleAndroid] = useState('');
	const [versionTitleAndroidError, setVersionTitleAndroidError] = useState('');

	const [androidStoreURL, setAndroidStoreURL] = useState('');
	const [androidStoreURLError, setAndroidStoreURLError] = useState('');

	const [versionTitleIOS, setVersionTitleIOS] = useState('');
	const [versionTitleIOSError, setVersionTitleIOSError] = useState('');

	const [iosStoreURL, setIOSStoreURL] = useState('');
	const [iosStoreURLError, setIOSStoreURLError] = useState('');

	const [versionDescription, setVersionDescription] = useState('');
	const [versionDescriptionError, setVersionDescriptionError] = useState('');
	const [isCompulsory, setIsCompulsory] = useState(false);
	const [isCompulsoryError, setIsCompulsoryError] = useState('');

	const [totalPageSize, setTotalPageSize] = useState(1);
	const [pageNumber, setPageNumber] = useState(1);
	const pageLimit = 20;

	//login Data
	useEffect(
		() => {
			if (userLogin && userLogin.userInfo) {
				dispatch(getAllAppVersionAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			}
		},
		[userLogin, dispatch]
	);


	useEffect(
		() => {
			if (createAppVersion && createAppVersion.category && createAppVersion.category.status) {
				clearData();
				setAddModalActive(false);
				toast.success(createAppVersion.category.message);
				dispatch({ type: ADD_APP_VERSION_RESET });
				dispatch(getAllAppVersionAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (createAppVersion && createAppVersion.category && !createAppVersion.category.status) {
				dispatch({ type: ADD_APP_VERSION_RESET });
				toast.error(createAppVersion.category.message);
			}
		},
		[createAppVersion, dispatch]
	);

	useEffect(
		() => {
			if (changeAppVersionStatus && changeAppVersionStatus.category && changeAppVersionStatus.category.status) {
				clearData();
				setAddModalActive(false);
				toast.success(changeAppVersionStatus.category.message);
				dispatch({ type: CHANGE_APP_VERSION_STATUS_RESET });
				dispatch(getAllAppVersionAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (changeAppVersionStatus && changeAppVersionStatus.category && !changeAppVersionStatus.category.status) {
				dispatch({ type: CHANGE_APP_VERSION_STATUS_RESET });
				toast.error(changeAppVersionStatus.category.message);
			}
		},
		[changeAppVersionStatus, dispatch]
	);

	useEffect(
		() => {
			console.log(deleteAppVersion, 'deleteAppVersion');
			if (deleteAppVersion && deleteAppVersion.category && deleteAppVersion.category.status) {
				clearData();
				setAddModalActive(false);
				toast.success(deleteAppVersion.category.message);
				dispatch({ type: DELETE_APP_VERSION_RESET });
				dispatch(getAllAppVersionAction({ pageNumber: pageNumber, pageSize: pageLimit, filter: '' }));
			} else if (deleteAppVersion && deleteAppVersion.category && !deleteAppVersion.category.status) {
				dispatch({ type: DELETE_APP_VERSION_RESET });
				toast.error(deleteAppVersion.category.message);
			}
		},
		[deleteAppVersion, dispatch]
	);

	const clearData = () => {
		setVersionTitleAndroid('');
		setVersionTitleAndroidError('');
		setVersionTitleIOS('');
		setVersionTitleIOSError('');
		setVersionDescription('');
		setVersionDescriptionError('');
		setIsCompulsory(false);
		setIsCompulsoryError('');
		setSelectUpdateModel({});
		setAndroidStoreURL('');
		setAndroidStoreURLError('');
		setIOSStoreURL('');
		setIOSStoreURLError('');
	};

	const handleAddModalClose = () => {
		clearData();
		setAddModalActive(false);
	};


	const handleSubmit = (e) => {
		// //e.preventDefault();
		if (versionTitleAndroid === '' && versionTitleAndroid.trim() === '') {
			setVersionTitleAndroidError('Please enter android version number');
		} else if (versionTitleIOS === '' && versionTitleIOS.trim() === '') {
			setVersionTitleIOSError('Please enter ios version number');
		} else if (androidStoreURL === '' && androidStoreURL.trim() === '') {
			setAndroidStoreURLError('Please enter package name');
		} else if (iosStoreURL === '' && iosStoreURL.trim() === '') {
			setIOSStoreURLError('Please enter bundle ID');
		} else {
			dispatch(createAppVersionAction({
				versionTitleIOS,
				versionTitleAndroid,
				packageName: androidStoreURL,
				bundleID: iosStoreURL,
				versionDescription,
				isCompulsory
			}));
		}
	};

	const onChangeHandler = (d) => {
		dispatch(
			changeAppVersionStatusAction({
				id: d.id,
				enable: d.enable ? false : true
			})
		);
	};

	const onDeleteModalClose = () => {
		clearData();
		setModalDeleteState(false);
	};

	const onDeleteHandler = () => {
		dispatch(deleteAppVersionAction(selectUpdateModel.id));
		onDeleteModalClose();
	};

	const handlePageChange = async (currentPage) => {
		const selectedPage = currentPage.selected;
		dispatch(getAllAppVersionAction({ pageNumber: selectedPage + 1, pageSize: pageLimit, filter: '' }));
		setPageNumber(selectedPage + 1);
	};
	return (
		<Content
			currentMenu="App Versions"
			headerTitle="App Versions"
			addBtn={true}
			addBtnClick={() => setAddModalActive(true)}
			addBtnText="Add Version"
			addBtnIcon="/sprite.svg#icon-plus"
		>

			<div className='tableContainer' style={{ height: '80vh' }}>
				<table className='table'>
					<thead>
						<tr>
							<th>#</th>
							<th>
								Version( Android &nbsp;
								<svg className='table__button--icon'>
									<use xlinkHref={`/sprite.svg#icon-android`} />
								</svg>{' '}
								)
							</th>
							<th>
								Version( iOS &nbsp;
								<svg className='table__button--icon'>
									<use xlinkHref={`/sprite.svg#icon-apple`} />
								</svg> &nbsp;)
							</th>

							<th>Description</th>
							<th>Required</th>
							<th>Status</th>
							<th>Options</th>
						</tr>
					</thead>
					<tbody>
						{appVersionList && appVersionList.loading ? (
							<TableRows columns='13' />
						) : appVersionList &&
							appVersionList.categories &&
							appVersionList.categories.data &&
							appVersionList.categories.data.length > 0 ? (
							appVersionList.categories.data.map((res, index) => (
								<tr key={res.id}>
									<td>{index + 1}</td>
									<td>{res.versionTitleAndroid}</td>
									<td>{res.versionTitleIOS}</td>
									<td className='text_wrap_desc' >{res.versionDescription}</td>
									<td>{res.isCompulsory ? 'Yes' : 'No'}</td>
									<td>
										<label className='switch'>
											<input
												id={res.id}
												checked={res.enable}
												onChange={() => {
													onChangeHandler(res);
												}}
												type='checkbox'
												className='checkbox'
												name='active'
											/>
											<span className='slider round' />
										</label>
									</td>
									<td>
										{!res.enable && (
											<button
												className='table__button table__button--delete'
												onClick={() => {
													setSelectUpdateModel(res);
													console.log(res, 'res');
													setModalDeleteState(true);
												}}>
												<svg className='table__button--icon-red'>
													<use xlinkHref={`/sprite.svg#icon-delete`} />
												</svg>
												<span>Delete Version</span>
											</button>
										)}
									</td>
								</tr>
							))
						) : (
							<tr>
								<td colSpan="13" rowSpan='20' style={{ textAlign: 'center', height: "18vh", border: 'None' }}>
									<h4>No Data Found!</h4>
									<h5>We could not find any app version. Please add one</h5>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</div>

			<AddAppVersion
				data={{
					addModalActive,
					handleAddModalClose,
					handleSubmit,

					versionTitleAndroid,
					setVersionTitleAndroid,
					versionTitleAndroidError,
					setVersionTitleAndroidError,
					versionTitleIOS,
					setVersionTitleIOS,
					versionTitleIOSError,
					setVersionTitleIOSError,
					versionDescription,
					setVersionDescription,
					versionDescriptionError,
					setVersionDescriptionError,
					isCompulsory,
					setIsCompulsory,
					isCompulsoryError,
					setIsCompulsoryError,

					androidStoreURL,
					setAndroidStoreURL,
					iosStoreURL,
					setIOSStoreURL,
					androidStoreURLError,
					setAndroidStoreURLError,
					iosStoreURLError,
					setIOSStoreURLError
				}}
			/>


			<DeleteAppVersion
				data={{ modalDeleteState, onDeleteModalClose, onDeleteHandler }}
			/>

			{/* <ActiveInactiveModal
				data={{ activeInactiveClientState, onActiveClose, handleActiveInactive, activeInactiveClient }}
			/> */}
		</Content>
	);
};

export default AppVersions;
