import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Terms from './Terms'
import InviteUsers from './InviteUsers'
import './RegisterClinic.css';
import { validateEmail, validateOnlyChacters, validatePhone } from '../../utils/validators';
import { clinicSignUpAction } from '../../redux/actions/clinicActions';
import RegisterForm from './RegisterForm';
import { toast } from 'react-toastify';
import { CLINIC_SIGN_UP_RESET } from '../../redux/constants/clinicsConstants';
import Loader from '../../components/Skeletons/Loader';
import { logout } from '../../redux/actions/userActions';

function Index({ history, match }) {

	const dispatch = useDispatch();
	const clinicSignUp = useSelector((state) => state.clinicSignUp);
	const userLogin = useSelector((state) => state.userLogin);
	const [secondInvite, setSecondInvite] = useState(false);
	const [data, setData] = useState({
		cName: '',
		fName: '',
		lName: '',
		cTitle: '',
		cPhone: '',
		address: '',
		address2: '',
		city: '',
		cState: '',
		zip: ''
	});

	const [userData, setUserData] = useState({
		email: match.params.userId,
		IsAdmin: true,
		IsProvider: false,
		FirstName: '',
		LastName: '',
		npi: '',
		credentials: '',
		gender: '',
		validDeaCert: false
	})

	const [cloneInvite, setCloneInvite] = useState({
		email: '',
		IsAdmin: false,
		IsProvider: false,
		FirstName: '',
		LastName: '',
		npi: '',
		credentials: '',
		gender: '',
		validDeaCert: false
	})

	const [dataError, setDataError] = useState({
		cName: '',
		fName: '',
		lName: '',
		cTitle: '',
		cPhone: '',
		address: '',
		address2: '',
		city: '',
		cState: '',
		zip: '',
		email: '',
		IsAdmin: '',
		FirstName: '',
		LastName: '',
		FirstName2: '',
		LastName2: '',
		npi: '',
		credentials: '',
		gender: '',
		validDeaCert: false
	});
	const [termAgreement, setTermAgreement] = useState(false)
	const [termAgreementError, setTermAgreementError] = useState('')
	const [pagination, setPagination] = useState(1)

	useEffect(() => {
		if (userLogin && userLogin.userInfo
			&& userLogin.userInfo.signUpComplete
			&& userLogin.userInfo.ispatient) {
			history.push('/patient/questionaire');
		} else if (userLogin && userLogin.userInfo
			&& userLogin.userInfo.signUpComplete
			&& !userLogin.userInfo.ispatient
			&& (userLogin.userInfo.isclinicadmin || userLogin.userInfo.isprovider)
		) {
			history.push('/clinic/home');
		}

	}, [userLogin, history])

	useEffect(() => {
		if (clinicSignUp && clinicSignUp.data) {
			toast.success('Success')
			history.push('/clinic/home')
		}
		else if (clinicSignUp && clinicSignUp.error !== '') {
			toast.error(clinicSignUp.error)
		}
	}, [clinicSignUp, history])

	useEffect(() => {
		if (!localStorage.getItem('userInfo')) {
			dispatch(logout());
			history.push('/login');
		}
	}, [dispatch, history])

	useEffect(() => {
		return () => {
			dispatch({ type: CLINIC_SIGN_UP_RESET });
		};
	}, [dispatch])

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!data.cName.trim() || data.cName.trim() === '') {
			setDataError({ ...dataError, cName: 'Please enter clinic name' });
		} else if (!data.fName.trim() || data.fName.trim() === '') {
			setData({ ...data, fName: '' });
			setDataError({ ...dataError, fName: 'Please enter user first name' });
		} else if (!validateOnlyChacters(data.fName)) {
			setDataError({ ...dataError, fName: 'Please enter only alphabets' });
		} else if (!data.lName.trim() || data.lName.trim() === '') {
			setData({ ...data, lName: '' });
			setDataError({ ...dataError, lName: 'Please enter user last name' });
		} else if (!validateOnlyChacters(data.lName)) {
			setDataError({ ...dataError, lName: 'Please enter only alphabets' });
		} else if (!data.cTitle.trim() || data.cTitle.trim() === '') {
			setDataError({ ...dataError, cTitle: 'Please enter a title' });
		} else if (!validatePhone(data.cPhone)) {
			setDataError({ ...dataError, cPhone: 'Phone number must be between 5 to 12 digits' });
		} else if (!data.address.trim() || data.address.trim() === '') {
			setDataError({ ...dataError, address: 'Please enter address' });
		} else if (!data.city.trim() || data.city.trim() === '') {
			setDataError({ ...dataError, city: 'Please enter City' });
		} else if (!data.cState.trim() || data.cState.trim() === '') {
			setDataError({ ...dataError, cState: 'Please enter State' });
		} else if (!validatePhone(data.zip)) {
			setDataError({ ...dataError, zip: 'Please enter Zip code' });
		} else {
			setUserData({
				email: match.params.userId,
				IsAdmin: true,
				IsProvider: false,
				FirstName: data.fName,
				LastName: data.lName,
				npi: '',
				credentials: data.cTitle,
				gender: '',
				validDeaCert: false
			})
			setPagination(2)
		}
	};
	const inviteSubmit = (e) => {
		e.preventDefault()
		if (!userData.FirstName.trim() || userData.FirstName.trim() === '') {
			setDataError({ ...dataError, FirstName: 'Please enter First Name' });
		} else if (!userData.LastName.trim() || userData.LastName.trim() === '') {
			setDataError({ ...dataError, LastName: 'Please enter Last Name' });
		} else if (secondInvite === true) {
			if (!cloneInvite.email.trim() || cloneInvite.email.trim() === '') {
				setDataError({ ...dataError, email: 'Please enter an email' });
			} else if (!validateEmail(cloneInvite.email)) {
				setDataError({ ...dataError, email: 'Please enter a valid email' });
			} else if (!(cloneInvite.IsAdmin === true || cloneInvite.IsProvider === true)) {
				setDataError({ ...dataError, IsAdmin: 'Please select at least one role' });
			} else if (!cloneInvite.FirstName.trim() || cloneInvite.FirstName.trim() === '') {
				setDataError({ ...dataError, FirstName2: 'Please enter First Name' });
			} else if (!cloneInvite.LastName.trim() || cloneInvite.LastName.trim() === '') {
				setDataError({ ...dataError, LastName2: 'Please enter Last Name' });
			} else setPagination(3)
		}
		else {
			setPagination(3)
		}
	}
	const finalSubmit = (event) => {
		event.preventDefault();
		if (termAgreement === false) {
			setTermAgreementError('You must agree to the terms and conditions to submit');
		} else {
			let arr = [];
			if (cloneInvite.email !== '') {
				arr = [userData, cloneInvite];
			} else {
				arr.push(userData)
			}

			const formData = {
				ClinicAddress1: data.address,
				ClinicAddress2: data.address2,
				ClinicCity: data.city,
				ClinicState: data.cState,
				ClinicZip: data.zip,
				ContactFirst: data.fName,
				ContactLast: data.lName,
				ContactTitle: data.cTitle,
				Phone: data.cPhone,
				TosAccepted: true,
				Users: arr,
				clinicName: data.cName
			}
			dispatch(clinicSignUpAction(formData));
		}
	}

	return (
		<main className="loginContainer" >
			<div className="clinicContainer__bg" />
			<div className="registerContainer__box">
				<div className="loginContainer__box--right">
					<img src="/images/logo.png" alt="Suremed Logo" className="loginContainer__box--logo" />
					<h1 className="loginContainer__heading">Clinic Sign Up</h1>
					<h1 className="loginContainer__subheading">Create Your Account</h1>
				</div>

				<div className="loginContainer__box--left">
					<div className="login__pagination">
						<ul className="login__pagination-bar">
							<li className={pagination === 1 ? "active" : ''}>
								<div className="stepper-text">Clinic Details</div>
							</li>
							<li className={pagination === 2 ? "active" : ''}>
								<div className="stepper-text">Invite Users</div>
							</li>
							<li className={pagination === 3 ? "active" : ''}>
								<div className="stepper-text">Terms of Service</div>
							</li>
						</ul>
					</div>
					{/* register form */}
					{clinicSignUp && !clinicSignUp.loading ?
						pagination === 1 ?
							<RegisterForm
								props={{ data, setData, dataError, setDataError, setPagination, handleSubmit }}
							/>
							: pagination === 2 ?
								<>
									<p style={{ marginLeft: '1.5rem', fontSize: '1.5rem', textAlign: 'center', marginTop: '2rem' }}>You are designated as the Admin of this clinic. If you are also a Provider, please check the “<b>Is Provider</b>” box</p>
									<InviteUsers
										props={{
											pagination, setPagination, inviteSubmit, data, setData, dataError, setDataError, userData, setUserData,
											cloneInvite, setCloneInvite, secondInvite, setSecondInvite
										}}
										inviteEmail={match.params.userId}
									/>
								</>
								:
								<Terms
									props={{ finalSubmit, setTermAgreement, termAgreement, termAgreementError, setTermAgreementError, pagination, setPagination }}
								/>
						:
						<div style={{ minHeight: '30rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
							<Loader />
						</div>
					}

				</div>
			</div>

		</main>
	);
}

export default Index;
