import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import { InputsSection } from '../../../components/Modal/InputsSection';
import { ModalButtonPrimary } from '../../../components/Modal/ModalButton';
import ModalForm from '../../../components/Modal/ModalForm';
import ModalHeading from '../../../components/Modal/ModalHeading';
import { UpdateModMedTextBox } from '../../../components/Modal/UpdateModMedTextBox';

const UpdateModeMedModal = ({ data }) => {
	const { showModal, EHRId, setEHRId, onEditClose, handleSubmit, loadingBtn } = data;

	return (
		<Modal show={showModal}>
			<ModalHeading
				heading='Update EHR ID' onClose={onEditClose}
			/>
			<Fragment>
				<ModalForm>
					<InputsSection style={{ justifyContent: 'inherit' }}>
						<UpdateModMedTextBox
							label='Enter Patient EHR ID'
							placeholder="Enter the EHR ID"
							value={EHRId.value}
							icon={`/sprite.svg#icon-user`}
							onChange={(e) => setEHRId({ error: '', value: e.target.value })}
							error={EHRId.error}
						/>
					</InputsSection>

					{loadingBtn ?
						<div className='modal__actions'>
							<div className='login__input--button-skeleton skeleton' />
						</div> :
						<ModalButtonPrimary className='modal__actions' title='Update' onClick={handleSubmit} />
					}
				</ModalForm>
			</Fragment>
		</Modal>
	);
};

export default UpdateModeMedModal;
