import React from 'react'

import TableRows from '../../components/Skeletons/TableRows';

export default function ShowData({data}) {
	const userIndex = 1;
    const {
		manageUsersList,
		setModalEditState,
		setDeleteModalState,
		newUserData,
		setNewUserData,
		setUserId,
		setIsDeleted,
		setModalActiveState,
	} = data;

    return (
        <div className='tableContainer' style={{ height: '63vh' }}>
			<table className='table'>
				<thead>
					<tr>
						<th>#</th>
						<th>NPI Number</th>
						<th>Name</th>
						<th>Credentials</th>
						<th>Role</th>
						<th>Gender</th>
						<th>Action</th>
						<th>Active / In-Active</th>

					</tr>
				</thead>
				<tbody>
					{
					manageUsersList && !manageUsersList.loading ? manageUsersList.data && manageUsersList.data.length > 0 ? (
						manageUsersList.data.map((user, index) => (
							<tr key={index + 1}>
								<td>{userIndex + index}</td>
								{
									user.npi === null || user.npi === '' ?
									<td style={{ textAlign: 'left' }}> NA</td>
									:
									<td style={{ textAlign: 'left' }}> {user.npi}</td>
								}
								<td>{user.shownAs !== "  "? user.shownAs: `${user.firstName} ${user.lastName}`}</td>
								<td>{user.credentials}</td>
								<td>{user.highestrole}</td>
								<td>{
									user.gender === null || user.gender === '' ?
									<td style={{ textAlign: 'left' }}> NA</td>
									:
									<td style={{ textAlign: 'left' }}> {user.gender}</td>
								}</td>

								<td>
									<div className='table__iconBox'>
										<button
											className='table__button'
											onClick={() => {
												setModalEditState(true);
												setNewUserData({
													...newUserData,
													credentials: user.credentials ,
													displayAsClinic: user.displayAsClinic,
													email: user.email,
													firstName: user.firstName,
													gender: user.gender,
													userId: user.id,
													isAdmin: user.isAdmin,
													isProvider: user.isProvider,
													lastName: user.lastName,
													npi: user.npi,
													password: "",
													shownAs: user.shownAs,
													validDEACert: user.validDEACert,
													providerId: user.providerId,
													clinicName: user.clinicName
												});
											}}
											>
											<svg className='table__button--icon'>
												<use xlinkHref={`/sprite.svg#icon-edit`} />
											</svg>
											<span>Edit Provider</span>
										</button>
										<button
											className='table__button table__button--delete'

											onClick={() => {
												setDeleteModalState(true)
												setNewUserData({
													...newUserData,
													userId: user.id,
													clinicId: user.clinicId})
											}}
											>
											<svg className='table__button--icon-red'>
												<use xlinkHref={`/sprite.svg#icon-delete2`} />
											</svg>
											<span>Delete Provider</span>
										</button>

									</div>
								</td>
								<td>
									<label className='switch'>
										<input
											id='active'
											type='checkbox'
											className='checkbox'
											checked={!user.isDeleted}
											onChange={() => {
												setModalActiveState(true);
												setUserId(user.id);
												setIsDeleted(user.isDeleted);
											}}
											name='active'
										/>
										<span className='slider round' />
									</label>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan='13'>No users found</td>
						</tr>
					) : (
						<TableRows columns='13' />
					)}
				</tbody>
			</table>
		</div>
    )
}
