import React from 'react';
import './Header.css';
import { Link } from 'react-router-dom';
import Notification from './Notification';

export const toggleMenu = () => {
  let navigation = document.querySelector('.navigation');
  let mainContent = document.querySelector('.mainContent');
  navigation.classList.toggle('active');
  mainContent.classList.toggle('mainContent__active');
};
const Header = ({ addPartner, onBack, title, addBtn = false, addBtnText, addBtnIcon, addBtnClick, showBack, current }) => {

  return (
    <div className='header'>
      <div className='header__left--container'>
        <div className='header__left'>
          <button className='header__button' onClick={toggleMenu}>
            <svg className='header__icon'>
              <use xlinkHref={`/sprite.svg#icon-menu`} />
            </svg>
          </button>
          {/* <div className='toggle' onClick={toggleMenu} style={{ zIndex: 510 }} /> */}
          <div className='header__container'>
            <h2 className='header__heading'>{title}</h2>
            {addBtn && (
              <button onClick={addBtnClick} className='header__container--btn'>
                <p>
                  <svg className='header__container--icon'>
                    <use xlinkHref={addBtnIcon} />
                  </svg>
                </p>
                <span>{addBtnText}</span>
              </button>
            )}
          </div>
          {/* {showBack && <div onClick={() => onBack()} className='header_back'>
					<svg className='header_back__icon'>
						<use xlinkHref={`/sprite.svg#icon-back`} />
					</svg>
					<h2 className='header__heading'>Back</h2>
				</div>} */}
          {addPartner && (
            <Link to='/partner-category'>
              <button id='searchBtn' className='filter__input--button'>
                Add Partner
              </button>
            </Link>
          )}
        </div>
        {/* <img src='/images/ccp.png' alt='CCP Logo' className='header__image' /> */}

        {current && current == 'questionare' && <Notification />}
      </div>

      {current && current == 'questionare' && <div id='google_translate_element'></div>}
    </div>
  );
};

export default Header;
