import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import ModalHeading from '../../components/Modal/ModalHeading';
import UnverfiedEditUsers from '../../components/Skeletons/UnverfiedEditUsers';
import UnverfiedChangePassword from '../../components/Skeletons/UnverfiedChangePassword';
import UnverifiedVerifyEmail from '../../components/Skeletons/UnverifiedVerifyEmail';

import { toast } from 'react-toastify';

import Content from '../../components/Content';
import Modal from '../../components/Modal';
import Filter from './Filter';
import EmailData from './data';

import { validateEmail, validateOnlyChacters, validatePassword } from '../../utils/validators';
import {
	manageUnverfiedAction,
	unverifiedResetPasswordAction,
	verifyClinicAction,
	editUnverifiedUserAction
} from '../../redux/actions/userActions';
import {
	MANAGE_UNVERIFIED_RESET,
	UNVERIFIED_PASSWORD_RESET,
	EDIT_UNVERIFIED_USER_RESET,
	VERIFY_CLINIC_RESET
} from '../../redux/constants/userConstants';

import './UnverifiedUsers.css';
import ModalTextBox from '../../components/Modal/ModalTextBox';

const UnverifiedUsers = ({ history }) => {
	const dispatch = useDispatch();
	const [showFilter, setShowFilter] = useState(true);
	const [modalState, setModalState] = useState(false);
	const [restPasswordState, setResetPasswordState] = useState(false);
	const [newPassword, setNewPassword] = useState('');
	const [modalVerify, setModalVerify] = useState(false);
	const [verifyEmailConfirm, setVerifyEmailConfirm] = useState('');

	const [newPasswordError, setNewPasswordError] = useState('');

	const [filterEmail, setFilterEmail] = useState({
		value: '',
		error: ''
	});
	const [role, setRole] = useState({
		value: '',
		error: ''
	});
	const [EmailPreview, setEmailPreview] = useState('');
	const [userId, setUserId] = useState('');

	const [firstName, setFirstName] = useState('');
	const [firstNameError, setFirstNameError] = useState('');
	const [lastName, setLastName] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');

	const [userIndex, setUserIndex] = useState(1);
	const [unverifiedList, setunverifiedList] = useState([]);
	const [totalunverifiedList, setTotalunverifiedList] = useState(0);
	const perPageUser = 10;
	const totalPages = totalunverifiedList / perPageUser;

	const unverifiedUsers = useSelector((state) => state.unverifiedUsers);
	const verifyClinic = useSelector((state) => state.verifyClinic);
	const unverifiedResetPassword = useSelector((state) => state.unverifiedResetPassword);
	const editUnverifiedUser = useSelector((state) => state.editUnverifiedUser);
	const userLogin = useSelector((state) => state.userLogin);
	const activeToken = useSelector((state) => state.activeToken);

	useEffect(
		() => {
			if (!activeToken) {
				history.push('/login');
			} else if (!activeToken.token) {
				history.push('/login');
			} else if (
				userLogin &&
				userLogin.userInfo &&
				userLogin.userInfo.applicationUser &&
				userLogin.userInfo.applicationUser.isPatient === true &&
				userLogin.userInfo.applicationUser.isSuperAdmin === false
			) {
				history.push('/questionaires');
			}
		},
		[activeToken, userLogin, history]
	);

	useEffect(
		() => {
			dispatch(manageUnverfiedAction());
		},
		[dispatch]
	);
	useEffect(
		() => {
			if (verifyClinic && verifyClinic.userInfo && verifyClinic.userInfo.statusCode) {
				setModalVerify(false)
				dispatch(manageUnverfiedAction());
				toast.success(verifyClinic.userInfo.message)
			}
		},
		[verifyClinic, dispatch]
	);

	useEffect(
		() => {
			if (unverifiedUsers && unverifiedUsers.data && unverifiedUsers.data.length > 0) {
				setunverifiedList(unverifiedUsers.data.slice(0, perPageUser));
				setTotalunverifiedList(unverifiedUsers.data.length);
			} else {
				setunverifiedList([]);
				setTotalunverifiedList(0);
			}
		},
		[unverifiedUsers]
	);

	useEffect(
		() => {
			if (
				unverifiedResetPassword &&
				unverifiedResetPassword.userInfo &&
				unverifiedResetPassword.userInfo.succeeded
			) {
				toast.success(unverifiedResetPassword.userInfo.message);
				setResetPasswordState(false);
				dispatch(manageUnverfiedAction());
				setNewPasswordError('');
				dispatch({ type: UNVERIFIED_PASSWORD_RESET });
			} else if (unverifiedResetPassword && unverifiedResetPassword.error && unverifiedResetPassword.error.data) {
				setResetPasswordState(false);
				toast.error(unverifiedResetPassword.error.data)
			}
		},
		[unverifiedResetPassword, dispatch]
	);
	useEffect(
		() => {
			if (editUnverifiedUser && editUnverifiedUser.data && editUnverifiedUser.data.statusCode === true) {
				toast.success(editUnverifiedUser.data.message);
				setModalState(false);
				setFirstName('');
				setLastName('');
				setEmailPreview('');
				dispatch(manageUnverfiedAction());
				dispatch({ type: EDIT_UNVERIFIED_USER_RESET });
			}
		},
		[editUnverifiedUser, dispatch]
	);

	useEffect(
		() => {
			return () => {
				dispatch({ type: MANAGE_UNVERIFIED_RESET });
				dispatch({ type: VERIFY_CLINIC_RESET });
				dispatch({ type: UNVERIFIED_PASSWORD_RESET });
				dispatch({ type: EDIT_UNVERIFIED_USER_RESET });
			};
		},
		[dispatch]
	);

	const onClose = () => {
		setModalState(false);
	};
	const onResetClose = () => {
		setResetPasswordState(false);

		setNewPasswordError('');
	};

	const onClearHandler = (e) => {
		//e.preventDefault();
		setFilterEmail({ value: '', error: '' });
		setRole({ value: 'select-role', error: '' });
		dispatch(manageUnverfiedAction());
	};

	const onFilterSubmitHandler = (e) => {
		//e.preventDefault();
		if (role.value === 'select-role' && filterEmail.value === '') {
			setRole({ ...role, error: 'Please Select user role or enter email to filter' });
		} else {
			dispatch(manageUnverfiedAction(role.value, filterEmail.value));
		}
	};

	let nextContentFrom = 0;
	const handlePageChange = (currentPage) => {
		nextContentFrom = currentPage.selected * perPageUser;
		const nextContentTo = currentPage.selected * perPageUser + 10;
		setunverifiedList(
			unverifiedUsers && unverifiedUsers.data && unverifiedUsers.data.slice(nextContentFrom, nextContentTo)
		);
		setUserIndex(currentPage.selected * perPageUser + 1);
	};

	//reset password handler
	const onResetSubmitHandler = (e) => {
		//e.preventDefault();
		if (!newPassword.trim() || newPassword.trim() === '') {
			setNewPasswordError('please enter new password');
		} else if (!validatePassword(newPassword)) {
			setNewPasswordError("Password must have at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character.");
		} else {
			dispatch(unverifiedResetPasswordAction(EmailPreview, newPassword));
		}
	};

	const onSubmitHandler = (e) => {
		//e.preventDefault();
		if (!firstName.trim() || firstName.trim() === '') {
			setFirstNameError('Please enter first name');
		} else if (!validateOnlyChacters(firstName)) {
			setFirstNameError("First name can't allow special characters or blank space");
		} else if (!lastName.trim() || lastName.trim() === '') {
			setLastNameError('Please enter last name');
		} else if (!validateOnlyChacters(lastName)) {
			setLastNameError("Last name can't allow special characters or blank space");
		} else if (!EmailPreview.trim() || EmailPreview.trim() === '') {
			setEmailError('Please enter correct email address');
		} else if (!validateEmail(EmailPreview)) {
			setEmailError('Please enter correct email address');
		} else {
			dispatch(editUnverifiedUserAction(userId, EmailPreview, firstName, lastName));
		}
	};

	const onVerifyClose = () => {
		setModalVerify(false);
	};
	const onVerify = () => {
		dispatch(verifyClinicAction(verifyEmailConfirm));

		onVerifyClose();
	};

	return (
		<Content currentMenu="Unverified Users" headerTitle="Unverified Users">
			<Modal show={modalVerify}>
				{verifyEmailConfirm && verifyEmailConfirm.value ? (
					<UnverifiedVerifyEmail />
				) : (
					<Fragment>
						<ModalHeading heading="Verify Email" onClose={onVerifyClose} />
						<form className="modalContainer__form">
							<p className="modal__text">
								Are you sure you want to verify this user with email <span>{verifyEmailConfirm} </span>?{' '}
							</p>
						</form>
						<div className="modal__actions">
							<button className="modal__button modal__button--update" onClick={onVerify}>
								Confirm
							</button>
						</div>
					</Fragment>
				)}
			</Modal>

			<Modal show={restPasswordState}>
				{EmailPreview && EmailPreview.value ? (
					<UnverfiedChangePassword />
				) : (
					<Fragment>
						<ModalHeading heading="Reset password" onClose={onResetClose} />

						<form className="modalContainer__form">
							<div className="filter__input--labels">
								<ModalTextBox
									disabled
									label="Email"
									value={EmailPreview}
									icon="/sprite.svg#icon-email"
								/>
								<ModalTextBox
									type="password" autoComplete="new-password"
									id="newPassword"
									label="New Password"
									placeholder="***************"
									icon="/sprite.svg#icon-key"
									onChange={(event) => {
										setNewPassword(event.target.value);
										setNewPasswordError('');
									}}
								/>

								{newPasswordError && <p className="error_message">{newPasswordError}</p>}
							</div>
						</form>

						<div className="modal__actions">
							<button className="modal__button modal__button--update" onClick={onResetSubmitHandler}>
								Reset
							</button>
						</div>
					</Fragment>
				)}
			</Modal>

			<Modal show={modalState}>
				{firstName && firstName.loading ? (
					<UnverfiedEditUsers />
				) : (
					<Fragment>
						<ModalHeading heading="Edit user details" onClose={onClose} />

						<form className="modalContainer__form">
							<div className="filter__input--labels">
								<div className="flex">
									<ModalTextBox
										type="text"
										label="First name"
										placeholder="john"
										value={firstName}
										icon="/sprite.svg#icon-user"
										onChange={(event) => {
											setFirstName(event.target.value);
											setFirstNameError('');
										}}
									/>

									<ModalTextBox
										type="text"
										label="Last name"
										placeholder="john"
										value={lastName}
										icon="/sprite.svg#icon-user"
										onChange={(event) => {
											setLastName(event.target.value);
											setLastNameError('');
										}}
									/>
								</div>

								<ModalTextBox
									type="text"
									label="Email"
									placeholder="User Email"
									value={EmailPreview}
									icon="/sprite.svg#icon-email"
									onChange={(event) => {
										setEmailPreview(event.target.value);
										setEmailError('');
									}}
									error={lastNameError.error}
								/>
								{firstNameError && <p className="error_message">{firstNameError}</p>}
								{lastNameError && <p className="error_message">{lastNameError}</p>}
								{emailError && <p className="error_message">{emailError}</p>}
							</div>
						</form>

						<div className="modal__actions">
							<button className="modal__button modal__button--update" onClick={onSubmitHandler}>
								Update
							</button>
						</div>
					</Fragment>
				)}
			</Modal>

			<Filter
				data={{
					showFilter,
					setShowFilter,
					filterEmail,
					setFilterEmail,
					role,
					setRole,
					onClearHandler,
					onFilterSubmitHandler,
					onVerify
				}}
			/>

			<EmailData
				data={{
					setEmailPreview,
					showFilter,
					setModalState,
					setResetPasswordState,
					setModalVerify,
					setVerifyEmailConfirm,
					userIndex,
					unverifiedList,
					nextContentFrom,
					setLastName,
					setFirstName,
					setUserId
				}}
				loading={unverifiedUsers && unverifiedUsers.loading}
			/>

			{unverifiedUsers &&
				unverifiedUsers.data &&
				unverifiedUsers.data.length > 10 && (
					<div className="tableContainer--paginater">
						<ReactPaginate
							nextClassName={''}
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={totalPages}
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={5}
							breakLinkClassName={''}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'paginationActive'}
						/>
					</div>
				)}
		</Content>
	);
};

export default UnverifiedUsers;
