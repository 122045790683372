import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
	userLoginReducer,
	adminLoginReducer,
	manageUserReducer,
	addClientReducer,
	editClientReducer,
	deleteClientReducer,
	updatemanageUserReducer,
	deleteManageUserReducer,
	activeInactivetReducer,
	activatemanageUserReducer,
	manageClinicReducer,
	manageUnverifiedReducer,
	profileReducer,
	updateUserReducer,
	deactivateUserReducer,
	passwordResetReducer,
	unverifiedResetPasswordReducer,
	verifyClinicReducer,
	editUnverifiedUserReducer,
	activeToeknReducer,
	contentsReducer,
	uploadHistoryReducer,
	contentPdfReducer,
	contentVideoReducer,
	getScheduleReducer,
	scheduleMapReducer,
	saveScheduleReducer,
	getAllClientReducer,
	uploadContentReducer,
	deleteContentReducer,
	baseUrlReducer,
	registerPatientReducer,
	clientIdReducer,
	getMenusReducer,
	forgotPasswordReducer,
	resetPasswordReducer,
	verifiedUserReducer,
	partnerContentsReducer
} from './reducers/userReducers';

import {
	registerAdminReducer,
	inviteAdminReducer,
	createInviteAdminReducer,
	deleteInviteAdminReducer,
	updateEmailAdminReducer
} from './reducers/adminReducers';
import {
	inviteClinicReducer,
	addInviteClinicReducer,
	deleteInviteClinicReducer,
	clinicProvidersReducer,
	clinicsReducer,
	clinicProviderByIdReducer,
	updateEmailClincReducer,
	allClinicsToggleReducer,
	providerClinicsToggleReducer,
	highestRoleReducer,
	registerClinicNoInviteReducer,
	registerClinicInitialAdminReducer,
	registerClinicUserReducer,
	clinicSignUpReducer,
	npiDetailsReducer,
	notficationTempReducer
} from './reducers/clinicReducers';

import { getAllWesitesReducers } from './reducers/websiteReducers';

import {
	assessmentReducer,
	prevoiusAssessmentReducer,
	selfAssessmentReducer,
	saveAnswerReducer,
	nextQuestionReducer,
	questionResultReducer,
	previousQuestionReducer,
	reportDtoReducer,
	getbmiQuestionsReducer,
	bmiCalculationReducer,
	updateUserDetailsReducer,
	summaryPdfReducer
} from './reducers/assessmentReducers';

import {
	clinicFilterReducer,
	clinicProviderReducer,
	manageUserListReducer,
	providerAssessmentReducer,
	addInviteProviderReducer,
	saveClinicUserReducer,
	videoDetailsReducer,
	videoThumbReducer,
	deleteUserReducer,
	currentUserReducer,
	temrsAcceptReducer,
	consentFormsDocReducer,
	changeConsentFormStatusReducer,
	clinicEHRFilterReducer
} from './reducers/providerReducers'
import { setUrl } from './actions/ip';
import { getPillCountReducer, refreshPillCountReducer, requestPillCountReducer, savePdmpCredentialsReducer } from './reducers/PillCountReducers';
import { getProductsReducer } from './reducers/paymentReducers';
import {
	changeAppVersionStatusReducer,
	createAppVersionReducer,
	deleteAppVersionReducer,
	getAllAppVersionReducer
} from './reducers/appVersionReducers';


const reducer = combineReducers({
	//login
	userLogin: userLoginReducer,
	forgotPassword: forgotPasswordReducer,
	resetPassword: resetPasswordReducer,
	activeToken: activeToeknReducer,
	adminLogin: adminLoginReducer,
	getMenus: getMenusReducer,
	verifiedUser: verifiedUserReducer,

	//client
	clientURL: baseUrlReducer,
	clientId: clientIdReducer,
	allClientDetails: getAllClientReducer,
	addClient: addClientReducer,
	deleteClient: deleteClientReducer,
	editClient: editClientReducer,
	activeInactiveClient: activeInactivetReducer,
	registerPatient: registerPatientReducer,
	manageUsers: manageUserReducer,
	updatemanageuser: updatemanageUserReducer,
	deleteManageUser: deleteManageUserReducer,
	activatemanageUser: activatemanageUserReducer,
	loggedinUserDetails: profileReducer,
	updateUser: updateUserReducer,
	deactivateAccount: deactivateUserReducer,
	passwordData: passwordResetReducer,
	contentDetails: contentsReducer,
	partnerContentDetails:partnerContentsReducer,
	uploadHistory: uploadHistoryReducer,
	contentPdf: contentPdfReducer,
	contentVideo: contentVideoReducer,
	getScheduleDetails: getScheduleReducer,
	scheduleMapDetails: scheduleMapReducer,
	saveSchedule: saveScheduleReducer,
	uploadContent: uploadContentReducer,
	deleteContent: deleteContentReducer,

	//clinics
	registerClinic: registerAdminReducer,
	registerClinicInitial: registerClinicInitialAdminReducer,
	registerClinicNoInvite: registerClinicNoInviteReducer,
	clinicSignUp: clinicSignUpReducer,
	registerClinicUser: registerClinicUserReducer,
	manageClinics: manageClinicReducer,
	allClinics: clinicsReducer,
	providerClinicsToggle: providerClinicsToggleReducer,
	allClinicsToggle: allClinicsToggleReducer,
	invitedClinics: inviteClinicReducer,
	inviteClinic: addInviteClinicReducer,
	deleteInviteClinic: deleteInviteClinicReducer,
	clinicProviders: clinicProvidersReducer,
	verifyClinic: verifyClinicReducer,
	providerById: clinicProviderByIdReducer,
	updateClinicEmail: updateEmailClincReducer,
	highestRole: highestRoleReducer,
	npiDetails: npiDetailsReducer,


	//admin
	registerAdmin: registerAdminReducer,
	invitedAdmins: inviteAdminReducer,
	createInviteAdmin: createInviteAdminReducer,
	deleteInviteAdmin: deleteInviteAdminReducer,
	updateAdminEmail: updateEmailAdminReducer,

	//unverified
	unverifiedUsers: manageUnverifiedReducer,
	unverifiedResetPassword: unverifiedResetPasswordReducer,
	allWebsites: getAllWesitesReducers,
	editUnverifiedUser: editUnverifiedUserReducer,

	//assessment
	nextQuestion: nextQuestionReducer,
	questionResult: questionResultReducer,
	consentFormsDocList: consentFormsDocReducer,
	previousAssesment: prevoiusAssessmentReducer,
	previousAssesment: prevoiusAssessmentReducer,

	selfAssessment: selfAssessmentReducer,
	previousQuestion: previousQuestionReducer,
	reportDTO: reportDtoReducer,
	saveAnswer: saveAnswerReducer,
	getbmiquestions: getbmiQuestionsReducer,
	bmicalculation: bmiCalculationReducer,
	updateUserDetails: updateUserDetailsReducer,
	summaryPdf: summaryPdfReducer,

	//Provider
	currentUser: currentUserReducer,
	clinicFilter: clinicFilterReducer,
	clinicProvider: clinicProviderReducer,
	manageUsersList: manageUserListReducer,
	providerAssessment: providerAssessmentReducer,
	addInviteProvider: addInviteProviderReducer,
	saveClinicUser: saveClinicUserReducer,
	videoDetails: videoDetailsReducer,
	videoThumb: videoThumbReducer,
	deleteUser: deleteUserReducer,
	termsAccept: temrsAcceptReducer,
	clinicEHRFilter: clinicEHRFilterReducer,


	//PillCount
	savePdmpCredentials: savePdmpCredentialsReducer,
	getPillCounts: getPillCountReducer,
	refreshPillCount: refreshPillCountReducer,
	requestPillCount: requestPillCountReducer,

	//Stripe Payment 
	getProductsList: getProductsReducer,

	//App versions
	appVersionList: getAllAppVersionReducer,
	createAppVersion: createAppVersionReducer,
	changeAppVersionStatus: changeAppVersionStatusReducer,
	deleteAppVersion: deleteAppVersionReducer, 

	chaneConsentFormStatus: changeConsentFormStatusReducer,
	allNotficationTemp: notficationTempReducer,

});


const userInfo = localStorage.getItem('userInfo');
const tokenInfo = localStorage.getItem('token');
const clientIdInfo = localStorage.getItem('clientId');
const getMenusInfo = localStorage.getItem('getMenus');
const clientUrlInfo = localStorage.getItem('clientUrl');


const checkUserInfo = (userInfo === "undefined" || userInfo === undefined) ? localStorage.removeItem('userInfo') : null;
const checkToken = (tokenInfo === "undefined" || tokenInfo === undefined) ? localStorage.removeItem('token') : null;
const checkClientId = (clientIdInfo === "undefined" || clientIdInfo === undefined) ? localStorage.removeItem('clientId') : null;
const checkGetMenus = (getMenusInfo === "undefined" || getMenusInfo === undefined) ? localStorage.removeItem('getMenus') : null;
const clientUrl = (clientUrlInfo === "undefined" || clientUrlInfo === undefined) ? localStorage.removeItem('clientUrl') : null;


const userInfoFromStorage = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
const clientId = localStorage.getItem('clientId') ? JSON.parse(localStorage.getItem('clientId')) : null;
const getMenus = localStorage.getItem('getMenus') ? JSON.parse(localStorage.getItem('getMenus')) : null;

setUrl(localStorage.getItem('clientUrl') ? localStorage.getItem('clientUrl') : userInfoFromStorage && userInfoFromStorage.apiurl)

const initialState = {
	userLogin: { userInfo: userInfoFromStorage && userInfoFromStorage },
	activeToken: { token: token && token },
	clientId: clientId && clientId,
	getMenus: { data: getMenus && getMenus }
};
const middleware = [thunk];

const store = createStore(reducer, initialState, composeWithDevTools(applyMiddleware(...middleware)));

export default store;