import React from 'react';
import classes from '../Notifications.module.css';

const TextArea = ({ label, id, placeholder, value, onChange, error, rows = 5,style }) => {
  return (
    <div className={classes.textBox}>
      <label htmlFor={id} className={classes.textBox_label}>
        {label}
      </label>
      <textarea id={id} placeholder={placeholder} value={value} onChange={onChange} className={classes.textArea_input} rows={rows} style={style} />
      {error && <p className={classes.textBox_error}>{error}</p>}
    </div>
  );
};

export default TextArea;
