import {
	REGISTER_ADMIN_REQUEST,
	REGISTER_ADMIN_SUCCESS,
	REGISTER_ADMIN_FAIL,
	REGISTER_ADMIN_RESET,
	INVITE_ADMIN_REQUEST,
	INVITE_ADMIN_SUCCESS,
	INVITE_ADMIN_FAIL,
	INVITE_ADMIN_RESET,
	ADD_INVITE_ADMIN_REQUEST,
	ADD_INVITE_ADMIN_SUCCESS,
	ADD_INVITE_ADMIN_FAIL,
	ADD_INVITE_ADMIN_RESET,
	DELETE_INVITE_ADMIN_REQUEST,
	DELETE_INVITE_ADMIN_SUCCESS,
	DELETE_INVITE_ADMIN_FAIL,
	DELETE_INVITE_ADMIN_RESET,
	UPDATE_INVITE_ADMIN_REQUEST,
	UPDATE_INVITE_ADMIN_SUCCESS,
	UPDATE_INVITE_ADMIN_FAIL,
	UPDATE_INVITE_ADMIN_RESET
} from '../constants/adminConstants';

export const registerAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_ADMIN_REQUEST:
			return { loading: true };

		case REGISTER_ADMIN_SUCCESS:
			return { data: action.payload, loading: false };

		case REGISTER_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};
export const inviteAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case INVITE_ADMIN_REQUEST:
			return { loading: true };

		case INVITE_ADMIN_SUCCESS:
			return { data: action.payload, loading: false };

		case INVITE_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case INVITE_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};

export const createInviteAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_INVITE_ADMIN_REQUEST:
			return { loading: true };

		case ADD_INVITE_ADMIN_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_INVITE_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case ADD_INVITE_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteInviteAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_INVITE_ADMIN_REQUEST:
			return { loading: true };

		case DELETE_INVITE_ADMIN_SUCCESS:
			return { data: action.payload, loading: false };

		case DELETE_INVITE_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_INVITE_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};

export const updateEmailAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_INVITE_ADMIN_REQUEST:
			return { loading: true };

		case UPDATE_INVITE_ADMIN_SUCCESS:
			return { data: action.payload, loading: false };

		case UPDATE_INVITE_ADMIN_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_INVITE_ADMIN_RESET:
			return {};

		default:
			return state;
	}
};
