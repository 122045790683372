import axios from 'axios';
import {
  CREATE_NEW_USER_REQUEST,
  CREATE_NEW_USER_SUCCESS,
  CREATE_NEW_USER_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  GET_ALL_CLIENT_REQUEST,
  GET_ALL_CLIENT_SUCCESS,
  GET_ALL_CLIENT_FAIL,
  MANAGE_USER_REQUEST,
  MANAGE_USER_SUCCESS,
  MANAGE_USER_FAIL,
  MANAGE_CLINIC_REQUEST,
  MANAGE_CLINIC_SUCCESS,
  MANAGE_CLINIC_FAIL,
  UPDATE_MANAGE_USER_REQUEST,
  UPDATE_MANAGE_USER_SUCCESS,
  UPDATE_MANAGE_USER_FAIL,
  ADMINLOGIN_MANAGE_USER_REQUEST,
  ADMINLOGIN_MANAGE_USER_SUCCESS,
  ADMINLOGIN_MANAGE_USER_FAIL,
  DELETE_MANAGE_USER_REQUEST,
  DELETE_MANAGE_USER_SUCCESS,
  DELETE_MANAGE_USER_FAIL,
  ACTIVATE_MANAGE_USER_REQUEST,
  ACTIVATE_MANAGE_USER_SUCCESS,
  ACTIVATE_MANAGE_USER_FAIL,
  MANAGE_UNVERIFIED_REQUEST,
  MANAGE_UNVERIFIED_SUCCESS,
  MANAGE_UNVERIFIED_FAIL,
  PROFILE_REQUEST,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  POST_PROFILE_REQUEST,
  POST_PROFILE_SUCCESS,
  POST_PROFILE_FAIL,
  DEACTIVATE_REQUEST,
  DEACTIVATE_SUCCESS,
  DEACTIVATE_FAIL,
  RESETPASSWORDS_REQUEST,
  RESETPASSWORDS_SUCCESS,
  RESETPASSWORDS_FAIL,
  UNVERIFIED_PASSWORD_REQUEST,
  UNVERIFIED_PASSWORD_SUCCESS,
  UNVERIFIED_PASSWORD_FAIL,
  VERIFY_CLINIC_REQUEST,
  VERIFY_CLINIC_SUCCESS,
  VERIFY_CLINIC_FAIL,
  EDIT_UNVERIFIED_USER_REQUEST,
  EDIT_UNVERIFIED_USER_SUCCESS,
  EDIT_UNVERIFIED_USER_FAIL,
  ACTIVE_TOKEN_REQUEST,
  ACTIVE_TOKEN_SUCCESS,
  ACTIVE_TOKEN_FAIL,
  ADD_CLIENT_REQUEST,
  ADD_CLIENT_SUCCESS,
  ADD_CLIENT_FAIL,
  EDIT_CLIENT_REQUEST,
  EDIT_CLIENT_SUCCESS,
  EDIT_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  ACTIVEINACTIVE_CLIENT_REQUEST,
  ACTIVEINACTIVE_CLIENT_SUCCESS,
  ACTIVEINACTIVE_CLIENT_FAIL,
  CONTENTS_REQUEST,
  CONTENTS_SUCCESS,
  CONTENTS_FAIL,
  UPLOAD_HISTORY_REQUEST,
  UPLOAD_HISTORY_SUCCESS,
  UPLOAD_HISTORY_FAIL,
  CONTENT_PDF_REQUEST,
  CONTENT_PDF_SUCCESS,
  CONTENT_PDF_FAIL,
  CONTENT_VIDEO_REQUEST,
  CONTENT_VIDEO_SUCCESS,
  CONTENT_VIDEO_FAIL,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  SCHEDULE_MAP_REQUEST,
  SCHEDULE_MAP_SUCCESS,
  SCHEDULE_MAP_FAIL,
  SAVE_SCHEDULE_REQUEST,
  SAVE_SCHEDULE_SUCCESS,
  SAVE_SCHEDULE_FAIL,
  UPLOAD_CONTENT_REQUEST,
  UPLOAD_CONTENT_SUCCESS,
  UPLOAD_CONTENT_FAIL,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_SUCCESS,
  DELETE_CONTENT_FAIL,
  CLIENT_URL,
  REGISTER_PATIENT_REQUEST,
  REGISTER_PATIENT_SUCCESS,
  REGISTER_PATIENT_FAIL,
  CLIENT_NAME,
  GET_MENUS_REQUEST,
  GET_MENUS_SUCCESS,
  GET_MENUS_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  VERFIY_PATIENT_REQUEST,
  VERFIY_PATIENT_SUCCESS,
  VERFIY_PATIENT_FAIL,
  GET_PARTNER_CONTENTS_FAIL,
  GET_PARTNER_CONTENTS_SUCCESS,
  GET_PARTNER_CONTENTS_REQUEST,
} from '../constants/userConstants';
import { BASE_URL_LOGIN } from './ip';
import { toast } from 'react-toastify';

//Interceptor
// axios.interceptors.request.use(
// 	(req) => {
// 		if (req.status === 401) {
// 			alert("You are not authorized");
// 		}
// 		const { userLogin } = store.getState();
// 		const { activeToken: { token } } = store.getState();
// 		if (userLogin && userLogin.userInfo && userLogin.userInfo.length > 0) {
// 			req.headers = { ...req.headers, Authorization: 'Bearer ' + token, 'custom-clinicid-header': 1 }
// 		}
// 		return req;
// 	},
// 	(err) => {
// 		return Promise.reject(err);
// 	}
// );

//login
export const login = (email, password) => async dispatch => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const config = {
      headers: {
        'custom-clinicid-header': 1,
      },
    };

    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/Auth/NewLogin`,
      {
        username: email,
        password,
        websiteId: '98DF879A-A222-4B39-AFA0-FE4C38429EA8',
      },
      config
    );
    let userInfo;
    userInfo = data.filter(item => item.issuccess);

    if (userInfo && userInfo.length === 0) {
      userInfo = data.filter(item => item.message);
    }

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: userInfo[0],
    });

    if (Array.isArray(data)) {
      const info = userInfo.map(data => data.token);
      dispatch({
        type: ACTIVE_TOKEN_SUCCESS,
        payload: info[0],
      });
    }

    if (userInfo && userInfo.length > 0 && userInfo[0] && userInfo[0].token) {
      localStorage.setItem('userInfo', JSON.stringify(userInfo[0]));
      localStorage.setItem('token', JSON.stringify(userInfo[0].token));
      localStorage.setItem('clientId', JSON.stringify(userInfo[0].clientId));
    }
  } catch (err) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: err.response,
    });
  }
};

export const updateLoginInfo = data => async dispatch => {
  try {
    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
    localStorage.setItem('token', JSON.stringify(data.token));
    localStorage.setItem('clientId', JSON.stringify(data.clientId));
  } catch (err) { }
};

export const getMenusAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_MENUS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };
    let { data } = await axios.get(`${BASE_URL_LOGIN}/Client/getallmenus`, config);

    dispatch({
      type: GET_MENUS_SUCCESS,
      payload: data,
    });

    localStorage.setItem('getMenus', JSON.stringify(data));
  } catch (err) {
    dispatch({
      type: GET_MENUS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const activeTokenAction = token => async dispatch => {
  try {
    dispatch({ type: ACTIVE_TOKEN_REQUEST });

    dispatch({
      type: ACTIVE_TOKEN_SUCCESS,
      payload: token,
    });

    localStorage.setItem('token', JSON.stringify(token));
  } catch (err) {
    dispatch({
      type: ACTIVE_TOKEN_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const forgotPasswordAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const config = {
      headers: {
        'custom-clinicid-header': 1,
      },
    };
    let { data } = await axios.post(`${BASE_URL_LOGIN}/Auth/ForgotPassword`, formData, config);

    dispatch({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const ResetforgotPasswordAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = {
      headers: {
        'custom-clinicid-header': 1,
      },
    };
    let { data } = await axios.post(`${BASE_URL_LOGIN}/auth/ResetForgotPassword`, formData, config);

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//Logout
export const logout = () => async dispatch => {
  localStorage.removeItem('userInfo');
  localStorage.removeItem('token');
  localStorage.removeItem('clientId');
  localStorage.removeItem('clientUrl');
  localStorage.removeItem('getMenus');
  sessionStorage.removeItem('userInfo');
  dispatch({ type: USER_LOGOUT });
};

//get All Clients
export const getAllClientAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ALL_CLIENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header':userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    let { data } = await axios.get(`${BASE_URL_LOGIN}/Client/getallclient`, config);
    // debugger
    // data = data.map((item) => {
    // 	if (item.clientId === "2e0d706e-f906-47ac-8dd4-9176264b3d51") {
    // 		item.apiurl = 'https://api.suremedcompliance.com:5015/api'
    // 	} else if (item.clientId === "cbc03e68-c5f8-4191-b33b-e7a2d2b53cf5") {
    // 		item.apiurl = 'https://api.suremedcompliance.com:5015/api'
    // 	} else {
    // 		item.apiurl = null
    // 	}
    // 	return item
    // });
    dispatch({
      type: GET_ALL_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_ALL_CLIENT_FAIL,
      payload: err.response,
    });
  }
};

export const setClientUrlAction = (url, clientName) => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_URL,
      payload: url,
    });
  } catch (err) { }
};
export const setClientNameAction = clientName => async (dispatch, getState) => {
  try {
    dispatch({
      type: CLIENT_NAME,
      payload: clientName,
    });
  } catch (err) { }
};

//add Client
export const addClientAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: ADD_CLIENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'multipart/form-data',
      },
    };

    const { data } = await axios.post(`${BASE_URL_LOGIN}/Client/saveclient`, formData, config);

    dispatch({
      type: ADD_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADD_CLIENT_FAIL,
      payload: err.response && err.response.data,
    });
  }
};

//delete Client
export const deleteClientAction = Id => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_CLIENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.delete(`${BASE_URL_LOGIN}/Client/deleteclient/${Id}`, config);

    dispatch({
      type: DELETE_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_CLIENT_FAIL,
      payload: err.response,
    });
  }
};

//EDIT Client
export const editClientAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_CLIENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL_LOGIN}/Client/Updateclient`, formData, config);

    dispatch({
      type: EDIT_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_CLIENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//activeinactive Client
export const activeInactiveAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTIVEINACTIVE_CLIENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${BASE_URL_LOGIN}/Client/activeinactiveclient`, formData, config);

    dispatch({
      type: ACTIVEINACTIVE_CLIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVEINACTIVE_CLIENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const editUnverifiedUserAction = (userId, email, firstName, lastName) => async (dispatch, getState) => {
  try {
    dispatch({ type: EDIT_UNVERIFIED_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(`${userInfo.apiurl}/Auth/support/update-user`, { userId, email, firstName, lastName }, config);

    dispatch({
      type: EDIT_UNVERIFIED_USER_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: EDIT_UNVERIFIED_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const verifyClinicAction = email => async (dispatch, getState) => {
  try {
    dispatch({ type: VERIFY_CLINIC_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/Auth/support/verify`,
      {
        email,
      },
      config
    );

    dispatch({
      type: VERIFY_CLINIC_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VERIFY_CLINIC_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const unverifiedResetPasswordAction = (EmailPreview, newPassword) => async (dispatch, getState) => {
  try {
    dispatch({ type: UNVERIFIED_PASSWORD_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/Auth/support/resetpassword`,
      {
        email: EmailPreview,
        password: newPassword,
      },
      config
    );

    dispatch({
      type: UNVERIFIED_PASSWORD_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UNVERIFIED_PASSWORD_FAIL,
      payload: err.response,
    });
  }
};

export const createNewUserAction = userData => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_NEW_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, //made changes here
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/users/`, userData, config);

    dispatch({
      type: CREATE_NEW_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CREATE_NEW_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//manageUserAction

export const managerUserAction =
  (filter = false, filterData) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: MANAGE_USER_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`, //made changes here
            'custom-clinicid-header': userInfo.clinicid,
          },
        };
        let res;
        filter
          ? (res = await axios.post(`${userInfo.apiurl}/Providers/GetUserList`, filterData, config))
          : (res = await axios.get(`${userInfo.apiurl}/Providers/GetUserList`, config));

        dispatch({
          type: MANAGE_USER_SUCCESS,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: MANAGE_USER_FAIL,
          payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        });
      }
    };

//update edit manage user
export const updatemanageUserAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_MANAGE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };
    const { data } = await axios.post(`${userInfo.apiurl}/Auth/support/update-user`, formData, config);

    dispatch({
      type: UPDATE_MANAGE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPDATE_MANAGE_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//Homescreen Active Toggle Action
export const activatemanageUserAction = (id, status) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACTIVATE_MANAGE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.post(
      `${userInfo.apiurl}/Providers/SaveUserEDbyUserId`,
      {
        id,
        isdisabled: !status,
        type: 0,
      },
      config
    );
    dispatch({
      type: ACTIVATE_MANAGE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATE_MANAGE_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//manageClinicAction

export const manageClinicAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: MANAGE_CLINIC_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Providers/GetProviderByIdList?clinicid=${id}`, config);

    dispatch({
      type: MANAGE_CLINIC_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: MANAGE_CLINIC_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//manageUnverfiedAction
export const manageUnverfiedAction =
  (role = '', email = '') =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: MANAGE_UNVERIFIED_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'custom-clinicid-header': userInfo.clinicid,
            'Content-Type': 'application/json',
          },
        };

        const { data } = await axios.get(`${userInfo.apiurl}/Auth/support/unverified-users?term=${email}&searchrole=${role}`, config);

        dispatch({
          type: MANAGE_UNVERIFIED_SUCCESS,
          payload: data,
        });
      } catch (err) {
        if (err.response?.status === 401) {
          dispatch(logout());
        }
        dispatch({
          type: MANAGE_UNVERIFIED_FAIL,
          payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        });
      }
    };

//Login as Admin User (Manage Users)

export const adminLoginAction = userName => async (dispatch, getState) => {
  try {
    dispatch({ type: ADMINLOGIN_MANAGE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Auth/LogInAsAdmin`, { userName }, config);
    const adminData = {
      apiurl: userInfo.apiurl,
      token: data.token,
      issuccess: data.emailConfirmed,
      clinicid: userInfo.clinicid,
      clientId: userInfo.clientId,
      issuperadmin: data && data.applicationUser && data.applicationUser.isSuperAdmin,
      isclinicadmin: data && data.applicationUser && data.applicationUser.isClinicAdmin,
      isprovider: data && data.applicationUser && data.applicationUser.isClinicProvider,
      ispatient: data && data.applicationUser && data.applicationUser.isPatient,
      isenablepillcount: data && data.applicationUser && data.applicationUser.isenablepillcount,
    };

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: adminData,
    });

    dispatch({
      type: ADMINLOGIN_MANAGE_USER_SUCCESS,
      payload: data,
    });
    localStorage.setItem('token', JSON.stringify(data && data.token));
    localStorage.setItem('userInfo', JSON.stringify(adminData));
  } catch (err) {
    dispatch({
      type: ADMINLOGIN_MANAGE_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
//Delete User (Manage Users)

export const deleteManageUserAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_MANAGE_USER_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Clinics/deleteuser`, formData, config);

    dispatch({
      type: DELETE_MANAGE_USER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_MANAGE_USER_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//profileAction
export const profileAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/UserProfile`, config);

    dispatch({
      type: PROFILE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
//update User Profile
export const updateUserProfilerAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: POST_PROFILE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const res = await axios.post(`${userInfo.apiurl}/Auth/support/update-user`, formData, config);

    dispatch({
      type: POST_PROFILE_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POST_PROFILE_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//Deactivate Account Action
export const deactivateAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DEACTIVATE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Providers/SaveUserEDbyUserId`, config);

    dispatch({
      type: DEACTIVATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DEACTIVATE_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

//PasswordReset
export const passwordResetAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: RESETPASSWORDS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/auth/ResetPasswordAdmin`, formData, config);

    dispatch({
      type: RESETPASSWORDS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: RESETPASSWORDS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const uploadHistoryAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: UPLOAD_HISTORY_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/VideoMaster/getHistoryDetails`, formData, config);
    dispatch({
      type: UPLOAD_HISTORY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPLOAD_HISTORY_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const contentsAction =
  (Partner = null, Type = null) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: CONTENTS_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'custom-clinicid-header': userInfo.clinicid,
            'Content-Type': 'application/json',
          },
        };

        let BaseURL = `${userInfo.apiurl}/VideoMaster/GetVideoDetails`;

        if (Partner && Type) {
          BaseURL += `?title=${Partner}&type=${Type}`;
        } else if (Partner) {
          BaseURL += `?title=${Partner}`;
        } else if (Type) {
          BaseURL += `?type=${Type}`;
        }

        const { data } = await axios.get(BaseURL, config);

        dispatch({
          type: CONTENTS_SUCCESS,
          payload: data,
        });
      } catch (err) {
        dispatch({
          type: CONTENTS_FAIL,
          payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        });
      }
    };
export const contentPdfAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTENT_PDF_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config1 = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/octet-stream',
      },
      responseType: 'blob',
    };
    const { data } = await axios.get(`${userInfo.apiurl}/VideoMaster/getFileById?fileId=${id}`, config1);
    dispatch({
      type: CONTENT_PDF_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CONTENT_PDF_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const contentPdfViewAction = id => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/VideoMaster/GetDisclosureFileById?fileId=${id}`, config);
    return data;
  } catch (err) { }
};

export const getSponserDisclosureAction =
  (Partner = null, Type = null) =>
    async (dispatch, getState) => {
      try {
        const {
          userLogin: { userInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
            'custom-clinicid-header': userInfo.clinicid,
            'Content-Type': 'application/json',
          },
        };

        let BaseURL = `${userInfo.apiurl}/VideoMaster/GetVideoDetails`;

        if (Partner && Type) {
          BaseURL += `?title=${Partner}&type=${Type}`;
        } else if (Partner) {
          BaseURL += `?title=${Partner}`;
        } else if (Type) {
          BaseURL += `?type=${Type}`;
        }

        const { data } = await axios.get(BaseURL, config);

        return data;
      } catch (err) {
        return err.response && err.response.data.msg ? err.response.data.msg : err.message;
      }
    };

export const addDocumentHistoryAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: CONTENT_VIDEO_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/VideoMaster/AddDocumentHistory`, formData, config);
    dispatch({
      type: CONTENT_VIDEO_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: CONTENT_VIDEO_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getScheduleAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_SCHEDULE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Schedule/getSchedule`, config);
    dispatch({
      type: GET_SCHEDULE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_SCHEDULE_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const scheduleMapAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: SCHEDULE_MAP_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Schedule/getScheduleMappingUser?id=${id}`, config);
    dispatch({
      type: SCHEDULE_MAP_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SCHEDULE_MAP_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const saveScheduleAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: SAVE_SCHEDULE_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Schedule/saveSchedule`, formData, config);
    dispatch({
      type: SAVE_SCHEDULE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: SAVE_SCHEDULE_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const uploadContentAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: UPLOAD_CONTENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/VideoMaster/saveVideoMaster`, formData, config);
    dispatch({
      type: UPLOAD_CONTENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPLOAD_CONTENT_FAIL,
      payload: err.response,
    });
  }
};

export const deleteContentAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_CONTENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/VideoMaster/${id}`, config);

    dispatch({
      type: DELETE_CONTENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_CONTENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getPartnerContentsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PARTNER_CONTENTS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    let BaseURL = `${userInfo.apiurl}/PartnerMaster/GetPartnerList`;

    const { data } = await axios.get(BaseURL, config);

    dispatch({
      type: GET_PARTNER_CONTENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: GET_PARTNER_CONTENTS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const addPartnerContentAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: UPLOAD_CONTENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/PartnerMaster/savePartnerCategory`, formData, config);
    dispatch({
      type: UPLOAD_CONTENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: UPLOAD_CONTENT_FAIL,
      payload: err.response,
    });
  }
};

export const deletePartnerContentAction = id => async (dispatch, getState) => {
  try {
    dispatch({ type: DELETE_CONTENT_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/PartnerMaster/${id}`, config);

    dispatch({
      type: DELETE_CONTENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: DELETE_CONTENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const registerPatientAction = formDataInput => async (dispatch, getState) => {
  try {
    dispatch({ type: REGISTER_PATIENT_REQUEST });

    //const { userLogin: { userInfo } } = getState();
    const config = {
      headers: {
        'custom-clinicid-header': 1,
      },
    };

    const { data } = await axios.post(`${BASE_URL_LOGIN}/auth/RegisterPatient`, formDataInput, config);

    dispatch({
      type: REGISTER_PATIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: REGISTER_PATIENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};

export const getConsentFormsAction =
  (clinicPin = '82077888') =>
    async (dispatch, getState) => {
      try {
        //const { userLogin: { userInfo } } = getState();
        const config = {
          headers: {
            'custom-clinicid-header': 1,
          },
        };

        const { data } = await axios.get(`${BASE_URL_LOGIN}/ConsentForm/GetConsentFormsFileByClinicPin?ClinicPin=${clinicPin}`, config);

        return data;
      } catch (err) {
        return err.response && err.response.data.message ? err.response.data.message : err.message;
      }
    };

export const verfiyPatientAction = formDataInput => async dispatch => {
  try {
    dispatch({ type: VERFIY_PATIENT_REQUEST });

    const config = {
      headers: {
        'custom-clinicid-header': 1,
      },
    };

    const { data } = await axios.post(`${BASE_URL_LOGIN}/Auth/VerifyEmail`, formDataInput, config);

    dispatch({
      type: VERFIY_PATIENT_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: VERFIY_PATIENT_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};
export const baaPdfAction = clinicId => async (dispatch, getState) => {
  try {
    const config = {
      headers: {
        'custom-clinicid-header': 1,
      },
    };

    const { data } = await axios.get(`${BASE_URL_LOGIN}/Clinics/getClinicBAA?ClinicId=${clinicId}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getConsentFormByPatientAction = id => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/consentForm/getConsentFormByPatientId?patientId=${id}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const addSignedConsentFormsAction = SignedConsentForms => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/consentForm/addSignedConsentForms`, SignedConsentForms, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getUserListForSFTPAction =
  (filter = false, filterData) =>
    async (dispatch, getState) => {
      try {
        dispatch({ type: MANAGE_USER_REQUEST });

        const {
          userLogin: { userInfo },
        } = getState();

        const config = {
          headers: {
            Authorization: `Bearer ${userInfo.token}`, //made changes here
            'custom-clinicid-header': userInfo.clinicid,
          },
        };
        let res;
        filter
          ? (res = await axios.post(`${userInfo.apiurl}/Providers/GetUserListForSFTP`, filterData, config))
          : (res = await axios.get(`${userInfo.apiurl}/Providers/GetUserListForSFTP`, config));

        dispatch({
          type: MANAGE_USER_SUCCESS,
          payload: res.data,
        });
      } catch (err) {
        dispatch({
          type: MANAGE_USER_FAIL,
          payload: err.response && err.response.data.message ? err.response.data.message : err.message,
        });
      }
    };

export const GetSFTPGenerateByAssessmentIdPDFAction = assessmentId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const data = await axios.get(`${userInfo.apiurl}/Assessments/GetSFTPGenerateByAssessmentIdPDF?assessmentId=${assessmentId}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};


export const GetSFTPGenerateByPatientIdPDFAction = patientId => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const data = await axios.get(`${userInfo.apiurl}/Assessments/GenerateModMedPDFByPatientId?patientId=${patientId}`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const updateUserDetailsFormsAction = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Auth/UpdateUserDetailsForms`, formData, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const updateModMedIdAction = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/ModMed/updateModMedId`, formData, config);

    return data;
  } catch (err) {
    toast.error(err.response.data.message);
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const getUserProfile = () => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Mobile/getUserDetails`, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};

export const updateUserDetail = formData => async (dispatch, getState) => {
  try {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Mobile/updateUserDetails`, formData, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};

export const passwordResetPatientAction = formData => async (dispatch, getState) => {
  try {
    dispatch({ type: RESETPASSWORDS_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Mobile/ChangePassword`, formData, config);

    dispatch({
      type: RESETPASSWORDS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: RESETPASSWORDS_FAIL,
      payload: err.response && err.response.data.message ? err.response.data.message : err.message,
    });
  }
};




export const addReminderDateAction = formData => async (dispatch, getState) => {
  try {

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      },
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Mobile/updateReminderDate`, formData, config);

    return data;
  } catch (err) {
    return err.response && err.response.data.message ? err.response.data.message : err.message;
  }
};



export const GetNotifications = () => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Notification/getNotificationList`, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};



export const readAllNotifications = () => async (dispatch, getState) => {
  try {
    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Notification/MarkAsReadAll`, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};



export const getPreviousNotificationList = () => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Notification/getPreviousNotificationList`, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};


export const SendNotificationAction = (formData) => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Notification/SendMessageMutiplePatientAsync`, formData, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};



export const saveNotificationTemplateAction = (formData) => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.post(`${userInfo.apiurl}/Notification/AddNotificationTemplate`, formData, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};

export const deleteNotificationTemplateAction = (Id) => async (dispatch, getState) => {
  try {

    const { userLogin: { userInfo } } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        'custom-clinicid-header': userInfo.clinicid,
      }
    };

    const { data } = await axios.get(`${userInfo.apiurl}/Notification/deleteTemplate?Id=${Id}`, config);

    return data;
  } catch (error) {
    return error.response && error.response ? error.response : error;
  }
};