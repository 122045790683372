import React from 'react'; 
import TableRows from '../../components/Skeletons/TableRows';
 

const ContentTable = ({ data }) => {  
	const { contentDetails, setContentId, setDeleteModalState, setTitle } = data;
	return (
		<div className="tableContainer" style={{ height: '75vh', marginTop: '0' }}>
			<table className="table">
				<thead>
					<tr>
						<th>#</th>
						<th>Title</th> 
						<th />
					</tr>
				</thead>
				<tbody>
					{
						contentDetails && contentDetails.loading ?
							<TableRows columns='13' />
							:
							contentDetails && contentDetails.data && contentDetails.data.length > 0 ?
								contentDetails.data.map((details,i) => (
									<tr key={details.id}>
										<td>{i+1}</td>
										<td>{details.title}</td> 
										<td>
											<div className="table__iconBox">
												<button className="table__buttonBig" onClick={
													() => {
														setTitle(details.title)
														setContentId(details.id)
														setDeleteModalState(true)
													}
												}>
													<svg className="table__button--iconBigDelete">
														<use xlinkHref={`/sprite.svg#icon-delete`} />
													</svg>
													<span>Delete</span>
												</button>
											</div>
										</td>
									</tr>
								))
								:
								<tr>
									<td style={{ textAlign: "center" }} colSpan="7">No partner content data found</td>
								</tr>
					} 
				</tbody>
			</table>
		</div>
	);
};

export default ContentTable;
