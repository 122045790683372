import React from 'react';
import styles from './PaymentSuccess.module.css';

const PaymentSuccess = ({URL}) => { 

  const redirectURLHandler = () => { 
    window.open(URL, '_self')
  }

  return (
    <div className={styles.container}>
      <img
        src='images/payment-success.png'
        alt='Payment Success'
        className={styles.success_image}
      />
      
      <h1 className={styles.success_heading}>Congratulations!</h1>

      <p className={styles.success_sub_heading}> Your payment is successful.</p>

      <p className={styles.success_desc}>
        An email has been sent to your account with the details.
      </p>

      <div className={styles.success_tip_box}>
        <p className={styles.success_tip}>
          Click the button below to complete your registration.
        </p>
      </div>

      <div className={styles.success__button_layout}>
        <button
          className={styles.success__button}
          onClick={redirectURLHandler}
        >
          <span className={styles.success__button_text}>
            COMPLETE REGISTRATION
          </span>
          <div className={styles.success__button_iconBox}>
            <svg className={styles.success__button_icon}>
              <use xlinkHref='/sprite.svg#icon-login' />
            </svg>
          </div>
        </button>
      </div>
    </div>
  );
};

export default PaymentSuccess;
