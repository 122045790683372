export const REGISTER_ADMIN_REQUEST = 'REGISTER_ADMIN_REQUEST';
export const REGISTER_ADMIN_SUCCESS = 'REGISTER_ADMIN_SUCCESS';
export const REGISTER_ADMIN_FAIL = 'REGISTER_ADMIN_FAIL';
export const REGISTER_ADMIN_RESET = 'REGISTER_ADMIN_RESET';

export const INVITE_ADMIN_REQUEST = 'INVITE_ADMIN_REQUEST';
export const INVITE_ADMIN_SUCCESS = 'INVITE_ADMIN_SUCESSS';
export const INVITE_ADMIN_FAIL = 'INVITE_ADMIN_FAIL';
export const INVITE_ADMIN_RESET = 'INVITE_ADMIN_RESET';

export const ADD_INVITE_ADMIN_REQUEST = 'ADD_INVITE_ADMIN_REQUEST';
export const ADD_INVITE_ADMIN_SUCCESS = 'ADD_INVITE_ADMIN_SUCESSS';
export const ADD_INVITE_ADMIN_FAIL = 'ADD_INVITE_ADMIN_FAIL';
export const ADD_INVITE_ADMIN_RESET = 'ADD_INVITE_ADMIN_RESET';

export const DELETE_INVITE_ADMIN_REQUEST = 'DELETE_INVITE_ADMIN_REQUEST';
export const DELETE_INVITE_ADMIN_SUCCESS = 'DELETE_INVITE_ADMIN_SUCESSS';
export const DELETE_INVITE_ADMIN_FAIL = 'DELETE_INVITE_ADMIN_FAIL';
export const DELETE_INVITE_ADMIN_RESET = 'DELETE_INVITE_ADMIN_RESET';

export const UPDATE_INVITE_ADMIN_REQUEST = 'UPDATE_INVITE_ADMIN_REQUEST';
export const UPDATE_INVITE_ADMIN_SUCCESS = 'UPDATE_INVITE_ADMIN_SUCCESS';
export const UPDATE_INVITE_ADMIN_FAIL = 'UPDATE_INVITE_ADMIN_FAIL';
export const UPDATE_INVITE_ADMIN_RESET = 'UPDATE_INVITE_ADMIN_RESET';
