import React from 'react';
import { Fragment } from 'react';
import { AdClinIQAPIUrl } from '../../redux/actions/ip';

const SummaryPdf = ({ children, imageURL, pdfURL }) => { 
	return (
		<Fragment>
			{imageURL ?
				<div className='footerTop_new'>
					<div
						className='bannerAdd_Container_new'
						style={{
							width: pdfURL ? '74%' : '100%',
						}}>
						<div class='ads-text'>Advertisement</div>
						<img alt='footerImage1' style={{ maxWidth: "100%" }} src={imageURL ? AdClinIQAPIUrl + 'uploads/v1/' + imageURL : '/images/footer-img1.png'} />
					</div>
					{pdfURL && <div className='PIContainer'>
						<iframe
							src={AdClinIQAPIUrl + 'upload-pdf/v1/' + pdfURL + '?#zoom=100'}
							type='application/pdf'
							className='PIpdf'
							title='summary-pdf'
						/>
						<button className='zoomButton' onClick={() => window.open(pdfURL)}>
							<svg className='print__button--icon'>
								<use xlinkHref='/sprite.svg#icon-save' />
							</svg>
						</button>
					</div>}
				</div>
				:
				<div className='footerTop_new'>
					<div
						className='bannerAdd_Container_new'
						style={{
							width: pdfURL ? '74%' : '100%',
						}}>
						<div class='ads-text'>Advertisement</div>
						<img alt='footerImage1' style={{ maxWidth: "100%" }} src={AdClinIQAPIUrl + 'uploads/v1/doc-1676965460663.jpg'} />
					</div>
				</div>}
		</Fragment >
	);
};

export default SummaryPdf;
