import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addReminderDateAction, logout } from '../../redux/actions/userActions';
import AssessmentSkeleton from '../../components/Skeletons/AssessmentSkeleton';
import './SelfAssessment.css';
import ImageMapperBody from './ImageMapperBody';
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { toast } from 'react-toastify';



const Assessment = ({ data }) => {
	const dispatch = useDispatch();
	const {
		allQuestionData,
		setAllQuestionData,
		onNextQuestion,
		onPreviousQuestion,
		setMultipleAnswer,
		multipleAnswer,
		yesToggle,
		NoToggle,
		setYesToggle,
		setNoToggle,
		noInputError,
		setNoInputError,
		setBmiDemographic,
		bmiDemographic,
		pounds,
		setPounds,
		feet,
		setFeet,
		inches,
		setInches,
		setSingleAnswer,
		setBenzQuestion,
		benzQuestion,
		userDetails,
		iscellincluded,
		requestData,
		setRequestData,
		requestDataError,
		setRequestDataError,
		mapAreas,
		setMapAreas
	} = data;

	const nextQuestion = useSelector((state) => state.nextQuestion);
	const questionResult = useSelector((state) => state.questionResult);
	const previousQuestion = useSelector((state) => state.previousQuestion);

	const [focus, setFocus] = React.useState(true);
	const [startDate, setStartDate] = React.useState(null);
	const [component, setComponent] = React.useState(null);
	const [reminderDate, setReminderDate] = React.useState({
		value: '',
		error: '',
	});
	const years = range(new Date().getFullYear(), getYear(new Date()) + 4, 1);
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const prev = () => {
		setBenzQuestion('')
		onPreviousQuestion()
	}
	const handleBenz = () => {
		let answerList = allQuestionData.answers;
		allQuestionData && allQuestionData.answers.filter(item => item.isSelected).map((answer) => (
			benzQuestion !== '' ?
				onNextQuestion()
				:
				answerList[3].isSelected ?
					onNextQuestion()
					:
					answerList[0].isSelected && answerList[1].isSelected && answerList[2].isSelected ?
						onNextQuestion()
						:
						answerList[0].isSelected && answerList[1].isSelected ?
							setBenzQuestion("You chose Benzhydrocodone and Hydrocodone. Benzhydrocodone and Hydrocodone sound very similar to Oxycodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Benzhydrocodone and Hydrocodone by clicking next. If you made a mistake, please click the back button and select again.")
							// setAllQuestionData({...allQuestionData, questionText: 'You chose Benzhydrocodone and Hydrocodone. Benzhydrocodone and Hydrocodone sound very similar to Oxycodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Benzhydrocodone and Hydrocodone by clicking next. If you made a mistake, please click the back button and select again.',
							//  answerType: 'Note1', name: 'BenzNote'})
							:
							answerList[0].isSelected && answerList[2].isSelected ?
								setBenzQuestion("You chose Benzhydrocodone and Oxycodone. Benzhydrocodone and Oxycodone sound very similar to Hydrocodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Benzhydrocodone and Oxycodone by clicking next. If you made a mistake, please click the back button and select again.")
								// setAllQuestionData({...allQuestionData, questionText: 'You chose Benzhydrocodone and Oxycodone. Benzhydrocodone and Oxycodone sound very similar to Hydrocodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Benzhydrocodone and Oxycodone by clicking next. If you made a mistake, please click the back button and select again.',
								//  answerType: 'Note1', name: 'BenzNote'})
								:
								answerList[1].isSelected && answerList[2].isSelected ?
									setBenzQuestion("You chose Hydrocodone and Oxycodone. Hydrocodone and Oxycodone sound very similar to Benzhydrocodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Hydrocodone and Oxycodone by clicking next. If you made a mistake, please click the back button and select again.")
									//  setAllQuestionData({...allQuestionData, questionText: 'You chose Hydrocodone and Oxycodone. Hydrocodone and Oxycodone sound very similar to Benzhydrocodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Hydrocodone and Oxycodone by clicking next. If you made a mistake, please click the back button and select again.',
									//   answerType: 'Note1', name: 'BenzNote'})
									:
									answer.displayText === 'Benzhydrocodone (e.g., Apadaz®)' ?
										setBenzQuestion("You chose Benzhydrocodone. Benzhydrocodone sounds very similar to Hydrocodone and Oxycodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Benzhydrocodone by clicking next. If you made a mistake, please click the back button and select again.")
										// setAllQuestionData({...allQuestionData, questionText: 'You chose Benzhydrocodone. Benzhydrocodone sounds very similar to Hydrocodone and Oxycodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Benzhydrocodone by clicking next. If you made a mistake, please click the back button and select again.',
										// answerType: 'Note1', name: 'BenzNote'})
										:
										answer.displayText === "Hydrocodone (e.g., Norco®, Zohydro ER®, Hysingla ER®, Vicodin®, Lortab®, Lorcet®, Anexsia®, Rezira®, Vicoprofen®, Reprexain®, Ultragesic®, Zohydro®)" ?
											setBenzQuestion("You chose Hydrocodone. Hydrocodone sounds very similar to Benzhydrocodone and Oxycodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Hydrocodone by clicking next. If you made a mistake, please click the back button and select again.")
											// setAllQuestionData({...allQuestionData, questionText: 'You chose Hydrocodone. Hydrocodone sounds very similar to Benzhydrocodone and Oxycodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Hydrocodone by clicking next. If you made a mistake, please click the back button and select again.',
											//  answerType: 'Note1', name: 'BenzNote'})
											:
											answer.displayText === 'Oxycodone (e.g., OxyContin®, Xartemis XR®, Targiniq ER®, OxyContin®, Percocet®, Percodan®, Oxecta®, Oxycet®, Oxaydo®, Oxyfast®, Oxyir®, Roxicodone®, Roxicet®, Tylox®, Endocet®, Combunox®)' ?
												setBenzQuestion("You chose Oxycodone. Oxycodone sounds very similar to Benzhydrocodone and Hydrocodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Oxycodone by clicking next. If you made a mistake, please click the back button and select again.")
												// setAllQuestionData({...allQuestionData, questionText: 'You chose Oxycodone. Oxycodone sounds very similar to Benzhydrocodone and Hydrocodone and they often get confused for one another. Please review your prescriptions and confirm that you are taking/being prescribed Oxycodone by clicking next. If you made a mistake, please click the back button and select again.',
												//  answerType: 'Note1', name: 'BenzNote'})
												:
												null
		))
	}


	const onChange = async (selectedDate) => {
		const formData = {
			// UserId: userDetails.id,
			ReminderDate: moment(selectedDate).format("YYYY-MM-DD"),
		}
		let data = await dispatch(addReminderDateAction(formData));
		if (data?.status) {
			toast.success(data.message);
		} else {
			toast.error(data.message);
		}
	};

	return (
		<div className="selfContainer">
			<div className="selfContainer__box">

				{(nextQuestion && nextQuestion.loading) ||
					(questionResult && questionResult.loading) ||
					(previousQuestion && previousQuestion.loading) ? (
					<AssessmentSkeleton />
				) : (
					<Fragment>
						{/* <div className="audioContainer__formgroup--btn">
						<span className="audioContainer__button--text">Take audio assessment</span>
						<button className="audioContainer__button" onClick={handleListen}>
							<div className="audioContainer__button--iconBox">
								<svg className="audioContainer__button--icon">
									<use xlinkHref="/sprite.svg#icon-audio" />
								</svg>
							</div>
						</button>
					</div> */}
						<div className='questionAnswer--main'>
							{benzQuestion !== '' ?
								<p className="audioContainer__button--text1" dangerouslySetInnerHTML={{ __html: benzQuestion }} />
								:
								allQuestionData &&
									allQuestionData.name === 'Pre_1' ?
									<p className="audioContainer__button--text1" style={{ fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: allQuestionData.questionText && allQuestionData.questionText.replace(/\n/g, '<br/>') }} />
									:
									<p className="audioContainer__button--text1" dangerouslySetInnerHTML={{ __html: allQuestionData.questionText && allQuestionData.questionText.replace(/\n/g, '<br/>') }} />
							}
							<div className='answersOnlyContainer'>
								{
									allQuestionData.name == "Q_7_1" ?
										<ImageMapperBody data={{
											allQuestionData,
											setAllQuestionData,
											mapAreas,
											setMapAreas,
											multipleAnswer,
											setMultipleAnswer
										}} />
										:
										allQuestionData && allQuestionData.answers && allQuestionData.answerType === 'YesNo' ? (
											<div>
												<div className="self__switchContainer">
													<label className="switch">
														<input
															id="active"
															type="checkbox"
															className="checkbox"
															name="active"
															checked={yesToggle}
															onChange={() => {
																setYesToggle(!yesToggle);
																setNoToggle(yesToggle);
																if (!yesToggle) {

																	setMultipleAnswer([{ answer: 'yes' }])
																	setSingleAnswer(['yes'])
																} else {
																	setMultipleAnswer([{ answer: 'no' }])
																	// setSingleAnswer(['no'])
																	//setMultipleAnswer([])
																}
																setNoInputError('')
															}}
														/>
														<span className="slider round" />
													</label>
													<h3 className="switchbox-text">Yes</h3>
												</div>
												<div className="self__switchContainer">
													<label className="switch">
														<input
															id="active"
															type="checkbox"
															className="checkbox"
															name="active"
															checked={NoToggle}
															onChange={() => {
																setNoToggle(!NoToggle);
																setYesToggle(NoToggle);

																if (!NoToggle) {
																	setMultipleAnswer([{ answer: 'no' }])
																	setSingleAnswer(['no'])
																} else {
																	setMultipleAnswer([{ answer: 'yes' }])
																}
																//!NoToggle ? setMultipleAnswer([{ answer: 'no'}]) : setMultipleAnswer([]);
																setNoInputError('')
															}}
														/>
														<span className="slider round" />
													</label>
													<h3 className="switchbox-text">No</h3>
												</div>
											</div>
										) : allQuestionData && allQuestionData.answers && allQuestionData.answerType === 'NoteFinal' ? (
											<div className="finalMainContainer">
												{/* <div className="finalContainer"> */}
												<div className="staticTextContainer">
													{/* <p>You have successfully completed your assignment.</p> */}

												</div>
												<div
													style={{ marginBottom: '1rem' }}
												>
													<p style={{ fontWeight: 'bold' }}>Completion Date: {allQuestionData.completiondate}</p>
													<p style={{ fontWeight: 'bold' }}>Reference Number: {allQuestionData.referenceNumber}</p>
												</div>
												<div className="staticTextContainer">
													{/* <p>Press the view PDF button to check your printable questionare</p> */}
													<p>You will also receive an email with your reference number.</p>
												</div>

												{/* <button className="finalContainer__button" onClick={onReportDTO}>
								<div className='finalContainer__button--iconBox'>
									<svg className='finalContainer__button--icon'>
										<use xlinkHref='/sprite.svg#icon-pdf' />
									</svg>
								</div>
								<span className="finalContainer__button--text">View PDF</span>
							</button> */}
												{/* </div> */}
											</div>
										) : allQuestionData &&
											allQuestionData.answers &&
											allQuestionData.answerType === 'MultiSelect' ? (
											allQuestionData.answers.map((answers, i) => (
												<div key={i + 1} className="self__switchContainer" style={{ display: benzQuestion !== '' ? 'none' : null }}>
													<input
														id="active"
														type="checkbox"
														className="assessmentCheckbox"
														name="active"
														checked={answers.isSelected}
														onChange={() => {
															setNoInputError('')

															let ansStatus = !answers.isSelected;
															// if (multipleAnswer.indexOf(answers.answerOptionId) === -1 && ansStatus === true) {
															// setMultipleAnswer([ ...multipleAnswer, answers.answerOptionId ]);
															// } else if(ansStatus === false && multipleAnswer){
															// 	const array = multipleAnswer.filter(
															// 	(answerId) => answerId !== answers.answerOptionId);
															//
															// 	setMultipleAnswer(array);
															// }
															let answerList = allQuestionData.answers;

															if (answers.nota === true) {

																if (multipleAnswer.indexOf(answers.answerOptionId) === -1 && ansStatus === true) {
																	setMultipleAnswer([answers.answerOptionId]);

																} else if (ansStatus === false && multipleAnswer) {
																	// const array = multipleAnswer.filter((answerId) => answerId !== answers.answerOptionId); 
																	setMultipleAnswer([]);
																}

																answerList.filter(item => !item.nota)
																	.map((item) =>

																		// if(multipleAnswer.length>0){
																		// 	const array = multipleAnswer.filter((answerId) => answerId !== item.answerOptionId)
																		// setMultipleAnswer(array)
																		// }

																		item.isSelected = false
																	)

															} else if (answers.nota === false) {
																const checkNota = answerList.find(item => item.isSelected && item.nota)
																if (checkNota) {
																	if (multipleAnswer.indexOf(checkNota.answerOptionId) !== -1) {
																		const multipleAnswerCopy = multipleAnswer.splice(multipleAnswer.indexOf(checkNota.answerOptionId), 1)
																		setMultipleAnswer([...multipleAnswerCopy])
																	}
																}
																if (multipleAnswer.indexOf(answers.answerOptionId) === -1 && ansStatus === true) {
																	setMultipleAnswer([...multipleAnswer, answers.answerOptionId]);
																} else if (ansStatus === false && multipleAnswer) {
																	const array = multipleAnswer.filter(
																		(answerId) => answerId !== answers.answerOptionId);

																	setMultipleAnswer(array);
																}
																answerList.filter(item => item.nota)
																	.map((item) => item.isSelected = false)
															}
															answers.isSelected = ansStatus;
															answerList[i] = answers;
															setAllQuestionData({ ...allQuestionData, answers: answerList })
														}}
													/>
													{/* <span className="slider round" /> */}

													<h3 className="switchbox-text">{answers.displayText}</h3>
												</div>
											))) :
											allQuestionData.answerType === 'SingleSelect' ?
												(allQuestionData.answers.map((answers, index) => (
													<div className="self__switchContainer" key={index + 1}>
														<label className="switch">
															<input
																id="active"
																type="checkbox"
																className="checkbox"
																name="active"
																checked={multipleAnswer.indexOf(answers.answerOptionId) !== -1}
																onChange={() => {
																	setNoInputError('')
																	setMultipleAnswer([answers.answerOptionId])
																	setSingleAnswer([answers.answerOptionId])
																}}
															/>
															<span className="slider round" />
														</label>
														<h3 className="switchbox-text">{answers.displayText}</h3>
													</div>
												))
												) : allQuestionData.answerType === 'FreeText' ?
													<div className="bmiSection1">
														<div style={{ display: 'flex', marginTop: '1rem' }}>
															<input
																type='text'
																value={multipleAnswer}
																onChange={(e) => {
																	setSingleAnswer([e.target.value])
																	setMultipleAnswer([e.target.value])
																	setNoInputError('')
																}}
																style={{ width: '30%', height: '4rem' }}
																className="modal_input--box"
															/>

														</div>
													</div>
													: null
								}
								{
									allQuestionData &&
										allQuestionData.name === 'Anon_Basic_3' ?
										<div className='bmiCalculate'>
											<div className="bmiSection1">
												<h3 className="audioContainer__button--BMItext">About how much do you weigh without shoes?</h3>
												<div style={{ display: 'flex', marginTop: '1rem' }} className='bmi--weight__container'>
													<input
														type='number'
														value={pounds}
														onChange={(e) => {
															setPounds(e.target.value)
															setNoInputError('')
														}}
														style={{ width: '10rem' }}
														className="modal_input--box"
													/>
													<span style={{ marginLeft: '1rem' }}>lbs</span>
												</div>
											</div>
											<div className="bmiSection1">
												<h3 className="audioContainer__button--BMItext">About how tall are you without shoes?</h3>
												<div style={{ display: 'flex' }}>
													<div style={{ display: 'flex', marginTop: '1rem' }}>
														<span style={{ marginRight: '1rem' }}>Feet:</span>
														<select
															className='modal_input--box'
															name='roles'
															id='roles'
															value={feet}
															onChange={(e) => {
																setFeet(e.target.value)
																setNoInputError('')
															}}
															style={{ width: '10rem', marginRight: '1rem', backgroundColor: 'white' }}
														>
															<option value='' >
																Select
															</option>
															<option value='0'>0</option>
															<option value='1'>1</option>
															<option value='2'>2</option>
															<option value='3'>3</option>
															<option value='4'>4</option>
															<option value='5'>5</option>
															<option value='6'>6</option>
															<option value='7'>7</option>
															<option value='8'>8</option>
														</select>
													</div>
													<div style={{ display: 'flex', marginTop: '1rem' }}>
														<span style={{ marginRight: '1rem' }}>Inches:</span>
														<select
															className='modal_input--box'
															name='roles'
															id='roles'
															value={inches}
															onChange={(e) => {
																setInches(e.target.value)
																setNoInputError('')
															}}
															style={{ width: '10rem', marginRight: '1rem', backgroundColor: 'white' }}
														>
															<option value='' >
																Select
															</option>
															<option value='0'>0</option>
															<option value='1'>1</option>
															<option value='2'>2</option>
															<option value='3'>3</option>
															<option value='4'>4</option>
															<option value='5'>5</option>
															<option value='6'>6</option>
															<option value='7'>7</option>
															<option value='8'>8</option>
															<option value='9'>9</option>
															<option value='10'>10</option>
															<option value='11'>11</option>

														</select>
													</div>
												</div>
											</div>
										</div>
										:
										allQuestionData.name === 'Anon_Basic_4' ?
											<div className='bmiCalculate'>
												<div className="bmiSection1">
													<input
														style={{ width: '10rem', marginTop: '1rem' }}
														className="modal_input--box"
														value={bmiDemographic}
														onChange={(e) => {
															setBmiDemographic(e.target.value)
															setNoInputError('')
														}}
													/>
												</div>
											</div>
											: null
								}
								<p className='error_message__deleteClient'>{noInputError}</p>
							</div>
						</div>
						{

							allQuestionData.name === 'Anon_StartNote' && userDetails.phoneNumber == null ?
								<div><div className="form-group mt-5 ">
									<div className='phoneTextBox'>
										<p className="ml-2" htmlFor="IsCellIncluded">
											Please enter your phone number
										</p>
										<input type="text" name="phonenumber" className="form-control width-25"
											placeholder="Phone Number"
											value={requestData.phoneNumber}
											onChange={(e) => {
												setRequestData({ ...requestData, phoneNumber: e.target.value });
												setRequestDataError({
													...requestDataError, phoneNumberError: ""
												})
											}}
										/>
										<p className="error_message" style={{ marginLeft: '1.5rem' }}>{requestDataError.phoneNumberError}</p>
									</div>
								</div>
									<div className="form-group mt-2 phonelabel">
										<input type="checkbox" id="IsCellIncluded" name="IsCellIncluded"
											checked={requestData.iscellincluded}
											onChange={(e) => { setRequestData({ ...requestData, iscellincluded: !requestData.iscellincluded }); }}
											className="mb-6" />
										<label className="ml-2" htmlFor="IsCellIncluded">
											This cell phone contains a camera and can send/upload pictures
										</label>
										<p className="error_message" style={{ marginLeft: '1.5rem' }}>{requestDataError.iscellincludedError}</p>
									</div></div> : ""
						}
						{
							// allQuestionData &&
							// allQuestionData.name !== 'Q_Final' ?

							allQuestionData && (allQuestionData.name === 'Anon_StartNote' || allQuestionData.name === 'Pre_1') ?
								<div className="assessmnet__buttons" style={{ justifyContent: 'center' }}>
									<div className="flex">
										<button
											className="assessmnet__button assessmnet__button--right"
											id='nextBtn'
											onClick={onNextQuestion}
										>
											<span className="assessmnet__button--text">Next</span>
											<div className="assessmnet__button--iconBox">
												<svg className="assessmnet__button--icon">
													<use xlinkHref="/sprite.svg#icon-next" />
												</svg>
											</div>
										</button>
									</div>
								</div>
								: allQuestionData.name === 'Anon_1' ?
									<div className="assessmnet__buttons" style={{ justifyContent: 'space-between' }}>
										<div className="assessment_buttonContainer">
											<button
												id='prevBtn'
												className="assessmnet__button assessmnet__button--left"
												onClick={prev}
											>
												<div className="assessmnet__button--iconBox">
													<svg className="assessmnet__button--icon">
														<use xlinkHref="/sprite.svg#icon-previous" />
													</svg>
												</div>
												<span className="assessmnet__button--text">Previous</span>
											</button>

											<button
												id='nextBtn'
												className="assessmnet__button assessmnet__button--right"
												onClick={handleBenz}
											>
												<span className="assessmnet__button--text">Next</span>
												<div className="assessmnet__button--iconBox">
													<svg className="assessmnet__button--icon">
														<use xlinkHref="/sprite.svg#icon-next" />
													</svg>
												</div>
											</button>
										</div>
										<button
											id='SaveLogoutBtn'
											className="assessmnet__button assessmnet__button--right"
											onClick={() => {
												setPounds("");
												setInches("");
												setFeet("");
												onNextQuestion()
												dispatch(logout())
											}
											}>
											<span className="assessmnet__button--text">Save & Logout</span>
											<div className="assessmnet__button--iconBox">
												<svg className="assessmnet__button--icon">
													<use xlinkHref="/sprite.svg#icon-save" />
												</svg>
											</div>
										</button>
									</div>
									: allQuestionData.name === 'Q_Final' ?
										<React.Fragment>
											<div className="assessmnet__buttons--finalQuestion" style={{
												flexDirection:'column'
											}}>
												<div>
													<label>Would you like to set a reminder to complete your next assessment?</label>
												</div>
												<div className="patient__flex-update-form date-flex"  >
													<div className="formgroup--validation"
														style={{
															alignItems: 'start'
														}}>
														<div className="__input" style={{
															maxWidth: '35rem',
															minWidth: '25vh'
														}}
														>

															<div className='patient_form_dob'>
																<DatePicker
																	key="reminderDate"
																	minDate={new Date(moment().utc())} 
																	renderCustomHeader={({
																		date,
																		changeYear,
																		changeMonth,
																		decreaseMonth,
																		increaseMonth,
																		prevMonthButtonDisabled,
																		nextMonthButtonDisabled,
																	}) => (
																		<div
																			style={{
																				margin: 10,
																				display: "flex",
																				justifyContent: "center"
																			}}
																		>
																			<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
																				{"<"}
																			</button>
																			<select
																				value={getYear(date)}
																				onChange={({ target: { value } }) => changeYear(value)}
																			>
																				{years.map((option) => (
																					<option key={option} value={option}>
																						{option}
																					</option>
																				))}
																			</select>

																			<select
																				value={months[getMonth(date)]}
																				onChange={({ target: { value } }) =>
																					changeMonth(months.indexOf(value))
																				}
																			>
																				{months.map((option) => (
																					<option key={option} value={option}>
																						{option}
																					</option>
																				))}
																			</select>

																			<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
																				{">"}
																			</button>
																		</div>
																	)}
																	selected={startDate}
																	dateFormat="MM-dd-yyyy"
																	ref={(r) => {
																		setComponent(r);
																	}}
																	onChange={(date) => {
																		setStartDate(date);
																		setReminderDate({
																			value: moment(date).format("YYYY-MM-DD"),
																			error: ''
																		});
																		onChange(date) 
																	}}
																	className="loginContainer__formgroup--input"
																	placeholderText="Select reminder date"
																/>
																<svg
																	className="patient_form_dob_icon"
																	onClick={() => {
																		setFocus(focus ? false : true);
																		component.setOpen(focus);
																	}}
																>
																	<use xlinkHref="/sprite.svg#icon-calendar" />
																</svg>
															</div>
														</div>
														<p className="error_message" >
															{reminderDate.error}
														</p>
													</div>
												</div> 
											</div>
											<div className="assessmnet__buttons--finalQuestion" > 
												<button
													id='SaveSubmitBtn'
													className="assessmnet__button assessmnet__button--right"
													onClick={() => {
														setPounds("");
														setInches("");
														setFeet("");
														onNextQuestion()
														dispatch(logout())
													}
													}>
													<span className="assessmnet__button--text">Submit</span>
													<div className="assessmnet__button--iconBox">
														<svg className="assessmnet__button--icon">
															<use xlinkHref="/sprite.svg#icon-save" />
														</svg>
													</div>
												</button>
											</div>
										</React.Fragment>
										:
										<div className="assessmnet__buttons" style={{ justifyContent: 'space-between' }}>
											<div className="assessment_buttonContainer">
												<button
													id='prevBtn'
													className="assessmnet__button assessmnet__button--left"
													onClick={onPreviousQuestion}
												>
													<div className="assessmnet__button--iconBox">
														<svg className="assessmnet__button--icon">
															<use xlinkHref="/sprite.svg#icon-previous" />
														</svg>
													</div>
													<span className="assessmnet__button--text">Previous</span>
												</button>

												<button
													id='nextBtn'
													className="assessmnet__button assessmnet__button--right"
													onClick={onNextQuestion}
												>
													<span className="assessmnet__button--text">Next</span>
													<div className="assessmnet__button--iconBox">
														<svg className="assessmnet__button--icon">
															<use xlinkHref="/sprite.svg#icon-next" />
														</svg>
													</div>
												</button>
											</div>
											<button
												id='SaveSubmitBtn'
												className="assessmnet__button assessmnet__button--right"
												onClick={() => {
													setPounds("");
													setInches("");
													setFeet("");
													onNextQuestion()
													dispatch(logout())
												}
												}>
												<span className="assessmnet__button--text">Save & Logout</span>
												<div className="assessmnet__button--iconBox">
													<svg className="assessmnet__button--icon">
														<use xlinkHref="/sprite.svg#icon-save" />
													</svg>
												</div>
											</button>
										</div>

						}

					</Fragment>
				)}
			</div>
		</div>
	);
};

export default Assessment;
