import React from 'react';
import MainSidebar from './Sidebar/MainSidebar';
import Header from './Header';
import { Helmet } from 'react-helmet';

const ContentAdmin = ({ children, headerTitle, currentMenu, addBtn, addBtnText, addBtnIcon, addBtnClick }) => {
	return (
		<main className="container">
			<Helmet>
				<title>Perspectives in Care</title>
			</Helmet> 
			<MainSidebar current={currentMenu} />
			<div className="mainContent">
				<Header
					title={headerTitle}
					addBtn={addBtn}
					addBtnText={addBtnText}
					addBtnIcon={addBtnIcon}
					addBtnClick={addBtnClick}
				/>
				<div className="content3">{children}</div>
			</div>
		</main>
	);
};

export default ContentAdmin;
