import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeadingVideo';
// import { DeleteUserSkeleton } from '../../components/Skeletons/manageUsers';

const PlayVideoModal = ({ data }) => {
	const { playVideoModalState, videoWarningClose, playVideo } = data;

	return (
		<Modal show={playVideoModalState}>
			<ModalHeading
			heading='Warning' onClose={videoWarningClose}
			/>
				<form className='modalContainer__form' style={{padding: '0.5rem'}}>
					<div className='filter__input--labels' >
						<label className='modal__text' style={{textAlign: 'center', lineHeight: '2.5rem'}}>
							The information you are attempting to access is intended <br/>solely for healthcare  providers engaged in the treatment of patients.<br/> <span style={{fontWeight: '500'}}>If you are not a healthcare provider, please do not proceed.</span>
						</label>
					</div>
				</form>

				<div className='modal__actions' style={{display: 'flex', justifyContent: 'center',}}>
					<button className='modal__button modal__button--update' onClick={playVideo}>
						Continue
					</button>
				</div>


		</Modal>

	);
};

export default PlayVideoModal;
