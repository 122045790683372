import {
	GET_ALL_WEBSITES_REQUEST,
	GET_ALL_WEBSITES_SUCCESS,
	GET_ALL_WEBSITES_FAIL,
	GET_ALL_WEBSITES_RESET
} from '../constants/websiteConstants';

export const getAllWesitesReducers = (state = {}, action) => {
	switch (action.type) {
		case GET_ALL_WEBSITES_REQUEST:
			return { loading: true };

		case GET_ALL_WEBSITES_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_ALL_WEBSITES_FAIL:
			return { loading: false, error: action.payload };

		case GET_ALL_WEBSITES_RESET:
			return {};

		default:
			return state;
	}
};
