import axios from "axios";
import {
	SAVE_PDMP_CREDENTIALS_REQUEST,
	SAVE_PDMP_CREDENTIALS_SUCCESS,
	SAVE_PDMP_CREDENTIALS_FAIL,
	GET_PILL_COUNT_FAIL,
	GET_PILL_COUNT_REQUEST,
	GET_PILL_COUNT_SUCCESS,
	REQUEST_PILL_COUNT_REQUEST,
	REQUEST_PILL_COUNT_SUCCESS,
	REQUEST_PILL_COUNT_FAIL,
	REFRESH_PILL_COUNT_REQUEST,
	REFRESH_PILL_COUNT_SUCCESS,
	REFRESH_PILL_COUNT_FAIL,

	DELETE_PILL_COUNT_REQUEST,
	DELETE_PILL_COUNT_SUCCESS,
	DELETE_PILL_COUNT_FAIL,

} from "../constants/PillCountConstants";
import { toast } from 'react-toastify';

export const savepdmpCredentialsAction = (formData) => async (dispatch, getState) => {

	try {
		dispatch({ type: SAVE_PDMP_CREDENTIALS_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/PillCount/savepdmpdetails`, formData, config);

		dispatch({
			type: SAVE_PDMP_CREDENTIALS_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: SAVE_PDMP_CREDENTIALS_FAIL,
			payload: err.response
		});
	}
};

export const getPillCountAction = (formData) => async (dispatch, getState) => {

	try {
		dispatch({ type: GET_PILL_COUNT_REQUEST });

		const { userLogin: { userInfo } } = getState();
		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/PillCount/GetMedicineByPatientId`, formData, config);
		dispatch({
			type: GET_PILL_COUNT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: GET_PILL_COUNT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};



export const refreshPillCountAction = (patientId) => async (dispatch, getState) => {

	try {
		dispatch({ type: REFRESH_PILL_COUNT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.get(`${userInfo.apiurl}/PillCount/RefreshMedicineByPatientId?patientId=${patientId}`, config);

		dispatch({
			type: REFRESH_PILL_COUNT_SUCCESS,
			payload: data
		});
	} catch (err) {
		dispatch({
			type: REFRESH_PILL_COUNT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
		toast.error(err.response.data.message);
	}
};




export const deletePillCountAction = (formData) => async (dispatch, getState) => {

	try {
		dispatch({ type: DELETE_PILL_COUNT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/PillCount/DeletePillCountRequest`, formData, config);

		dispatch({
			type: DELETE_PILL_COUNT_SUCCESS,
			payload: data
		});
		toast.success("Pill count requested has been deleted");
	} catch (err) {
		dispatch({
			type: DELETE_PILL_COUNT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
		toast.error(err.response.data.message);
	}
};






export const sendPillCountRequestAction = (formData) => async (dispatch, getState) => {
	try {
		dispatch({ type: REQUEST_PILL_COUNT_REQUEST });

		const { userLogin: { userInfo } } = getState();

		const config = {
			headers: {
				Authorization: `Bearer ${userInfo.token}`,
				'custom-clinicid-header': userInfo.clinicid,
			}
		};

		const { data } = await axios.post(`${userInfo.apiurl}/PillCount/SendPillCountRequest`, formData, config);

		dispatch({
			type: REQUEST_PILL_COUNT_SUCCESS,
			payload: data
		});
		toast.success(data.message);
	} catch (err) {
		toast.error(err.response.data.message);
		dispatch({
			type: REQUEST_PILL_COUNT_FAIL,
			payload: err.response && err.response.data.msg ? err.response.data.msg : err.message
		});
	}
};
