import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Content from '../../components/Content';
import ContentTable from './SchedulerTable';

import './ContentHistory.css';

import {
	uploadHistoryAction
} from '../../redux/actions/userActions';

const ContentHistory = () => {
	const dispatch = useDispatch();

	const uploadHistory = useSelector((state) => state.uploadHistory);
	const items = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14 ];

	useEffect(
		() => {
			var formData = {
				providerid: '',
				role: '',
				searchText: ''
			}
			dispatch(uploadHistoryAction(formData));

		},
		[ dispatch ]
	);

	return (
		<Content currentMenu='contents' headerTitle='Upload Histories'>
			<ContentTable
			items={items}
			data= {{uploadHistory}}
			/>
		</Content>
	);
};

export default ContentHistory;
