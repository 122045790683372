import {
	REGISTER_CLINIC_REQUEST,
	REGISTER_CLINIC_SUCCESS,
	REGISTER_CLINIC_FAIL,
	REGISTER_CLINIC_RESET,
	INVITE_CLINIC_REQUEST,
	INVITE_CLINIC_SUCCESS,
	INVITE_CLINIC_FAIL,
	INVITE_CLINIC_RESET,
	ADD_INVITE_CLINIC_REQUEST,
	ADD_INVITE_CLINIC_SUCCESS,
	ADD_INVITE_CLINIC_FAIL,
	ADD_INVITE_CLINIC_RESET,
	DELETE_INVITE_CLINIC_REQUEST,
	DELETE_INVITE_CLINIC_SUCCESS,
	DELETE_INVITE_CLINIC_FAIL,
	DELETE_INVITE_CLINIC_RESET,
	GET_CLINIC_PROVIDERS_REQUEST,
	GET_CLINIC_PROVIDERS_SUCCESS,
	GET_CLINIC_PROVIDERS_FAIL,
	GET_CLINIC_PROVIDERS_RESET,
	GET_CLINICS_REQUEST,
	GET_CLINICS_SUCCESS,
	GET_CLINICS_FAIL,
	GET_CLINICS_RESET,
	GET_PROVIDER_BY_ID_REQUEST,
	GET_PROVIDER_BY_ID_SUCCESS,
	GET_PROVIDER_BY_ID_FAIL,
	GET_PROVIDER_BY_ID_RESET,
	UPDATE_INVITE_CLINIC_REQUEST,
	UPDATE_INVITE_CLINIC_SUCCESS,
	UPDATE_INVITE_CLINIC_FAIL,
	UPDATE_INVITE_CLINIC_RESET,
	ALL_CLINICS_TOGGLE_REQUEST,
	ALL_CLINICS_TOGGLE_SUCCESS,
	ALL_CLINICS_TOGGLE_FAIL,
	ALL_CLINICS_TOGGLE_RESET,
	PROVIDER_CLINICS_TOGGLE_REQUEST,
	PROVIDER_CLINICS_TOGGLE_SUCCESS,
	PROVIDER_CLINICS_TOGGLE_FAIL,
	PROVIDER_CLINICS_TOGGLE_RESET,
	HIGHEST_ROLE_SUCCESS,
	HIGHEST_ROLE_RESET,
	REGISTER_CLINIC_NO_INVITE_REQUEST,
	REGISTER_CLINIC_NO_INVITE_SUCCESS,
	REGISTER_CLINIC_NO_INVITE_FAIL,
	REGISTER_CLINIC_NO_INVITE_RESET,
	REGISTER_CLINIC_INITIAL_REQUEST,
	REGISTER_CLINIC_INITIAL_SUCCESS,
	REGISTER_CLINIC_INITIAL_FAIL,
	REGISTER_CLINIC_INITIAL_RESET,
	REGISTER_CLINIC_USER_REQUEST,
	REGISTER_CLINIC_USER_SUCCESS,
	REGISTER_CLINIC_USER_FAIL,
	REGISTER_CLINIC_USER_RESET,
	CLINIC_SIGN_UP_REQUEST,
	CLINIC_SIGN_UP_SUCCESS,
	CLINIC_SIGN_UP_FAIL,
	CLINIC_SIGN_UP_RESET,
	GET_NPI_NUMBER_DETAIL_REQUEST,
	GET_NPI_NUMBER_DETAIL_SUCCESS,
	GET_NPI_NUMBER_DETAIL_FAIL,
	GET_NPI_NUMBER_DETAIL_RESET,
	GET_NOTIFICATION_TEMP_REQUEST,
	GET_NOTIFICATION_TEMP_SUCCESS,
	GET_NOTIFICATION_TEMP_FAIL,
	GET_NOTIFICATION_TEMP_RESET,

} from '../constants/clinicsConstants';

export const registerAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_CLINIC_REQUEST:
			return { loading: true };

		case REGISTER_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case REGISTER_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const registerClinicInitialAdminReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_CLINIC_INITIAL_REQUEST:
			return { loading: true };

		case REGISTER_CLINIC_INITIAL_SUCCESS:
			return { data: action.payload, loading: false };

		case REGISTER_CLINIC_INITIAL_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_CLINIC_INITIAL_RESET:
			return {};

		default:
			return state;
	}
};

export const registerClinicNoInviteReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_CLINIC_NO_INVITE_REQUEST:
			return { loading: true };

		case REGISTER_CLINIC_NO_INVITE_SUCCESS:
			return { data: action.payload, loading: false };

		case REGISTER_CLINIC_NO_INVITE_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_CLINIC_NO_INVITE_RESET:
			return {};

		default:
			return state;
	}
};
export const clinicSignUpReducer = (state = {}, action) => {
	switch (action.type) {
		case CLINIC_SIGN_UP_REQUEST:
			return { loading: true };

		case CLINIC_SIGN_UP_SUCCESS:
			return { data: action.payload, loading: false };

		case CLINIC_SIGN_UP_FAIL:
			return { loading: false, error: action.payload };

		case CLINIC_SIGN_UP_RESET:
			return {};

		default:
			return state;
	}
};
export const registerClinicUserReducer = (state = {}, action) => {
	switch (action.type) {
		case REGISTER_CLINIC_USER_REQUEST:
			return { loading: true };

		case REGISTER_CLINIC_USER_SUCCESS:
			return { data: action.payload, loading: false };

		case REGISTER_CLINIC_USER_FAIL:
			return { loading: false, error: action.payload };

		case REGISTER_CLINIC_USER_RESET:
			return {};

		default:
			return state;
	}
};

export const providerClinicsToggleReducer = (state = {}, action) => {
	switch (action.type) {
		case PROVIDER_CLINICS_TOGGLE_REQUEST:
			return { loading: true };
		case PROVIDER_CLINICS_TOGGLE_SUCCESS:
			return { data: action.payload, loading: false };
		case PROVIDER_CLINICS_TOGGLE_FAIL:
			return { loading: false, error: action.payload };
		case PROVIDER_CLINICS_TOGGLE_RESET:
			return {};
		default:
			return state;
	}
};

export const allClinicsToggleReducer = (state = {}, action) => {
	switch (action.type) {
		case ALL_CLINICS_TOGGLE_REQUEST:
			return { loading: true };
		case ALL_CLINICS_TOGGLE_SUCCESS:
			return { data: action.payload, loading: false };
		case ALL_CLINICS_TOGGLE_FAIL:
			return { loading: false, error: action.payload };
		case ALL_CLINICS_TOGGLE_RESET:
			return {};
		default:
			return state;
	}
};
export const inviteClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case INVITE_CLINIC_REQUEST:
			return { loading: true };

		case INVITE_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case INVITE_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case INVITE_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const addInviteClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case ADD_INVITE_CLINIC_REQUEST:
			return { loading: true };

		case ADD_INVITE_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case ADD_INVITE_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case ADD_INVITE_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const deleteInviteClinicReducer = (state = {}, action) => {
	switch (action.type) {
		case DELETE_INVITE_CLINIC_REQUEST:
			return { loading: true };

		case DELETE_INVITE_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case DELETE_INVITE_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case DELETE_INVITE_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};

export const clinicProvidersReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CLINIC_PROVIDERS_REQUEST:
			return { loading: true };

		case GET_CLINIC_PROVIDERS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_CLINIC_PROVIDERS_FAIL:
			return { loading: false, error: action.payload };

		case GET_CLINIC_PROVIDERS_RESET:
			return {};

		default:
			return state;
	}
};

export const clinicsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_CLINICS_REQUEST:
			return { loading: true };

		case GET_CLINICS_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_CLINICS_FAIL:
			return { loading: false, error: action.payload };

		case GET_CLINICS_RESET:
			return {};

		default:
			return state;
	}
};

export const clinicProviderByIdReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_PROVIDER_BY_ID_REQUEST:
			return { loading: true };

		case GET_PROVIDER_BY_ID_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_PROVIDER_BY_ID_FAIL:
			return { loading: false, error: action.payload };

		case GET_PROVIDER_BY_ID_RESET:
			return {};

		default:
			return state;
	}
};
export const updateEmailClincReducer = (state = {}, action) => {
	switch (action.type) {
		case UPDATE_INVITE_CLINIC_REQUEST:
			return { loading: true };

		case UPDATE_INVITE_CLINIC_SUCCESS:
			return { data: action.payload, loading: false };

		case UPDATE_INVITE_CLINIC_FAIL:
			return { loading: false, error: action.payload };

		case UPDATE_INVITE_CLINIC_RESET:
			return {};

		default:
			return state;
	}
};
export const highestRoleReducer = (state = {}, action) => {
	switch (action.type) {

		case HIGHEST_ROLE_SUCCESS:
			return { data: action.payload, loading: false };


		case HIGHEST_ROLE_RESET:
			return {};

		default:
			return state;
	}
};


export const npiDetailsReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_NPI_NUMBER_DETAIL_REQUEST:
			return { loading: true };

		case GET_NPI_NUMBER_DETAIL_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_NPI_NUMBER_DETAIL_FAIL:
			return { loading: false, error: action.payload };

		case GET_NPI_NUMBER_DETAIL_RESET:
			return {};

		default:
			return state;
	}
};



export const notficationTempReducer = (state = {}, action) => {
	switch (action.type) {
		case GET_NOTIFICATION_TEMP_REQUEST:
			return { loading: true };

		case GET_NOTIFICATION_TEMP_SUCCESS:
			return { data: action.payload, loading: false };

		case GET_NOTIFICATION_TEMP_FAIL:
			return { loading: false, error: action.payload };

		case GET_NOTIFICATION_TEMP_RESET:
			return {};

		default:
			return state;
	}
};