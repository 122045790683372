import React from 'react'; 

const NotificationItem = ({ title, subTitle, date, read }) => {
  return (
    // <Link to='#!'
    <div className={read ? `dropdown_link` : `dropdown_link unread`}>
      {title}
      <span className='dropdown_link_msg'>{subTitle}</span>
      <span className='dropdown_link_time'>Received on {date}</span>
    </div>
  );
};

export default NotificationItem;
