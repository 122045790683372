import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import ReactPaginate from 'react-paginate';
import TableRows from '../../components/Skeletons/TableRows';
import {
	getPillCountAction,
	refreshPillCountAction,
	sendPillCountRequestAction,
	deletePillCountAction
} from '../../redux/actions/PillCountActions';
import './PillCountScreen.css';
import FilterPillCount from './FilterPillCount';
import { toast } from 'react-toastify';
import { REFRESH_PILL_COUNT_RESET, REQUEST_PILL_COUNT_RESET } from '../../redux/constants/PillCountConstants';
import axios from 'axios';
import { BASE_URL_LOGIN } from '../../redux/actions/ip';
//import { deleteUserAction } from "../../redux/actions/providerActions";

const PillCountScreen = ({ match }) => {
	const dispatch = useDispatch();
	const [userIndex, setUserIndex] = useState(1);
	const [pillCountsList, setPillCountsList] = useState([]);
	const getPillCounts = useSelector((state) => state.getPillCounts); //map data
	const refreshPillCount = useSelector((state) => state.refreshPillCount); //map data
	const requestPillCount = useSelector((state) => state.requestPillCount); //map data
	const userLogin = useSelector((state) => state.userLogin.userInfo);

	//const deletePillCount = useSelector((state)=>state.deletePillCountReducer)

	const [totalPillCountList, setTotalPillCountList] = useState([]);
	const perPageList = 10;
	const totalPages = totalPillCountList / perPageList;

	const [showFilter, setShowFilter] = useState(true);
	const [result, setResult] = useState(true);
	const [filterData, setFilterData] = useState({
		firstname: null,
		lastname: null,
		dateofbirth: null,
		phonenumber: null,
		patientid: match.params.userId
	});

	const config = {
		headers: {
			Authorization: `Bearer ${userLogin.token}`,
			'custom-clinicid-header': userLogin.clinicid
		}
	};

	useEffect(
		() => {
			if (getPillCounts && getPillCounts.data && getPillCounts.data.length > 0) {
				setPillCountsList(getPillCounts.data.slice(0, perPageList));
				setTotalPillCountList(getPillCounts.data.length);
				axios.get(`${BASE_URL_LOGIN}/Providers/getpdmpprovider/${filterData.patientid}`, config).then((res) => {
					if (res.data.success === true) {
						setResult(true);
					} else {
						setResult(false);
					}
				});
			} else {
				setPillCountsList([]);
			}
		},
		[getPillCounts]
	);

	useEffect(
		() => {
			if (refreshPillCount && refreshPillCount.data && refreshPillCount.data.length > 0) {
				dispatch(
					getPillCountAction({
						firstname: null,
						lastname: null,
						dateofbirth: null,
						phonenumber: null,
						patientid: match.params.userId
					})
				);
				dispatch({ type: REFRESH_PILL_COUNT_RESET });
			}
		},
		[refreshPillCount, dispatch]
	);

	useEffect(
		() => {
			if (
				requestPillCount &&
				requestPillCount.data &&
				!requestPillCount.data.ispdmppasswordexpired &&
				requestPillCount.data.message
			) {
				toast.error(requestPillCount.data.message);
				dispatch({ type: REQUEST_PILL_COUNT_RESET });
			} else if (requestPillCount && requestPillCount.data && requestPillCount.data.message) {
				toast.success(requestPillCount.data.message);
				dispatch({ type: REQUEST_PILL_COUNT_RESET });
				dispatch(getPillCountAction(filterData));
			}
		},
		[refreshPillCount, dispatch]
	);

	useEffect(
		() => {
			dispatch(
				getPillCountAction({
					patientid: match.params.userId
				})
			);
		},
		[dispatch, match]
	);

	let nextContentFrom = 0;
	const handlePageChange = (currentPage) => {
		nextContentFrom = currentPage.selected * perPageList;
		const nextContentTo = currentPage.selected * perPageList + 10;
		setPillCountsList(
			getPillCounts && getPillCounts.data && getPillCounts.data.slice(nextContentFrom, nextContentTo)
		);
		setUserIndex(currentPage.selected * perPageList + 1);
	};

	const handleFilter = () => {
		var formData = {
			firstname: filterData.firstname,
			lastname: filterData.lastname,
			dateofbirth: filterData.dateofbirth,
			patientid: match.params.userId,
			phonenumber: filterData.phonenumber
		};

		dispatch(getPillCountAction(formData));
	};

	const handleClear = () => {
		setFilterData({
			...filterData,
			firstname: null,
			lastname: null,
			dateofbirth: null,
			phonenumber: null
		});
		dispatch(
			getPillCountAction({
				firstname: null,
				lastname: null,
				dateofbirth: null,
				phonenumber: null,
				patientid: match.params.userId
			})
		);
	};

	const handleDelete = (id) => {
		var formData = {
			patientid: match.params.userId,
			MedicineId: id
		};

		dispatch(deletePillCountAction(formData));
	};

	const refreshPillCountHandler = () => {
		dispatch(refreshPillCountAction(match.params.userId));
	};

	const requestPillCountHandler = () => {
		let pillCountsArr = pillCountsList.filter((d) => d.checked).map((obj) => obj.medicineId);
		if (!pillCountsArr || pillCountsArr.length == 0) {
			toast.error("Please select a medication from the list");
		} else {
			var formData = {
				patientid: match.params.userId,
				prescription: pillCountsArr
			};
			dispatch(sendPillCountRequestAction(formData));
		}
	};

	const onChangeHandler = (i) => {
		setPillCountsList(pillCountsList.map((d) => d.checked = false));
		const newArr = [...pillCountsList];
		if (newArr[i].checked) {
			newArr[i].checked = false;
		} else {
			newArr[i].checked = true;
		}
		setPillCountsList(newArr);
	};

	return (
		<Content currentMenu="dashboard" headerTitle="Pill Count">
			<FilterPillCount
				data={{
					showFilter,
					setShowFilter,
					handleClear,
					filterData,
					setFilterData,
					handleFilter,
					refreshPillCountHandler,
					requestPillCountHandler,
					result
				}}
			/>

			<div className="tableContainer" style={{ height: '65vh' }}>
				<table className="table" style={{ borderSpacing: 'none' }}>
					<thead>
						<tr>
							<th>#</th>
							<th>Name</th>
							<th>Date of Birth</th>
							<th>Phone Number</th>
							<th>Medications</th>
							<th>Fill Date</th>
							<th>Quantity</th>
							{/* <th style={{ textAlign: 'center', width: '10rem' }}>
								<button
									className="filter__input--button"
									disabled={pillCountsList.length === 0 ? true : false}
									onClick={() => requestPillCountHandler()}
								>
									Request Pill Count
								</button> 
							</th> */}
							{/* <th>Delete</th>  */}
						</tr>
					</thead>
					<tbody>
						{getPillCounts && !getPillCounts.loading ? pillCountsList && pillCountsList.length > 0 ? (
							pillCountsList.map((user, i) => (
								<tr key={i + 1}>
									<td>{userIndex + i}</td>
									<td>{user.patient_name}</td>
									<td>{user.patient_birth_date}</td>
									<td>{user.phonenumber}</td>
									<td>{user.drug_name}</td>
									<td>{user.fill_date}</td>
									<td>{user.quantity}</td>

									{/* <td><button
                    className="filter__input--button"
                  onClick={() =>handleDelete()}
                  >
                    Remove
                </button></td>*/}

									{/* <td style={{ justifyContent: 'center', display: 'flex' }}>
										<div className="modal__radioButton">
											<input
												type="checkbox"
												id={user.drug_name + i}
												name={user.drug_name + i}
												checked={user.checked}
												className="modal__radioButton--input"
												onChange={() => onChangeHandler(i)}
											/>
											<span className="modal__radioButton--radio" />
										</div>
									</td> */}
									{/* <td>
										<button
											className="table__button table__button--delete"
											onClick={() => handleDelete(user.medicineId)}
										>
											<svg className=" table__button--icon-red">
												<use href="/sprite.svg#icon-delete" />
											</svg>
											<span>Delete</span>
										</button>
									</td> */}
								</tr>
							))
						) : (
							<tr>
								<td colSpan="9">No medications found</td>
							</tr>
						) : (
							<TableRows columns="9" />
						)}
					</tbody>
				</table>
			</div>
			{getPillCounts &&
				getPillCounts.data &&
				getPillCounts.data.length > 10 && (
					<div className="tableContainer--paginater">
						<ReactPaginate
							nextClassName={''}
							previousLabel={'<'}
							nextLabel={'>'}
							pageCount={totalPages}
							onPageChange={handlePageChange}
							marginPagesDisplayed={1}
							pageRangeDisplayed={5}
							containerClassName={'paginationBttns'}
							previousLinkClassName={'previousBttn'}
							nextLinkClassName={'nextBttn'}
							disabledClassName={'paginationDisabled'}
							activeClassName={'paginationActive'}
						/>
					</div>
				)}
		</Content>
	);
};

export default PillCountScreen;
