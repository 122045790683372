import React from 'react';

const ModalTextInput = ({ label, placeholder, value, onChange, error, style, width }) => {
	return (
		<div className='modal_input--deleteClient' style={{ display: 'flex', flexDirection: 'column', width:width }}>
			<label htmlFor={label} className='modal_input--label'>
				{label}
			</label>
			<input
				type='text'
				id={label}
				placeholder={placeholder}
				value={value}
				onChange={(e) => onChange(e)}
				className='modal_input--text'
				style={style}
			/>
			{error && <p className='error_message'>{error}</p>}
		</div>
	);
};

export default ModalTextInput;
