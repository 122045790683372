import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { CLINIC_PROVIDER_RESET, EHR_CLINIC_FILTER_RESET } from '../../redux/constants/providerConstants'
import './ModMedScreen.css'
import FilterUsers from './FilterUsers'
import ShowData from './ShowData'
import { ModMedFilterAction } from '../../redux/actions/providerActions'
import Content from '../../components/Content';
import { GetSFTPGenerateByAssessmentIdPDFAction, logout, updateModMedIdAction } from '../../redux/actions/userActions';
import UpdateModeMedModal from '../HomeScreen/Modals/UpdateModeMedModal';
import ConfirmationModal from '../HomeScreen/Modals/ModMedConfirmationModal';
import { toast } from 'react-toastify';

const ModMedScreen = ({ history }) => {
    const dispatch = useDispatch();

    const clinicEHRFilter = useSelector((state) => state.clinicEHRFilter);
    const userLogin = useSelector((state) => state.userLogin);
    const activeToken = useSelector((state) => state.activeToken);
    const [usersList, setUsersList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [textMsg, setTextMsg] = useState(false);
    const [loading, setLoading] = useState(false);


    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentUserObject, setCurrentUserObject] = useState({
        applicationUserId: '',
        modMedPatientId: '',
        actionStatus: '',
        assessmentresid: null,
    });
    const [EHRId, setEHRId] = useState({
        value: '',
        error: ''
    });


    const [totalPageSize, setTotalPageSize] = useState(1);
    const [pageNumber, setPageNumber] = useState(1);
    const pageLimit = 10;
    let pageNo = totalPageSize / pageLimit;


    const [showFilter, setShowFilter] = useState(true);

    const [filterData, setFilterData] = useState({
        BirthDate: "",
        FirstName: "",
        LastName: "",
        Email: ""
    })


    useEffect(
        () => {
            if (!activeToken) {
                history.push('/login');
            } else if (!activeToken.token) {
                history.push('/login');
            } else if (
                userLogin &&
                userLogin.userInfo &&
                userLogin.userInfo.applicationUser &&
                userLogin.userInfo.applicationUser.isPatient === true &&
                userLogin.userInfo.applicationUser.isSuperAdmin === false
            ) {
                history.push('/questionaires');
            }
        },
        [activeToken, userLogin, history]
    );


    useEffect(() => {
        const userInfo = localStorage.getItem('userInfo');
        const tokenInfo = localStorage.getItem('token');
        const clientIdInfo = localStorage.getItem('clientId');
        const getMenusInfo = localStorage.getItem('getMenus');
        if (userInfo === "undefined" || userInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        } else if (tokenInfo === "undefined" || tokenInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        } else if (clientIdInfo === "undefined" || clientIdInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        } else if (getMenusInfo === "undefined" || getMenusInfo === undefined) {
            localStorage.removeItem('userInfo');
            localStorage.removeItem('token');
            localStorage.removeItem('clientId');
            localStorage.removeItem('clientUrl');
            localStorage.removeItem('getMenus');
            history.push("/login");
            dispatch(logout())
        }
    }, [dispatch, history])


    useEffect(
        () => {
            if (userLogin && userLogin.userInfo && !userLogin.userInfo.token) {
                dispatch(logout())
                history.push('/login');
            } else if (!localStorage.getItem('userInfo')) {
                dispatch(logout())
                history.push('/login');
            } else {
                let formData = {
                    BirthDate: "",
                    FirstName: "",
                    LastName: "",
                    Email: "",
                    pageNumber: pageNumber,
                    rowsOfPage: pageLimit
                }
                dispatch(ModMedFilterAction(formData))
            }

        }, [userLogin, history, dispatch, pageNumber]
    )

    useEffect(
        () => {
            if (clinicEHRFilter && clinicEHRFilter.data && clinicEHRFilter.data.length > 0) {
                setUsersList(clinicEHRFilter.data);
                setTotalPageSize(clinicEHRFilter.data[0].totalCount);
            } else if (clinicEHRFilter && clinicEHRFilter.data && clinicEHRFilter.data.length === 0) {
                setUsersList(clinicEHRFilter.data);
            } else if (clinicEHRFilter && clinicEHRFilter.error && clinicEHRFilter.error.status === 501) {
                dispatch(logout())
                history.push('/login');
            }
        },
        [clinicEHRFilter, dispatch, history]
    );

    useEffect(() => {

        return () => {
            dispatch({ type: CLINIC_PROVIDER_RESET })
            dispatch({ type: EHR_CLINIC_FILTER_RESET })
        }
    }, [dispatch])


    const handleClear = () => {
        setPageNumber(1);
        setFilterData({
            ...filterData,
            BirthDate: "",
            FirstName: "",
            LastName: "",
            Email: "",
        })
        var formData = {
            BirthDate: "",
            FirstName: "",
            LastName: "",
            Email: "",
            pageNumber: pageNumber,
            rowsOfPage: pageLimit
        }
        dispatch(ModMedFilterAction(formData));

    }

    const handleFilter = async (selectedPage) => {
        let formData = {
            BirthDate: filterData.BirthDate,
            FirstName: filterData.FirstName,
            LastName: filterData.LastName,
            Email: filterData.Email,
            pageNumber: pageNumber,
            rowsOfPage: pageLimit
        }
        formData.pageNumber = selectedPage ? selectedPage : pageNumber;
        formData.rowsOfPage = pageLimit;
        dispatch(ModMedFilterAction(formData));
    }



    const handlePageChange = async (currentPage) => {
        const selectedPage = currentPage.selected;
        handleFilter(selectedPage + 1);
        setPageNumber(selectedPage + 1);
    };


    const handleSubmit = async () => {
        if (showModal) {
            if (!EHRId.value || EHRId.value.trim() === '') {
                setEHRId({ value: EHRId.value, error: 'Please enter valid EHR Id' })
                return
            } else if (EHRId.value && EHRId.value.length < 8) {
                setEHRId({ value: EHRId.value, error: 'Minimum 8 characters required.' })
                return
            } else {
                const data = {
                    applicationUserId: currentUserObject.applicationUserId,
                    modMedPatientId: EHRId.value ? EHRId.value : currentUserObject.modMedPatientId,
                    actionStatus: currentUserObject.actionStatus
                }
                setLoading(true);
                let res = await dispatch(updateModMedIdAction(data));
                if (res.statusCode) {
                    if (currentUserObject.assessmentresid) {
                        let data = await dispatch(GetSFTPGenerateByAssessmentIdPDFAction(currentUserObject.assessmentresid));
                        if (data && data.data && data.data.status) {
                            toast.success(res.message);
                            handleFilter(pageNumber);
                            handleModalClose();
                        } else {
                            toast.success(res.message);
                            handleFilter(pageNumber);
                            handleModalClose();
                        }
                    } else {
                        toast.success(res.message);
                        handleFilter(pageNumber);
                        handleModalClose();
                    }
                }
            }
        } else {
            const data = {
                applicationUserId: currentUserObject.applicationUserId,
                modMedPatientId: EHRId.value ? EHRId.value : currentUserObject.modMedPatientId,
                actionStatus: currentUserObject.actionStatus
            }
            setLoading(true);
            let res = await dispatch(updateModMedIdAction(data));
            if (res.statusCode) {
                if (currentUserObject.assessmentresid) {
                    let data = await dispatch(GetSFTPGenerateByAssessmentIdPDFAction(currentUserObject.assessmentresid));
                    if (data && data.data && data.data.status) {
                        toast.success(res.message);
                        handleFilter(pageNumber);
                        handleModalClose();
                    } else {
                        toast.success(res.message);
                        handleFilter(pageNumber);
                        handleModalClose();
                    }
                } else {
                    toast.success(res.message);
                    handleFilter(pageNumber);
                    handleModalClose();
                }
            }
        }
    };

    const handleModalClose = useCallback(() => {
        setEHRId({
            value: '',
            error: ''
        })
        setCurrentUserObject({
            applicationUserId: '',
            modMedPatientId: '',
            actionStatus: '',
            assessmentresid: '',
        });
        setLoading(false);
        setShowModal(false);
        setShowConfirmModal(false);
    }, [])

    const onOptionsChange = useCallback((e, data) => {
        if (e.target.value === 'Yes, this is a match') {
            setTextMsg("Are you sure you want to match <span>" + data.piC_FirstName + " " + data.piC_LastName + "</span> with EHR ID <span>" + data.modMed_Id + "</span>?");
            setCurrentUserObject((prevUserObject) => ({
                ...prevUserObject,
                applicationUserId: data.applicationUserId,
                modMedPatientId: data.modMed_Id,
                actionStatus: e.target.value,
                assessmentresid: data.assessmentresid
            }));
            setShowConfirmModal(true);
        }
        else if (e.target.value === 'No, but I have the EHR Id') {
            setCurrentUserObject((prevUserObject) => ({
                ...prevUserObject,
                applicationUserId: data.applicationUserId,
                modMedPatientId: null,
                actionStatus: e.target.value,
                assessmentresid: data.assessmentresid
            }));
            setShowModal(true)
        }
        else if (e.target.value === 'No, Unknown Patient') {
            setTextMsg("Are you sure <span>" + data.piC_FirstName + " " + data.piC_LastName + "</span> has no match in the EHR?");
            setCurrentUserObject((prevUserObject) => ({
                ...prevUserObject,
                applicationUserId: data.applicationUserId,
                modMedPatientId: "000000",
                actionStatus: e.target.value
            }));
            setShowConfirmModal(true);
        }
    }, [])

    return (
        <Content current="EHR Probable Match" headerTitle="EHR Probable Match" currentMenu="EHR Probable Match" >
            <FilterUsers
                data={{
                    showFilter,
                    setShowFilter,
                    handleClear,
                    filterData,
                    setFilterData,
                    handleFilter
                }}
            />
            <ShowData
                onOptionsChange={onOptionsChange}
                data={{
                    showFilter,
                    usersList,
                    pageNumber
                }}
                loading={clinicEHRFilter && clinicEHRFilter.loading} />

            {clinicEHRFilter &&
                clinicEHRFilter.data &&
                (
                    <div className="tableContainer--paginater">
                        <ReactPaginate
                            nextClassName={''}
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageNo}
                            onPageChange={handlePageChange}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={5}
                            containerClassName={'paginationBttns'}
                            previousLinkClassName={'previousBttn'}
                            nextLinkClassName={'nextBttn'}
                            disabledClassName={'paginationDisabled'}
                            activeClassName={'paginationActive'}
                            forcePage={pageNumber - 1}
                        />
                    </div>
                )}

            <UpdateModeMedModal
                data={{
                    EHRId: EHRId,
                    setEHRId: setEHRId,
                    showModal: showModal,
                    onEditClose: handleModalClose,
                    handleSubmit: handleSubmit,
                    loadingBtn: loading
                }} />

            <ConfirmationModal
                data={{
                    textMsg: textMsg,
                    showModal: showConfirmModal,
                    onEditClose: handleModalClose,
                    handleSubmit: handleSubmit,
                    loadingBtn: loading
                }}
            />
        </Content>
    )
}

export default ModMedScreen
