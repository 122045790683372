import React, { Fragment } from 'react';
import './UnverifiedUsers.css';
import '../HomeScreen/HomeScreen.css'

const Filter = ({ data }) => {
	const {
		showFilter,
		setShowFilter,
		filterEmail,
		setFilterEmail,
		role,
		setRole,
		onClearHandler,
		onFilterSubmitHandler
	} = data;

	return (
		<Fragment>
			{!showFilter ? (
				<div className='filter__header'>
					<button id='filterHide' className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Hide Filter
					</button>
				</div>
			) : (
				<div className='filter__header'>
					<button id='filterShow' className='filter__heading--btn' onClick={() => setShowFilter(!showFilter)}>
						Show Filter
					</button>
				</div>
			)}
			<div className='filter' style={{ height: showFilter ? 0 : '20rem' }}>
				<div className='filter__inputs'>
					<h2 className='filter__heading'>Filter Users</h2>
				</div>
						<div className='unVerifiedfilter__inputs'>
							<div className='filter__input--labels'>
								<label htmlFor='roles' className='filter__input--label'>
									Role
								</label>
								<div className='flex'>
									<div className='filter__input'>
										<select
											className='filter__input--select'
											name='roles'
											id='roles'
											value={role.value}
											onChange={(e) => {
												setRole({ value: e.target.value, error: '' });
											}}>
											<option value='select-role' disabled>
												Select Role
											</option>
											<option value='Patient'>Patient</option>
											<option value='Clinic Provider'>Clinic Provider</option>
											<option value='Clinic Admin'>Clinic Admin</option>
										</select>
									</div>
									<h2 className='unverified-filter-h2'>OR</h2>
								</div>
								{role.error && <p className='error_message'>{role.error}</p>}
							</div>

							<div className='filter__input--labels'>
								<label htmlFor='email' className='filter__input--label'>
									Email
								</label>
								<div className='filter__input'>
									<label htmlFor='email' className='filter__input--iconBox'>
										<svg className='filter__input--icon'>
											<use xlinkHref={`/sprite.svg#icon-email`} />
										</svg>
									</label>
									<input
										type='text'
										id='email'
										placeholder='User Email'
										onChange={(e) => {
											setFilterEmail({ value: e.target.value, error: '' });
										}}
										className='filter__input--text'
									/>
								</div>
								{filterEmail.error && <p className='error_message'>{filterEmail.error}</p>}
							</div>
							<div className='filter__input--buttonContainer'>
								<button id='searchBtn' className='filter__input--button' onClick={onFilterSubmitHandler}>
									Filter
								</button>
								<button  id='clearBtn' className='filter__input--button' onClick={onClearHandler}>
									Clear
								</button>
							</div>

						</div>
			</div>
		</Fragment>
	);
};

export default Filter;
