import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Content from '../../components/Content';
import MyProfileStyle from './MyProfile.module.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { addReminderDateAction, getUserProfile, updateUserDetail } from '../../redux/actions/userActions';
import { getMonthDiff, inputPhoneMasking, unMasking, validateOnlyChacters, validatePhone } from '../../utils/validators';
import moment from 'moment';
import EditProfile from './EditProfile';
import { toast } from 'react-toastify';
import { MyProfile } from '../../components/Skeletons/MyProfile';
import ChangePassword from './ChangePassword';
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import "react-datepicker/dist/react-datepicker.css"; 



const MyProfileScreen = () => {
	const dispatch = useDispatch();
	const userLogin = useSelector((state) => state.userLogin);
	const [userData, setMyProfileData] = React.useState({
		data: {},
		loading: false,
	});
	const [IsCellIncluded, setIsCellIncluded] = React.useState({ value: null, error: '' });
	const [editProfileModal, setEditProfileModal] = React.useState(false);
	const [firstName, setFirstName] = React.useState({ value: '', error: '' });
	const [lastName, setLastName] = React.useState({ value: '', error: '' });
	const [phoneNumber, setPhoneNumber] = React.useState({ value: '', error: '' });
	const [email, setEmail] = React.useState({ value: '', error: '' });
	const [DOB, setDOB] = React.useState({ value: new Date(), error: '' });
	// const [reminderDate, setReminderDate] = React.useState({ value: null, error: '' });


	const [focus, setFocus] = React.useState(true);
	const [startDate, setStartDate] = React.useState(null);
	const [component, setComponent] = React.useState(null);
	const [reminderDate, setReminderDate] = React.useState({
		value: '',
		error: '',
	});
	const years = range(new Date().getFullYear(), getYear(new Date()) + 4, 1);
	const months = [
		"January",
		"February",
		"March",
		"April",
		"May",
		"June",
		"July",
		"August",
		"September",
		"October",
		"November",
		"December",
	];

	const [modalChangePasswordState, setModalChangePasswordState] = useState(false);

	const getData = React.useCallback(async () => {
		if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
			setMyProfileData({ data: {}, loading: true });
			const data = await dispatch(getUserProfile());
			setMyProfileData({ data, loading: false });
			if (data) {
				setFirstName({ value: data?.firstName, error: '' });
				setLastName({ value: data?.lastName, error: '' });
				setDOB({ value: new Date(data?.birthDate), error: '' });
				setEmail({ value: data?.email, error: '' });
				if (data?.reminderDate) {
					setReminderDate({ value: new Date(data?.reminderDate), error: '' });
					setStartDate(new Date(data?.reminderDate))
				}
				if (data?.phoneNumber) {
					setPhoneNumber({ value: inputPhoneMasking(data?.phoneNumber), error: '' });
				}
				if (data.isCellIncluded) {
					setIsCellIncluded({ value: 'Yes', error: '' });
				} else setIsCellIncluded({ value: 'No', error: '' });
			}
		}
	}, [userLogin, dispatch]);


	React.useEffect(() => {
		getData();
	}, [getData]);


	const handleEditModalClose = () => {
		setEditProfileModal(false);
		setModalChangePasswordState(false);
	};

	const handleEditProfile = async () => {
		const unmaskedPhone = unMasking(phoneNumber.value);
		if (firstName.value.trim() === '') {
			setFirstName(prevData => ({
				...prevData,
				error: 'Please enter your first name',
			}));
		} else if (!validateOnlyChacters(firstName.value)) {
			setFirstName(prevData => ({
				...prevData,
				error: 'Please enter valid first name',
			}));
		} else if (lastName.value.trim() === '') {
			setLastName(prevData => ({
				...prevData,
				error: 'Please enter your last name',
			}));
		} else if (!validateOnlyChacters(lastName.value)) {
			setLastName(prevData => ({
				...prevData,
				error: 'Please enter valid last name',
			}));
		} else if (unmaskedPhone.trim() === '') {
			setPhoneNumber(prevData => ({
				...prevData,
				error: 'Please enter your phone number',
			}));
		} else if (!validatePhone(unmaskedPhone)) {
			setPhoneNumber(prevData => ({
				...prevData,
				error: 'Phone number must be in between 5 to 12 digits',
			}));
		} else if (!DOB) {
			setDOB(prevData => ({
				...prevData,
				error: 'Please select your date of birth',
			}));
		} else if (getMonthDiff(DOB.value) < 12) {
			setDOB(prevData => ({
				...prevData,
				error: ' It seems you’ve entered your date of birth as a day within the last year. Please ensure that you enter your birthday correctly before proceeding.',
			}));
		} else if (!IsCellIncluded.value) {
			setIsCellIncluded(prevValue => ({
				...prevValue,
				error: 'Please answer the question',
			}));
		} else {
			const formData = {
				UserId: userData.data.id,
				firstName: firstName.value,
				lastName: lastName.value,
				birthDate: moment(DOB.value).format("YYYY-MM-DD"),
				phoneNo: unMasking(phoneNumber.value),
				email: userData.data.email,
				isCellIncluded: IsCellIncluded.value === 'Yes' ? true : false,
			};
			const data = await dispatch(updateUserDetail(formData));
			if (data?.statusCode) {
				handleEditModalClose();
				toast.success(data.message, "success");
				getData();
			} else {
				toast.error(data.message, "danger");
			}
		}
	};


	
	const onChange = async (selectedDate) => {
		const formData = {
			// UserId: userDetails.id,
			ReminderDate: moment(selectedDate).format("YYYY-MM-DD"),
		}
		let data = await dispatch(addReminderDateAction(formData));
		if (data?.status) {
			toast.success(data.message);
		} else {
			toast.error(data.message);
		}
	};

	return (
		<Content currentMenu="my-profile" headerTitle="My Profile">
			<div className="profile__content">
				{userData && userData.loading ? (
					<MyProfile />
				) : (
					<div className={MyProfileStyle.profileDetails}>
						<div>
							<svg className='table__icon primary-icon' style={{ width: '6rem', height: '6rem', marginLeft: '3rem' }}>
								<use xlinkHref={`/sprite.svg#icon-user`} />
							</svg>

							{userData && userData.data && (
								<div className={MyProfileStyle.profileCardStats}>
									<h1>PERSONAL INFORMATION</h1>
									<p className={MyProfileStyle.profileName}> {userData?.data.firstName + ' ' + userData?.data.lastName} </p>
									<h6 className={MyProfileStyle.profileContactHeading}>CONTACT INFORMATION</h6>
									{
										userData?.data?.phoneNumber &&
										<div className={MyProfileStyle.profileContact}>
											<div className={MyProfileStyle.profileColumn}>Phone :</div>
											<div>{inputPhoneMasking(userData?.data?.phoneNumber)}</div>
										</div>
									}
									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Email :</div>
										<div>{userData.data.email}</div>
									</div>
									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Date Of Birth :</div>
										<div className={MyProfileStyle.profileColumnValue}>{moment(userData?.data?.birthDate).format('MM-DD-YYYY')}</div>
									</div>
									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Gender :</div>
										<div className={MyProfileStyle.profileColumnValue}>{userData?.data?.gender}</div>
									</div>
									<div className={MyProfileStyle.profileContact}>
										<div className={MyProfileStyle.profileColumn}>Clinic :</div>
										<div className={MyProfileStyle.profileColumnValue}>{userData?.data?.clinic?.name}</div>
									</div>
									{
										userData?.data?.reminderDate &&
										// moment(userData?.data?.reminderDate).format('MM-DD-YYYY') >= moment(new Date()).subtract(2, 'days').format('MM-DD-YYYY') &&
										<div className={MyProfileStyle.profileContact}>
											<div className={MyProfileStyle.profileColumn}>Reminder Date :</div> 
											<div className="patient__flex-update-form date-flex"  >
											<div className="formgroup--validation"
												style={{
													alignItems: 'start'
												}}>
												<div className="__input" style={{
													maxWidth: '35rem',
													minWidth: '25vh'
												}}
												>

													<div className='patient_form_dob'>
														<DatePicker
															key="reminderDate"
															minDate={new Date(moment().utc())}
															renderCustomHeader={({
																date,
																changeYear,
																changeMonth,
																decreaseMonth,
																increaseMonth,
																prevMonthButtonDisabled,
																nextMonthButtonDisabled,
															}) => (
																<div
																	style={{
																		margin: 10,
																		display: "flex",
																		justifyContent: "center"
																	}}
																>
																	<button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
																		{"<"}
																	</button>
																	<select
																		value={getYear(date)}
																		onChange={({ target: { value } }) => changeYear(value)}
																	>
																		{years.map((option) => (
																			<option key={option} value={option}>
																				{option}
																			</option>
																		))}
																	</select>

																	<select
																		value={months[getMonth(date)]}
																		onChange={({ target: { value } }) =>
																			changeMonth(months.indexOf(value))
																		}
																	>
																		{months.map((option) => (
																			<option key={option} value={option}>
																				{option}
																			</option>
																		))}
																	</select>

																	<button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
																		{">"}
																	</button>
																</div>
															)}
															selected={startDate}
															dateFormat="MM-dd-yyyy"
															ref={(r) => {
																setComponent(r);
															}}
															onChange={(date) => {
																setStartDate(date);
																setReminderDate({
																	value: moment(date).format("YYYY-MM-DD"),
																	error: ''
																});
																onChange(date)
															}}
															className="loginContainer__formgroup--input"
															placeholderText="Select reminder date"
														/>
														<svg
															className="patient_form_dob_icon"
															onClick={() => {
																setFocus(focus ? false : true);
																component.setOpen(focus);
															}}
														>
															<use xlinkHref="/sprite.svg#icon-calendar" />
														</svg>
													</div>
												</div>
												<p className="error_message" >
													{reminderDate.error}
												</p>
											</div>
										</div>
										</div>
									} 
								</div>
							)}

							<div className={MyProfileStyle.profileContact} style={{ marginLeft: '2rem' }}>
								<button className='filter__input--button' id='searchBtn' onClick={() => setEditProfileModal(true)}>
									Edit Profile
								</button>
								<button className='filter__input--button' id='clearBtn' onClick={() => setModalChangePasswordState(true)}>
									Change Password
								</button>
							</div>
						</div>

					</div>
				)}
			</div>

			<ChangePassword
				data={{
					userData,
					handleEditModalClose,
					modalChangePasswordState,
					setModalChangePasswordState,
				}}
			/>

			<EditProfile
				data={{
					firstName,
					setFirstName,
					lastName,
					setLastName,
					phoneNumber,
					setPhoneNumber,
					email,
					setEmail,
					DOB,
					setDOB,
					IsCellIncluded,
					setIsCellIncluded,
					handleEditModalClose,
					handleSubmit: handleEditProfile,
					showEditModal: editProfileModal,
					setShowEditModal: setEditProfileModal
				}}
			/>
		</Content>
	);
};

export default MyProfileScreen;
