import React from 'react';

const InviteAdminForm = () => {
	return (
		<div className='filter__content--top1'>
			<div className='filter__input--labels'>
				<div className='filter__input--label-skeleton skeleton' />
				<div className='filter__input--textBox-skeleton skeleton' />
				<div className='filter__input--buttons-skeleton'>
					<div className='filter__input--button-skeleton skeleton' />
				</div>
			</div>
		</div>
	);
};

export default InviteAdminForm;
