import React, { Fragment, useEffect, useState } from 'react';
import './SelfAssessment.css';
import ImageMapper from "react-image-mapper";
import ModalHeading from '../../components/Modal/ModalHeading';
import Modal from '../../components/Modal';
import Rating from 'react-rating';
import useWindowWidth from '../../utils/useCheckMobileScreen';
import ModalForm from '../../components/Modal/ModalForm';
import { InputsSection } from '../../components/Modal/InputsSection';

const ImageMapperBody = ({ data }) => {
	const innerWidth = useWindowWidth();
	// console.log(innerWidth, 'innerWidth');
	//color code
	// Green => #3ab248
	// Yellow => #e5ba1e
	// Red => #b72025
	const {
		allQuestionData,
		setAllQuestionData,
		mapAreas,
		setMapAreas,
		multipleAnswer,
		setMultipleAnswer
	} = data;

	const [query, setQuery] = useState(1);
	const [ratingModalState, setRatingModalState] = useState(false);
	const [area, setArea] = useState(null);

	useEffect(() => {
		if (allQuestionData &&
			allQuestionData.answers &&
			allQuestionData.answerType === 'MultiSelect') {
			allQuestionData.answers.map((answers, i) => {
				mapAreas.areas.map(item => {
					if (item.name == answers.displayText && answers.score > 0) {
						item.value = answers.score;
						item.preFillColor = 4 >= answers.score ? '#3ab248' : answers.score <= 7 ? '#e5ba1e' : '#b72025';
					}
				});
				setMapAreas({ ...mapAreas });
			});
		}
	}, []);

	useEffect(() => {
		setQuery(Math.random());
	}, [mapAreas]);


	const getTipPosition = (area) => {
		setRatingModalState(true);
		setArea(area);
	};

	const updateMapArea = async () => {
		const areas = await mapAreas.areas.map(item =>
			item.id === area.id ? { ...item, value: area.value, preFillColor: area.preFillColor } : item
		);
		setMapAreas({
			name: mapAreas.name,
			areas
		});

		let newArr = allQuestionData.answers;
		areas.map(item => {
			newArr.map((answer, i) => {
				if (item.name.trim() == answer.displayText.trim() && item.value > 0) {
					answer.isSelected = true;
					answer.score = item.value;
				}

				if (answer.isSelected) {
					setMultipleAnswer([...multipleAnswer, answer.answerOptionId]);
				}
			});
			setAllQuestionData({ ...allQuestionData, answers: newArr });
		});
		onRatingModalClose();
	};

	const onDeleteHandler = async () => {
		const areas = await mapAreas.areas.map(item =>
			item.id === area.id ? { ...item, value: 0, preFillColor: "#c0c0c2" } : item
		);
		setMapAreas({
			name: mapAreas.name,
			areas
		});

		let newArr = allQuestionData.answers;
		areas.map(item => {
			newArr.map((answer, i) => {
				if (item.name.trim() == answer.displayText.trim() && item.value == 0) {
					answer.isSelected = false;
					answer.score = 0;
				}
				const array = multipleAnswer.filter((answerId) => answerId !== answer.answerOptionId);
				setMultipleAnswer([...array]);
			});
			setAllQuestionData({ ...allQuestionData, answers: newArr });
		});
		onRatingModalClose();
	};


	const onRatingModalClose = () => {
		setRatingModalState(false);
	};

	// const handleUpdateMapArea = React.useCallback(
	// 	evt =>
	// 		getUpdateMapArea(1, [evt.nativeEvent.layerX, evt.nativeEvent.layerY, 10]),
	// 	[]
	// );

	// const getUpdateMapArea = (id = 1, coords) => {
	// 	console.log(id, coords);

	// 	// const areas = mapAreas.areas.push({ id: id + 1, name: "Rt Jaw", shape: "circle", coords: coords, preFillColor: "#00000" });

	// 	const areas = mapAreas.areas.map(item =>
	// 		item.id === id ? { ...item, coords, preFillColor: "#000000" } : item
	// 	);
	// 	setMapAreas({
	// 		name: mapAreas.name,
	// 		areas
	// 	});
	// };

	return (
		<div className="selfContainer ImageMapperBody">
			<div style={{ position: "relative" }}>
				{innerWidth <= 990 ?
					mapAreas &&
					mapAreas.areas.length > 0 &&
					<div style={{
						display: 'flex',
						flexDirection: 'column'
					}}>
						<div className="painlocationBox">
							<div className="self__switchContainer" style={{ marginBottom: 0 }} >
								<ImageMapper
									src={`/images/bodychart_front.jpg?&q=${query}`}
									// onClick={area => getTipPosition(area)}
									// map={[]}
									width={300}
									lineWidth={1}
									strokeColor={"Black"}
								// onImageClick={handleUpdateMapArea} //important 
								/>
								<div style={{

								}}>
									{
										mapAreas &&
										mapAreas.areas.length > 0 &&
										mapAreas.areas.map((item, i) => (
											item.id <= 23 &&
											<span
												key={i + 1}
												className="tooltip"
												style={{
													top: `${item.top}px`,
													left: `${item.left}px`,
													background: item.preFillColor,
													border: 0,
													height: '20px',
													width: '20px',
													borderRadius: 20,
													textAlign: 'center',
													padding: 0
												}}
												onClick={() => getTipPosition(item)}
											>
												{item.value > 0 ? item.value : ' '}
											</span>))}
								</div>
							</div>
						</div>
						<div className="painlocationBox">
							<div className="self__switchContainer" style={{ marginBottom: 0 }} >
								<ImageMapper
									src={`/images/bodychart_back.jpg?&q=${query}`}
									// onClick={area => getTipPosition(area)}
									// map={[]}
									width={300}
									lineWidth={1}
									strokeColor={"Black"}
								// onImageClick={handleUpdateMapArea} //important 
								/>
								{
									mapAreas &&
									mapAreas.areas.length > 0 &&
									mapAreas.areas.map((item, i) => (
										item.id >= 24 &&
										<span
											key={i + 1}
											className="tooltip"
											style={{
												top: `${item.top}px`,
												left: `${item.left}px`,
												background: item.preFillColor,
												border: 0,
												height: '20px',
												width: '20px',
												borderRadius: 20,
												textAlign: 'center',
												padding: 0
											}}
											onClick={() => getTipPosition(item)}
										>
											{item.value > 0 ? item.value : ' '}
										</span>))}
							</div>
						</div>
					</div>
					:
					<React.Fragment>
						<ImageMapper
							src={`/images/bodychart.jpg?&q=${query}`}
							onClick={area => getTipPosition(area)}
							map={mapAreas}
							width={850}
							lineWidth={1}
							strokeColor={"Black"}
						// onImageClick={handleUpdateMapArea} //important 
						/>
						{
							mapAreas &&
							mapAreas.areas.length > 0 &&
							mapAreas.areas.map((item, i) => (
								<span
									key={i + 1}
									className="tooltip"
									onClick={() => getTipPosition(item)}
									style={{ top: `${item.coords[1]}px`, left: `${item.coords[0]}px` }}
								>
									{item.value > 0 ? item.value : ''}
								</span>))}
					</React.Fragment>
				}
				<Modal show={ratingModalState}>
					<Fragment>
						<ModalHeading
							heading={'Location of Pain - ' + area?.name}
							onClose={onRatingModalClose}
						/>
						<ModalForm>
							<InputsSection style={{ justifyContent: 'inherit',flexDirection:'column' }}>
								<span className="modal__text">Please rate the intensity of your pain from 1-10, with 10 being the worst pain imaginable.</span> 
							<div>
						{/* <form className='modalContainer__form'> */}
							<div className='painlocationContianer'>
								{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) =>
									<span key={item} className='painlocationNumberBox'>
										{item}
									</span>
								)
								}
							</div>
							<Rating
								stop={10}
								emptySymbol={['fa fa-square-o fa-3x high', 'fa fa-square-o fa-3x high',
									'fa fa-square-o fa-3x high', 'fa fa-square-o fa-3x high',
									'fa fa-square-o fa-3x medium', 'fa fa-square-o fa-3x medium',
									'fa fa-square-o fa-3x medium', 'fa fa-square-o fa-3x low',
									'fa fa-square-o fa-3x low', 'fa fa-square-o fa-3x low']}
								fullSymbol={['fa fa-square fa-3x high', 'fa fa-square fa-3x high',
									'fa fa-square fa-3x high', 'fa fa-square fa-3x high',
									'fa fa-square fa-3x medium', 'fa fa-square fa-3x medium',
									'fa fa-square fa-3x medium', 'fa fa-square fa-3x low',
									'fa fa-square fa-3x low', 'fa fa-square fa-3x low']}
								onHover={(rate) => rate}
								onChange={(rate) => setArea({ ...area, value: rate, preFillColor: 4 >= rate ? '#3ab248' : rate <= 7 ? '#e5ba1e' : '#b72025' })}
								initialRating={area && area.value ? area.value : 0}
							/>

						{/* </form> */}
						</div>
						</InputsSection> 
						</ModalForm>
						<div className='modal__actions'>
							<button
								className='modal__button modal__button--update'
								onClick={updateMapArea}
							>
								Submit
							</button>
							<button className='modal__button modal__button--delete' onClick={onDeleteHandler}>
								Delete
							</button>
						</div>
					</Fragment>
				</Modal>
			</div>
		</div>
	);
};

export default ImageMapperBody;
{/* <div key={i + 1} className="self__switchContainer" style={{ marginBottom: 0 }} >
								<input
									id="active"
									type="checkbox"
									className="assessmentCheckbox"
									name="active"
									checked={answers.value > 0 ? true : false}
									onChange={() => {
										getTipPosition(answers);
									}}
								/>
								<h3 className="switchbox-text">{answers.name} <b>{answers.value > 0 && ' ( Pain Scale:' + answers.value + ')'}</b></h3>
							</div> */} 