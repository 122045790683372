import React, { Fragment } from 'react';
import Modal from '../../../components/Modal';
import ModalHeading from '../../../components/Modal/ModalHeading';
import {DeleteUserSkeleton} from '../../../components/Skeletons/manageUsers'

const DeleteUser = ({ data }) => {
	const { modalDeleteState, deleteUserData, deleteEmail, setDeleteEmail, onDeleteModalClose, onDeleteHandler, deleteManageUser } = data;
	
	return (
		<Modal show={modalDeleteState}>
			<ModalHeading
			heading='Delete User' onClose={onDeleteModalClose}
			/>
			{deleteManageUser && deleteManageUser.loading ? (
					<DeleteUserSkeleton />
				) : (
			<Fragment>
				<form className='modalContainer__form'>
					<div className='filter__input--labels'>
						<label htmlFor='email' className='modal__text'>
							Please enter <span>{deleteUserData.patientEmail}</span> in text box
						</label>
						<div className='filter__input'>
							<label htmlFor='email' className='filter__input--iconBox'>
								<svg className='filter__input--icon'>
									<use xlinkHref={`/sprite.svg#icon-email`} />
								</svg>
							</label>
							<input
								type='text'
								id='email'
								placeholder='johndoe@example.com'
								className='filter__input--text'
								value={deleteEmail.value}
								onChange={(event) => {
									setDeleteEmail({ value: event.target.value });
								}}
							/>
						</div>
						{deleteEmail.error && <p className='error_message'>{deleteEmail.error}</p>}
					</div>
				</form>
				<div className='modal__actions'>
					<button className='modal__button modal__button--delete' onClick={onDeleteHandler}>
						Delete
					</button>
				</div>
			</Fragment>
			)}
		</Modal>
	);
};

export default DeleteUser;
