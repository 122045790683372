import React from 'react';
import SubscriptionCardStyle from '../../screens/SubscriptionScreen/SubscriptionCard/SubscriptionCard.module.css';

const SubscriptionCardSkeleton = ({ cards = 3 }) => {
  const [cardNum, setCardNum] = React.useState([]);
  const metas = [1, 3, 4, 2, 8, 6, 5, 7, 9];

  React.useEffect(() => {
    const card = [];
    for (let i = 1; i <= cards; i++) {
      card.push(i);
    }
    setCardNum(card);
  }, [cards]);

  return (
    cardNum.length > 0 &&
    cardNum.map((card) => (
      <div key={card} className={SubscriptionCardStyle.subscription_card}>
        <div className='subscription_title_skeleton skeleton'></div>
        <div className='subscription_price_skeleton skeleton'></div>

        <div
          className={SubscriptionCardStyle.subscription_items}
          style={{ gap: '1.2rem' }}
        >
          {metas.map((meta) => (
            <div key={meta} className={SubscriptionCardStyle.subscription_item}>
              <svg className={SubscriptionCardStyle.subscription_icon}>
                <use xlinkHref='/sprite.svg#icon-check' />
              </svg>
              <div>
                <span
                  className='subscription_meta_skeleton skeleton'
                  style={{ width: `${meta + 10 + 'rem'}` }}
                ></span>
                <span className='subscription_meta2_skeleton skeleton'></span>
              </div>
            </div>
          ))}
        </div>

        <div className='subscription_total_skeleton'>
          <div className='subscription_total_heading_skeleton skeleton'></div>
          <div className='subscription_total_subheading_skeleton skeleton'></div>
          <div
            className='subscription_total_subheading_skeleton skeleton'
            style={{ width: '14rem' }}
          ></div>
        </div>

        <div className={SubscriptionCardStyle.loginContainer__button_layout}>
          <button className='outline__button_skeleton skeleton'>
            CHOOSE PLAN
          </button>
        </div>
      </div>
    ))
  );
};

export default SubscriptionCardSkeleton;
