import axios from 'axios';
import {
  GET_PAYMENT_PRODUCTS_FAIL,
  GET_PAYMENT_PRODUCTS_REQUEST,
  GET_PAYMENT_PRODUCTS_SUCCESS,
} from '../constants/paymentConstants';
import { BASE_URL_LOGIN } from './ip';
import { logout } from './userActions';

//get All Products
export const getProductsListAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PAYMENT_PRODUCTS_REQUEST });

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    let { data } = await axios.get(
      `${BASE_URL_LOGIN}/Stripe/getProductsList`,
      config
    );

    dispatch({
      type: GET_PAYMENT_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    if (err.response?.status === 401) {
      dispatch(logout());
    }
    dispatch({
      type: GET_PAYMENT_PRODUCTS_FAIL,
      payload: err.response,
    });
  }
};

export const validatePromoCodeAction = async (code) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.get(
      `${BASE_URL_LOGIN}/Stripe/promoCode?code=${code}`,
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const addPaymentMethodAction = async (formData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/Stripe/makePayment`,
      formData,
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};

export const confirmPayment = async (formData) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `${BASE_URL_LOGIN}/Stripe/addClinicPaymentDetails`,
      formData,
      config
    );

    return data;
  } catch (err) {
    return err.response && err.response.data ? err.response.data : err.message;
  }
};
