import React from 'react';
import './SimpleButton.css';

const SimpleButton = ({ title, icon, onClick }) => {
	return (
		<button onClick={onClick} className='simpleButton'>
			<svg className='simpleButton__icon'>
				<use xlinkHref={icon} />
			</svg>
			<span>{title}</span>
		</button>
	);
};

export default SimpleButton;
