import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Content from '../../components/Content';
import { savepdmpCredentialsAction } from '../../redux/actions/PillCountActions';
import { SAVE_PDMP_CREDENTIALS_RESET } from '../../redux/constants/PillCountConstants';
import { validateEmail } from '../../utils/validators';
import './pdmp.css'

function Index() {
    const dispatch = useDispatch();

    const savePdmpCredentials = useSelector((state) => state.savePdmpCredentials);

    const [ currentEye, setCurrentEye ] = useState(false);
    const [ confirmCurrentEye, setConfirmCurrentEye ] = useState(false);
    const [formData, setformData] = useState({
        loginId: '',
        password: '',
        cPassword: '',
        state: '',

    })
    const [formDataError, setformDataError] = useState({
        loginId: '',
        password: '',
        cPassword: '',
        state: '',

    })

    useEffect(
		() => {
			if (savePdmpCredentials && savePdmpCredentials.data && savePdmpCredentials.data.succeeded === true) {
				toast.success(savePdmpCredentials.data.message)
                setformData({loginId: '',
                password: '',
                cPassword: '',
                state: ''})
                dispatch({ type: SAVE_PDMP_CREDENTIALS_RESET });
			} else if (savePdmpCredentials && savePdmpCredentials.error && savePdmpCredentials.error.data) {
				toast.error(savePdmpCredentials.error.data)
                setformData({loginId: '',
                password: '',
                cPassword: '',
                state: ''})
                dispatch({ type: SAVE_PDMP_CREDENTIALS_RESET });
			}
		},
		[ savePdmpCredentials, dispatch]
	);
    useEffect(
		() => {
			return () => {
				dispatch({ type: SAVE_PDMP_CREDENTIALS_RESET });
			};
		},
		[ dispatch ]
	);

    const handleRegister=(e)=>{
        e.preventDefault();
        if(!formData.loginId.trim() || formData.loginId.trim() === '') {
			setformDataError({ ...formDataError, loginId: 'Please enter Name' });
		} else if(!validateEmail(formData.loginId)) {
			setformDataError({ ...formDataError, loginId: 'Login Id needs to be an Email'});
		} else if(!formData.password.trim() || formData.password.trim() === '') {
			setformDataError({ ...formDataError, password: 'Please enter Password' });
		} else if(!formData.password.trim() || formData.password.trim() === '') {
			setformDataError({ ...formDataError, password: 'Please enter Password' });
		} else if(!formData.cPassword.trim() || formData.cPassword.trim() === '') {
			setformDataError({ ...formDataError, cPassword: 'Please confirm your Password'});
		} else if(formData.cPassword !== formData.password){
            setformDataError({ ...formDataError, cPassword: 'Passwords do not match'});
        } else if(!formData.state.trim() || formData.state.trim() === '') {
			setformDataError({ ...formDataError, state: 'Please select a State'});
		} else {
            const data = {
                loginuserid: formData.loginId,
                password: formData.password,
                state: formData.state
            }
            dispatch(savepdmpCredentialsAction(data))
        }
    }

    const showPassword = () => {
		var x = document.getElementById('password');
		setCurrentEye(!currentEye);
		if (x.type === 'password') {
			x.type = 'text';
		} else {
			x.type = 'password';
		}
	};
    const showConfirmPassword = () => {
		var y = document.getElementById('confirm-password');
		setConfirmCurrentEye(!confirmCurrentEye);
		if (y.type === 'password') {
			y.type = 'text';
		} else {
			y.type = 'password';
		}
	};
    return (
        <Content
        current="settings" headerTitle="PDMP Credentials" currentMenu='Settings'
        >
            <main className='pdmpMain'>
            {/* <div className='pdmp__bg' /> */}
            <div className='pdmpContainer__box'>
                <div className='pdmpContainer__box--right'>
                    <img src='/images/logo.png' alt='Suremed Logo' className='loginContainer__box--logo' />
                    <h1 className='loginContainer__heading'>PDMP Credentials</h1>
                    <p className='pdmpContainer__subheading'>
                    Please enter your PDMP username and password. All the information on this page will be encrypted. No one in our organization will have access to your credentials.
                    </p>
                </div>
                <div className='loginContainer__box--left'>
                    <form className='pdmpContainer__form'>

                        <div className='addressContainer__formgroup--inputBox'>
                            <div className='logoContainer__formgroup--iconBox'>
                                <svg className='loginContainer__formgroup--icon'>
                                    <use xlinkHref='/sprite.svg#icon-email' />
                                </svg>
                            </div>
                            <input
                                type='text'
                                placeholder='Login User ID'
                                id='address-1'
                                className='loginContainer__formgroup--input'
                                value={formData.loginId}
					            onChange={(event) => {
					            setformData({...formData, loginId: event.target.value });
                                setformDataError('')
					            }}
                            />
                        </div>
                        <p className='error_message' style={{marginLeft: '2rem'}}>{formDataError.loginId}</p>
                        <div className='addressContainer__formgroup--inputBox'>
                            <div className='logoContainer__formgroup--iconBox'>
                                <svg className='loginContainer__formgroup--icon'>
                                    <use xlinkHref='/sprite.svg#icon-key' />
                                </svg>
                            </div>
                            <input
                                type='password'
                                placeholder='Password'
                                id='password'
                                className='loginContainer__formgroup--input'
                                value={formData.password}
					            onChange={(event) => {
					            setformData({...formData, password: event.target.value });
                                setformDataError('')
					            }}
                            />
                            <svg
								style={{ margin: '1rem' }}
								className="loginContainer__formgroup--icon"
								onClick={showPassword}
							>
								{currentEye ? (
									<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
								) : (
									<use xlinkHref={`/sprite.svg#icon-showPassword`} />
								)}
							</svg>
                        </div>
                        <p className='error_message' style={{marginLeft: '2rem'}}>{formDataError.password}</p>
                        <div className='addressContainer__formgroup--inputBox'>
                            <div className='logoContainer__formgroup--iconBox'>
                                <svg className='loginContainer__formgroup--icon'>
                                    <use xlinkHref='/sprite.svg#icon-key' />
                                </svg>
                            </div>
                            <input
                                type='password'
                                placeholder='Password'
                                id='confirm-password'
                                className='loginContainer__formgroup--input'
                                value={formData.cPassword}
					            onChange={(event) => {
					            setformData({...formData, cPassword: event.target.value });
                                setformDataError('')
					            }}
                            />
                            <svg
								style={{ margin: '1rem' }}
								className="loginContainer__formgroup--icon"
								onClick={showConfirmPassword}
							>
								{confirmCurrentEye ? (
									<use xlinkHref={`/sprite.svg#icon-hidePassword`} />
								) : (
									<use xlinkHref={`/sprite.svg#icon-showPassword`} />
								)}
							</svg>
                        </div>
                        <p className='error_message' style={{marginLeft: '2rem'}}>{formDataError.cPassword}</p>
                        <div className='addressContainer__formgroup--inputBox'>
                            <div className='logoContainer__formgroup--iconBox'>
                                <svg className='loginContainer__formgroup--icon'>
                                    <use xlinkHref='/sprite.svg#icon-state' />
                                </svg>
                            </div>
                            <select style={{border: 'none', backgroundColor: 'transparent', width: '100%', marginRight: '1rem'}}
                            value={formData.state}
                            onChange={(event)=>{
                                setformData({...formData, state: event.target.value });
                                setformDataError('')
                            }}
                            >
                                <option value="">State</option>
                                <option value="alabama">Alabama</option>
                                <option value="alaska">Alaska</option>
                                <option value="arizona">Arizona</option>
                                <option value="arkansas">Arkansas</option>
                                <option value="california">California</option>
                                <option value="colorado">Colorado</option>P
                                <option value="connecticut">Connecticut</option>
                                <option value="delaware">Delaware</option>
                                <option value="discrict of columbia">District Of Columbia</option>
                                <option value="florida">Florida</option>
                                <option value="georgia">Georgia</option>
                                <option value="hawaii">Hawaii</option>
                                <option value="idaho">Idaho</option>
                                <option value="illinois">Illinois</option>
                                <option value="indiana">Indiana</option>
                                <option value="iowa">Iowa</option>
                                <option value="kansas">Kansas</option>
                                <option value="kentucky">Kentucky</option>
                                <option value="louisiana">Louisiana</option>
                                <option value="maine">Maine</option>
                                <option value="maryland">Maryland</option>
                                <option value="massachusetts">Massachusetts</option>
                                <option value="michigan">Michigan</option>
                                <option value="minnesota">Minnesota</option>
                                <option value="mississippi">Mississippi</option>
                                <option value="missouri">Missouri</option>
                                <option value="montana">Montana</option>
                                <option value="nebraska">Nebraska</option>
                                <option value="nevada">Nevada</option>
                                <option value="new hampshire">New Hampshire</option>
                                <option value="new jersey">New Jersey</option>
                                <option value="new mexico">New Mexico</option>
                                <option value="new york">New York</option>
                                <option value="north carolina">North Carolina</option>
                                <option value="north dakota">North Dakota</option>
                                <option value="ohio">Ohio</option>
                                <option value="Ooklahoma">Oklahoma</option>
                                <option value="oregon">Oregon</option>
                                <option value="pennsylvania">Pennsylvania</option>
                                <option value="rhode island">Rhode Island</option>
                                <option value="south carolina">South Carolina</option>
                                <option value="south dakota">South Dakota</option>
                                <option value="tennessee">Tennessee</option>
                                <option value="texas">Texas</option>
                                <option value="utah">Utah</option>
                                <option value="vermont">Vermont</option>
                                <option value="virginia">Virginia</option>
                                <option value="washington">Washington</option>
                                <option value="west virginia">West Virginia</option>
                                <option value="wisconsin">Wisconsin</option>
                                <option value="wyoming">Wyoming</option>
                            </select>
                        </div>
                        <p className='error_message' style={{marginLeft: '2rem'}}>{formDataError.state}</p>

                        <div className='registerContainer__formgroup--btn'>
                            <button className='registerContainer__button' onClick={handleRegister}>
                                <span className='loginContainer__button--text'>Save</span>
                                <div className='loginContainer__button--iconBox'>
                                    <svg className='loginContainer__button--icon'>
                                        <use xlinkHref='/sprite.svg#icon-login' />
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </main>


        </Content>
    )
}

export default Index
