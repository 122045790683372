export const validateOnlyChacters = (name) => {
	const re = /^[a-zA-Z \s]+$/;
	return re.test(name);
};

export const validatePassword = (password) => {
	const re = /(?=.*[a-zA-Z0-9])(?=.*[a-zA-Z0-9])(?=.*[0-9])(?=.*[$@$!%*?&#-`^-_()])[A-Za-z\d$@$!%*?&#-`^-_()].{7,}/;
	return re.test(password);
};

export const validateEmail = (email) => {
	const re = /[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
	return re.test(email);
};

export const validatePhone = (phone) => {
	// const re = /^[0-9]{10}$/;
	const re = /^[0-9]{5,12}$/;
	return re.test(phone);
};

export const validateUrl = (url) => {
	const re = /[(http(s)?):(www.)?a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
	return re.test(url);
};

export const validateOnlyNumber = (number) => {
	let re = /^\d+$/;
	return re.test(number);
};

export const getMonthDiff = (birthDateString) => {
	var today = new Date();
	var birthDate = new Date(birthDateString);
	let monthDiff = today.getMonth() - birthDate.getMonth() +
		(12 * (today.getFullYear() - birthDate.getFullYear()));

	return monthDiff;
};

export const getDayDiff = (birthDateString) => { 
	var day_end = new Date();
	var day_start = new Date(birthDateString); 
	let total_days = (day_end - day_start) / (1000 * 60 * 60 * 24); 
	return total_days;
};



export const inputPhoneMasking = (e) => {
	let value = e
	value = value.replace(/\D/g, "")
	value = value.replace(/(\d{3})(\d)/, "$1-$2")
	value = value.replace(/(\d{3})(\d)/, "$1-$2")
	value = value.substr(0, 12)

	return value

};

export const twoDigitMask = (e) => {
	let value = e
	value = value.replace(/\D/g, "")
	value = value.replace(/(\d[0-10]{2})(\d)/, "$1-$2")
	value = value.substr(0, 2)

	return value
}
export const zipCodeMask = (e) => {
	let value = e
	value = value.replace(/\D/g, "")
	value = value.replace(/(\d{5})(\d)/, "$1-$2")
	value = value.substr(0, 5)

	return value
}
export const unMasking = (e) => {
	let value = e
	value = value.replaceAll("-", "")

	return value
}



export const convertDate = (mm, yyyy) => {
	if (mm.toString().length === 1) {
		return '0' + mm + '/' + yyyy;
	} else {
		return mm + '/' + yyyy;
	}
};

export const inputDateMasking = (e) => {
	let value = e;
	value = value.replace(/\D/g, "")
	value = value.replace(/(\d{2})(\d{4})/, "$1/$2")
	value = value.substr(0, 7);
	return value
};

export const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
