import React from 'react';
import Modal from '../../components/Modal';
import ModalHeading from '../../components/Modal/ModalHeading';
import { InputsSection } from '../../components/Modal/InputsSection';
import ModalForm from '../../components/Modal/ModalForm';
import { ModalButtonPrimary } from '../../components/Modal/ModalButton';
import ModalTextBox from '../../components/Modal/ModalTextBox';
import TextAreaInput from '../../components/Modal/TextAreaInput';
import { useSelector } from 'react-redux';
import { AddClientSkeleton } from '../../components/Skeletons/ManageClient';

export const AddAppVersion = ({ data }) => {
	const {
		addModalActive,
		handleAddModalClose,
		handleSubmit, 
		versionTitleAndroid,
		setVersionTitleAndroid,
		versionTitleAndroidError,
		setVersionTitleAndroidError,
		versionTitleIOS,
		setVersionTitleIOS,
		versionTitleIOSError,
		setVersionTitleIOSError,
		appDescription,
		setVersionDescription,
		versionDescriptionError,
		setVersionDescriptionError,
		isCompulsory,
		setIsCompulsory,
		isCompulsoryError,
		setIsCompulsoryError, 
		androidStoreURL,
		setAndroidStoreURL,
		iosStoreURL,
		setIOSStoreURL,
		androidStoreURLError,
		setAndroidStoreURLError,
		iosStoreURLError,
		setIOSStoreURLError
	} = data;
	const createAppVersion = useSelector((state) => state.createAppVersion);


	return (
		<Modal show={addModalActive}>
			<ModalHeading heading="Add New Version" onClose={handleAddModalClose} />
			{
				createAppVersion && createAppVersion.loading ?
					<AddClientSkeleton />
					:
					<ModalForm onSubmit={handleSubmit} style={{ marginBottom: '2.5rem' }}>
						<InputsSection
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								flexDirection: 'row',
								alignItems: 'flex-start'
							}}
						>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-android"
								label="Android Version"
								id="clientName"
								placeholder="eg. 0.0.1"
								value={versionTitleAndroid}
								onChange={(event) => {
									setVersionTitleAndroid(event.target.value);
									setVersionTitleAndroidError('');
								}}
								error={versionTitleAndroidError}
							/>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-apple"
								label="iOS Version"
								id="clientName"
								placeholder="eg. 0.0.1"
								value={versionTitleIOS}
								onChange={(event) => {
									setVersionTitleIOS(event.target.value);
									setVersionTitleIOSError('');
								}}
								error={versionTitleIOSError}
							/>
						</InputsSection>
						<InputsSection
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								flexDirection: 'row',
								alignItems: 'flex-start'
							}}
						>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-android"
								label="Android Store URL"
								id="clientName"
								placeholder="eg, https://play.google.com/"
								value={androidStoreURL}
								onChange={(event) => {
									setAndroidStoreURL(event.target.value);
									setAndroidStoreURLError('');
								}}
								error={androidStoreURLError}
							/>
							<ModalTextBox
								type="text"
								icon="/sprite.svg#icon-apple"
								label="iOS Store URL"
								id="clientName"
								placeholder="eg, https://apps.apple.com/"
								value={iosStoreURL}
								onChange={(event) => {
									setIOSStoreURL(event.target.value);
									setIOSStoreURLError('');
								}}
								error={iosStoreURLError}
							/>
						</InputsSection>

						<InputsSection
							style={{
								display: 'flex',
								justifyContent: 'flex-start',
								flexDirection: 'row',
							}}
						>
							<TextAreaInput
								label='App Description'
								placeholder='Enter App Description'
								style={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}
								value={appDescription}
								onChange={(event) => {
									setVersionDescription(event.target.value);
									setVersionDescriptionError('');
								}}
								error={versionDescriptionError}
							/>
							<div className='attestContainer' style={{ display: "flex", gap: 10 }}>
								<label className='switch'>
									<input
										type='checkbox'
										className='checkbox'
										id='isPOS'
										checked={isCompulsory}
										onChange={() => {
											setIsCompulsory(!isCompulsory);
											setIsCompulsoryError('');
										}}
										name='active'
									/>
									<span className='slider round' />
								</label>
								<label>Compulsory</label>
							</div>
						</InputsSection>
						<ModalButtonPrimary title="Add New Version" onClick={handleSubmit} />
					</ModalForm>
			}

		</Modal>
	);
};
